import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./seccionesAI.css";
import Alert from "../../modal/alert";

class SeccionesAAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idseccion: "",
      cedulaAlumno: "",
      idseccionA: "",
      trimestre: "",
      idmateria: "",
      anoEscolar: [moment().format("YYYY")],
      ianoEscolar: [],
      iseccionesA: [],
      iinasistencia: [],
      ialumnos: [],
      isecciones: [],
      imaterias: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: false,
      xtotales: "",
    };
  }

  limpiar = () => {
    this.setState({
      idseccionP: "",
      elimi: false,
      listaOn: false,
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarAlumnos(e.target.value);
    //this.buscarSeccionesA(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.buscarAlumnos(e.target.value);
  }

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  imprimirI = () => {
    if (
      this.state.trimestre.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.cedulaAlumno.length > 0 &&
      this.state.idseccion.length > 0
    ) {
      let data = new FormData();
      data.append("idseccion", this.state.idseccion);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("trimestre", this.state.trimestre);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteInasistencia.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Inasistencias",
        cuerpoM:
          "Debe seleccionar un Trimestre, un Alumno, un Grado y una Materia.",
      });
    }
  };

  buscarI = () => {
    if (
      this.state.trimestre.length > 0 &&
      // this.state.idmateria.length > 0 &&
      this.state.idseccion.length > 0
    ) {
      let data = new FormData();
      data.append("idseccion", this.state.idseccion);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("trimestre", this.state.trimestre);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      if (this.state.cedulaAlumno === "") {
        data.append("boton", "buscarInasistenciaG");
      } else {
        data.append("boton", "buscarInasistencia");
      }

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/alumnoMaestro.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              xtotales: res.data.variable3,
            });
          } else {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              mensajealerta: true,
              tituloM: "Menu Consultar Inasistencias.",
              cuerpoM: res.data.variable3,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencias.",
        cuerpoM:
          "Debe seleccionar un Trimestre, un Alumno, un Grado y una Materia.",
      });
    }
  };

  eliminar = () => {
    if (this.state.idseccionA.length > 0) {
      let data = new FormData();
      data.append("idseccionesA", this.state.idseccionA);
      data.append("boton", "eliminarSeccionesA");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
          this.buscarSeccionesA(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Grados Alumnos.",
        cuerpoM: "Debe existir un registro para poder eliminar la Sección.",
      });
    }
  };

  buscarSeccionesA = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("anoEscolar", this.state.anoEscolar);
        data.append("cedulaAlumno", e);
        data.append("boton", "buscarSeccionesA");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xisecciones = res.data.variable3;
            let xiseccionesA = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                isecciones: xisecciones,
                iseccionesA: xiseccionesA,
              });
            } else {
              this.setState({
                iseccionesP: [],
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Grados Alumnos.",
          cuerpoM: "Debe existir un Alumno para realizar el proceso.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Seccion Alumnos.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  buscarSeccionesAG = () => {
    if (this.state.anoEscolar.length > 0) {
      let data = new FormData();
      data.append("boton", "buscarSeccionesAG");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xisecciones = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              isecciones: xisecciones,
            });
          } else {
            this.setState({
              isecciones: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencia.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  buscarSecciones = () => {
    let data = new FormData();
    data.append("boton", "buscarSecciones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/secciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiseccionesA = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iseccionesA: xiseccionesA,
          });
        } else {
          this.setState({
            iseccionesA: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosG");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Asignar Grados a Alumnos",
      cuerpoM:
        "Puede que el Grado Asignado ya tenga notas Asociadas, Desea Realmente Eliminar el Registro...",
      idseccionA: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  componentDidMount() {
    //this.buscarSeccionesA();
    //this.buscarAnioEscolar();
    this.buscarSeccionesAG();
    this.buscarMaterias();
    //this.buscarAlumnos(this.state.anoEscolar);
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CONSULTAR INASISTENCIA POR ALUMNO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idseccion"
                  name="idseccion"
                  value={this.state.idseccion}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.isecciones.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-4">
                <select
                  id="cedulaAlumno"
                  name="cedulaAlumno"
                  value={this.state.cedulaAlumno}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Alumno --</option>

                  {this.state.ialumnos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                  <option value="">TODOS LOS ALUMNOS</option>
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                <select
                  id="idmateria"
                  name="idmateria"
                  value={this.state.idmateria}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Materia --</option>
                  {this.state.imaterias.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                  <option value="">TODAS</option>
                </select>
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.buscarI.bind(this)}>
                Buscar
              </button>
              {this.state.cedulaAlumno !== "" && (
                <button className="boton" onClick={this.imprimirI.bind(this)}>
                  Imprimir
                </button>
              )}
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    {this.state.cedulaAlumno.length === 0 && (
                      <th className="titucxc1">ALUMNOS</th>
                    )}
                    <th className="titucxc1">FECHA</th>
                    <th className="titucxc2">MATERIAS</th>
                    <th className="titucxc3">T/INASISTENCIA</th>
                  </tr>
                </thead>

                {this.state.iinasistencia.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      {this.state.cedulaAlumno.length === 0 && (
                        <td className="titucx2">{item.variable2}</td>
                      )}
                      <td className="titucx2">{item.variable3}</td>
                      <td className="titucx3">{item.variable4}</td>
                      <td className="titucx3">{item.variable5}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <span>{this.state.xtotales}</span>
          </div>
        </div>
      </div>
    );
  }
}
export default SeccionesAAction;
