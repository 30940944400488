import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import "./referenciaPago.css";
import Alert from "../../modal/alert";

class ReciboI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recibo: "",
      mensajealerta: false,
      bancoT1: "",
      bancoT2: "",
      bancoT3: "",
      fechaT1: "",
      fechaT2: "",
      fechaT3: "",
      tipoT1: "",
      tipoT2: "",
      tipoT3: "",
    };
  }

  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  limpiar = () => {
    this.setState({
      recibo: "",
      bancoT1: "",
      bancoT2: "",
      bancoT3: "",
      fechaT1: "",
      fechaT2: "",
      fechaT3: "",
      tipoT1: "",
      tipoT2: "",
      tipoT3: "",
      mensajealerta: false,
    });
  };

  consultaReferencia = () => {
    if (this.state.recibo.length > 0) {
      let data = new FormData();
      data.append("referencia", this.state.recibo);
      data.append("boton", "consultaReferenciaT");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              tipoT1: res.data.variable2,
              fechaT1: res.data.variable3,
              bancoT1: res.data.variable4,
              tipoT2: res.data.variable5,
              fechaT2: res.data.variable6,
              bancoT2: res.data.variable7,
              tipoT3: res.data.variable8,
              fechaT3: res.data.variable9,
              bancoT3: res.data.variable10,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Consulta de Referencia de Pagos",
              cuerpoM:
                "No existe ninguna referencia asociada con la indicada, gracias...",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consulta de Referencia de Pagos",
        cuerpoM: "Por favor debe indicar un numero de referencia, gracias...",
      });
    }
  };

  render() {
    //console.log(this.state.token)
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago1 col-12">
          <div className="titulo-pago ">
            <p>MENU CONSULTA DE REFERENCIAS DE PAGO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="contenedor-cedula col-12">
            <label className="labelcedulac">No. Referencia.</label>
            <input
              className="form-control"
              type="text"
              name="recibo"
              id="recibo"
              autoComplete="off"
              onChange={this.onChange.bind(this)}
              value={this.state.recibo.toUpperCase()}
            />
          </div>
          <div className="xxcontenedorcedula1 col-12">
            <button
              className="boton"
              onClick={this.consultaReferencia.bind(this)}
            >
              Consultar
            </button>
            <button
              className="boton"
              onClick={this.limpiar.bind(this)}
            >
              Limpiar
            </button>
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>
          <div className="titulo-pago ">
            <p>DATOS DE LA CONSULTA. </p>
          </div>
          {this.state.tipoT1.length > 0 && (
            <div className="col-12">
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Tipo Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="tipoT1"
                  id="tipoT1"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.tipoT1.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-3">
                <label className="labelcedulac">Fecha Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="fechaT1"
                  id="fechaT1"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.fechaT1.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Banco Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="bancoT1"
                  id="bancoT1"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.bancoT1.toUpperCase()}
                />
              </div>
            </div>
          )}
          {this.state.tipoT2.length > 0 && (
            <div className="col-12">
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Tipo Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="tipoT2"
                  id="tipoT2"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.tipoT2.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-3">
                <label className="labelcedulac">Fecha Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="fechaT2"
                  id="fechaT2"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.fechaT2.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Banco Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="bancoT2"
                  id="bancoT2"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.bancoT2.toUpperCase()}
                />
              </div>
            </div>
          )}
          {this.state.tipoT3.length > 0 && (
            <div className="col-12">
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Tipo Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="tipoT3"
                  id="tipoT3"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.tipoT3.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-3">
                <label className="labelcedulac">Fecha Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="fechaT3"
                  id="fechaT3"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.fechaT3.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Banco Transaccion.</label>
                <input
                  className="form-control"
                  type="text"
                  name="bancoT3"
                  id="bancoT3"
                  autoComplete="off"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.bancoT3.toUpperCase()}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default ReciboI;
