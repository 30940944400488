import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";

//componentes
import "./evaluacionesTotales.css";
import Alert from "../../modal/alert";
import EvaluarTareas from "../evaluarTareas/index";
import CalificarTareas from "../calificarTareas/calificarTareas";
import PublicarNotas from "../publicarNotas/index";

class MostrarTrabajos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      cedulaAlumno: "",
      nombreAlumno: "",
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcionE: "",
      ocultar: "",
      idactividad: "",
      idtareas: "",
      idarchivos: "",
      nombreEvaluacion: "",
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      activarNotaOn: false,
      activarEvaluacionOn: false,
      iactividades: [],
      imaterias: [],
      icursos: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      idescripcionE: [],
      ianoEscolar: [moment().format("YYYY")],
      itrabajoAlumnos: [],
      trimestre: "",
      tipocierre: "NO",
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      cedulaAlumno: "",
      nombreAlumno: "",
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcionE: "",
      trimestre: "",
      ocultar: "",
      idactividad: "",
      idarchivos: "",
      nombreEvaluacion: "",
      imaterias: [],
      idescripcionE: [],
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
      activarNotaOn: false,
      activarEvaluacionOn: false,
      tipocierre: "NO",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange1(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        tipocierre: "SI",
      });
    } else {
      this.setState({
        tipocierre: "NO",
      });
    }
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarProfesor();
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "idcurso") this.buscarPlanE(e.target.value);
    if (index === "trimestre") this.buscarTrimestre(e.target.value);
    if (index === "idmateria") this.buscarDE();
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.iactividades.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.iactividades.indexOf(itemfind)
        : 0;
    //console.log(index)
    let nombreA = this.state.iactividades[index].variable5;
    //console.log("nombre Actividad: "+nombreA)
    //console.log("idmateria: "+xidmateria)
    this.setState({
      [e.target.name]: e.target.value,
      nombreEvaluacion: nombreA,
    });
  };

  buscarTrimestre = (e) => {
    if (this.props.usuario1.nivel === "3") {
      let data = new FormData();
      data.append("codigo", e);
      data.append("tipocierre", this.state.tipocierre);
      data.append("boton", "consultarActivo");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/activarTrimestre.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
        } else {
          this.setState({
            trimestre: "",
            cedulaAlumno: "",
            mensajealerta: true,
            tituloM: "Menu Evaluaciones Creadas.",
            cuerpoM:
              "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
          });
        }
      });
    }
  };

  consultar() {
    if (
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.descripcionE.length > 0
    ) {
      this.setState({ itrabajoAlumnos: [] });
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idcurso", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("trimestre", this.state.trimestre);
      data.append("descripcion", this.state.descripcionE);

      if (this.props.usuario1.nivel === "3") {
        data.append("boton", "consultarAdjuntosAlumnos5");
      } else {
        data.append("boton", "consultarAdjuntosAlumnos5");
      }

      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/terapias/backend/subirTareas.php",
        data: data,
      }).then((res) => {
        let xitrabajoAlumnos = res.data.variable2;
        this.setState({
          itrabajoAlumnos: [],
          itrabajoAlumnos: xitrabajoAlumnos,
        });
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consulta Evaluaciones Creadas",
        cuerpoM:
          "Debe seleccionar Grado, Materia, trimestre y el tipo de Evaluación.",
      });
    }
  }

  consultar2() {
    if (this.state.idtareas > 0) {
      this.setState({ itrabajoAlumnos: [] });
      let data = new FormData();
      data.append("idactividad", this.state.idtareas);
      data.append("boton", "consultarAdjuntosAlumnos4");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/terapias/backend/subirTareas.php",
        data: data,
      }).then((res) => {
        let xitrabajoAlumnos = res.data.variable2;
        this.setState({
          itrabajoAlumnos: xitrabajoAlumnos,
        });
      });
    }
  }

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarFPI = (e) => {
    let form = new FormData();
    form.append("idfechaPlanificacion", e);
    form.append("boton", "buscarFPI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          tipoPlanificacion: res.data.variable2,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Planificacion",
          cuerpoM:
            "Debe comunicarse con la coordinacion e indicarle que el tipo de planificacion que usted intenta generar no tiene asignada el tipo de Planificacion (SEMANAL o MENSUAL).",
        });
      }
    });
  };

  buscarFP = (e) => {
    let form = new FormData();
    form.append("idgrado", e);
    form.append("boton", "buscarFP");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xifechaPlani = res.data.variable2;
      this.setState({
        ifechaPlani: xifechaPlani,
        idfechaPlani: "",
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    //console.log(this.props.usuario1.nivel);
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    if (this.props.usuario1.nivel === "3") {
      form.append("boton", "buscarPersonal3");
    } else {
      form.append("boton", "buscarPersonal2");
    }
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      if (this.props.usuario1.nivel === "3") {
        let iprofesor = res.data.variable1;
        iprofesor.map((item, i) => {
          this.setState({ idprofesor: item.variable1 });
          this.buscarCurso(item.variable1);
        });
      } else {
        let xiprofesor = res.data.variable1;
        this.setState({ iprofesor: xiprofesor });
      }
    });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              idmateria: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Actividad.",
      cuerpoM: "Realmente Quiere Eliminar la Actividad...",
      idactividad: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  EvaluarTrabajo = (e, f, x) => {
    if (this.state.activarEvaluacionOn) {
      this.setState({
        activarEvaluacionOn: false,
        idarchivos: "",
        cedulaAlumno: "",
        nombreAlumno: "",
      });
    } else {
      this.setState({
        activarEvaluacionOn: true,
        idarchivos: e,
        nombreAlumno: f,
        cedulaAlumno: x,
      });
    }
  };

  notasTrabajo = (e) => {
    if (this.state.activarNotaOn) {
      this.setState({ activarNotaOn: false, idarchivos: "" });
    } else {
      this.setState({ activarNotaOn: true, idarchivos: e });
      //console.log(e);
    }
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  handleEditorChange(textoevo, editor) {
    this.setState({ textoevo });
  }
  componentDidMount() {
    this.buscarProfesor();
    //this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CONSULTA EVALUACIONES CREADAS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          {this.state.activarNotaOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <PublicarNotas
                  idarchivos={this.state.idarchivos}
                  handleClose={this.notasTrabajo}
                />
              </div>
            </div>
          )}

          {/* {this.state.activarNotaOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <EvaluarTareas
                  idarchivos={this.state.idarchivos}
                  handleClose={this.notasTrabajo}
                />
              </div>
            </div>
          )} */}

          {this.state.activarEvaluacionOn === true && (
            <div className="flotadorTE">
              <div className="flotador1f">
                <CalificarTareas
                  idprofesor={this.state.idprofesor}
                  idmateria={this.state.idmateria}
                  idcurso={this.state.idcurso}
                  idtareas={this.state.idtareas}
                  anoEscolar={this.state.anoEscolar}
                  cedulaAlumno={this.state.cedulaAlumno}
                  nombreAlumno={this.state.nombreAlumno}
                  idarchivos={this.state.idarchivos}
                  nombreEvaluacion={this.state.nombreEvaluacion}
                  handleClose={this.EvaluarTrabajo}
                />
              </div>
            </div>
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  {this.props.usuario1.nivel === "2" ||
                  this.props.usuario1.nivel === "1" ||
                  this.props.usuario1.nivel === "6" ? (
                    <div className="contenedorcedula1f col-4">
                      <select
                        id="idprofesor"
                        name="idprofesor"
                        value={this.state.idprofesor}
                        onChange={this.onChange2.bind(this)}
                      >
                        <option value="">--- Seleccione un Profesor ---</option>
                        {this.state.iprofesor.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idmateria"
                      name="idmateria"
                      value={this.state.idmateria}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Materia --</option>
                      {this.state.imaterias.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="descripcionE"
                      name="descripcionE"
                      value={this.state.descripcionE}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Tipo de Evaluación --</option>
                      {this.state.idescripcionE.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                      <option value="0">Todas las Evaluaciones</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="botones col-12">
                  <div className="acciones">
                    {/* {this.props.usuario1.nivel === "3" && (
                      <button
                        className={
                          !this.state.botonguardarOn ? "boton" : "botonOf"
                        }
                        onClick={
                          !this.state.botonguardarOn
                            ? this.consultar2.bind(this)
                            : ""
                        }
                      >
                        Datos Pendientes
                      </button>
                    )} */}
                    {/* {this.props.usuario1.nivel !== "3" && (
                      <div className="contenedorcedula1f col-2">
                        <p className="">Sin Cierre:</p>

                        <input
                          type="checkbox"
                          id="oferta1"
                          name="tipocierre"
                          value="SI"
                          checked={this.state.tipocierre === "SI"}
                          onChange={this.onChange1.bind(this)}
                        />
                      </div>
                    )} */}
                    <button
                      className={
                        !this.state.botonguardarOn ? "boton" : "botonOf"
                      }
                      onClick={
                        !this.state.botonguardarOn
                          ? this.consultar.bind(this)
                          : ""
                      }
                    >
                      Consultar
                    </button>

                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    {/* <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button> */}
                  </div>
                </div>
              </div>
              <div className="cont-listmedix row">
                <div className="listcxcx col-12">
                  <table className="table table-sm table-hover" border="1">
                    {this.state.itrabajoAlumnos.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          <thead>
                            {item.variable9 === 0 && (
                              <tr>
                                <th scope="col" colspan="4" border="0">
                                  {item.variable3}
                                </th>
                              </tr>
                            )}
                            {item.variable9 === 0 && (
                              <tr>
                                <th className="titucxc3x">NOMBRE ALUMNO</th>

                                <th className="titucxc3n">NOTA</th>
                                <th className="titucxc3f">FECHA</th>
                                <th className="titucxc3xpe">
                                  <span className="titulo-eliminar">
                                    <span className="cerrarme5"></span>
                                  </span>
                                </th>
                              </tr>
                            )}
                          </thead>
                          <tr>
                            <td className="cuerpo1ex">{item.variable2}</td>
                            <td className="cuerpo3ex">{item.variable4}</td>
                            <td className="titulo3ex">{item.variable5}</td>
                            <td className="titucxc3xpe">
                              {this.props.usuario1.nivel === "2" ||
                              this.props.usuario1.nivel === "3" ? (
                                item.variable7 === 0 || item.variable8 === 0 ? (
                                  <span
                                    className="cerrarme6"
                                    onClick={this.notasTrabajo.bind(
                                      this,
                                      item.variable1
                                    )}
                                  ></span>
                                ) : (
                                  ""
                                )
                              ) : (
                                item.variable5 !== "" && (
                                  <span
                                    className="cerrarme6"
                                    onClick={this.notasTrabajo.bind(
                                      this,
                                      item.variable1
                                    )}
                                  ></span>
                                )
                              )}
                            </td>
                          </tr>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(MostrarTrabajos);
