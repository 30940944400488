//dependencias
import React, { Component } from 'react';

//componentes
import ConsultaITAction from './consultaIT'

class Index extends Component {

    render(){
        return(
            <div>
                <ConsultaITAction />
            </div>
        );
    }

}
export default Index;