import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Comprobante from "../comprobante/comprobante";

//componentes
import "./cxpAbonosM.css";
import FeatherIcon from "feather-icons-react";
import ModificarMonto from "../modificarMonto/generarPagosC";
import Alert from "../../modal/alert";

class CxPAbonos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idabonocxp: "",
      fecha: "",
      monto1: "",
      descripcion1: "",
      tbusqueda: "",
      banco: "",
      referencia: "",
      subtotal: "",
      impuesto: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      proveedores: [],
      cxpproveedor: [],
      cxpAbonos: [],
      cxpAbonosMultiples: [],
      montopagar: [],
      asientoOn: false,
      botonAsientoOn:false,
    };
  }

  limpiar = () => {
    this.setState({
      idabonocxp: "",
      //fecha: "",
      monto1: "",
      //descripcion1: "",
      guardarOn: false,
      //banco: "",
      //referencia: "",
      subtotal: "",
      impuesto: "",
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      montopagar: [],
      asientoOn: false,
      botonAsientoOn:false,
    });
  };

  limpiar2 = () => {
    this.setState({
      idcxp: "",
      fecha: "",
      monto1: "",
      descripcion1: "",
      banco: "",
      referencia: "",
      documento: "",
      //descripcion1: "",
      tbusqueda: "",
      //banco: "",
      //referencia: "",
      subtotal: "",
      impuesto: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      cxpAbonos: [],
      cxpAbonosMultiples: [],
      baseimp: "",
      impue: "",
      total: "",
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.cxpproveedor.find((e) => e.variable1 === idplane);

    let index =
      typeof itemfind !== "undefined"
        ? this.state.cxpproveedor.indexOf(itemfind)
        : 0;
    //console.log(index)

    let element = e.target;
    let cxpAbonosMultiples_ = Array.from(this.state.cxpAbonosMultiples);
    const resultado = cxpAbonosMultiples_.find(
      (cxpAbonosM) =>
        cxpAbonosM.variable1 === this.state.cxpproveedor[index].variable1
    );
    if (resultado === undefined) {
      cxpAbonosMultiples_.push({
        variable1: this.state.cxpproveedor[index].variable1,
        variable2: this.state.cxpproveedor[index].variable3,
        variable3: this.state.cxpproveedor[index].variable4,
        variable4: this.state.cxpproveedor[index].variable5,
        variable5: this.state.cxpproveedor[index].variable6,
        variable6: this.state.cxpproveedor[index].variable7,
      });
      // console.log({
      //   "copia:": cxpAbonosMultiples_,
      //   global: this.state.cxpproveedor,
      // });
    }
    this.setState({ cxpAbonosMultiples: cxpAbonosMultiples_ });
  };

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  consultarCxPI = (e) => {
    let data = new FormData();
    data.append("idabonocxp", e);
    data.append("boton", "consultarCxPIA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            idabonocxp: res.data.variable2,
            monto1: res.data.variable3,
            descripcion1: res.data.variable4,
            fecha: res.data.variable5,
            banco: res.data.variable6,
            referencia: res.data.variable7,
            subtotal: res.data.variable8,
            impuesto: res.data.variable9,
            guardarOn: true,
          });
        }
      });
  };

  consultarProveedores = () => {
    let data = new FormData();
    data.append("boton", "consultarProveedor");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarCxPM = (e) => {
    let data = new FormData();
    data.append("idproveedor", e);
    //data.append("tipoC", this.props.tipoC);
    data.append("boton", "consultarCxP2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        console.log(res.data.variable2);
        if (res.data.variable1) {
          let xfacturasP = res.data.variable2;
          this.setState({
            cxpproveedor: xfacturasP,
          });
        }
      });
  };

  eliminarCxP2 = () => {
    let data = new FormData();
    data.append("clave", this.props.usuario1.password);
    data.append("boton", "eliminarCxP2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {});
  };

  eliminarCxPI2 = (e) => {
    let element = e;
    let cxpAbonosMultiples_ = Array.from(this.state.cxpAbonosMultiples);
    console.log(e);
    let pos = cxpAbonosMultiples_.indexOf(element);
    console.log(pos);
    cxpAbonosMultiples_.splice(pos, 1);
    this.setState({ cxpAbonosMultiples: cxpAbonosMultiples_ });
  };

  consultarCxP = (e) => {
    let data = new FormData();
    data.append("idcxp", e);
    data.append("boton", "consultarCxPA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        // if (res.data.variable1) {
        let xfacturasP = res.data.variable2;
        this.setState({
          cxpAbonos: xfacturasP,
        });
        // }else{

        // }
      });
  };

  eliminarCxP = () => {
    let data = new FormData();
    data.append("idabonocxp", this.state.idabonocxp);
    data.append("boton", "eliminarA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.consultarCxP(this.state.tbusqueda);
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Se Elimino el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  guardarCxP = () => {
    if (this.state.banco.length > 0 && this.state.referencia.length > 0) {
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.password);
      data.append("descripcion", this.state.descripcion1);
      data.append("fecha", this.state.fecha);
      data.append("banco", this.state.banco);
      data.append("referencia", this.state.referencia);
      data.append("arregloCxP", JSON.stringify(this.state.cxpAbonosMultiples));
      data.append("boton", "guardarA");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable4);
          //console.log(res.data.variable3);
          if (res.data.variable1) {
            this.consultarCxPM(this.props.idcxpM);
            this.limpiar2();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Cuentas por Pagar.",
              cuerpoM: "Se Guardaron los Registro con exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Cuentas por Pagar.",
              cuerpoM: "Ocurrio un error, Verifique por favor.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Cuentas por Pagar.",
        cuerpoM: "Debe Ingresar un Banco y una Referencia, gracias",
      });
    }
  };

  modificarCxP = () => {
    let data = new FormData();
    data.append("idabonocxp", this.state.idabonocxp);
    data.append("subtotal", this.state.subtotal);
    data.append("impuesto", this.state.impuesto);
    data.append("monto", this.state.monto1);
    data.append("descripcion", this.state.descripcion1);
    data.append("fecha", this.state.fecha);
    data.append("banco", this.state.banco);
    data.append("referencia", this.state.referencia);
    data.append("boton", "modificarA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.consultarCxP(this.state.tbusqueda);
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Se Modifico el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
    } else {
      this.setState({ datosChequeOn: true });
    }
  };

  componentDidMount() {
    //console.log(this.props.idcxpM)
    this.consultarCxPM(this.props.idcxpM);
    //this.eliminarCxP2();
  }

  changeValorEntrada = (index, value) => {
    //console.log(index);
    let cxpAbonosMultiples_ = Array.from(this.state.cxpAbonosMultiples);
    cxpAbonosMultiples_[index].valorEntrada = parseFloat(value);
    this.setState({ cxpAbonosMultiples: cxpAbonosMultiples_ });
  };

  calcularSumaValorEntrada = (index, value) => {
    let facturas = Array.from(this.state.cxpAbonosMultiples);
    facturas[index].valorEntrada = value;
    let total = 0;
    let baseimp = 0;
    let impue = 0;
    //console.log(parseFloat(value))
    for (let x in facturas) {
      if (
        parseFloat(facturas[x].valorEntrada) <=
        facturas[x].variable5
      ) {
        //console.log(facturas[x].valorEntrada)
        //console.log(facturas[x].variable5)
        baseimp += parseFloat(facturas[x].valorEntrada);        
        total += parseFloat(facturas[x].valorEntrada);
        // if (parseFloat(facturas[x].variable6) > 0) {
        //   baseimp += facturas[x].valorEntrada / 1.07;
        //   impue +=
        //     facturas[x].valorEntrada -
        //     facturas[x].valorEntrada / 1.07;
        // } else {
        //   baseimp += facturas[x].valorEntrada;
        // }
      }
    }
    //if (impue >= 0){}else{impue=0;}

    this.setState({ baseimp, impue, total, cxpAbonosMultiples: facturas });
  };

  asientoContable = () => {
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
      });
    }
  };

  agregarItemAsientoPorPagar = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("fecha", this.state.fecha);
    data.append("montoBanco", this.state.total);
    data.append("descripcion", this.state.descripcion1);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("arregloCxP", JSON.stringify(this.state.cxpAbonosMultiples));

    data.append("boton", "pagosAsientoContable1M");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          asientoOn: true,
          botonAsientoOn: true,
          loading: false,
        });
      });
  };

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc3 col-12">
          <div className="titulo-cxc "></div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.asientoOn && (
            <div className="flotadorPe">
              <div className="flotadorPe1">
                <Comprobante
                  claveUsuario={this.props.usuario1.password}
                  comprobanteClose={this.asientoContable}
                />
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="fechas col-12">
              <select
                id="tbusqueda"
                name="tbusqueda"
                value={this.state.tbusqueda}
                onChange={this.onChange2.bind(this)}
              >
                <option>-- Seleccione una Factura --</option>
                {this.state.cxpproveedor.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {"Fecha: " +
                        item.variable2 +
                        " --  Documento: " +
                        item.variable3 +
                        " --  Monto: " +
                        item.variable4 +
                        " --  Abonado: " +
                        item.variable5}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="form-group row">
            {/* <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="text"
                name="subtotal"
                id="subtotal"
                autoComplete="off"
                placeholder="Sub-Total"
                maxLength="10"
                value={
                  parseFloat(this.state.baseimp).toFixed(2) > 0
                    ? parseFloat(this.state.baseimp).toFixed(2)
                    : ""
                }
              />
            </div>
            <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="text"
                name="impuesto"
                id="impuesto"
                autoComplete="off"
                placeholder="Impuesto"
                maxLength="10"
                value={
                  parseFloat(this.state.impue).toFixed(2) > 0
                    ? parseFloat(this.state.impue).toFixed(2)
                    : ""
                }
              />
            </div> */}
            <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="text"
                name="monto1"
                id="monto1"
                autoComplete="off"
                placeholder="Total"
                maxLength="10"
                value={
                  parseFloat(this.state.total).toFixed(2) > 0
                    ? parseFloat(this.state.total).toFixed(2)
                    : ""
                }
              />
            </div>

            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fecha"
                id="fecha"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="contenedorcedula col-6">
              <input
                className="form-control"
                type="text"
                name="descripcion1"
                id="descripcion1"
                autoComplete="off"
                placeholder="Descripcion"
                maxLength="200"
                onChange={this.onChange.bind(this)}
                value={this.state.descripcion1.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula col-3">
              <select
                id="banco"
                name="banco"
                value={this.state.banco}
                onChange={this.onChange.bind(this)}
              >
                <option>-- Seleccionar Banco --</option>
                <option value="1"> Banco BAC</option>
                <option value="2"> Banco BANESCO</option>
                <option value="5"> Banco GENERAL</option>
                <option value="3"> Efectivo</option>
                <option value="4"> Otros</option>
              </select>
            </div>
            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="text"
                name="referencia"
                id="referencia"
                autoComplete="off"
                placeholder="Referencia"
                maxLength="200"
                onChange={this.onChange.bind(this)}
                value={this.state.referencia.toUpperCase()}
              />
            </div>
          </div>
          <div className="row">
            <div className="contenedorcedula2 col-12">
            {this.state.botonAsientoOn=== true &&
              <button
                className={this.state.guardarOn ? "botonOf" : "boton"}
                onClick={this.state.guardarOn ? "" : this.guardarCxP}
              >
                Guardar
              </button>
  }
              <button
                className="boton"
                onClick={this.agregarItemAsientoPorPagar}
              >
                Asiento Contable
              </button>
              {/* <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                disabled={!this.state.guardarOn}
                onClick={this.state.guardarOn ? this.modificarCxP : ""}
              >
                Modificar
              </button>
              <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                disabled={!this.state.guardarOn}
                onClick={this.eliminarCxP}
              >
                Eliminar
              </button> */}
              <button className="boton" onClick={this.limpiar2.bind(this)}>
                Limpiar
              </button>
              <button className="boton" onClick={this.props.generarPagoClose}>
                Salir
              </button>
            </div>
          </div>
          {this.state.modificarSaldoOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <ModificarMonto
                  idcxc={this.state.idcxc}
                  montoDebe={this.state.montoDebe}
                  montoAbono={this.state.montoAbono}
                  montoModificar={this.state.montoModificar}
                  idcontrolPago={this.state.idcontrolPago}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">DOCUMENTO</th>
                    <th className="titucxcM3">MONTO FACT.</th>
                    <th className="titucxcM3">MONTO ABO.</th>
                    <th className="titucxcM3">DEUDA</th>
                    <th className="titucxcM3">MONTO</th>
                    <th className="titucxcM3">
                      <span className="titulo-eliminar">
                        <span className="cerrarme1"></span>
                      </span>
                    </th>
                  </tr>
                </thead>
                {this.state.cxpAbonosMultiples.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxc"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable2}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable4}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {parseFloat(item.variable5).toFixed(2)}
                      </td>
                      <td className="totalescxcx" width="10">
                        <div className="contenedorcedula col-12">
                          <input
                            className="form-control"
                            type="text"
                            name={item.variable1}
                            id={item.variable1}
                            autoComplete="off"
                            step="any"
                            onChange={(e) =>
                              this.calcularSumaValorEntrada(i, e.target.value)
                            }
                            //value={item.valorEntrada}
                            value={
                              parseFloat(item.valorEntrada) <=
                              parseFloat(item.variable5)
                                ? item.valorEntrada
                                : ""
                            }
                          />
                        </div>
                      </td>
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminarCxPI2.bind(
                            this,
                            item.variable1
                          )}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          {/* {this.calcularSumaValorEntrada2(this.state.cxpAbonosMultiples)} */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxPAbonos);
