import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
//componentes
import "./copiaAgendaA.css";
import Modal from "../modal4/modal";
import Alert from "../../modal/alert";

class ReportePla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: moment().format("YYYY-MM-DD"),
      fechaSeleccion: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      dateArray: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolarV: moment().format("YYYY"),
      anoEscolarN: moment().format("YYYY"),
      idcurso: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      trimestre: "",
      idmateria: "",
      verplanillaOn: false,
      mostrarOn: false,
      nalumno: [],
      ianoEscolar: [],
      icursos: [],
      ifechaPlani: [],
      someHTML: [],
      someHTML2: [],
      iprofesor: [],
      imaterias: [],
      fechaTemporal: "",
      idagenda: "",
    };
  }

  limpiar = () => {
    this.setState({
      fecha: moment().format("YYYY-MM-DD"),
      fechaSeleccion: "",
      ifechamedi: [],
      mensajealerta: false,
      mostrarOn: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolarV: moment().format("YYYY"),
      anoEscolarN: moment().format("YYYY"),
      idcurso: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      trimestre: "",
      idmateria: "",
      someHTML: [],
      someHTML2: [],
      verplanillaOn: false,
      nalumno: [],
      dateArray: [],
      fechaTemporal: "",
      idagenda: "",
    });
  };
  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

 
  
  handleClose = () => {
    this.setState({ mensajealerta: false });
  };
  handleClose2 = () => {
    this.setState({ mostrarOn: false });
    this.consulta();
  };

  copiarAgendaAnio = () => {
    if (
      this.state.anoEscolarV.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.anoEscolarN.length > 0
    ) {
      let data = new FormData();
      data.append("anoEscolarV", this.state.anoEscolarV);
      data.append("anoEscolarN", this.state.anoEscolarN);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "copiarHorarioNuevoAnio");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/horarioPlanificacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Copia de Año.",
            cuerpoM:res.data.variable2,
          });
        } else{
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Copia de Año.",
            cuerpoM:res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Copia de Año.",
        cuerpoM:
          "Para realizar el proceso se deben seleccionar todos los campos.",
      });
    }
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolarV: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  componentDidMount() {
    //this.buscarMaterias();
    this.buscarAnioEscolar();
    // this.cargarFechas();
    this.buscarProfesor();
    //this.buscarCurso();
  }

  ax = () => {};
  render() {
    //console.log(this.state.fecha1)
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>COPIAR AGENDA POR PROFESOR AÑO ESCOLAR ANTERIOR A NUEVO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Profesor --</option>
                      {this.state.iprofesor.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolarV"
                      name="anoEscolarV"
                      value={this.state.anoEscolarV}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Año Escolar Anterior--</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolarN"
                      name="anoEscolarN"
                      value={this.state.anoEscolarN}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Año Escolar Nuevo --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="cont-personalx col-12">
                  <div className="contenedorcedula1f col-8">
                    <div className="acciones">
                      <button
                        className="boton"
                        onClick={this.copiarAgendaAnio.bind(this)}
                      >
                        Procesar Copia
                      </button>

                      <Link to="/">
                        <button className="boton">Salir</button>
                      </Link>
                      <button
                        className="boton"
                        onClick={this.limpiar.bind(this)}
                      >
                        Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(ReportePla);
