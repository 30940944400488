import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";

 

//componentes
import './inscripcionG.css';
import Alert from '../../modal/alert';

class CxC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mensajealerta:false,

        }      
    }

    limpiar =() =>{
        this.setState({
            mensajealerta:false,
        })
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }
    
    onChange3(e) {
        //console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value             
        })
        this.calcularEdad(e.target.value)
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }


    generarInscripcion = () =>{
        let data = new FormData();
        data.append('boton', 'inscripcionGeneral');

        let config = {
            headers: {
              'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxc.php';
        axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data.variable3);
                console.log(res.data.variable2);
                if (res.data.variable1) {
                   this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Generar Inscripción General.',
                        cuerpoM: res.data.variable2,
                    });
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Generar Inscripción General.',
                        cuerpoM: res.data.variable2,
                    });

                }
            })
    }


    render(){
        return(
            <div className="principal-pago row">
                <div className="contenedor-cxc col-12">
                    <div className="titulo-cxc ">
                        <p>GENERAR INSCRIPCIÓN GENERAL. </p>
                    </div>
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    }

                    <div className="contenedorp row">
                        <div className="titulo-planillai col-12">
                            <p>PARA GENERAR LA INSCRIPCIÓN GENERAL DEBE PRESIONAR EL BOTON GENERAR INSCRIPCIÓN. </p>
                        </div>
                        <div className="xcontenedorcedula1 col-12">
                            <button className="boton" onClick={this.generarInscripcion.bind(this)}>Generar Iscripción</button>
                            <Link to="/"><button className="boton">Salir</button></Link>
                        </div>
                
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token
    }
}
export default connect(mapStateToProps,)(CxC);