import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";

//componentes
import "./maestroUsuarios.css";
import Alert from "../../modal/alert";

class ConceptoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cedula: "",
      usuario: "",
      clave: "",
      iconcepto: [],
      listaOn: false,
      openvio: "",
    };
  }

  limpiar = () => {
    this.setState({
      cedula: "",
      usuario: "",
      clave: "",
      listaOn: false,
      openvio: "",
      iconcepto: [],
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange1(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarConceptos(e.target.value);
  }

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              codigo: res.data.variable1,
              cedula: res.data.variable2,
              nombre: res.data.variable3,
              motivo: res.data.variable4,
              ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  guardar = () => {
    let data = new FormData();
    data.append("cedula", this.state.cedula);
    data.append("usuario", this.state.usuario);
    data.append("clave", this.state.clave);
    if (this.state.openvio === "1") data.append("boton", "crearUsuarios");
    if (this.state.openvio === "2") data.append("boton", "crearUsuariosA");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/clavesAcudientes.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.buscarConceptos(this.state.openvio);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Usuarios del Sistema.",
            cuerpoM: "Se guardo correctamente el Usuario.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Usuarios del Sistema.",
            cuerpoM:
              "Ocurrio un problema para guardar el Usuario, Verifique que la cedula coincida con la registrada en el Sistema, o la clave ya existe..",
          });
        }
      });
  };

  bloqueo = (e, f) => {
    //    if (e > 0 && parseFloat(f) > 0) {
    let data = new FormData();
    data.append("tipoUsuario", this.state.openvio);
    data.append("clave", e);
    data.append("usuario", f);
    data.append("boton", "bloqueo");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/clavesAcudientes.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.buscarConceptos(this.state.openvio);
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Usuarios del Sistema.",
          cuerpoM: "Se Modifico correctamente el Usuario.",
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Usuarios del Sistema.",
          cuerpoM: "Ocurrio un error, verifique por favor.",
        });
      }
    });
    //  }
  };

  eliminar = (e, f) => {
    //    if (e > 0 && parseFloat(f) > 0) {
    let data = new FormData();
    data.append("tipoUsuario", this.state.openvio);
    data.append("clave", e);
    data.append("usuario", f);
    data.append("boton", "eliminar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/clavesAcudientes.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.buscarConceptos(this.state.openvio);
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Usuarios del Sistema.",
          cuerpoM: "Se Elimino correctamente el Usuario.",
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Usuarios del Sistema.",
          cuerpoM: "Ocurrio un error, No Se Pudo Eliminar el Usuario.",
        });
      }
    });
    //  }
  };

  buscarConceptos = (e) => {
    let data = new FormData();
    data.append("tipoUsuario", e);
    data.append("boton", "buscarUsuarios");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/clavesAcudientes.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiconcepto = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iconcepto: xiconcepto,
          });
        } else {
          this.setState({
            iconcepto: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    //this.buscarConceptos();
  }

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MAESTRO DE USUARIOS DEL SISTEMA. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.openvio === "" && (
            <div className="row">
              <div className="cont-personal col-12">
                {this.props.usuario1.nivel !== "8" && (
                  <div className="radio">
                    <label className="container">
                      Administrativo
                      <input
                        type="radio"
                        value="1"
                        name="openvio"
                        checked={this.state.openvio === "1"}
                        onChange={this.onChange1.bind(this)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                )}
                <div className="radio">
                  <label className="container">
                    Acudiente
                    <input
                      type="radio"
                      value="2"
                      name="openvio"
                      checked={this.state.openvio === "2"}
                      onChange={this.onChange1.bind(this)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="xcontenedorcedula1x col-12">
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          )}
          {this.state.openvio === "1" && (
            <div className="contenedorp row">
              <div className="contenedorcedula1x col-12">
                <input
                  className="form-control"
                  type="text"
                  name="cedula"
                  id="cedula"
                  autoComplete="off"
                  placeholder="Cedula del Profesor"
                  maxLength="150"
                  onChange={this.onChange.bind(this)}
                  value={this.state.cedula.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula1x col-12">
                <input
                  className="form-control"
                  type="text"
                  name="usuario"
                  id="usuario"
                  autoComplete="off"
                  placeholder="Indique el Usuario"
                  maxLength="150"
                  onChange={this.onChange.bind(this)}
                  value={this.state.usuario}
                />
              </div>
              <div className="contenedorcedula1x col-12">
                <input
                  className="form-control"
                  type="text"
                  name="clave"
                  id="clave"
                  autoComplete="off"
                  placeholder="Indique la Clave"
                  maxLength="150"
                  onChange={this.onChange.bind(this)}
                  value={this.state.clave.toUpperCase()}
                />
              </div>
              <div className="xcontenedorcedula1x col-12">
                <button className="boton" onClick={this.guardar.bind(this)}>
                  Agregar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            </div>
          )}

          {this.state.openvio === "2" && (
            <div className="contenedorp row">
              <div className="contenedorcedula1x col-12">
                <input
                  className="form-control"
                  type="text"
                  name="cedula"
                  id="cedula"
                  autoComplete="off"
                  placeholder="Cedula del Representante"
                  maxLength="150"
                  onChange={this.onChange.bind(this)}
                  value={this.state.cedula.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula1x col-12">
                <input
                  className="form-control"
                  type="text"
                  name="clave"
                  id="clave"
                  autoComplete="off"
                  placeholder="Indique la Clave"
                  maxLength="150"
                  onChange={this.onChange.bind(this)}
                  value={this.state.clave.toUpperCase()}
                />
              </div>
              <div className="xcontenedorcedula1x col-12">
                <button className="boton" onClick={this.guardar.bind(this)}>
                  Agregar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            </div>
          )}

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc3">NOMBRE USUARIO</th>
                    <th className="titucxc3x">USUARIO</th>
                    <th className="titucxc3x">CLAVE</th>
                    {this.state.openvio === "2" && (
                      <th className="titucxc3x">BLOQUEADO</th>
                    )}
                    <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.iconcepto.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable3}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>
                      <td className="totalescxcx">{item.variable1}</td>
                      {this.state.openvio === "2" && (
                        <td
                          className="xcerr"
                          onClick={this.bloqueo.bind(
                            this,
                            item.variable1,
                            item.variable3
                          )}
                        >
                          {item.variable4}
                        </td>
                      )}
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(
                            this,
                            item.variable1,
                            item.variable3
                          )}
                        ></span>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps) (ConceptoAction);
