import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
//componentes
import "./nomina.css";
import Alert from "../../modal/alert";
import ReporteN from "./reporteNominaE";
import ReporteN2 from "./reporteNominaE2";
import ReporteN3 from "./reporteNominaE3";

function NominaC(props) {
  const [listaOn, setlistaOn] = React.useState(false);
  const [prioridadOn, setprioridadOn] = React.useState(false);
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [activarOn, setactivarOn] = React.useState(false);
  const [personal, setpersonal] = React.useState([]);
  const [anio, setanio] = React.useState([]);
  const [mes, setmes] = React.useState([]);
  const [desde, setDesde] = React.useState([]);
  const [hasta, setHasta] = React.useState([]);
  const [cuenta, setcuenta] = React.useState([]);
  const [nominaPersonal, setnominaPersonal] = React.useState([]);
  const [tipoNomina, settipoNomina] = React.useState(0);
  const [totalPrioridad, settotalPrioridad] = React.useState(0);
  const [totalPrioridadPP, settotalPrioridadPP] = React.useState(0);
  const [totalPrioridadPagada, settotalPrioridadPagada] = React.useState(0);
  const [salarioB, setsalarioB] = React.useState(0);
  const [montosso, setmontosso] = React.useState(0);
  const [montose, setmontose] = React.useState(0);
  const [subtotal, setsubtotal] = React.useState(0);
  const [pagoprestamo, setpagoprestamo] = React.useState(0);
  const [totalapagar, settotalapagar] = React.useState(0);
  const [islr, setislr] = React.useState(0);
  const [montoQuincena, setmontoQuincena] = React.useState(0);
  const [salarioD, setsalarioD] = React.useState(0);
  const [ssoP, setssoP] = React.useState(0);
  const [seguroE, setseguroE] = React.useState(0);
  const [xislr, setxislr] = React.useState(0);
  const [cuentaContable, setcuentaContable] = React.useState("");
  const [quincena, setquincena] = React.useState("");
  const [anopagar, setanopagar] = React.useState("");
  const [mesapagar, setmesapagar] = React.useState("");
  const [openvio, setopenvio] = React.useState("");
  const [flotadorNOn, setflotadorNOn] = React.useState(false);
  const [flotador2NOn, setflotador2NOn] = React.useState(false);
  const [flotador3NOn, setflotador3NOn] = React.useState(false);
  const [flotador4NOn, setflotador4NOn] = React.useState(false);
  const [ocultarOn, setocultarOn] = React.useState(false);
  const [asistenciaOn, setAsistenciaOn] = React.useState(false);
  const [deducOn, setDdeducOn] = React.useState(false);

  const [data, setData] = React.useState({
    cedulaNomina: "",
    diasTrabajado: "",
    montoDescontarP: 0,
    saldoPrestamo: "",
    deducciones: "",
  });
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");

  const limpiar = () => {
    setsalarioB(0);
    setmontosso(0);
    setmontose(0);
    setsubtotal(0);
    settotalPrioridad(0);
    settotalPrioridadPP(0);
    settotalPrioridadPagada(0);
    setpagoprestamo(0);
    settotalapagar(0);
    setislr(0);
    setmontoQuincena(0);
    setmensajealerta(false);
    setactivarOn(false);
    setsalarioB(0);
    setDesde("");
    setHasta("");
    let data_ = Object.assign({}, data);
    data_ = {
      montoDescontarP: 0,
      cedulaNomina: 0,
    };
    setData(data_);
  };

  const cerrarLista = () => {
    //console.log(listaOn)
    if (listaOn) {
      setlistaOn(false);
    } else {
      setlistaOn(true);
      buscarNomina();
    }
    //console.log(listaOn);
  };
  const cerrarPrioridad = () => {
    //console.log(listaOn)
    if (prioridadOn) {
      setprioridadOn(false);
    } else {
      setprioridadOn(true);
      buscarNomina();
    }
    //console.log(listaOn);
  };

  const onChange1 = (e) => {
    //console.log(e.target.name)
    setDesde(e.target.value);
    //console.log(e.target.value)
  };
  const onChange2 = (e) => {
    //console.log(e.target.name)
    setHasta(e.target.value);
    //console.log(e.target.value)
  };

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    // console.log (e.target);
    // console.log(index)
    let data_ = Object.assign({}, data);
    data_[index] = e.target.value;
    setData(data_);
    if (index === "quincena") {
      setquincena(e.target.value);
      limpiar();
    }
    if (index === "anopagar") {
      setanopagar(e.target.value);
      limpiar();
    }
    if (index === "mesapagar") {
      setmesapagar(e.target.value);
      limpiar();
    }
    if (index === "cuentaotrosEgresos") {
      setcuentaContable(e.target.value);
      limpiar();
    }
  };

  const onChange6 = (index, value) => {
    let nominaP_ = Array.from(nominaPersonal);
    // nominaP_[index].cuentaContable = 0;
    nominaP_[index].variable35 = value;
    setnominaPersonal(nominaP_);
  };

  const onChange4 = (e, f) => {
    let form = new FormData();
    form.append("cedulaT", e);
    form.append("idnomina", f);
    form.append("quincena", quincena);
    form.append("mesapagar", mesapagar);
    form.append("anopagar", anopagar);
    form.append("boton", "guardarPri");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
    axios
      .post(xurl, form, config)

      .then((res) => {
        if (res.data.variable1) {
          buscarNomina();
        } else {
          // this.setState({});
        }
      });
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  const onChange5 = (e, f) => {
    let form = new FormData();
    form.append("cedulaT", e);
    form.append("idnomina", f);
    form.append("quincena", quincena);
    form.append("mesapagar", mesapagar);
    form.append("anopagar", anopagar);
    form.append("boton", "guardarPriPago");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
    axios
      .post(xurl, form, config)

      .then((res) => {
        if (res.data.variable1) {
          buscarNomina();
        } else {
          // this.setState({});
        }
      });
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };
  const handleClose = () => {
    setmensajealerta(false);
  };

  const buscarNomina = () => {
    let form = new FormData();
    form.append("quincena", quincena);
    form.append("mesapagar", mesapagar);
    form.append("anoapagar", anopagar);
    form.append("openvio", openvio);
    form.append("boton", "buscarNomina");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        let xnominaPersonal = res.data.variable2;
        setnominaPersonal(xnominaPersonal);
        settotalPrioridad(res.data.variable3);
        settotalPrioridadPP(res.data.variable4);
        settotalPrioridadPagada(res.data.variable5);
      } else {
        let xnominaPersonal = res.data.variable2;
        setnominaPersonal(xnominaPersonal);
        setmensajealerta(true);
        settituloM("Menu Generar Nomina.");
        setcuerpoM(res.data.variable3);
      }
    });
  };

  const buscarPrestamo = async (e) => {
    if (quincena.length > 0) {
      if (mesapagar.length > 0) {
        if (anopagar.length > 0) {
          if (e.target.value.length > 0) {
            limpiar();
            let rcedu = e.target.value;
            let form = new FormData();
            form.append("cedulaEmpleado", e.target.value);
            form.append("boton", "saldoPrestamo");
            let config = {
              headers: { "Content-Type": "text/html" },
            };
            const xurl =
              "https://institutobilinguelasnaciones.com/terapias/backend/prestamo.php";
            let res = await axios.post(xurl, form, config);
            let data_ = Object.assign({}, data);
            data_["saldoPrestamo"] = Number.parseFloat(
              res.data.variable1
            ).toFixed(2);
            data_["cedulaNomina"] = rcedu;
            setData(data_);
            settipoNomina(res.data.variable2);
            buscarDatosNomina(rcedu);
          }
        } else {
          setmensajealerta(true);
          settituloM("Menu Generar Nomina.");
          setcuerpoM("Debe elegir un Año, Gracias");
        }
      } else {
        setmensajealerta(true);
        settituloM("Menu Generar Nomina.");
        setcuerpoM("Debe elegir un Mes, Gracias");
      }
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe elegir una Quincena, Gracias");
    }
  };

  const buscarPersonal = () => {
    let form = new FormData();
    form.append("dedonde", "crearNomina");
    form.append("boton", "buscarPersonal");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xpersonal = res.data.variable1;
      setpersonal(xpersonal);
    });
  };

  const actualizarNomina = () => {
    if (quincena.length > 0) {
      if (mesapagar.length > 0) {
        if (anopagar.length > 0) {
          let data = new FormData();
          data.append("quincena", quincena);
          data.append("mesapagar", mesapagar);
          data.append("anoapagar", anopagar);
          data.append("arregloNomina", JSON.stringify(nominaPersonal));
          data.append("boton", "modificarNomina");
          let config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          const xurl =
            "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
          axios
            .post(xurl, data, config)

            .then((res) => {
              if (res.data.variable1) {
                buscarNomina();
                setmensajealerta(true);
                settituloM("Menu Nomina.");
                setcuerpoM(res.data.variable2);
              } else {
                setmensajealerta(true);
                settituloM("Menu Nomina.");
                setcuerpoM(res.data.variable2);
              }
            });
        } else {
          setmensajealerta(true);
          settituloM("Menu Generar Nomina.");
          setcuerpoM("Debe elegir un Año, Gracias");
        }
      } else {
        setmensajealerta(true);
        settituloM("Menu Generar Nomina.");
        setcuerpoM("Debe elegir un Mes, Gracias");
      }
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe elegir una Quincena, Gracias");
    }
  };

  const guardarNomina = () => {
    if (quincena.length > 0 && mesapagar.length > 0 && anopagar.length > 0) {
      let form = new FormData();
      // form.append("cedula", data.cedulaNomina);
      // form.append("diasTrabajado", data.diasTrabajado);
      // form.append("salarioB", salarioB);
      // form.append("montosso", montosso);
      // form.append("montose", montose);
      // form.append("subtotal", subtotal);
      // form.append("islr", islr);
      // form.append("pagoprestamo", pagoprestamo);
      // form.append("totalapagar", totalapagar);
      form.append("quincena", quincena);
      form.append("mesapagar", mesapagar);
      form.append("anoapagar", anopagar);
      form.append("openvio", openvio);
      form.append("boton", "guardarNomina");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setmensajealerta(true);
          settituloM("Menu Generar Nomina.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Generar Nomina.");
          setcuerpoM(res.data.variable2);
        }
      });
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe existir una Quincena, Mes y Año a Pagar");
    }
  };

  const guardarNominaInd = () => {
    if (
      quincena.length > 0 &&
      mesapagar.length > 0 &&
      anopagar.length > 0 &&
      data.cedulaNomina.length > 0
    ) {
      let form = new FormData();
      form.append("cedulaPersonal", data.cedulaNomina);
      form.append("quincena", quincena);
      form.append("mesapagar", mesapagar);
      form.append("anoapagar", anopagar);
      form.append("openvio", openvio);
      form.append("boton", "guardarNominaInd");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setflotador2NOn(false);
          setmensajealerta(true);
          buscarNomina();
          settituloM("Menu Generar Nomina.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Generar Nomina.");
          setcuerpoM(res.data.variable2);
        }
      });
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe existir una Quincena, Mes y Año a Pagar");
    }
  };

  const consultaRecibo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idnomina", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboEmpleados.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  const reporteMensual = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idnomina", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboEmpleados.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  const eliminarEmpleado = () => {
    if (data.cedula.length > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("boton", "eliminarEmpleado");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          limpiar();
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const buscarDatosNomina = (e) => {
    let form = new FormData();
    form.append("quincena", quincena);
    form.append("mesapagar", mesapagar);
    form.append("anoapagar", anopagar);
    form.append("cedulaNomina", e);
    form.append("boton", "buscarDatosNomina");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      setsalarioD(parseFloat(res.data.variable1));
      setssoP(parseFloat(res.data.variable2));
      setseguroE(parseFloat(res.data.variable4));
    });
  };

  const calcular = () => {
    //console.log(data.cedulaNomina);
    if (data.cedulaNomina.length > 1) {
      if (quincena.length > 0) {
        if (mesapagar.length > 0) {
          if (anopagar.length > 0) {
            if (parseFloat(data.diasTrabajado) > 0) {
              let xislr = 0;
              //buscarDatosNomina(data.cedulaNomina);

              // console.log(ssoP);
              // console.log(seguroE);
              let hsalaB = 0;
              hsalaB = salarioD * data.diasTrabajado;
              setsalarioB(Number.parseFloat(hsalaB).toFixed());
              //console.log(salarioD);
              //console.log(hsalaB);

              if (tipoNomina === "1" || tipoNomina === "2") {
                let hsso = 0;
                hsso = (ssoP * (salarioD * data.diasTrabajado)) / 100;
                setmontosso(Number.parseFloat(hsso).toFixed(2));

                let hsse = 0;
                hsse = (seguroE * (salarioD * data.diasTrabajado)) / 100;
                setmontose(Number.parseFloat(hsse).toFixed(2));
                let hislr = 0;
                //console.log(xislr);
                if (hsalaB >= 424) {
                  hislr = ((hsalaB - 423.07) * 15) / 100;
                  //console.log(hislr);
                  setislr(Number.parseFloat(hislr).toFixed(2));
                }
                let subt = 0;
                subt = hsalaB - hsso - hsse - hislr;
                setsubtotal(Number.parseFloat(subt).toFixed());

                if (data.montoDescontarP.length > 0) {
                  setpagoprestamo(
                    Number.parseFloat(data.montoDescontarP).toFixed(2)
                  );
                } else {
                  setpagoprestamo("0");
                }
                let htotal = subt - data.montoDescontarP;
                settotalapagar(Number.parseFloat(htotal).toFixed());
              }
              if (tipoNomina > 2) {
                setsubtotal(
                  Number.parseFloat(salarioD * data.diasTrabajado).toFixed()
                );

                if (data.montoDescontarP.length > 0) {
                  setpagoprestamo(
                    Number.parseFloat(data.montoDescontarP).toFixed(2)
                  );
                } else {
                  setpagoprestamo("0");
                }

                settotalapagar(
                  Number.parseFloat(
                    salarioD * data.diasTrabajado - data.montoDescontarP
                  ).toFixed()
                );
              }
            } else {
              setmensajealerta(true);
              settituloM("Menu Generar Nomina.");
              setcuerpoM(
                "Debe Indicar los Días Trabajados del Empleado, Gracias"
              );
            }
          } else {
            setmensajealerta(true);
            settituloM("Menu Generar Nomina.");
            setcuerpoM("Debe elegir un Año, Gracias");
          }
        } else {
          setmensajealerta(true);
          settituloM("Menu Generar Nomina.");
          setcuerpoM("Debe elegir un Mes, Gracias");
        }
      } else {
        setmensajealerta(true);
        settituloM("Menu Generar Nomina.");
        setcuerpoM("Debe elegir una Quincena, Gracias");
      }
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe elegir un Empleado, Gracias");
    }
  };

  const cargarFechas = () => {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        //let xdia = res.data.variable3;
        //dia: xdia,
        setmes(xmes);
        setanio(xanio);
      }
    });
  };
  const eliminarNomina = (e) => {
    let form = new FormData();
    form.append("idnomina", e);
    form.append("boton", "eliminarNomina");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setmensajealerta(true);
        settituloM("Menu Nomina.");
        setcuerpoM(res.data.variable2);
        buscarNomina();
      } else {
        setmensajealerta(true);
        settituloM("Menu Nomina.");
        setcuerpoM(res.data.variable2);
      }
    });
  };

  const cargarCuentas = () => {
    let form = new FormData();
    form.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/contabilidad.php";
    axios.post(xurl, form, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      setcuenta(res.data.variable1);
    });
  };
  const activarReporte2 = () => {
    if (flotadorNOn) {
      setflotadorNOn(false);
    } else {
      setflotadorNOn(true);
    }
  };
  const activarReporte3 = () => {
    if (flotador3NOn) {
      setflotador3NOn(false);
    } else {
      setflotador3NOn(true);
    }
  };
  const activarReporte4 = () => {
    if (flotador4NOn) {
      setflotador4NOn(false);
    } else {
      setflotador4NOn(true);
    }
  };

  const adicionarTra = () => {
    if (flotador2NOn) {
      setflotador2NOn(false);
    } else {
      setflotador2NOn(true);
    }
  };

  const ocultarCuentas = () => {
    if (ocultarOn) {
      setocultarOn(false);
    } else {
      setocultarOn(true);
    }
  };

  const calcularSumaValorEntrada1 = (
    index,
    value,
    diasNT,
    ssop,
    seduP,
    tipoNo
  ) => {
    let nominaP_ = Array.from(nominaPersonal);
    nominaP_[index].valorEntrada1 = value;
    // console.log(parseFloat(value))
    // console.log(nominaP_[index].valorEntrada1)
    // console.log(diasNT)
    // console.log(nominaP_[index].variable5)
    nominaP_[index].variable5 = parseFloat(value * diasNT).toFixed(2);

    nominaP_[index].variable8 = parseFloat(
      ((nominaP_[index].variable3 -
        nominaP_[index].variable5 -
        nominaP_[index].variable7 -
        nominaP_[index].variable34) *
        ssop) /
        100
    ).toFixed(2);
    nominaP_[index].variable9 = parseFloat(
      ((nominaP_[index].variable3 -
        nominaP_[index].variable5 -
        nominaP_[index].variable7 -
        nominaP_[index].variable34) *
        seduP) /
        100
    ).toFixed(2);
    //console.log(tipoNo)
    if (tipoNo.substring(0, 4) !== "HONO") {
      //console.log(tipoNo)
      if (
        nominaP_[index].variable3 -
          nominaP_[index].variable5 -
          nominaP_[index].variable7 -
          nominaP_[index].variable34 >=
        424
      ) {
        nominaP_[index].variable10 = parseFloat(
          ((nominaP_[index].variable3 -
            nominaP_[index].variable5 -
            nominaP_[index].variable7 -
            nominaP_[index].variable34 -
            423.07) *
            15) /
            100
        ).toFixed(2);
      } else {
        nominaP_[index].variable10 = 0;
      }
    }

    nominaP_[index].variable11 = parseFloat(
      nominaP_[index].variable3 -
        nominaP_[index].variable5 -
        nominaP_[index].variable7 -
        nominaP_[index].variable34 -
        nominaP_[index].variable8 -
        nominaP_[index].variable9 -
        nominaP_[index].variable10
    ).toFixed(2);
    nominaP_[index].variable13 = parseFloat(
      nominaP_[index].variable11 - nominaP_[index].variable12
    ).toFixed(2);
    setnominaPersonal(nominaP_);
  };

  const calcularSumaValorEntrada2 = (
    index,
    value,
    horasNT,
    ssop,
    seduP,
    tipoNo
  ) => {
    let nominaP_ = Array.from(nominaPersonal);
    nominaP_[index].valorEntrada2 = 0;
    nominaP_[index].valorEntrada2 = value;
    //  console.log(parseFloat(value))
    //  console.log(nominaP_[index].valorEntrada2)
    //  console.log(horasNT)
    // console.log(nominaP_[index].variable2);
    if (parseFloat(value) === 0) value = 0;
    nominaP_[index].variable7 = parseFloat(value * horasNT).toFixed(2);

    nominaP_[index].variable8 = parseFloat(
      ((nominaP_[index].variable3 -
        nominaP_[index].variable5 -
        nominaP_[index].variable7 -
        nominaP_[index].variable34) *
        ssop) /
        100
    ).toFixed(2);
    nominaP_[index].variable9 = parseFloat(
      ((nominaP_[index].variable3 -
        nominaP_[index].variable5 -
        nominaP_[index].variable7 -
        nominaP_[index].variable34) *
        seduP) /
        100
    ).toFixed(2);

    if (tipoNo.substring(0, 4) !== "HONO") {
      if (
        nominaP_[index].variable3 -
          nominaP_[index].variable5 -
          nominaP_[index].variable7 -
          nominaP_[index].variable34 >=
        424
      ) {
        nominaP_[index].variable10 = parseFloat(
          ((nominaP_[index].variable3 -
            nominaP_[index].variable5 -
            nominaP_[index].variable7 -
            nominaP_[index].variable34 -
            423.07) *
            15) /
            100
        ).toFixed(2);
      } else {
        nominaP_[index].variable10 = 0;
      }
    }
    nominaP_[index].variable11 = parseFloat(
      nominaP_[index].variable3 -
        nominaP_[index].variable5 -
        nominaP_[index].variable7 -
        nominaP_[index].variable34 -
        nominaP_[index].variable8 -
        nominaP_[index].variable9 -
        nominaP_[index].variable10
    ).toFixed(2);
    nominaP_[index].variable13 = parseFloat(
      nominaP_[index].variable11 - nominaP_[index].variable12
    ).toFixed(2);
    setnominaPersonal(nominaP_);
  };

  const calcularSumaValorEntrada3 = (index, value, restaP) => {
    if (value <= restaP) {
      let nominaP_ = Array.from(nominaPersonal);
      nominaP_[index].valorEntrada3 = 0;
      nominaP_[index].valorEntrada3 = value;

      nominaP_[index].variable12 = value;
      nominaP_[index].variable13 = parseFloat(
        nominaP_[index].variable11 - value
      ).toFixed(2);
      setnominaPersonal(nominaP_);
    }
  };

  const calcularSumaValorEntrada4 = (index, value, salarioHora, tipoC, ssop, seduP) => {
    let nominaP_ = Array.from(nominaPersonal);
    nominaP_[index].valorEntrada4 = 0;
    nominaP_[index].valorEntrada4 = value;
    console.log(parseFloat(value));
    console.log(nominaP_[index].valorEntrada4);
    // console.log(horasNT)
    //console.log(nominaP_[index].variable2);
    if (parseFloat(value) === 0) value = 0;
    nominaP_[index].variable21 = parseFloat(value * salarioHora).toFixed(2);

    if (tipoC !== "5") {
      nominaP_[index].variable3 =
        parseFloat(nominaP_[index].variable23) +
        parseFloat(value * salarioHora);
    }
    if (tipoC === "5") {
        nominaP_[index].variable3 = ((parseFloat(nominaP_[index].variable36)*parseFloat(nominaP_[index].variable15))+
        parseFloat(nominaP_[index].variable23) + parseFloat(value * salarioHora))

        nominaP_[index].variable8 = parseFloat(
          ((nominaP_[index].variable3 -
            nominaP_[index].variable5 -
            nominaP_[index].variable7 -
            nominaP_[index].variable34) *
            ssop) /
            100
        ).toFixed(2);

        nominaP_[index].variable9 = parseFloat(
          ((nominaP_[index].variable3 -
            nominaP_[index].variable5 -
            nominaP_[index].variable7 -
            nominaP_[index].variable34) *
            seduP) /
            100
        ).toFixed(2);

        if (
          nominaP_[index].variable3 -
            nominaP_[index].variable5 -
            nominaP_[index].variable7 -
            nominaP_[index].variable34 >=
          424
        ) {
          nominaP_[index].variable10 = parseFloat(
            ((nominaP_[index].variable3 -
              nominaP_[index].variable5 -
              nominaP_[index].variable7 -
              nominaP_[index].variable34 -
              423.07) *
              15) /
              100
          ).toFixed(2);
        } else {
          nominaP_[index].variable10 = 0;
        }
    
    }

    nominaP_[index].variable11 = parseFloat(
      nominaP_[index].variable3 -
        nominaP_[index].variable5 -
        nominaP_[index].variable7 -
        nominaP_[index].variable34 -
        nominaP_[index].variable8 -
        nominaP_[index].variable9 -
        nominaP_[index].variable10
    ).toFixed(2);
    nominaP_[index].variable13 = parseFloat(
      nominaP_[index].variable11 - nominaP_[index].variable12
    ).toFixed(2);
    setnominaPersonal(nominaP_);
  };

  const calcularSumaValorEntrada5 = (
    index,
    value,
    horasNT,
    ssop,
    seduP,
    tipoNo
  ) => {
    let nominaP_ = Array.from(nominaPersonal);
    //nominaP_[index].variable34 = 0;
    nominaP_[index].variable34 = value;

    // nominaP_[index].variable8 = parseFloat(
    //   ((nominaP_[index].variable3 -
    //     nominaP_[index].variable5 -
    //     nominaP_[index].variable7 -
    //     nominaP_[index].variable34) *
    //     ssop) /
    //     100
    // ).toFixed(2);
    // nominaP_[index].variable9 = parseFloat(
    //   ((nominaP_[index].variable3 -
    //     nominaP_[index].variable5 -
    //     nominaP_[index].variable7 -
    //     nominaP_[index].variable34) *
    //     seduP) /
    //     100
    // ).toFixed(2);
    // if (tipoNo.substring(0, 4) !== "HONO") {
    //   if (
    //     nominaP_[index].variable3 -
    //       nominaP_[index].variable5 -
    //       nominaP_[index].variable7 -
    //       nominaP_[index].variable34 >=
    //     424
    //   ) {
    //     nominaP_[index].variable10 = parseFloat(
    //       ((nominaP_[index].variable3 -
    //         nominaP_[index].variable5 -
    //         nominaP_[index].variable7 -
    //         nominaP_[index].variable34 -
    //         423.07) *
    //         15) /
    //         100
    //     ).toFixed(2);
    //   } else {
    //     nominaP_[index].variable10 = 0;
    //   }
    // }

    nominaP_[index].variable11 = parseFloat(
      nominaP_[index].variable3 -
        nominaP_[index].variable5 -
        nominaP_[index].variable7 -
        nominaP_[index].variable34 -
        nominaP_[index].variable8 -
        nominaP_[index].variable9 -
        nominaP_[index].variable10
    ).toFixed(2);
    nominaP_[index].variable13 = parseFloat(
      nominaP_[index].variable11 - nominaP_[index].variable12
    ).toFixed(2);
    setnominaPersonal(nominaP_);
  };

  const calcularSumaValorEntrada6 = (index, value, ssop, seduP, valorHora) => {
    let nominaP_ = Array.from(nominaPersonal);
    //nominaP_[index].variable34 = 0;
    nominaP_[index].variable36 = value;

    // nominaP_[index].variable8 = parseFloat(
    //   ((nominaP_[index].variable3 -
    //     nominaP_[index].variable5 -
    //     nominaP_[index].variable7 -
    //     nominaP_[index].variable34) *
    //     ssop) /
    //     100
    // ).toFixed(2);
    // nominaP_[index].variable9 = parseFloat(
    //   ((nominaP_[index].variable3 -
    //     nominaP_[index].variable5 -
    //     nominaP_[index].variable7 -
    //     nominaP_[index].variable34) *
    //     seduP) /
    //     100
    // ).toFixed(2);
    // if (tipoNo.substring(0, 4) !== "HONO") {
    //   if (
    //     nominaP_[index].variable3 -
    //       nominaP_[index].variable5 -
    //       nominaP_[index].variable7 -
    //       nominaP_[index].variable34 >=
    //     424
    //   ) {
    //     nominaP_[index].variable10 = parseFloat(
    //       ((nominaP_[index].variable3 -
    //         nominaP_[index].variable5 -
    //         nominaP_[index].variable7 -
    //         nominaP_[index].variable34 -
    //         423.07) *
    //         15) /
    //         100
    //     ).toFixed(2);
    //   } else {
    //     nominaP_[index].variable10 = 0;
    //   }
    // }

    nominaP_[index].variable3 =parseFloat(
      parseFloat(nominaP_[index].variable21) + parseFloat(value * valorHora)).toFixed(0);

      nominaP_[index].variable8 = parseFloat(
        ((nominaP_[index].variable3 -
          nominaP_[index].variable5 -
          nominaP_[index].variable7 -
          nominaP_[index].variable34) *
          ssop) /
          100
      ).toFixed(2);

      nominaP_[index].variable9 = parseFloat(
        ((nominaP_[index].variable3 -
          nominaP_[index].variable5 -
          nominaP_[index].variable7 -
          nominaP_[index].variable34) *
          seduP) /
          100
      ).toFixed(2);

      if (
        nominaP_[index].variable3 -
          nominaP_[index].variable5 -
          nominaP_[index].variable7 -
          nominaP_[index].variable34 >=
        424
      ) {
        nominaP_[index].variable10 = parseFloat(
          ((nominaP_[index].variable3 -
            nominaP_[index].variable5 -
            nominaP_[index].variable7 -
            nominaP_[index].variable34 -
            423.07) *
            15) /
            100
        ).toFixed(2);
      } else {
        nominaP_[index].variable10 = 0;
      }
  
      nominaP_[index].variable11 = parseFloat(
        nominaP_[index].variable3 -
          nominaP_[index].variable5 -
          nominaP_[index].variable7 -
          nominaP_[index].variable34 -
          nominaP_[index].variable8 -
          nominaP_[index].variable9 -
          nominaP_[index].variable10
      ).toFixed(2);
      nominaP_[index].variable13 = parseFloat(
        nominaP_[index].variable11 - nominaP_[index].variable12
      ).toFixed(2);

    // nominaP_[index].variable11 = parseFloat(
    //   nominaP_[index].variable3 -
    //     nominaP_[index].variable5 -
    //     nominaP_[index].variable7 -
    //     nominaP_[index].variable34 -
    //     nominaP_[index].variable8 -
    //     nominaP_[index].variable9 -
    //     nominaP_[index].variable10
    // ).toFixed(2);
    // nominaP_[index].variable13 = parseFloat(
    //   nominaP_[index].variable11 - nominaP_[index].variable12
    // ).toFixed(2);
    setnominaPersonal(nominaP_);
  };

  const prioridad = () => {
    console.log("paso");
  };

  const consultaPrestamo = (x) => {
    if (parseFloat(x) > 0) {
      setmensajealerta(true);
      settituloM("Menu Nomina.");
      setcuerpoM("Deuda del Trabajador por Prestamo = " + x);
      buscarNomina();
    } else {
      setmensajealerta(true);
      settituloM("Menu Nomina.");
      setcuerpoM("El trabajador no tiene Prestamo Pendiente");
    }
  };

  const datosBanco = () => {
    if (quincena.length > 0 && mesapagar.length > 0 && anopagar > 0) {
      let form = new FormData();
      form.append("quincena", quincena);
      form.append("mesapagar", mesapagar);
      form.append("anoapagar", anopagar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteDatosBancarios.php",
        responseType: "arraybuffer",
        data: form,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      setmensajealerta(true);
      settituloM("Reporte Datos Bancarios.");
      setcuerpoM("Debe seleccionar la Quincena, mes y año de la Nomina");
    }
  };

  const registroContable = () => {
    if (mesapagar.length > 0 && anopagar > 0) {
      let form = new FormData();
      form.append("mesapagar", mesapagar);
      form.append("anoapagar", anopagar);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteRegistroContable.php",
        responseType: "arraybuffer",
        data: form,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      setmensajealerta(true);
      settituloM("Reporte Registro Codigo Contable.");
      setcuerpoM("Debe seleccionar el Mes y Año.");
    }
  };

  const empleadoCorreos = () => {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteEmpleadosCorreo.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  const empleadoCorreos2 = () => {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteEmpleadosSalarios.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  const empleadoSalarioExcel = () => {
    let data = new FormData();
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteEmpleadosSalariosExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "SalariosExcel.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  const asistencia = () => {
    if (asistenciaOn) {
      setAsistenciaOn(false);
    } else {
      setAsistenciaOn(true);
    }
  };

  const deduc = () => {
    if (deducOn) {
      setDdeducOn(false);
    } else {
      setDdeducOn(true);
    }
  };

  const deducExcel = () => {
    let data = new FormData();
    data.append("desde", desde);
    data.append("hasta", hasta);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteNominaDeducciones.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "Deducciones.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  const asistenciaExcel = () => {
    let data = new FormData();
    data.append("desde", desde);
    data.append("hasta", hasta);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteAsistenciaPersonal.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "AsistenciaExcel.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  const empleadoCodigoContable = () => {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteEmpleadoCuentaContable.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  React.useEffect(() => {
    //console.log(props.cedulaempleado);
    buscarPersonal();
    cargarFechas();
    cargarCuentas();
  }, []);
  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        {/* <div className="titulo-cxc ">
          <p>CREAR NOMINA DE EMPLEADOS. </p>
        </div> */}
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        {flotadorNOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <ReporteN personalClose={activarReporte2} />
            </div>
          </div>
        )}
        {flotador3NOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <ReporteN2 personalClose={activarReporte3} />
            </div>
          </div>
        )}
        {flotador4NOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <ReporteN3 personalClose={activarReporte4} />
            </div>
          </div>
        )}

        {flotador2NOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  Adicionar Trabajador a la Nomina
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    adicionarTra();
                  }}
                >
                  X
                </span>
              </div>

              <div className="cont-personal col-4">
                <label className="titulo-salario2">
                  Seleccione un Empleado.
                  <select
                    id="cedulaNomina"
                    name="cedulaNomina"
                    value={
                      typeof data.cedulaNomina !== "undefined"
                        ? data.cedulaNomina
                        : ""
                    }
                    onChange={onChange}
                  >
                    <option value="0">-- Seleccione Empleado --</option>
                    {personal.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2 + " ==> " + item.variable3}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
              <div className="cont-listmedi row">
                <div className="cont-personal col-3">
                  <button className="boton" onClick={guardarNominaInd}>
                    Crear Nomina
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {asistenciaOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <div className="cont-titulomedi col-12">
                <span className="titulo-asistencia">
                  Reporte Asistencia Personal
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    asistencia();
                  }}
                >
                  X
                </span>
              </div>

              <div className="cont-personal col-3">
                <label className="titulo-asistencia">
                  Fecha desde.
                  <input
                    className="form-control"
                    type="date"
                    name="desde"
                    id="desde"
                    autoComplete="off"
                    // placeholder="Hora Entrada 07:00"
                    // maxLength="5"
                    onChange={onChange1}
                    value={desde}
                  />
                </label>
              </div>
              <div className="cont-personal col-3">
                <label className="titulo-asistencia">
                  Fecha Hasta.
                  <input
                    className="form-control"
                    type="date"
                    name="hasta"
                    id="hasta"
                    autoComplete="off"
                    // placeholder="Hora de Salida 12:00"
                    // maxLength="5"
                    onChange={onChange2}
                    value={hasta}
                  />
                </label>
              </div>
              <div className="cont-listmedi row">
                <div className="cont-personal col-3">
                  <button className="boton" onClick={asistenciaExcel}>
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {deducOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <div className="cont-titulomedi col-12">
                <span className="titulo-asistencia">
                  Reporte Deducciones Personal
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    deduc();
                  }}
                >
                  X
                </span>
              </div>

              <div className="cont-personal col-3">
                <label className="titulo-asistencia">
                  Fecha desde.
                  <input
                    className="form-control"
                    type="date"
                    name="desde"
                    id="desde"
                    autoComplete="off"
                    // placeholder="Hora Entrada 07:00"
                    // maxLength="5"
                    onChange={onChange1}
                    value={desde}
                  />
                </label>
              </div>
              <div className="cont-personal col-3">
                <label className="titulo-asistencia">
                  Fecha Hasta.
                  <input
                    className="form-control"
                    type="date"
                    name="hasta"
                    id="hasta"
                    autoComplete="off"
                    // placeholder="Hora de Salida 12:00"
                    // maxLength="5"
                    onChange={onChange2}
                    value={hasta}
                  />
                </label>
              </div>
              <div className="cont-listmedi row">
                <div className="cont-personal col-3">
                  <button className="boton" onClick={deducExcel}>
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {prioridadOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  Prioridad de Pago a la {quincena} Quincena {mesapagar}-
                  {anopagar}
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    cerrarPrioridad();
                  }}
                >
                  X
                </span>
              </div>
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  Monto Seleccionado{" "}
                  <span className="titulocasos1">
                    {totalPrioridad.toFixed(2)}
                  </span>{" "}
                  Monto Pagado{" "}
                  <span className="titulocasos1">
                    {totalPrioridadPagada.toFixed(2)}
                  </span>{" "}
                  Monto Por Pagar{" "}
                  <span className="titulocasos1">
                    {totalPrioridadPP.toFixed(2)}
                  </span>
                </span>
              </div>
              <span className="titulo-nomina"></span>

              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomi" border="1">
                    <thead className="theadcreartabla">
                      <tr className="trcreartabla">
                        <th className="xtitunomina2">Nombre Empleado.</th>
                        <th className="xtitunomina2">Tipo Nomina.</th>
                        <th className="xtitunomina3">Total a Pagar.</th>
                        <th className="xtitunomina3">Cargo.</th>
                        <th className="xtitunomina1">Prioridad.</th>
                        <th className="xtitunomina1">Pagado.</th>
                      </tr>
                    </thead>

                    <tbody className="evocuerpo1">
                      {nominaPersonal.map((item, i) => {
                        return (
                          <tr
                            className={
                              item.variable30 === "COLABO. C/DEDU. DEFI. ADM."
                                ? "odd1"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. DEFI. DOCENTE."
                                ? "odd2"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. DEFI. H/PROFE."
                                ? "odd3"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. INDEFI. ADM."
                                ? "odd4"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. INDEFI. DOCENTE."
                                ? "odd5"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. INDEFI. H/PROFE."
                                ? "odd6"
                                : item.variable30 === "HONO. PROFE. ADM."
                                ? "odd7"
                                : item.variable30 === "HONO. PROFE. DOCENTE."
                                ? "odd8"
                                : item.variable30 === "HONO. PROFESIONALES."
                                ? "odd9"
                                : ""
                            }
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="nombrePersonal"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable2}
                            </td>
                            <td
                              className="nombrePersonal"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable30}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable13}
                            </td>
                            <td
                              className="cargoo"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable31}
                            </td>

                            <td width="20">
                              {item.variable33 !== "SI" ? (
                                <input
                                  type="radio"
                                  className="opvacunacionsi"
                                  name={item.variable1}
                                  id={item.variable1}
                                  value="A"
                                  checked={item.variable32 === "A"}
                                  onClick={() => {
                                    onChange4(item.variable20, item.variable1);
                                  }}
                                />
                              ) : (
                                <span className="pagado">Pagado</span>
                              )}
                            </td>
                            <td width="20">
                              {item.variable32 === "A" && (
                                <input
                                  type="radio"
                                  className="pagadosi"
                                  name={item.variable1}
                                  id={item.variable1}
                                  value="SI"
                                  checked={item.variable33 === "SI"}
                                  onClick={() => {
                                    onChange5(item.variable20, item.variable1);
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {listaOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  Nomina Creada a la {quincena} Quincena {mesapagar}-{anopagar}
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    cerrarLista();
                  }}
                >
                  X
                </span>
                <div className="cont-listmedi row">
                  <div className="cont-personal col-2">
                    <button className="boton" onClick={actualizarNomina}>
                      Actualizar Nomina
                    </button>
                  </div>
                  <div className="cont-personal col-2">
                    <button className="boton" onClick={adicionarTra}>
                      Adicionar Trabajador a la Nomina
                    </button>
                  </div>
                  <div className="cont-personal col-2">
                    {ocultarOn === false && (
                      <button className="boton" onClick={ocultarCuentas}>
                        Ocultar Cuentas
                      </button>
                    )}
                    {ocultarOn === true && (
                      <button className="boton" onClick={ocultarCuentas}>
                        Mostrar Cuentas
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <span className="titulo-nomina"></span>

              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomi" border="1">
                    <thead className="theadcreartabla">
                      <tr className="trcreartabla">
                        <th className="xtitunomina2">Nombre Empleado.</th>
                        <th className="xtitunomina1">H/tra.</th>
                        <th className="xtitunomina1">$/Hora.</th>
                        <th className="xtitunomina1">H/Ext.</th>
                        <th className="xtitunomina1">Monto H/E.</th>
                        <th className="xtitunomina3">S.Quin.</th>
                        <th className="xtitunomina1">Dias N/T.</th>
                        <th className="xtitunomina1">Monto D/N/T.</th>
                        <th className="xtitunomina1">Horas N/T.</th>
                        <th className="xtitunomina1">Monto H/N/T.</th>
                        <th className="xtitunomina1">S.S.O.</th>
                        <th className="xtitunomina1">S.Edu.</th>
                        <th className="xtitunomina1">I.S.L.R.</th>

                        <th className="xtitunomina1">O./Deducc.</th>
                        {ocultarOn === false && (
                          <th className="xtitunomina1">Cuenta</th>
                        )}
                        <th className="xtitunomina3">Sub-Total.</th>
                        <th className="xtitunomina3">Monto P.</th>
                        <th className="xtitunomina1">P.Pres.</th>
                        <th className="xtitunomina3">Total a Pagar.</th>
                        <th className="xtitunomina3">Cargo.</th>
                        <th className="xtitunomina1">
                          <span className="cerrarme3"></span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="evocuerpo1">
                      {nominaPersonal.map((item, i) => {
                        return (
                          <tr
                            className={
                              item.variable30 === "COLABO. C/DEDU. DEFI. ADM."
                                ? "odd1"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. DEFI. DOCENTE."
                                ? "odd2"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. DEFI. H/PROFE."
                                ? "odd3"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. INDEFI. ADM."
                                ? "odd4"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. INDEFI. DOCENTE."
                                ? "odd5"
                                : item.variable30 ===
                                  "COLABO. C/DEDU. INDEFI. H/PROFE."
                                ? "odd6"
                                : item.variable30 === "HONO. PROFE. ADM."
                                ? "odd7"
                                : item.variable30 === "HONO. PROFE. DOCENTE."
                                ? "odd8"
                                : item.variable30 === "HONO. PROFESIONALES."
                                ? "odd9"
                                : item.variable30 ===
                                  "CONTRATO POR HORA DEFINIDO."
                                ? "odd10"
                                : ""
                            }
                            key={item.variable1}
                            id="tabla"
                          >
                            {/* <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable1}
                            </td> */}
                            <td
                              className="nombrePersonal"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable2 + " - " + item.variable30}
                            </td>
                            <td width="20">
                              {/* {parseFloat(item.variable36) === 0 ? ( */}
                                <div className="contenedorcedulaNomi col-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name={item.variable1}
                                    id="horasT"
                                    autoComplete="off"
                                    step="any"
                                    readOnly={
                                      item.variable37 !== "5" ? "readonly" : ""
                                    }
                                    onChange={(e) =>
                                      calcularSumaValorEntrada6(
                                        i,
                                        e.target.value,
                                        item.variable28,
                                        item.variable29,
                                        item.variable15
                                      )
                                    }
                                    //value={item.valorEntrada}
                                    value={
                                      parseFloat(item.variable36) > 0
                                        ? item.variable36
                                        : ""
                                    }
                                  />
                                </div>
                              {/* // ) : (
                              //   item.variable27
                              // )} */}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable15}
                            </td>
                            <td width="20">
                              {parseFloat(item.variable22) === 0 ? (
                                <div className="contenedorcedulaNomi col-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name={item.variable1}
                                    id="horasE"
                                    autoComplete="off"
                                    step="any"
                                    onChange={(e) =>
                                      calcularSumaValorEntrada4(
                                        i,
                                        e.target.value,
                                        item.variable15,
                                        item.variable37,
                                        item.variable28,
                                        item.variable29,
                                      )
                                    }
                                    //value={item.valorEntrada}
                                    value={
                                      parseFloat(item.variable21) > 0
                                        ? item.valorEntrada4
                                        : ""
                                    }
                                  />
                                </div>
                              ) : (
                                item.variable27
                              )}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable21}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable3}
                            </td>

                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              // onClick={() => {
                              //   consultaRecibo(item.variable1);
                              // }}
                            >
                              {parseFloat(item.variable17) === 0 ? (
                                <div className="contenedorcedula col-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name={item.variable1}
                                    id="horasE"
                                    autoComplete="off"
                                    step="any"
                                    readOnly={
                                      item.variable37 === "5" ? "readonly" : ""
                                    }
                                    onChange={(e) =>
                                      calcularSumaValorEntrada1(
                                        i,
                                        e.target.value,
                                        item.variable14,
                                        item.variable28,
                                        item.variable29,
                                        item.variable30
                                      )
                                    }
                                    //value={item.valorEntrada}
                                    value={
                                      parseFloat(item.variable5) > 0
                                        ? item.valorEntrada1
                                        : ""
                                    }
                                  />
                                </div>
                              ) : (
                                item.variable25
                              )}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable5}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              // onClick={() => {
                              //   consultaRecibo(item.variable1);
                              // }}
                            >
                              {parseFloat(item.variable18) === 0 ? (
                                <div className="contenedorcedula col-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name={item.variable1}
                                    id="horasE"
                                    autoComplete="off"
                                    step="any"
                                    readOnly={
                                      item.variable37 === "5" ? "readonly" : ""
                                    }
                                    onChange={(e) =>
                                      calcularSumaValorEntrada2(
                                        i,
                                        e.target.value,
                                        item.variable15,
                                        item.variable28,
                                        item.variable29,
                                        item.variable30
                                      )
                                    }
                                    //value={item.valorEntrada}
                                    value={
                                      parseFloat(item.variable7) >= 0
                                        ? item.valorEntrada2
                                        : ""
                                    }
                                  />
                                </div>
                              ) : (
                                parseFloat(item.variable26).toFixed(2)
                              )}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable7}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable8}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable9}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable10}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              // onClick={() => {
                              //   consultaRecibo(item.variable1);
                              // }}
                            >
                              <div className="contenedorcedula col-12">
                                <input
                                  className="form-control"
                                  type="text"
                                  name={item.variable1}
                                  id="otrosEgresos"
                                  autoComplete="off"
                                  step="any"
                                  // readOnly={
                                  //   item.variable37 === "5" ? "readonly" : ""
                                  // }
                                  onChange={(e) =>
                                    calcularSumaValorEntrada5(
                                      i,
                                      e.target.value,
                                      item.variable15,
                                      item.variable28,
                                      item.variable29,
                                      item.variable30
                                    )
                                  }
                                  //value={item.valorEntrada}
                                  value={
                                    parseFloat(item.variable34) > 0
                                      ? item.variable34
                                      : ""
                                  }
                                />
                              </div>
                            </td>
                            {ocultarOn === false && (
                              <td
                                className="contenedorcedulaNomi"
                                width="20"
                                // onClick={() => {
                                //   consultaRecibo(item.variable1);
                                // }}
                              >
                                {parseFloat(item.variable34) > 0 ? (
                                  <div className="contenedorcedula col-12">
                                    <select
                                      id={item.variable1}
                                      name={item.variable1}
                                      value={
                                        item.variable35 !== ""
                                          ? item.variable35
                                          : ""
                                      }
                                      onChange={onChange6}
                                      onChange={(e) =>
                                        onChange6(i, e.target.value)
                                      }
                                    >
                                      <option>Cuenta Contable</option>

                                      {cuenta.map((item, i) => {
                                        return (
                                          <option
                                            key={i}
                                            value={item.variable2}
                                          >
                                            {item.variable6 +
                                              " - " +
                                              item.variable3}{" "}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </td>
                            )}

                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable11}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              // onClick={() => {
                              //   consultaRecibo(item.variable1);
                              // }}
                            >
                              {parseFloat(item.variable16) > 0 &&
                              parseFloat(item.variable19) === 0 ? (
                                <div className="contenedorcedula col-12">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name={item.variable1}
                                    id={item.variable1}
                                    autoComplete="off"
                                    step="any"
                                    // readOnly={
                                    //   item.variable37 !== "1" ? "readonly" : ""
                                    // }
                                    onChange={(e) =>
                                      calcularSumaValorEntrada3(
                                        i,
                                        e.target.value,
                                        item.variable16
                                      )
                                    }
                                    onKeyPress={(e) =>
                                      calcularSumaValorEntrada3(
                                        i,
                                        e.target.value,
                                        item.variable16
                                      )
                                    }
                                    //value={item.valorEntrada}
                                    value={
                                      parseFloat(item.valorEntrada3) > 0
                                        ? item.valorEntrada3
                                        : item.variable24
                                    }
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                consultaPrestamo(item.variable16);
                              }}
                            >
                              {item.variable12}
                            </td>
                            <td
                              className="contenedorcedulaNomi"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable13}
                            </td>
                            <td
                              className="cargoo"
                              width="20"
                              onClick={() => {
                                //consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable31}
                            </td>

                            <td width="20">
                              <span
                                className="cerrarme1"
                                onClick={() => {
                                  eliminarNomina(item.variable1);
                                }}
                              ></span>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="cont-listmedi row">
          <div className="cont-personal col-3">
            <label className="titulo-salario2">
              Quincena a Pagar.
              <select
                id="quincena"
                name="quincena"
                value={typeof quincena !== "undefined" ? quincena : ""}
                onChange={onChange}
              >
                <option value="0">Seleccione Quincena</option>
                <option value="1">Primera Quincena.</option>
                <option value="2">Segunda Quincena.</option>
              </select>
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Mes a Pagar.
              <select
                id="mesapagar"
                name="mesapagar"
                value={typeof mesapagar !== "undefined" ? mesapagar : ""}
                onChange={onChange}
              >
                <option value="0">Mes a Pagar</option>
                {mes.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Año a Pagar.
              <select
                id="anopagar"
                name="anopagar"
                value={typeof anopagar !== "undefined" ? anopagar : ""}
                onChange={onChange}
              >
                <option value="0">Año a Pagar</option>
                {anio.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable1}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
        <div className="cont-listmedi row">
          <div className="cont-personal col-3">
            <button className="boton" onClick={guardarNomina}>
              Crear Nomina
            </button>
          </div>
          <div className="cont-listmedi row">
            <div className="titulo-cxc2 col-10 ">
              <p className="confi">CONSULTAS Y REPORTES. </p>
            </div>
          </div>
        </div>
        <div className="contenedorcedula1 col-12">
          {/* <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? guardarNomina : ""}
          >
            Crear Nomina
          </button> */}
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? cerrarLista : ""}
          >
            Consultar Nomina
          </button>
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? activarReporte2 : ""}
          >
            Reporte Quincenal
          </button>

          {props.usuario1.nivel !== "10" && (
            <button
              className={!activarOn ? "boton" : "botoncp"}
              onClick={!activarOn ? activarReporte3 : ""}
            >
              Reporte Quincenal de Pago
            </button>
          )}
          {props.usuario1.nivel !== "10" && (
            <button
              className={!activarOn ? "boton" : "botoncp"}
              onClick={!activarOn ? cerrarPrioridad : ""}
            >
              Crear Prioridad Pago
            </button>
          )}
          {props.usuario1.nivel !== "10" && (
            <button
              className={!activarOn ? "boton" : "botoncp"}
              onClick={!activarOn ? activarReporte4 : ""}
            >
              Reporte Prioridad Pago
            </button>
          )}
          <button className="boton" onClick={datosBanco}>
            Reporte Datos Bancarios
          </button>
          <button className="boton" onClick={limpiar}>
            Limpiar Datos
          </button>
          <button className="boton" onClick={registroContable}>
            Reporte SIPE
          </button>
          <button className="boton" onClick={empleadoCodigoContable}>
            Reporte Empleados Codigo Contable
          </button>
          <button className="boton" onClick={empleadoCorreos}>
            Reporte Empleados Correos
          </button>
          <button className="boton" onClick={empleadoCorreos2}>
            Reporte Empleados Salarios
          </button>
          <button className="boton" onClick={empleadoSalarioExcel}>
            Reporte Empleados Salarios Excel
          </button>
          <button className="boton" onClick={asistencia}>
            Reporte Asistencia Empleados Excel
          </button>
          <button className="boton" onClick={deduc}>
            Reporte Deducciones Nomina Excel
          </button>
          <button className="boton" onClick={props.personalClose}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(NominaC);
