import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./registroPagoNomina.css";
import FeatherIcon from "feather-icons-react";
import ModificarMonto from "../modificarMonto/generarPagosC";
import Alert from "../../modal/alert";
import Comprobante from "../comprobante/comprobante";

class CxPAbonos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idprofesor: "",
      quincena: "",
      mes: "",
      monto3: "",
      monto4: "",
      banco: "",
      referencia: "",
      tpago: "",
      openvio: "",
      fechaE: moment().format("YYYY-MM-DD"),
      anoEscolar: moment().format("YYYY"),
      ianoEscolar: [],
      iprofesor: [],
      pagosNomina: [],

      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      activarPago: false,
      proveedores: [],
      cxpproveedor: [],
      nominaPendiente: [],
      listaNominaOn: false,
      listaNomina2On: false,
      nominaCreadaOn: false,
      tipoNomina: "",
      tNomina: "",
      fechaAprobada: "",
      idnomina: "",
      botonAsientoOn: false,
      otrosDescuentos: "",
      otrosCodigoC: "",
    };
  }

  limpiar = () => {
    this.setState({
      idprofesor: "",
      quincena: "",
      mes: "",
      monto3: "",
      monto4: "",
      banco: "",
      referencia: "",
      tpago: "",
      openvio: "",
      fechaE: moment().format("YYYY-MM-DD"),
      anoEscolar: moment().format("YYYY"),
      pagosNomina: [],
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      activarPago: false,
      nominaPendiente: [],
      listaNominaOn: false,
      listaNomina2On: false,
      nominaCreadaOn: false,
      idnomina: "",
      botonAsientoOn: false,
      otrosDescuentos: "",
      otrosCodigoC: "",
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  cerrarListaNomina = () => {
    //console.log(listaOn)
    if (this.state.listaNominaOn) {
      this.setState({
        listaNominaOn: false,
      });
    } else {
      this.setState({
        listaNominaOn: true,
      });
    }
    //console.log(listaOn);
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange1(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarProfesor(e.target.value);
  }

  onChange2(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarPago(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.consultarPago2(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  consultaNominaI = (e, f, g, h) => {
    let data = new FormData();
    data.append("idnomina", e);
    data.append("tipoNomina", f);
    data.append("montoDeuda", g);
    data.append("tipoNominaPersonal", h);
    data.append("boton", "consultaNominaI");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xpagosN = res.data.variable6;
        if (this.state.nominaCreadaOn) {
          this.setState({
            activarPago: false,
            listaNominaOn: false,
            listaNomina2On: false,
            quincena: res.data.variable2,
            mes: res.data.variable3,
            anoEscolar: res.data.variable4,
            monto3: res.data.variable5,
            monto4: res.data.variable5,
            pagosNomina: xpagosN,
            tipoNomina: res.data.variable7,
            fechaAprobada: res.data.variable8,
            idnomina: res.data.variable9,
            tNomina: res.data.variable11,
            otrosDescuentos: res.data.variable12,
            otrosCodigoC: res.data.variable13,
          });
        } else {
          this.setState({
            activarPago: true,
            listaNominaOn: false,
            listaNomina2On: false,
            quincena: res.data.variable2,
            mes: res.data.variable3,
            anoEscolar: res.data.variable4,
            monto3: res.data.variable5,
            monto4: res.data.variable5,
            pagosNomina: xpagosN,
            tipoNomina: res.data.variable7,
            fechaAprobada: res.data.variable8,
            idnomina: res.data.variable9,
            tNomina: res.data.variable11,
            otrosDescuentos: res.data.variable12,
            otrosCodigoC: res.data.variable13,
          });
        }
      } else {
        // let xcompras = res.data.variable2;
        // this.setState({
        //   pagosNomina: xcompras,
        // });
      }
    });
  };

  consultarPago = (e) => {
    let data = new FormData();
    data.append("anoEscolar", e);
    data.append("cedulaPersonal", this.state.idprofesor);
    data.append("boton", "consultarPagos");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      }
    });
  };

  consultarPago2 = (e) => {
    let data = new FormData();
    data.append("cedulaPersonal", e);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "consultarPago");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      }
    });
  };

  eliminarPago = (e) => {
    let data = new FormData();
    data.append("idpagoNomina", e);
    data.append("boton", "eliminarPago");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.consultarPago2(this.state.idprofesor);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Registrar Pago Nomina.",
            cuerpoM: "Se Elimino el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Registrar Pago Nomina.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  buscarNomina = () => {
    let data = new FormData();
    data.append("cedulaPersonal", this.state.idprofesor);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarNomina");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xnominaP = res.data.variable2;
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            nominaPendiente: xnominaP,
            listaNominaOn: true,
            //activarPago: true,
          });
          //this.limpiar();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Pago de Nomina.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  buscarNominaP = () => {
    let data = new FormData();
    data.append("cedulaPersonal", this.state.idprofesor);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarNominaP");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xnominaP = res.data.variable2;
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            nominaPendiente: xnominaP,
            listaNominaOn: true,
            nominaCreadaOn: true,
            //activarPago: true,
          });
          //this.limpiar();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Pago de Nomina.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  guardarCxP = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("cedulaPersonal", this.state.idprofesor);
    data.append("quincena", this.state.quincena);
    data.append("fechaAprobada", this.state.fechaAprobada);
    data.append("tipoNominaPersonal", this.state.tNomina);
    data.append("tipoNomina", this.state.tipoNomina);
    data.append("mes", this.state.mes);
    data.append("monto", this.state.monto3);
    data.append("monto4", this.state.monto4);
    data.append("banco", this.state.banco);
    data.append("fechaRegistro", this.state.fechaE);
    data.append("referencia", this.state.referencia);
    data.append("tpago", this.state.tpago);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("idnomina", this.state.idnomina);

    data.append("boton", "guardarPago");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.consultarPago2(this.state.idprofesor);
          //this.limpiar();
          this.setState({
            monto3: "",
            monto4: "",
            banco: "",
            referencia: "",
            tpago: "",
            listaNomina2On: true,
            mensajealerta: true,
            tituloM: "Menu Detalle de Compras.",
            cuerpoM: "Se Guardo el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Detalle de Compras.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  datosCobro = (e, f, g, h) => {
    if (this.state.registrarCOn) {
      this.setState({ registrarCOn: false });
      this.limpiar2();
    } else {
      this.consultarDetalleC(e);
      this.setState({
        idcompra: e,
        registrarCOn: true,
        xbeneficiario: f,
        xmonto1: g,
        xfecha: h,
      });
    }
  };

  buscarProfesor = (e) => {
    let form = new FormData();
    form.append("openvio", e);
    form.append("boton", "buscarPersonal4");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  enviarReciboCorreo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idpagoNomina", e);
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboEmpleadoCorreo.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Envio de Recibo por Correo.",
              cuerpoM: "Se envio el correo de forma correcta.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Envio de Recibo por Correo.",
              cuerpoM: "Ocurrio un error, Verifique por favor.",
            });
          }
        });
    }
  };

  imprimirRecibo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idpagoNomina", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboEmpleados.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  agregarItemAsientoIndividual2 = () => {
    let data = new FormData();
    data.append("idnomina", this.state.idnomina);
    data.append("claveUsu", this.props.usuario1.password);
    data.append("fechaAprobada", this.state.fechaAprobada);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("nombreNomina", this.state.tipoNomina);
    data.append("tipoNomina", this.state.tNomina);
    data.append("cedula", this.state.idprofesor);
    data.append("monto", this.state.monto3);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("monto5", this.state.monto3);
    data.append("otrosDescuentos", this.state.otrosDescuentos);
    data.append("otrosCodigoC", this.state.otrosCodigoC);
    data.append("anoEscolar", this.state.anoEscolar);

    data.append("boton", "agregarItemAsientoIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.guardarCxP();
        this.setState({
          loading: false,
        });
      });
  };


  agregarItemAsientoIndividual = () => {
    let data = new FormData();
    data.append("idnomina", this.state.idnomina);
    data.append("claveUsu", this.props.usuario1.password);
    data.append("fechaAprobada", this.state.fechaAprobada);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("nombreNomina", this.state.tipoNomina);
    data.append("tipoNomina", this.state.tNomina);
    data.append("cedula", this.state.idprofesor);
    data.append("monto", this.state.monto3);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("monto5", this.state.monto3);
    data.append("otrosDescuentos", this.state.otrosDescuentos);
    data.append("otrosCodigoC", this.state.otrosCodigoC);
    data.append("anoEscolar", this.state.anoEscolar);

    data.append("boton", "agregarItemAsientoIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          asientoOn: true,
          listaNominaOn: false,
          botonAsientoOn: true,
          loading: false,
        });
      });
  };

  asientoContable = () => {
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
      });
    }
  };

  componentDidMount() {
    this.buscarProfesor();
    this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc3 col-12">
          <div className="titulo-cxc "></div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.asientoOn && (
            <div className="flotadorPe">
              <div className="flotadorPe1">
                <Comprobante
                  claveUsuario={this.props.usuario1.password}
                  comprobanteClose={this.asientoContable}
                />
              </div>
            </div>
          )}

          {this.state.listaNominaOn && (
            <div className="cont-tablanomina row">
              <div className="tablanomina1 col-7">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Nominas Creadas Por Pagar</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={() => {
                      this.cerrarListaNomina();
                    }}
                  >
                    X
                  </span>
                  {/* <div className="cont-listmedi row">
                    <div className="cont-personal col-3">
                      <button className="boton" onClick={actualizarNomina}>
                        Actualizar Nomina
                      </button>
                    </div>
                  </div> */}
                </div>
                <span className="titulo-nomina"></span>

                <div className="cont-listnomi row">
                  <div className="listnomi col-12">
                    <table className="tablanomi" border="1">
                      <thead className="theadcreartabla">
                        <tr className="trcreartabla">
                          {/* <th className="xtitunomina1">item.</th> */}
                          <th className="xtitunomina1">Fecha Nomina.</th>
                          <th className="xtitunomina1">Quincena.</th>
                          <th className="xtitunomina3">Monto.</th>
                          <th className="xtitunomina3">Abonado.</th>
                          <th className="xtitunomina3">Deuda.</th>
                          <th className="xtitunomina3">Tipo Pago.</th>
                        </tr>
                      </thead>

                      <tbody className="evocuerpo1">
                        {this.state.nominaPendiente.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="nombrePersonal"
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8
                                  );
                                }}
                              >
                                {item.variable4}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8
                                  );
                                }}
                              >
                                {item.variable3}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8
                                  );
                                }}
                              >
                                {item.variable2}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8
                                  );
                                }}
                              >
                                {item.variable5}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8
                                  );
                                }}
                              >
                                {item.variable6}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8
                                  );
                                }}
                              >
                                {item.variable7}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            {/* {!this.state.activarPago && (
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="radio">
                    <label className="container">
                      N/Interna
                      <input
                        type="radio"
                        value="1"
                        name="openvio"
                        checked={this.state.openvio === "1"}
                        onChange={this.onChange1.bind(this)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="radio">
                    <label className="container">
                      N/Externa
                      <input
                        type="radio"
                        value="2"
                        name="openvio"
                        checked={this.state.openvio === "2"}
                        onChange={this.onChange1.bind(this)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            )} */}
            <div className="contenedorcedula col-4">
              <select
                id="idprofesor"
                name="idprofesor"
                value={this.state.idprofesor}
                onChange={this.onChange3.bind(this)}
              >
                <option>-- Seleccionar Profesor --</option>
                {this.state.iprofesor.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable3}
                    </option>
                  );
                })}
              </select>
            </div>

            {this.state.activarPago && (
              <div className="contenedorcedula col-2">
                <select
                  id="quincena"
                  name="quincena"
                  value={this.state.quincena}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Quincena --</option>
                  <option value="1"> 1ra. Quincena</option>
                  <option value="2"> 2da. Quincena</option>
                  <option value="3"> 1er Cuatrimestre</option>
                  <option value="4"> 2do Cuatrimestre</option>
                  <option value="5"> 3er Cuatrimestre</option>
                </select>
              </div>
            )}
            {this.state.activarPago && (
              <div className="contenedorcedula col-3">
                <select
                  id="mes"
                  name="mes"
                  value={this.state.mes}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Mes de Nomina --</option>
                  <option value="1"> Enero.</option>
                  <option value="2"> Febrero.</option>
                  <option value="3"> Marzo.</option>
                  <option value="4"> Abril.</option>
                  <option value="5"> Mayo.</option>
                  <option value="6"> Junio.</option>
                  <option value="7"> Julio.</option>
                  <option value="8"> Agosto.</option>
                  <option value="9"> Septiembre.</option>
                  <option value="10"> Octubre.</option>
                  <option value="11"> Noviembre.</option>
                  <option value="12"> Diciembre.</option>
                </select>
              </div>
            )}
            {this.state.activarPago && (
              <div className="contenedorcedula col-2">
                <select
                  id="anoEscolar"
                  name="anoEscolar"
                  value={this.state.anoEscolar}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Año Escolar --</option>
                  {this.state.ianoEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          {this.state.activarPago === true &&
          this.state.listaNomina2On === false ? (
            <div className="form-group row">
              <div className="contenedorcedula1f col-2">
                <select
                  id="tpago"
                  name="tpago"
                  value={this.state.tpago}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Forma de Pago --</option>
                  <option value="1"> Transferencia.</option>
                  <option value="2"> Efectivo.</option>
                  <option value="3"> Cheque.</option>
                </select>
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="date"
                  name="fechaE"
                  id="fechaE"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.fechaE.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula col-3">
                <select
                  id="banco"
                  name="banco"
                  value={this.state.banco}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Banco --</option>
                  <option value="1"> Banco BAC</option>
                  <option value="2"> Banco BANESCO</option>
                  <option value="5"> Banco GENERAL</option>
                  <option value="3"> Efectivo</option>
                  <option value="4"> Otros</option>
                </select>
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="text"
                  name="referencia"
                  id="referencia"
                  autoComplete="off"
                  placeholder="Referencia"
                  maxLength="200"
                  onChange={this.onChange.bind(this)}
                  value={this.state.referencia.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="text"
                  name="monto3"
                  id="monto3"
                  autoComplete="off"
                  placeholder="Monto"
                  maxLength="10"
                  onChange={this.onChange.bind(this)}
                  value={this.state.monto3}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="contenedorcedula2 col-12">
              {this.state.activarPago === false ||
              this.state.listaNomina2On === true ? (
                <button
                  className={this.state.guardarOn ? "botonOf" : "boton"}
                  onClick={this.state.guardarOn ? "" : this.buscarNomina}
                >
                  Nomina por Pagar
                </button>
              ) : (
                ""
              )}
              {this.state.activarPago === false ||
              this.state.listaNomina2On === true ? (
                <button
                  className={this.state.guardarOn ? "botonOf" : "boton"}
                  onClick={this.state.guardarOn ? "" : this.buscarNominaP}
                >
                  Nominas Pagadas
                </button>
              ) : (
                ""
              )}
              {this.state.activarPago === true &&
              this.state.listaNomina2On === false ? (
                <button
                  className={
                    this.state.botonAsientoOn === false ? "botonOf" : "boton"
                  }
                  onClick={
                    this.state.botonAsientoOn === false ? "" : this.agregarItemAsientoIndividual2
                  }
                >
                  Guardar
                </button>
              ) : (
                ""
              )}
              {this.state.activarPago === true &&
              this.state.listaNomina2On === false ? (
                <button
                  className="boton"
                  onClick={this.agregarItemAsientoIndividual}
                >
                  Asiento Contable
                </button>
              ) : (
                ""
              )}
              <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                disabled={!this.state.guardarOn}
                onClick={this.state.guardarOn ? this.modificarCxP : ""}
              >
                Modificar
              </button>
              <button className="boton" onClick={this.limpiar.bind(this)}>
                Limpiar
              </button>
              <button className="boton" onClick={this.props.personalClose}>
                Salir
              </button>
            </div>
          </div>
          {/* {this.state.modificarSaldoOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <ModificarMonto
                  idcxc={this.state.idcxc}
                  montoDebe={this.state.montoDebe}
                  montoAbono={this.state.montoAbono}
                  montoModificar={this.state.montoModificar}
                  idcontrolPago={this.state.idcontrolPago}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )} */}
          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">QUINCENA</th>
                    <th className="titucxc2X">MES</th>
                    <th className="titucxc1">MONTO</th>
                    <th className="titucxc1">BANCO</th>
                    <th className="titucxc1">REFERENCIA</th>
                    <th className="titucxc1">TIPO PAGO</th>
                    <th className="titucxc3p" width="10">
                      <FeatherIcon
                        icon="delete"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                    </th>
                    <th className="titucxc3p" width="10">
                      <FeatherIcon
                        icon="printer"
                        size="30px"
                        stroke="#999"
                        color="green"
                      />
                    </th>
                    <th className="titucxc3p" width="10">
                      <FeatherIcon
                        icon="mail"
                        size="30px"
                        stroke="#999"
                        color="green"
                      />
                    </th>
                  </tr>
                </thead>
                {this.state.pagosNomina.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxc" width="10">
                        {item.variable2}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable3}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable4}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable5}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable6}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable7}
                      </td>
                      <td className="titucxc3p" width="10">
                        <FeatherIcon
                          icon="delete"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.eliminarPago.bind(this, item.variable1)}
                        />
                      </td>
                      <td className="titucxc3p" width="10">
                        <FeatherIcon
                          icon="printer"
                          size="30px"
                          stroke="#999"
                          color="green"
                          onClick={this.imprimirRecibo.bind(
                            this,
                            item.variable1
                          )}
                        />
                      </td>
                      <td className="titucxc3p" width="10">
                        <FeatherIcon
                          icon="mail"
                          size="30px"
                          stroke="#999"
                          color="green"
                          onClick={this.enviarReciboCorreo.bind(
                            this,
                            item.variable1
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxPAbonos);
