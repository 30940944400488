import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import RingLoader from "react-spinners/ScaleLoader";
import AsyncSelect from "react-select/async-creatable";

//componentes
import "./cxp.css";
import Reporte from "./reporte";
import FacturasP from "./cxpFacturasPagas";
import CxPAbonos from "../cxpAbonos/cxpAbonos";
import CxPAbonosM from "../cxpAbonosMultiples/cxpAbonosM";
import Alert from "../../modal/alert";
import Comprobante from "../comprobante/comprobante";

class CxP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idcxp: "",
      fecha: "",
      fechaC: "",
      subtotal: "",
      subtotalC: "",
      impuesto: "",
      impuestoC: "",
      monto: "",
      montoC: "",
      montoT: "",
      saldo: "",
      banco: "",
      oferta: "NO",
      referencia: "",
      documento: "",
      descripcion1: "",
      tbusqueda: "",
      guardarOn: false,
      multipleOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      modificarSaldoMOn: false,
      proveedores: [],
      cxpproveedor: [],
      reporteOn: false,
      libroCOn: false,
      facturasPagasOn: false,
      consultaROn: false,
      codigo3: "",
      codigo4: "",
      referencia2: "",
      fecha2: "",
      detalleOn: false,
      cuentaContable: "",
      cuentaContable2: "",
      cuenta: [],
      itenFacturasC: [],
      descripcionC: "",
      botonAsientoOn: false,
      asientoOn: false,
      nombrex: "",
      buscarOn: false,
      sinicial: "NO",
      sinicialH: "NO",
      nControl: "",
      nombreEvaluacion_selected: null,
      saldoT: 0,
      claseNormal: false,
      conta: "SI",
    };
  }

  limpiar = () => {
    this.setState({
      idcxp: "",
      fecha: "",
      fechaC: "",
      subtotalC: "",
      impuestoC: "",
      montoC: "",
      monto: "",
      montoT: "",
      saldo: "",
      banco: "",
      oferta: "NO",
      referencia: "",
      documento: "",
      descripcion1: "",
      subtotal: "",
      impuesto: "",
      guardarOn: false,
      multipleOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      modificarSaldoMOn: false,
      reporteOn: false,
      libroCOn: false,
      facturasPagasOn: false,
      consultaROn: false,
      codigo3: "",
      codigo4: "",
      referencia2: "",
      fecha2: "",
      detalleOn: false,
      cuentaContable: "",
      cuentaContable2: "",
      itenFacturasC: [],
      descripcionC: "",
      botonAsientoOn: false,
      asientoOn: false,
      //nombrex: "",
      sinicial: "NO",
      sinicialH: "NO",
      saldoT: 0,
      //buscarOn: false,
      claseNormal: false,
      conta: "SI",
    });
  };

  limpiar2 = () => {
    this.setState({
      idcxp: "",
      subtotal: "",
      impuesto: "",
      fecha: "",
      monto: "",
      saldo: "",
      documento: "",
      descripcion1: "",
      //tbusqueda: "",
      guardarOn: false,
      multipleOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      modificarSaldoMOn: false,
      cxpproveedor: [],
      reporteOn: false,
      facturasPagasOn: false,
      consultaROn: false,
      referencia2: "",
      fecha2: "",
      buscarOn: false,
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarOn = () => {
    this.consultarProveedores2();
    this.setState({
      tbusqueda: "",
    });
  };

  onChange1(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        oferta: "SI",
        sinicial: "NO",
        sinicialH: "NO",
      });
    } else {
      this.setState({
        oferta: "NO",
      });
    }
  }

  onChange6(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        sinicial: "SI",
      });
    } else {
      this.setState({
        sinicial: "NO",
      });
    }
  }

  onChange7(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        sinicialH: "SI",
      });
    } else {
      this.setState({
        sinicialH: "NO",
      });
    }
  }

  onChange8(e) {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.value === "5") {
      this.controlNumeroBancoG();
    }
  }

  onChange(e) {
    //console.log(e.target.name)
    let index = e.target.getAttribute("id");
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (index === "subtotal") {
      this.state.impuesto = (
        parseFloat(e.target.value) * 1.07 -
        parseFloat(e.target.value)
      ).toFixed(2);
      this.state.monto = (parseFloat(e.target.value) * 1.07).toFixed(2);
    }
    if (index === "impuesto") {
      let valor = 0;
      if (e.target.value !== "") valor = e.target.value;

      this.state.monto = (
        parseFloat(this.state.subtotal) + parseFloat(valor)
      ).toFixed(2);
    }
  }

  onChange2(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarCxP(e.target.value);
    this.limpiarDesgloce();
    //console.log(e.target.value)
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange12() {
    //console.log(this.state.conta)
    // console.log(e.target.checked)
    if (this.state.conta === "SI") {
      this.setState({ conta: "NO" });
    } else {
      this.setState({ conta: "SI" });
    }
    console.log(this.state.conta);
  }
  onChange5(e) {
    //console.log(e.target.name)
    let index = e.target.getAttribute("id");
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (index === "subtotalC") {
      this.state.impuestoC = (
        parseFloat(e.target.value) * 1.07 -
        parseFloat(e.target.value)
      ).toFixed(2);
      this.state.montoC = (parseFloat(e.target.value) * 1.07).toFixed(2);
    }
    if (index === "impuestoC") {
      let valor = 0;
      if (e.target.value !== "") valor = e.target.value;

      this.state.montoC = (
        parseFloat(this.state.subtotalC) + parseFloat(valor)
      ).toFixed(2);
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  reporte = () => {
    if (this.state.reporteOn) {
      this.setState({ reporteOn: false });
    } else {
      this.setState({ reporteOn: true });
    }
  };

  detalle = () => {
    if (this.state.detalleOn) {
      this.setState({ detalleOn: false });
    } else {
      this.consultarDesgloceCxP();
      this.setState({ detalleOn: true });
    }
  };

  generarPagoClose = () => {
    this.setState({ modificarSaldoOn: false });
    this.limpiar();
    this.consultarCxP(this.state.tbusqueda);
  };

  generarPagoCloseM = () => {
    this.setState({ modificarSaldoMOn: false });
    this.limpiar();
    this.consultarCxP(this.state.tbusqueda);
  };

  consultarCxPI = (e) => {
    let data = new FormData();
    data.append("idcxp", e);
    data.append("boton", "consultarCxPI");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            idcxp: res.data.variable2,
            documento: res.data.variable3,
            subtotal: res.data.variable7,
            impuesto: res.data.variable8,
            monto: res.data.variable4,
            descripcion1: res.data.variable5,
            fecha: res.data.variable6,
            guardarOn: true,
          });
        }
      });
  };

  consultarCxPDM = () => {
    if (this.state.modificarSaldoMOn) {
      this.setState({ modificarSaldoMOn: false });
    } else {
      this.setState({
        modificarSaldoMOn: true,
      });
    }
  };

  consultarCxPD = (e, f, g, h) => {
    if (this.state.modificarSaldoOn) {
      this.setState({ modificarSaldoOn: false });
    } else {
      this.setState({
        modificarSaldoOn: true,
        idcxp: e,
        documento: f,
        monto: g,
        saldo: h,
      });
    }
    //console.log(this.state.tbusqueda);
  };

  consultarProveedores = () => {
    let data = new FormData();
    data.append("boton", "consultarProveedor");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProveedores2 = () => {
    let data = new FormData();
    data.append("nombre", this.state.nombrex);
    data.append("boton", "consultarProveedorN");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
            listaOn: true,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarCxP = (e) => {
    let data = new FormData();
    data.append("idproveedor", e);
    data.append("tipoC", "");
    data.append("boton", "consultarCxP");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xfacturasP = res.data.variable2;
          this.setState({
            cxpproveedor: xfacturasP,
            multipleOn: true,
            listaOn: false,
            tbusqueda: e,
            nombrex: res.data.variable3,
            saldoT: res.data.variable4,
          });
        } else {
          this.setState({
            multipleOn: false,
            cxpproveedor: [],
            listaOn: false,
            tbusqueda: e,
            nombrex: res.data.variable3,
            saldoT: res.data.variable4,
          });
        }
      });
  };

  referenciaC = () => {
    let data = new FormData();
    data.append("referencia", this.state.referencia2);
    //data.append("fecha", this.state.fecha2);
    data.append("boton", "consultarR");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Consulta de Referencias.",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Consulta de Referencias.",
            cuerpoM: "No Existe la Referencia.",
          });
        }
      });
  };

  eliminarCxP = () => {
    let data = new FormData();
    data.append("idcxp", this.state.idcxp);
    data.append("boton", "eliminar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.consultarCxP(this.state.tbusqueda);
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  guardarCxP = () => {
    let paso = 0;
    let valor = 0;
    if (this.state.sinicial === "NO") {
      if (parseFloat(this.state.montoT).toFixed(2) === this.state.monto) {
        paso = 0;
      } else {
        paso = 1;
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Cuentas por Pagar.",
          cuerpoM:
            "El monto de la factura no coincide con lo desglosado, gracias",
        });
      }
    } else {
      paso = 0;
    }

    if (paso === 0) {
      if (this.state.oferta === "SI") {
        if (this.state.banco.length > 0) {
          if (this.state.referencia !== "") {
            valor = 1;
          }
        }
      }
      // console.log("oferta"+this.state.oferta)
      // console.log("banco"+this.state.banco)
      // console.log("referencia"+this.state.referencia)
      // console.log("Valor"+valor)

      if (this.state.oferta !== "SI") valor = 1;

      if (valor === 1) {
        this.setState({
          loading: true,
        });
        let data = new FormData();
        data.append("claveUsu", this.props.usuario1.password);
        data.append("documento", this.state.documento);
        data.append("monto", this.state.monto);
        data.append("impuesto", this.state.impuesto);
        data.append("subtotal", this.state.subtotal);
        data.append("descripcion", this.state.descripcion1);
        data.append("fecha", this.state.fecha);
        data.append("fechaC", this.state.fechaC);
        data.append("idproveedor", this.state.tbusqueda);
        data.append("oferta", this.state.oferta);
        data.append("banco", this.state.banco);
        data.append("referencia", this.state.referencia);
        data.append("codigoContable2", this.state.cuentaContable2);
        data.append("guardarConta", this.state.conta);

        data.append("boton", "guardar");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            this.setState({
              loading: false,
            });
            //console.log(res.data.variable4);
            //console.log(res.data.variable3);
            if (res.data.variable1) {
              this.consultarCxP(this.state.tbusqueda);
              this.limpiar();
              this.controlNumero();
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Crear Cuentas por Pagar.",
                cuerpoM: "Se Guardo el Registro con exito.",
              });
            } else {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Crear Cuentas por Pagar.",
                cuerpoM: res.data.variable2,
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Cuentas por Pagar.",
          cuerpoM: "Debe Ingresar un Banco y una Referencia, gracias",
        });
      }
    }
  };

  estadoCuentaExcel = () => {
    let data = new FormData();
    data.append("idproveedor", this.state.tbusqueda);

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/edoCuentaCxPExcel.php",
      responseType: "arraybuffer",
      data: data,
    }).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "edoCuentaCxPExcel.xlsx"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  };

  reporteLibroC = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/libroCompras.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  modificarCxP = () => {
    let data = new FormData();
    data.append("idcxp", this.state.idcxp);
    data.append("documento", this.state.documento);
    data.append("monto", this.state.monto);
    data.append("impuesto", this.state.impuesto);
    data.append("subtotal", this.state.subtotal);
    data.append("descripcion", this.state.descripcion1);
    data.append("fecha", this.state.fecha);
    data.append("boton", "modificar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.consultarCxP(this.state.tbusqueda);
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Se Modifico el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  facturasPagas = () => {
    if (this.state.facturasPagasOn) {
      this.setState({ facturasPagasOn: false });
    } else {
      this.setState({ facturasPagasOn: true });
    }
  };

  libroC = () => {
    if (this.state.libroCOn) {
      this.setState({ libroCOn: false });
    } else {
      this.setState({ libroCOn: true });
    }
  };

  consultaR = () => {
    if (this.state.consultaROn) {
      this.setState({ consultaROn: false, referencia2: "", fecha2: "" });
    } else {
      this.setState({ consultaROn: true, referencia2: "", fecha2: "" });
    }
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
    } else {
      this.setState({ datosChequeOn: true });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  consultarDesgloceCxP = () => {
    let data = new FormData();
    if (this.state.idcxp.length === 0) {
      data.append("claveUsu", this.props.usuario1.password);
      data.append("idproveedor", this.state.tbusqueda);
      data.append("boton", "consultarDesgloceCxP");
    }
    if (this.state.idcxp.length > 0) {
      data.append("idcxp", this.state.idcxp);
      data.append("boton", "consultarDesgloceCxP2");
    }
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        itenFacturasC: xcuentas,
        montoT: res.data.variable2,
      });
      //console.log(this.state.itenFacturasC)
    });
  };

  limpiarDesgloce = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("boton", "limpiarDesgloce");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {});
  };

  guardarDesgloce = () => {
    if (
      this.state.cuentaContable.length > 0 &&
      this.state.descripcionC.length > 0 &&
      this.state.subtotalC.length > 0
    ) {
      let montoAcu = 0;
      let montoDife = 0;
      montoAcu = parseFloat(this.state.montoT) + parseFloat(this.state.montoC);
      montoDife =
        parseFloat(this.state.monto).toFixed(2) -
        parseFloat(montoAcu).toFixed(2);
      // console.log(montoAcu);
      // console.log(this.state.monto);
      // console.log(montoDife);
      if (montoDife >= -0.003) {
        let data = new FormData();
        data.append("documento", this.state.documento);
        data.append("cuentaContable", this.state.cuentaContable);
        data.append("descripcion", this.state.descripcionC);
        data.append("subtotal", this.state.subtotalC);
        data.append("impuesto", this.state.impuestoC);
        data.append("monto", this.state.montoC);
        data.append("claveUsu", this.props.usuario1.password);
        data.append("idproveedor", this.state.tbusqueda);
        data.append("fecha", this.state.fecha);
        data.append("boton", "agregarDesgloce");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            if (res.data.variable1) {
              this.consultarDesgloceCxP();
              this.setState({
                claseNormal: false,
                descripcionC: "",
                subtotalC: "",
                impuestoC: "",
                montoC: "",
                cuentaContable: "",
                // mensajealerta: true,
                // tituloM: "Menu Crear Cuentas por Pagar.",
                // cuerpoM: "Se Modifico el Registro con exito.",
              });
            } else {
              this.setState({
                claseNormal: false,
                mensajealerta: true,
                tituloM: "Menu Crear Cuentas por Pagar.",
                cuerpoM: "Ocurrio un error, Verifique por favor.",
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Desgloce.",
          cuerpoM:
            "El monto que intanta agregar excede el monto de la factura, Verifique por favor.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Desgloce.",
        cuerpoM:
          "Debe existir una Cuenta, una descripcion y un monto, Verifique por favor.",
      });
    }
  };

  eliminarItenFactura = (e) => {
    let data = new FormData();
    data.append("iddesgloce", e);
    data.append("boton", "eliminarDesgloceCxP");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios.post(xurl, data, config).then((res) => {
      this.consultarDesgloceCxP();
    });
  };

  controlNumeroBancoG = () => {
    let data = new FormData();
    data.append("boton", "controlNumeroBG");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          referencia: res.data.variable1,
        });
      });
  };

  controlNumero = () => {
    let data = new FormData();
    data.append("boton", "controlNumero");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          nControl: res.data.variable1,
        });
      });
  };

  asientoContable = () => {
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
        listaNominaOn: true,
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
        listaNominaOn: false,
      });
    }
  };

  agregarItemAsientoPorPagar2 = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("idproveedor", this.state.tbusqueda);
    data.append("fecha", this.state.fecha);
    data.append("subtotal", this.state.subtotal);
    data.append("impuesto", this.state.impuesto);
    data.append("monto", this.state.monto);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("tipoPago", this.state.oferta);
    data.append("sinicial", this.state.sinicial);
    data.append("sinicialH", this.state.sinicialH);
    data.append("cuentaContable2", this.state.cuentaContable2);
    data.append("documento", this.state.documento);

    data.append("boton", "agregarItemAsientoPorPagar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.guardarCxP();
        this.setState({
          // asientoOn: true,
          // listaNominaOn: false,
          // botonAsientoOn: true,
          loading: false,
        });
      });
  };

  agregarItemAsientoPorPagar = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("idproveedor", this.state.tbusqueda);
    data.append("fecha", this.state.fecha);
    data.append("subtotal", this.state.subtotal);
    data.append("impuesto", this.state.impuesto);
    data.append("monto", this.state.monto);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("tipoPago", this.state.oferta);
    data.append("sinicial", this.state.sinicial);
    data.append("sinicialH", this.state.sinicialH);
    data.append("cuentaContable2", this.state.cuentaContable2);
    data.append("documento", this.state.documento);

    data.append("boton", "agregarItemAsientoPorPagar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          asientoOn: true,
          listaNominaOn: false,
          botonAsientoOn: true,
          loading: false,
        });
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
              });
              this.buscarCXC(res.data.variable4);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
              });
              this.buscarCXC(res.data.variable4);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  loadOptions = async (inputValue) => {
    //if (inputValue.length > 0) {
    let data = new FormData();
    data.append("inputValue", inputValue);
    //data.append("boton", "buscarNE");
    if (inputValue.length > 1) data.append("boton", "buscarNE");
    if (inputValue.length <= 1) data.append("boton", "buscarNE2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/contabilidad.php";
    let res = await axios.post(xurl, data, config);
    if (res.data.length > 0) {
      this.setState({ claseNormal: true });
      let arr = [];
      for (let x = 0; x < res.data.length; x++) {
        arr.push({
          label: res.data[x].variable6 + " - " + res.data[x].variable3,
          value: res.data[x].variable2,
        });
      }
      return arr;
    } else {
      this.setState({ claseNormal: false });
      //   const arr = [{ value: "-1", label: "Crear Evaluación()" }];
      //   return arr;
    }
    //}
  };

  onSelectChange = (e) => {
    //console.log(e);
    this.setState({ nombreEvaluacion_selected: e });
    if (e !== null) {
      this.setState({
        cuentaContable: e.value,
        evaluacionNombre: e.label,
      });
    }
  };

  componentDidMount() {
    this.cargarCuentas();
    this.controlNumero();
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            {this.state.conta === "SI" ? (
              <p>CUENTAS POR PAGAR. SERA CONTABILIZADA </p>
            ) : (
              <p>CUENTAS POR PAGAR. NO SERA CONTABILIZADA </p>
            )}
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}
          {this.state.asientoOn && (
            <div className="flotadorPe">
              <div className="flotadorPe1">
                <Comprobante
                  claveUsuario={this.props.usuario1.password}
                  comprobanteClose={this.asientoContable}
                />
              </div>
            </div>
          )}
          {this.state.modificarSaldoOn === true && (
            <div className="flotador3x">
              <div className="flotador1">
                <CxPAbonos
                  idcxp={this.state.idcxp}
                  documento={this.state.documento}
                  proveedor={this.state.tbusqueda}
                  monto={parseFloat(this.state.monto).toFixed(2)}
                  saldo={parseFloat(this.state.saldo).toFixed(2)}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.listaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Listado de Proveedores</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrarLista.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.proveedores.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consultarCxP.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable3}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.detalleOn === true && (
            <div className="flotador3xMC">
              <div className="flotador1">
                <div className="form-group row">
                  <div className="contenedorcedula1 col-6">
                    <AsyncSelect
                      id="cuentaContable"
                      name="cuentaContable"
                      className="nombreEvaluacion"
                      cacheOptions
                      defaultOptions
                      isClearable
                      classNamePrefix="Nombre de la Cuenta"
                      loadOptions={(inputValue) => this.loadOptions(inputValue)}
                      onChange={this.onSelectChange}
                      //onCreateOption={this.onCreate}
                      value={this.state.nombreEvaluacion_selected}
                    />
                  </div>

                  {/* 
                  <div className="contenedorcedula1 col-6">
                    <select
                      id="cuentaContable"
                      name="cuentaContable"
                      value={this.state.cuentaContable}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>Cuenta Contable</option>

                      {this.state.cuenta.map((item, i) => {
                        return (
                          <option key={i} value={item.variable2}>
                            {item.variable6 + " - " + item.variable3}{" "}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  <div className="contenedorcedula col-2">
                    <input
                      className="form-control"
                      type="date"
                      name="fecha"
                      id="fecha"
                      autoComplete="off"
                      readOnly="readonly"
                      onChange={this.onChange.bind(this)}
                      value={this.state.fecha}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="contenedorcedula1 col-4">
                    <input
                      className="form-control"
                      type="text"
                      name="descripcionC"
                      id="descripcionC"
                      autoComplete="off"
                      placeholder="Descripcion Item"
                      maxLength="60"
                      onChange={this.onChange.bind(this)}
                      value={this.state.descripcionC.toUpperCase()}
                    />
                  </div>
                  <div className="contenedorcedula1 col-2">
                    <input
                      className="form-control"
                      type="text"
                      name="subtotalC"
                      id="subtotalC"
                      autoComplete="off"
                      placeholder="Sub-Total"
                      maxLength="10"
                      onChange={this.onChange5.bind(this)}
                      value={this.state.subtotalC}
                    />
                  </div>
                  <div className="contenedorcedula1 col-2">
                    <input
                      className="form-control"
                      type="text"
                      name="impuestoC"
                      id="impuestoC"
                      autoComplete="off"
                      placeholder="Impuesto"
                      maxLength="10"
                      onChange={this.onChange5.bind(this)}
                      value={this.state.impuestoC}
                    />
                  </div>
                  <div className="contenedorcedula1 col-2">
                    <input
                      className="form-control"
                      type="text"
                      name="montoC"
                      id="montoC"
                      autoComplete="off"
                      placeholder="Monto"
                      maxLength="10"
                      onChange={this.onChange5.bind(this)}
                      value={this.state.montoC}
                    />
                  </div>
                  <button
                    className={
                      this.state.sinicial === "NO" ? "boton" : "botonOf"
                    }
                    onClick={
                      this.state.sinicial === "NO" ? this.guardarDesgloce : ""
                    }
                  >
                    Aceptar Item
                  </button>
                </div>
                <div clasName="form-group row">
                  <div className="cont-listmedi row">
                    <div className="listcxc col-12">
                      <table className="tablacxc" border="1">
                        {this.state.claseNormal === false && (
                          <thead>
                            <tr>
                              <th className="titucxc2">CUENTA</th>
                              {/* <th className="titucxc1">FECHA</th> */}
                              <th className="titucxc2">DESCRIPCION</th>
                              <th className="titucxc1">P/UNITARIO</th>
                              <th className="titucxc1">ITBMS</th>
                              <th className="titucxc1">TOTAL</th>
                              <th className="titucxc1">
                                <FeatherIcon
                                  icon="delete"
                                  size="30px"
                                  stroke="#999"
                                  color="red"
                                />
                              </th>
                            </tr>
                          </thead>
                        )}
                        {this.state.claseNormal === true && (
                          <tr>
                            <th className="titucxc2">CUENTA</th>
                            {/* <th className="titucxc1">FECHA</th> */}
                            <th className="titucxc2">DESCRIPCION</th>
                            <th className="titucxc1">P/UNITARIO</th>
                            <th className="titucxc1">ITBMS</th>
                            <th className="titucxc1">TOTAL</th>
                            <th className="titucxc1">
                              <FeatherIcon
                                icon="delete"
                                size="30px"
                                stroke="#999"
                                color="red"
                              />
                            </th>
                          </tr>
                        )}
                        {this.state.itenFacturasC.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : "odd"}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td className="totalescxc" width="10">
                                {item.variable2}
                              </td>
                              {/* <td className="renglon" width="10">
                                {item.variable3}
                              </td> */}
                              <td className="totalescxc1" width="10">
                                {item.variable4}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable5}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable6}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable7}
                              </td>
                              <td className="titucxc3" width="10">
                                <FeatherIcon
                                  icon="delete"
                                  size="30px"
                                  stroke="#999"
                                  color="red"
                                  onClick={this.eliminarItenFactura.bind(
                                    this,
                                    item.variable1
                                  )}
                                ></FeatherIcon>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="fechasLC col-12">
                    <button
                      className="boton"
                      onClick={this.agregarItemAsientoPorPagar}
                    >
                      Asiento Contable
                    </button>

                    <button
                      className={
                        this.state.botonAsientoOn === true ? "boton" : "botonOf"
                      }
                      onClick={
                        this.state.botonAsientoOn === true
                          ? this.agregarItemAsientoPorPagar2
                          : ""
                      }
                    >
                      Guardar
                    </button>
                    <button className="boton" onClick={this.detalle}>
                      SALIR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.libroCOn === true && (
            <div className="flotador3xM">
              <div className="flotador1">
                <div className="titulo-cxc ">
                  <p>LIBRO DE COMPRAS. </p>
                </div>
                <div className="fechas col-12">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="fechasL col-12">
                  <button className="boton" onClick={this.reporteLibroC}>
                    IMPRIMIR
                  </button>
                  <button className="boton" onClick={this.libroC}>
                    SALIR
                  </button>
                </div>
              </div>
            </div>
          )}

          {this.state.consultaROn === true && (
            <div className="flotador3xM">
              <div className="flotador1">
                <div className="titulo-cxc ">
                  <p>CONSULTA DE REFERENCIA. </p>
                </div>
                <div className="form-group row">
                  <div className="contenedorcedula col-3">
                    <input
                      className="form-control"
                      type="text"
                      name="referencia2"
                      id="referencia2"
                      autoComplete="off"
                      placeholder="Referencia"
                      maxLength="200"
                      onChange={this.onChange.bind(this)}
                      value={this.state.referencia2.toUpperCase()}
                    />
                  </div>
                  {/* <div className="contenedorcedula1 col-3">
                    <input
                      className="form-control"
                      type="date"
                      name="fecha2"
                      id="fecha2"
                      // readOnly="readonly"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.fecha2}
                    />
                  </div> */}
                  <div className="fechas col-3">
                    <button className="boton" onClick={this.referenciaC}>
                      Consultar
                    </button>
                    <button className="boton" onClick={this.consultaR}>
                      SALIR
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.modificarSaldoMOn === true && (
            <div className="flotador3xM">
              <div className="flotador1">
                <CxPAbonosM
                  idcxpM={this.state.tbusqueda}
                  generarPagoClose={this.generarPagoCloseM}
                />
              </div>
            </div>
          )}

          {this.state.facturasPagasOn === true && (
            <div className="flotador3xM">
              <div className="flotador1">
                <FacturasP tipoC="FP" generarPagoClose={this.facturasPagas} />
              </div>
            </div>
          )}

          {this.state.reporteOn === true && (
            <div className="flotador3x">
              <div className="flotador1">
                <Reporte reporteClose={this.reporte} />
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula1k col-2">
              <span className="ncontrol">
                Control Egreso {this.state.nControl}
              </span>
            </div>
            <div className="contenedorcedula1k col-5">
              <input
                className="form-control"
                type="text"
                name="nombrex"
                id="nombrex"
                autoComplete="off"
                placeholder="Nombre Proveedor"
                maxLength="150"
                onChange={this.onChange.bind(this)}
                value={this.state.nombrex.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-1">
              <button className="boton" onClick={this.buscarOn}>
                BUSCAR
              </button>
            </div>
            {/* 
            <div className="fechas col-6">
              <select
                id="tbusqueda"
                name="tbusqueda"
                value={this.state.tbusqueda}
                onChange={this.onChange2.bind(this)}
              >
                <option>-- Seleccionar Proveedor --</option>
                {this.state.proveedores.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2 + " - " + item.variable3}{" "}
                    </option>
                  );
                })}
              </select>
            </div> */}
          </div>

          <div className="form-group row">
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="documento"
                id="documento"
                autoComplete="off"
                placeholder="No. Documento"
                maxLength="15"
                onChange={this.onChange.bind(this)}
                value={this.state.documento.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="subtotal"
                id="subtotal"
                autoComplete="off"
                placeholder="Sub-Total"
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.subtotal}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="impuesto"
                id="impuesto"
                autoComplete="off"
                placeholder="Impuesto"
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.impuesto}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="monto"
                id="monto"
                autoComplete="off"
                placeholder="Monto"
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.monto}
              />
            </div>
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="date"
                name="fecha"
                id="fecha"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="text"
                name="descripcion1"
                id="descripcion1"
                autoComplete="off"
                placeholder="Descripcion"
                maxLength="200"
                onChange={this.onChange.bind(this)}
                value={this.state.descripcion1.toUpperCase()}
              />
            </div>
            <div className="cont-opcioncas col-1">
              <p className="titulooferta1">Contado:</p>
              <input
                type="checkbox"
                id="oferta"
                name="oferta"
                value="SI"
                checked={this.state.oferta === "SI"}
                onChange={this.onChange1.bind(this)}
              />
            </div>
            {this.state.oferta !== "SI" && (
              <div className="cont-opcioncas col-1">
                <p className="titulooferta1">S/Inicial:</p>
                <input
                  type="checkbox"
                  id="sinicial"
                  name="sinicial"
                  value="SI"
                  checked={this.state.sinicial === "SI"}
                  onChange={this.onChange6.bind(this)}
                />
              </div>
            )}
            {this.state.sinicial === "SI" && (
              <div className="cont-opcioncas col-1">
                <p className="titulooferta1">Credito:</p>
                <input
                  type="checkbox"
                  id="sinicialH"
                  name="sinicialH"
                  value="SI"
                  checked={this.state.sinicialH === "SI"}
                  onChange={this.onChange7.bind(this)}
                />
              </div>
            )}
            {this.state.oferta !== "SI" && (
              <div className="contenedorcedula1 col-6">
                <select
                  id="cuentaContable2"
                  name="cuentaContable2"
                  value={this.state.cuentaContable2}
                  onChange={this.onChange.bind(this)}
                >
                  <option>Cuenta Contable</option>

                  {this.state.cuenta.map((item, i) => {
                    return (
                      <option key={i} value={item.variable2}>
                        {item.variable6 + " - " + item.variable3}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {this.state.oferta === "SI" && (
              <div className="contenedorcedula col-2">
                <select
                  id="banco"
                  name="banco"
                  value={this.state.banco}
                  onChange={this.onChange8.bind(this)}
                >
                  <option>-- Seleccionar Banco --</option>
                  <option value="1"> Banco BAC</option>
                  <option value="2"> Banco BANESCO</option>
                  <option value="5"> Banco GENERAL</option>
                  <option value="3"> Efectivo</option>
                  <option value="4"> Otros</option>
                </select>
              </div>
            )}
            {this.state.oferta === "SI" && (
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="text"
                  name="referencia"
                  id="referencia"
                  autoComplete="off"
                  placeholder="Referencia"
                  maxLength="200"
                  onChange={this.onChange.bind(this)}
                  value={this.state.referencia}
                />
              </div>
            )}
            {this.state.oferta === "SI" && (
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="date"
                  name="fechaC"
                  id="fechaC"
                  // readOnly="readonly"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.fechaC}
                />
              </div>
            )}
          </div>

          <div className="contenedorcedula1 col-12">
            <button className="boton" onClick={this.detalle}>
              Desgloce Factura
            </button>
            {/* <button className="boton" onClick={this.reporte}>
              Reporte CxP
            </button>
            <button className="boton" onClick={this.libroC.bind(this)}>
              Libro Compras
            </button> */}
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">FECHA</th>
                    <th className="titucxc3">REGISTRO</th>
                    <th className="titucxc3">DOCUMENTO</th>
                    <th className="titucxc3">MONTO</th>
                    <th className="titucxc3">ABONO</th>
                    <th className="titucxc3">SALDO</th>
                    <th className="titucxc3">
                      <FeatherIcon
                        icon="search"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                    </th>
                  </tr>
                </thead>
                {this.state.cxpproveedor.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxc"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable2}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable8}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable4}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable5}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable6}
                      </td>

                      <td className="titucxc3" width="10">
                        <FeatherIcon
                          icon="search"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.consultarCxPD.bind(
                            this,
                            item.variable1,
                            item.variable3,
                            item.variable4,
                            item.variable6
                          )}
                        ></FeatherIcon>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
          <div className="cont-listmedi row">
            <div className="saltoT col-10">
              <span>Saldo: {this.state.saldoT}</span>
            </div>
          </div>
          <div className="contenedorcedula1 col-12">
            <button
              className={this.state.multipleOn ? "boton" : "botonOf"}
              disabled={!this.state.multipleOn}
              onClick={this.consultarCxPDM.bind(this)}
            >
              Pagos Multiples
            </button>
            <button className="boton" onClick={this.facturasPagas.bind(this)}>
              Facturas Pagadas
            </button>
            <button className="boton" onClick={this.consultaR}>
              Consulta Referencia
            </button>
            <button className="boton" onClick={this.estadoCuentaExcel}>
              Edo.Cuenta Excel
            </button>
            <button
              className={this.state.guardarOn ? "boton" : "botonOf"}
              disabled={!this.state.guardarOn}
              onClick={this.eliminarCxP}
            >
              Eliminar
            </button>
            <button className="boton" onClick={this.onChange12.bind(this)}>
              {this.state.conta === "SI" ? "No Contabilizar" : "Contabilizar"}
            </button>
            <button className="boton" onClick={this.limpiar2.bind(this)}>
              Limpiar
            </button>
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxP);
