import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./dosificacionDE.css";
import Alert from "../../modal/alert";

class ConceptoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: "",
      horario: "",
      numeroH: "",
      ihorario: [],
      inumeroH: [],
      icursos: [],
      igradohorario: [],
      imaterias: [],
      idescripcionE: [],
      listaOn: false,
      numeroHOn: false,
      asociarGHOn: false,
      activar: "",
      idgrado: "",
      idevaluacion: "",
      idmateria: "",
      trimestre: "",
      nombreDE: "",
      iddosificacionEva: "",
      modificarOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      codigo: "",
      horario: "",
      numeroH: "",
      listaOn: false,
      numeroHOn: false,
      asociarGHOn: false,
      activar: "",
      idgrado: "",
      idevaluacion: "",
      idmateria: "",
      trimestre: "",
      nombreDE: "",
      ihorario: [],
      iddosificacionEva: "",
      modificarOn: false,
    });
  };

  onChange(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (index === "idgrado") {
      this.buscarMaterias(e.target.value);
      this.setState({ trimestre: "", idmateria: "" });
    }
    if (index === "idmateria") {
      this.setState({ trimestre: "" });
    }
    if (index === "trimestre") {
      this.buscarPlanE(e.target.value);
      this.consultar(e.target.value);
    }
  }

  guardar = () => {
    if (
      this.state.idgrado.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.idevaluacion.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.nombreDE.length > 0
    ) {
      let data = new FormData();
      data.append("idgrado", this.state.idgrado);
      data.append("idmateria", this.state.idmateria);
      data.append("idevaluacion", this.state.idevaluacion);
      data.append("trimestre", this.state.trimestre);
      data.append("nombreEva", this.state.nombreDE);

      data.append("boton", "guardarDE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultar(this.state.trimestre);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Nombre Evaluacion.",
              cuerpoM: "Se guardo correctamente.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Nombre Evaluacion.",
              cuerpoM: "El No. del Bloque ya esta Registrado.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asociar Nombre Evaluacion.",
        cuerpoM: "Debe llenar todos los datos que se piden.",
      });
    }
  };

  modificar = () => {
    if (this.state.nombreDE.length > 0) {
      let data = new FormData();
      data.append("iddosificacionEva", this.state.iddosificacionEva);
      data.append("nombreEva", this.state.nombreDE);
      data.append("boton", "modificarDE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultar(this.state.trimestre);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Nombre Evaluacion.",
              cuerpoM: "Se modifico correctamente.",
              iddosificacionEva:'',
              idevaluacion: '',
              nombreDE: '',
              modificarOn: false,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asociar Nombre Evaluacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asociar Nombre Evaluacion.",
        cuerpoM: "Debe llenar el nombre de la evaluacion.",
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();

      data.append("iddosificacionEva", e);
      data.append("boton", "eliminarDE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asociar Nombre Evaluacion.",
            cuerpoM: "Se Elimino correctamente el registro.",
          });
          this.consultar(this.state.trimestre);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asociar Nombre Evaluacion.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }
  };

  consultar = (e) => {
    let data = new FormData();
    data.append("trimestre", e);
    data.append("idmateria", this.state.idmateria);
    data.append("idgrado", this.state.idgrado);
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xihorario = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            ihorario: xihorario,
          });
        } else {
          this.setState({
            ihorario: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarGrados = () => {
    let data = new FormData();
    data.append("boton", "buscarSalones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/secciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;

        this.setState({
          icursos: xicursos,
        });
      });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarMaterias = (e) => {
    let data = new FormData();
    data.append("idcurso", e);
    data.append("boton", "buscarMateriasAsignadas");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  buscarPlanE = (e) => {
    let data = new FormData();
    data.append("trimestre", e);
    data.append("idgrado", this.state.idgrado);
    data.append("idmateria", this.state.idmateria);
    data.append("boton", "buscarPlanE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  buscarItem = (e) => {
    let data = new FormData();
    data.append("item", e);
    data.append("boton", "buscarDEItem");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iddosificacionEva: res.data.variable2,
            idevaluacion: res.data.variable3,
            nombreDE: res.data.variable4,
            modificarOn: true,
          });
        } else {
          this.setState({
            iddosificacionEva: "",
            modificarOn: false,
          });
        }
      });
  };

  componentDidMount() {
    this.buscarGrados();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>Asociar Nombre de Evaluación a Tipos de Evaluación. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          <div className="cont-datoscasos col-12">
            <div className="botonescasos row">
              <div className="contenedorcedula1f col-2">
                <select
                  id="idgrado"
                  name="idgrado"
                  value={this.state.idgrado}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-Indique Grado-</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idmateria"
                  name="idmateria"
                  value={this.state.idmateria}
                  onChange={this.onChange.bind(this)}
                >
                  <option value="">-Indique Materia-</option>
                  {this.state.imaterias.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>
              <div className="contenedorcedula1f col-4">
                <select
                  id="idevaluacion"
                  name="idevaluacion"
                  value={this.state.idevaluacion}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-Tipo de Evaluacion-</option>
                  {this.state.idescripcionE.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* </div> */}
              {/* </div> */}
              <div className="xcontenedorcedula1x col-12">
                <div className="xcontenedorcedula1x col-6">
                  <input
                    className="form-control"
                    type="text"
                    name="nombreDE"
                    id="nombreDE"
                    autoComplete="off"
                    placeholder="Nombre Evaluacion:"
                    onChange={this.onChange.bind(this)}
                    value={this.state.nombreDE}
                  />
                </div>
              </div>

              {/* <div className="contenedorp row"> */}
              <div className="xcontenedorcedula1x col-12">
                {this.state.modificarOn === false && (
                  <button className="boton" onClick={this.guardar.bind(this)}>
                    Agregar
                  </button>
                )}
                {this.state.modificarOn === true && (
                  <button className="boton" onClick={this.modificar.bind(this)}>
                    Modificar
                  </button>
                )}
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar Datos
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>

              <div className="cont-listmedixDE row">
                <div className="listcxcx col-12">
                  <table className="tablacxcx" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc2">Grado</th>
                        <th className="titucxc2">Materia</th>
                        <th className="titucxc2">Tipo Evaluación</th>
                        <th className="titucxc2">Nombre Evaluación</th>
                        <th className="titucxc4">Trimestre</th>
                        <th className="titucxc4">
                          <span className="titulo-eliminarDE">
                            <span className="cerrarme3"></span>
                          </span>
                        </th>
                      </tr>
                    </thead>

                    {this.state.ihorario.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          <td
                            className="totalescxcx1"
                            onClick={this.buscarItem.bind(this, item.variable1)}
                          >
                            {item.variable2}
                          </td>
                          <td
                            className="totalescxcx1"
                            onClick={this.buscarItem.bind(this, item.variable1)}
                          >
                            {item.variable3}
                          </td>
                          <td
                            className="totalescxcx1"
                            onClick={this.buscarItem.bind(this, item.variable1)}
                          >
                            {item.variable4}
                          </td>
                          <td
                            className="totalescxcx1"
                            onClick={this.buscarItem.bind(this, item.variable1)}
                          >
                            {item.variable5}
                          </td>
                          <td
                            className="totalescxcx1"
                            onClick={this.buscarItem.bind(this, item.variable1)}
                          >
                            {item.variable6}
                          </td>
                          <td className="xcerr">
                            <span
                              className="cerrarme1"
                              onClick={this.eliminar.bind(this, item.variable1)}
                            ></span>{" "}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ConceptoAction;
