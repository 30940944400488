import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async-creatable";
//componentes
import "./reporteNotas.css";
import Alert from "../../modal/alert";
import PublicarNotas from "../publicarNotas/index";

class EvaluacionAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idcurso: "",
      descripcionE: "",
      //inputValue:'',
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      anoEscolar: moment().format("YYYY"),
      idtareas: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nombreEvaluacion_selected: null,
      nota: "",
      trimestre: "",
      idarchivos: "",
      ianoEscolar: [moment().format("YYYY")],
      imaterias: [],
      icursos: [],
      idescripcionE: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      iactividades: [],
      inombreEvaluacion: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      activarNotaOn: false,
      valueSelect: null,
      claseNormal: false,
      codigoEvaluacion: "",
      nombreEvaluacion2: "",
      idprofesor:'',
      idgrado:'',
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      nombreEvaluacion_selected: null,
      idcurso: "",
      //idprofesor: "",
      descripcionE: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      nombreAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      idtareas: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nota: "",
      idarchivos: "",
      ievaluaciones: [],
      listaOn: false,
      modificarOn: false,
      activarNotaOn: false,
      valueSelect: null,
      claseNormal: false,
      codigoEvaluacion: "",
      nombreEvaluacion2: "",
      idprofesor:'',
      idgrado:'',
      icursos:[],
      idescripcionE: [],
      imaterias: [],

    });
  };

  limpiar2(e) {
    this.setState({
      idmateria: "",
      idcurso: "",
      //idprofesor: "",
      idplanE: "",
      cedulaAlumno: "",
      nombreAlumno: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nombreEvaluacion_selected: null,
      nota: "",
      trimestre: "",
      idarchivos: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
      valueSelect: null,
      codigoEvaluacion: "",
      nombreEvaluacion2: "",
      
    });
  }

  onChange(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (index === "idprofesor") {
      this.buscarCurso(e.target.value);
      this.setState({
        idgrado: "",
        idmateria: "",
        ihorario: [],
      });
    }
    if (index === "idgrado") {
      this.buscarMaterias();
    }
    if (index === "idmateria") {
      this.buscarPlanE(e.target.value);
      this.setState({
        idevaluacion: "",
        nombreDE: "",
        nombreDEP: "",
      });
    }
    if (index === "idevaluacion") {
      //this.buscarEvaluacionesDosi(e.target.value);
    }
  }


 
 


  buscarCurso = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idprofesor", e);
      data.append("boton", "buscarCurso");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xicursos = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              icursos: xicursos,
            });
          } else {
            this.setState({
              icursos: [],
            });
            if (res.data.variable3.length > 0) {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Asignar Notas.",
                cuerpoM: res.data.variable3,
              });
            }
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    let data = new FormData();
    data.append("trimestre", this.state.trimestre);
    data.append("idgrado", this.state.idgrado);
    data.append("idmateria", e);
    data.append("boton", "buscarPlanEDosi");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };
 
  buscarMaterias = (e) => {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("boton", "buscarMateriasAsignadasDosifi");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/dosificaciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  buscarProfesor = () => {
    let form = new FormData();

    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarEvaluaciones = () => {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.descripcionE.length > 0 &&
      this.state.trimestre.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.idgrado.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idgrado);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("descripcionE", this.state.descripcionE);
      data.append("trimestre", this.state.trimestre);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteNotasP.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Reporte de Notas.",
        cuerpoM: "Debe Seleccionar todas las opciones que se piden, gracias.",
      });
    }
  };

 
  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  onSelectChange = (e) => {
    console.log(e);
    this.setState({ nombreEvaluacion_selected: e });
    if (e !== null) {
      this.setState({
        nombreEvaluacion: e.value,
        evaluacionNombre: e.label,
      });
    }
  };

  componentDidMount() {
    this.buscarProfesor();
  }

  render() {

    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>REPORTE DE NOTAS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Indique Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idprofesor"
                  name="idprofesor"
                  value={this.state.idprofesor}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Profesor --</option>
                  {this.state.iprofesor.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idgrado"
                  name="idgrado"
                  value={this.state.idgrado}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-Indique Grado-</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-3">
                <select
                  id="idmateria"
                  name="idmateria"
                  value={this.state.idmateria}
                  onChange={this.onChange.bind(this)}
                >
                  <option value="">-Indique Materia-</option>
                  {this.state.imaterias.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1 col-3">
                <select
                  id="descripcionE"
                  name="descripcionE"
                  value={this.state.descripcionE}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Descripcion Evaluacion --</option>
                  {this.state.idescripcionE.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className="cont-personal col-12"> */}
              {/* <div className="titulo-reprecxc row">
              <div className="contenedorcedula1x col-12">
                <select
                  id="nombreEvaluacion"
                  name="nombreEvaluacion"
                  value={this.state.nombreEvaluacion}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Nombre Evaluacion --</option>
                  {this.state.inombreEvaluacion.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.variable1}
                        data-descripcion={item.variable3}
                      >
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div> */}
            </div>
            {/* <div className="cont-personal col-12"> */}

            {/* </div> */}
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-12">
                  <button
                    className="boton"
                    onClick={this.buscarEvaluaciones.bind(this)}
                  >
                    Imprimir
                  </button>
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(EvaluacionAction);
