import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./expedienteNotasDN.css";
import Alert from "../../modal/alert";

class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar: "",
      idactividad: "",
      trimestre: "",
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      consul: false,
      iactividades: [],
      imaterias: [],
      icursos: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      ianoEscolar: [],
      iexpediente: [],
      ievaluadas: [],
      ialumnos: [],
      loading: false,
      tipocierre: "NO",
      cedulaAlumno:'',
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      trimestre: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar: "",
      idactividad: "",
      imaterias: [],
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
      consul: false,
      iexpediente: [],
      ievaluadas: [],
      ialumnos: [],
      loading: false,
      tipocierre: "NO",
      cedulaAlumno:'',
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange1(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        tipocierre: "SI",
      });
    } else {
      this.setState({
        tipocierre: "NO",
      });
    }
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarProfesor();
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "idcurso") {
      //this.buscarPlanE(e.target.value);
      this.buscarAlumnos(e.target.value);
    }
    if (index === "trimestre") this.buscarTrimestre(e.target.value);
    if (index === "idmateria") this.consultarActividades(e.target.value);
  }

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosGD");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  }; 

  buscarTrimestre = (e) => {
    if (this.props.usuario1.nivel === "3") {
      let data = new FormData();
      data.append("codigo", e);
      data.append("tipocierre", this.state.tipocierre);
      data.append("boton", "consultarActivo");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/activarTrimestre.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
        } else {
          this.setState({
            trimestre: "",
            cedulaAlumno: "",
            mensajealerta: true,
            tituloM: "Menu Expediente de Notas.",
            cuerpoM:
              "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
          });
        }
      });
    }
  };

  eliminar = () => {
    if (this.state.idactividad.length > 0) {
      let data = new FormData();

      data.append("idactividad", this.state.idactividad);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          let e = "";
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Planificacion.",
        cuerpoM: "Debe existir un registro para poder eliminarlo.",
      });
    }
  };

  consultar(e) {
    if (e.length > 0) {
      this.setState({ idactividad: e });
      let data = new FormData();
      data.append("idactividad", e);
      data.append("boton", "buscarActividadesI");

      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            idmateria: res.data.variable2,
            idcurso: res.data.variable3,
            descripcion: res.data.variable4,
            ocultar: res.data.variable5,
            botonguardarOn: true,
            botonmodiOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }
  }

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  grabartexto() {
    if (
      this.state.descripcion.length > 0 &&
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.ocultar.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("descripcion", this.state.descripcion);
      data.append("ocultar", this.state.ocultar);
      data.append("boton", "crearActividades");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          this.consultarActividades(this.state.idmateria);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Actividades",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  }

  mostrarAsignacion(e) {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idevaluacion", e);
      data.append("boton", "consultarAsignacion");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          let xievaluadas = res.data.variable3;
          this.setState({
            mensajealerta: true,
            tituloM: "Descripción de la Evaluación",
            cuerpoM: res.data.variable2,
            consul: true,
            ievaluadas: xievaluadas,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Descripción de la Evaluación",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Descripción de la Evaluación",
        cuerpoM: "Debe hacer click sobre una Nota.",
      });
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarFPI = (e) => {
    let form = new FormData();
    form.append("idfechaPlanificacion", e);
    form.append("boton", "buscarFPI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          tipoPlanificacion: res.data.variable2,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Planificacion",
          cuerpoM:
            "Debe comunicarse con la coordinacion e indicarle que el tipo de planificacion que usted intenta generar no tiene asignada el tipo de Planificacion (SEMANAL o MENSUAL).",
        });
      }
    });
  };

  buscarFP = (e) => {
    let form = new FormData();
    form.append("idgrado", e);
    form.append("boton", "buscarFP");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xifechaPlani = res.data.variable2;
      this.setState({
        ifechaPlani: xifechaPlani,
        idfechaPlani: "",
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    if (this.props.usuario1.nivel === "3") {
      form.append("boton", "buscarPersonal3");
    } else {
      form.append("boton", "buscarPersonal2");
    }
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;

      this.setState({ iprofesor: xiprofesor });

      if (this.props.usuario1.nivel === "3") {
        //console.log(res.data.variable2)
        this.buscarCurso2(res.data.variable2);
      } else {
        this.buscarCurso();
      }
    });
  };

  buscarCurso2 = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarCurso = () => {
    let data = new FormData();
    data.append("boton", "buscarCurso2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            icursos: xicursos,
          });
        } else {
          this.setState({
            icursos: [],
          });
        }
      });
  };

  consultarExpediente = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("trimestre", this.state.trimestre);
    data.append("idgrado", this.state.idcurso);
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteExpedienteNotasD.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Actividad.",
      cuerpoM: "Realmente Quiere Eliminar la Actividad...",
      idactividad: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  handleConsultar = () => {
    this.setState({
      mensajealerta: false,
      consul: false,
      listaOn: true,
    });
  };

  handleEditorChange(textoevo, editor) {
    this.setState({ textoevo });
  }
  componentDidMount() {
    this.buscarAnioEscolar();
    this.buscarProfesor();
  }

  reporte = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("trimestre", this.state.trimestre);
    data.append("idgrado", this.state.idcurso);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteExpedienteNotas.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>EXPEDIENTE DE NOTAS DIAGNOSTICO POR GRADO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              consul={this.state.consul}
              handleEliminar={this.handleEliminar}
              handleConsultar={this.handleConsultar}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.listaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Listado de estudiantes</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrarLista.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.ievaluadas.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td className="renglon" width="10">
                                {item.variable2}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  {/* <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Año Escolar --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  {/* <div className="contenedorcedula1f col-3">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option value="">--- Seleccione un Profesor ---</option>
                      {this.state.iprofesor.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="cedulaAlumno"
                      name="cedulaAlumno"
                      value={this.state.cedulaAlumno}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Alumno --</option>

                      {this.state.ialumnos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                      {/* <option value="">TODOS LOS ALUMNOS</option> */}
                    </select>
                  </div>

                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="botones col-12">
                  <div className="acciones">
                    {/* {this.props.usuario1.nivel !== "3" && (
                      <div className="contenedorcedula1f col-2">
                        <p className="">Sin Cierre:</p>

                        <input
                          type="checkbox"
                          id="oferta1"
                          name="tipocierre"
                          value="SI"
                          checked={this.state.tipocierre === "SI"}
                          onChange={this.onChange1.bind(this)}
                        />
                      </div>
                    )} */}
                    {/* <button className="boton" onClick={this.reporte.bind(this)}>
                      Reporte
                    </button> */}
                    <button
                      className="boton"
                      onClick={this.consultarExpediente.bind(this)}
                    >
                      Consultar
                    </button>
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
              <div className="cont-listmedixe row">
                <div className="listcxcxe col-12">
                  <table className="table table-sm table-hover" border="1">
                    {this.state.iexpediente.map((item, i) => {
                      return (
                        <tr
                          className={
                            i % 2 === 0 ? "table-primary" : "table-secondary"
                          }
                          key={i}
                          id="tablax"
                        >
                          <thead>
                            {item.variable1 !== "" && (
                              <tr>
                                <th colspan="23" scope="col">
                                  {item.variable1}
                                </th>
                              </tr>
                            )}

                            {item.variable1 !== "" && (
                              <tr>
                                <th scope="col" colspan="23" border="0">
                                  {item.variable2} {item.variable3}
                                </th>
                              </tr>
                            )}

                            {item.variable1 !== "" && (
                              <tr>
                                <th scope="col" className="titulo1e">
                                  TIPO EXAMEN
                                </th>
                                <th scope="col" className="titulo2e">
                                  ASIGNACIONES.
                                </th>
                                <th scope="col" className="titulo3e">
                                  N01
                                </th>
                                <th scope="col" className="titulo3e">
                                  N02
                                </th>
                                <th scope="col" className="titulo3e">
                                  N03
                                </th>
                                <th scope="col" className="titulo3e">
                                  N04
                                </th>
                                <th scope="col" className="titulo3e">
                                  N05
                                </th>
                                <th scope="col" className="titulo3e">
                                  N06
                                </th>
                                <th scope="col" className="titulo3e">
                                  N07
                                </th>
                                <th scope="col" className="titulo3e">
                                  N08
                                </th>
                                <th scope="col" className="titulo3e">
                                  N09
                                </th>
                                <th scope="col" className="titulo3e">
                                  N10
                                </th>
                                <th scope="col" className="titulo3e">
                                  N11
                                </th>
                                <th scope="col" className="titulo3e">
                                  N12
                                </th>
                                <th scope="col" className="titulo3e">
                                  N13
                                </th>
                                <th scope="col" className="titulo3e">
                                  N14
                                </th>
                                <th scope="col" className="titulo3e">
                                  N15
                                </th>
                                <th scope="col" className="titulo3e">
                                  N16
                                </th>
                                <th scope="col" className="titulo3e">
                                  N17
                                </th>
                                <th scope="col" className="titulo3e">
                                  N18
                                </th>
                                <th scope="col" className="titulo3e">
                                  N19
                                </th>
                                <th scope="col" className="titulo3e">
                                  N20
                                </th>
                                <th
                                  scope="col"
                                  width="45px"
                                  className="titulo3e"
                                >
                                  Promedio
                                </th>
                              </tr>
                            )}
                          </thead>
                          <tr>
                            <td className="cuerpo1e">{item.variable4}</td>
                            <td className="cuerpo2e">{item.variable5}</td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable27
                              )}
                            >
                              {item.variable6}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable28
                              )}
                            >
                              {item.variable7}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable29
                              )}
                            >
                              {item.variable8}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable30
                              )}
                            >
                              {item.variable9}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable31
                              )}
                            >
                              {item.variable10}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable32
                              )}
                            >
                              {item.variable11}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable33
                              )}
                            >
                              {item.variable12}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable34
                              )}
                            >
                              {item.variable13}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable35
                              )}
                            >
                              {item.variable14}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable36
                              )}
                            >
                              {item.variable15}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable37
                              )}
                            >
                              {item.variable16}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable38
                              )}
                            >
                              {item.variable17}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable39
                              )}
                            >
                              {item.variable18}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable40
                              )}
                            >
                              {item.variable19}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable41
                              )}
                            >
                              {item.variable20}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable42
                              )}
                            >
                              {item.variable21}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable43
                              )}
                            >
                              {item.variable22}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable44
                              )}
                            >
                              {item.variable23}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable45
                              )}
                            >
                              {item.variable24}
                            </td>
                            <td
                              className="cuerpo3e"
                              onClick={this.mostrarAsignacion.bind(
                                this,
                                item.variable46
                              )}
                            >
                              {item.variable25}
                            </td>
                            <td className="table-warning" width="65px">
                              {item.variable26}
                            </td>
                          </tr>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Actividades);
