import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import {Link} from "react-router-dom";

 

//componentes
import './pagosAprobados.css';
import Alert from '../../modal/alert';

class PagosAprobados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identificacionp1:'',
            tokenVerificar:'',
            opnivel:'',
            acudiente:'',
            alumno:'',
            referencia:'',
            banco:'',
            oppago:'',
            nombreper:'',
            fecha:'',
            monto:'',
            btnEnviarOn:false,
            ifechamedi:[],
            listaOn:false,
            mensajealerta:false,
            correo:'',
            meses:[],
            anos:[],
            codigo3:'',
            codigo4:'',
            pantalla1On:false,
            fecharegistro:'',
            fechaaprobado:'',
        }
       
      
    }

    limpiar =() =>{
        this.setState({
            identificacionp1:'',
            tokenVerificar:'',
            opnivel:'',
            acudiente:'',
            alumno:'',
            referencia:'',
            banco:'',
            oppago:'',
            nombreper:'',
            fecha:'',
            monto:'',
            btnEnviarOn:false,
            ifechamedi:[],
            listaOn:false,
            mensajealerta:false,
            correo:'',
            codigo3:'',
            codigo4:'',
            pantalla1On:false,
            fecharegistro:'',
            idpago:'',
            fechaaprobado:'',
        })
    }

    cargarFechas=()=>{
        let data = new FormData();
        data.append('boton', 'cargarFechas');
        let config = {
            headers: {
                'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php';
        axios.post(xurl, data, config)
        .then(res => {
            //console.log("paso "+res.data.variable2)
            let xifechamedi1 = res.data.variable1
            let xifechamedi2 = res.data.variable2
            this.setState({
                meses:xifechamedi1,
                anos:xifechamedi2,
            });
         })
    } 

    cerrarLista=()=>{
        //console.log(this.state.listaOn)
        if (this.state.listaOn){
            this.setState({
                listaOn:false,
            })
        }
        if (!this.state.listaOn) {
            this.setState({
                listaOn:true,
            })
        }
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }
    
    onChange3(e) {
        //console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value             
        })
        this.calcularEdad(e.target.value)
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    
    
    consulta=(e)=>{
        if (e.length>0) {
            let data = new FormData();
            data.append('idpago', e); 
            data.append('boton', 'buscarPagoPersonaAprobados');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php';
            axios.post(xurl, data, config)

            .then(res => {
                if (res.data) {
                    console.log(res.data)    
                    this.setState({
                        idpago:e,
                        fecharegistro:res.data.variable1,
                        acudiente:res.data.variable2,
                        alumno:res.data.variable3,
                        oppago:res.data.variable4,
                        referencia:res.data.variable5,
                        banco:res.data.variable6,
                        nombreper:res.data.variable7,
                        fecha:res.data.variable8,
                        monto:res.data.variable9,
                        correo:res.data.variable10,
                        fechaaprobado:res.data.variable11,
                        listaOn:false,
                        pantalla1On:true,
                    });
                }else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu validar pago',
                        cuerpoM: 'Existe un problema, verifique por favor gracias...',
                        listaOn:false,
                    })
                }
            })
        }
    }

    buscarPagos=()=>{
        
    let data = new FormData();
    //data.append('cedulaRepresentante', this.state.identificacionp1); 
    data.append('mes', this.state.codigo3); 
    data.append('anio', this.state.codigo4); 
    data.append('boton', 'buscarPagosAprobados');
    let config = {
        headers: {
            'Content-Type': 'text/html'}
    };
    const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php';
    axios.post(xurl, data, config)

        .then(res => {
           // console.log(res)
            let xifechamedi = res.data
            this.setState({
                ifechamedi:xifechamedi,
                listaOn:true,
            });
        })
    }

    buscarPagosIndividual=()=>{
        
        if (this.state.identificacionp1.length>0) {

            //console.log('cedula '+this.state.identificacionp1)
            let data = new FormData();
            data.append('cedulaRepresentante', this.state.identificacionp1); 
            data.append('mes', this.state.codigo3); 
            data.append('anio', this.state.codigo4); 
            data.append('boton', 'buscarPagosIndividualAprobados');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php';
            axios.post(xurl, data, config)

            .then(res => {
            // console.log(res)
                let xifechamedi = res.data
                this.setState({
                    ifechamedi:xifechamedi,
                    listaOn:true,
                });
            })
        }
    }

    componentDidMount(){
       this.cargarFechas();
    }

    render(){
        return(
            <div className="principal-pago row">
                <div className="contenedor-pago col-12">
                    <div className="titulo-pago ">
                        <p>CONSULTA DE PAGOS REALIZADOS YA APROBADOS. </p>
                    </div>
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    }
                    {!this.state.pantalla1On &&
                        <div className="contenedor-titulo row">
                            <div className="titulo-pago col-12">
                                <p>DEBE SELECCIONAR MES Y AÑO E INGRESAR LA CEDULA/PASAPORTE DEL REPRESENTANTE O IR A EL LISTADO GENERAL. </p>
                            </div>
                            <div className="row">
                                <div className="fechas col-12">                                    
                                    <select id="codigo3" name="codigo3" value={this.state.codigo3} onChange={this.onChange.bind(this)}>
                                            <option>--Mes--</option>
                                        {this.state.meses.map((item, i)=>{
                                            return <option  key={item.variable1} value={item.variable1}>{item.variable2}</option>
                                        })}
                                    </select>
                                    <select id="codigo4" name="codigo4" value={this.state.codigo4} onChange={this.onChange.bind(this)}>
                                            <option>--Año--</option>
                                        {this.state.anos.map((item, i)=>{
                                            return <option  key={item.variable1} value={item.variable1}>{item.variable1}</option>
                                        })}
                                    </select>
                                </div>
                            </div>    
                            <div className="contenedor-cedula row">
                                <label className="labelcedulac">Inserte Cedula/Pasaporte Representante.</label>   
                                <div className="contenedorcedula1 col-4">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="identificacionp1"
                                        id="identificacionp1"
                                        autoComplete="off"
                                        placeholder="Identificación Representante."
                                        maxLength="30"
                                        required
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.identificacionp1.toUpperCase()}
                                    />  
                                </div>                               
                            </div>
                            {!this.state.tokenOn &&
                                <div className="xcontenedorcedula1 col-12">
                                    <button className="boton" onClick={this.buscarPagosIndividual.bind(this)}>Consultar</button>
                                    <button className="boton" onClick={this.buscarPagos.bind(this)}>Listado</button>
                                    <Link to="/"><button className="boton">Salir</button></Link>
                                </div>
                            }
                        </div>
                    }
                   
                   {this.state.listaOn &&
                        <div className="cont-tablamedi row">
                            <div className="tablamedi1 col-11">
                                <div className="cont-titulomedi col-12">
                                    <span className="titulocasos">Listado de Pagos Recibidos Aprobados</span>
                                    <span className="titulo-cerrarcasos" onClick={this.cerrarLista.bind(this)}>X</span>
                                </div>
                                {/* <span className="titulo-casos">Datos del Pago</span> */}
                                <div className="cont-listmedi row">
                                    <div className="listmedi col-12">
                                        <table className="tablame" border="1">
                                            <tbody className="evocuerpo1">
                                                {this.state.ifechamedi.map((item, i)=>{
                                                     return<tr className={i%2 === 0 ? "odd" : ""}  key={item.variable1} id="tabla"><td className="renglon" width="20" onClick={this.consulta.bind(this,item.variable1)}>{item.variable2}</td></tr>                                
                                                })}
                                            </tbody>
                                        </table>
                                    </div>    
                                </div>
                            </div>  
                        </div>
                    }
                    {this.state.pantalla1On &&
                    !this.state.btnEnviarOn &&
                            <div className="contenedor-titulo row">
                                <div className="titulo-pago col-12">
                                    <p>AHORA PUEDE CONSULTAR EL PAGO DEL REPRESENTANTE YA APROBADO. </p>
                                </div>
                                <div className="contenedor-cedula col-6">
                                        <label className="labelcedulac">Acudiente.</label>   
                                    
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="ide"
                                                id="acudiente"
                                                autoComplete="off"
                                                readOnly="readonly"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.acudiente}
                                            />  
                                                    
                                </div>
                                <div className="contenedor-cedula col-6">
                                        <label className="labelcedulac">Estudiante.</label>   
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="alumno"
                                                id="alumno"
                                                autoComplete="off"
                                                readOnly="readonly"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.alumno}
                                            />  
                                </div>   
                                <div className="cont-radio col-4">
                                    <label className="labelcedulac1">Forma de Pago.</label>   
                                    <label>
                                        <input
                                            className='opnivel1' 
                                            type="radio" 
                                            value="TRANSFERENCIA"
                                            name="oppago"
                                            readOnly="readonly" 
                                            checked={this.state.oppago === 'TRANSFERENCIA'}
                                            //onChange={this.onChange.bind(this)}
                                        />
                                        Transferencia.
                                    </label>
                                    <label>
                                        <input 
                                            className='opnivel2' 
                                            type="radio" 
                                            value="DEPOSITO"
                                            name="oppago" 
                                            readOnly="readonly"
                                            checked={this.state.oppago === 'DEPOSITO'}
                                            //onChange={this.onChange.bind(this)}
                                        />
                                        Deposito
                                    </label>
                                </div>
                                <div className="contenedor-cedula col-3">
                                        <label className="labelcedulac">Banco.</label>   
                                    
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="banco"
                                                id="banco"
                                                readOnly="readonly"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.banco.toUpperCase()}
                                            />  
                                                    
                                </div>
                                <div className="contenedor-cedula col-3">
                                        <label className="labelcedulac">Referencia.</label>   
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="referencia"
                                                id="referencia"
                                                readOnly="readonly"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.referencia.toUpperCase()}
                                            />  
                                </div>
                                <div className="contenedor-cedula col-2">
                                        <label className="labelcedulac">Monto a Pagar.</label>   
                                    
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="monto"
                                                id="monto"
                                                readOnly="readonly"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.monto}
                                            />                
                                </div>
                                <div className="contenedor-cedula col-3">
                                        <label className="labelcedulac">Fecha Registrada.</label>   
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="fecharegistro"
                                                id="fecharegistro"
                                                readOnly="readonly"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.fecharegistro}
                                            />  
                                </div>     
                                <div className="contenedor-cedula col-3">
                                        <label className="labelcedulac">Fecha Transacción.</label>   
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="fecha"
                                                id="fecha"
                                                readOnly="readonly"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.fecha}
                                            />  
                                </div>
                                <div className="contenedor-cedula col-3">
                                        <label className="labelcedulac">Fecha Aprobación.</label>   
                                            <input
                                                className="form-control"
                                                type="date"
                                                name="fechaaprobado"
                                                id="fechaaprobado"
                                                readOnly="readonly"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.fechaaprobado}
                                            />  
                                </div>                
                                <div className="contenedor-cedula col-3">
                                        <label className="labelcedulac">Nombre de la persona que realizó la Transferencia.</label>   
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="nombreper"
                                                id="nombreper"
                                                readOnly="readonly"
                                                autoComplete="off"
                                                onChange={this.onChange.bind(this)}
                                                value={this.state.nombreper.toUpperCase()}
                                            />  
                                </div>
                                {!this.state.btnEnviarOn &&
                                <div className="contenedorcedula1 col-12">
                                    <button className="boton" onClick={this.limpiar.bind(this)}>Limpiar</button>
                                    <Link to="/"><button className="boton">Salir</button></Link>

                                </div>        
                                }
                        </div>                 
                    }
                </div>

            </div>
        )
    }
}export default PagosAprobados;