import React, { Component } from "react";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import RingLoader from "react-spinners/ScaleLoader";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardSubtitle,
  CardImg,
  CardTitle,
  CardText,
  CardColumns,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";

//componentes
import "./subirPublicarNotas.css";
import Alert from "../../modal/alert";
class AdjuntarArchivosI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      mensajealerta: false,
      rutasArchivos: "",
      irutas: [],
      identificacion: "",
      paso: false,
      btnOK: false,
      mensaje: "",
      loading: false,
      enviadosOn: false,
      adjuntosOn: false,
    };
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  limpiar = () => {
    this.setState({
      botonOn: true,
      mensajealerta: false,
      rutasArchivos: "",
      irutas: [],
      identificacion: "",
      paso: false,
      mensaje: "",
      loading: false,
      adjuntosOn: false,
    });
  };

  consultarAdjuntos() {
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("idarchivos", this.props.idarchivos);
    data.append("boton", "consultarAdjuntosPublicarNotas");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/subirTareas.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xrutas = res.data.variable2;
          this.setState({
            irutas: xrutas,
            adjuntosOn: true,

          });
        } else {
          let xrutas = res.data.variable2;
          this.setState({
            irutas: xrutas,
            adjuntosOn: false,

          });
        }
      });
    this.setState({
      loading: false,
    });
  }

  eliarchivo = (e, i) => {
    //console.log(e);
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("idarchivos", e);
    data.append("rutaArchivos", i);
    data.append("boton", "eliminarAdjuntosPublicarNotas");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/subirTareas.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          this.consultarAdjuntos();
        } else {
          this.setState({
            paso: true,
            mensaje: "Hubo un Error los Archivos no Fueron Adjuntados",
          });
        }
        //console.log(res);
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  Upload = (e) => {
    //console.log(e.target.files)
    this.setState({
      loading: true,
    });
    const form_data = new FormData();

    let name_fvar = "";
    for (let x = 0; x < e.target.files.length; x++) {
      name_fvar = "file_" + String(x + 1);
      form_data.append(name_fvar, e.target.files[x]);
      //console.log(name_fvar)
    }
    form_data.append("idarchivos", this.props.idarchivos);
    form_data.append("boton", "GuardarAdjuntosPublicarNotas");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/subirTareas.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        this.setState({
          loading: false,
          adjuntosOn: true,
          mensajealerta: true,
          tituloM: "Menu Publicar Notas Corregidas.",
          cuerpoM: "El Archivo fue enviado de forma correcta.",
        });
        if (res.data.variable1) {
          // alert ('Los ArDocumentos fueron enviados exitosamente');
          let xrutas = res.data.variable2;
          this.setState({
            irutas: xrutas,
          });
        } else {
          if (res.data.variable3 > 0) {
            this.setState({
              paso: true,
              mensaje: res.data.variable2,
            });
          } else {
            this.setState({
              paso: true,
              mensaje:
                "Es posible que uno o mas archivos no fueron guardados en el servidor",
            });
          }
        }
        //console.log(res);
      });
  };

  ClickUpload = (e) => {
    document.getElementById("upload-file").click();
  };

  componentDidMount() {
   this.consultarAdjuntos()
  }

  render() {
    return (
      <div className="cont-servi-principal row">
        <div className="eslogan1su1 col-12">
          {!this.state.adjuntosOn && (
            <div>
              <p className="titulosu1">Publicar Archivo de Evaluación.</p>
            </div>
          )}
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="contenido1 row">
            <div className="form-groupef1 col-12">
              {!this.state.adjuntosOn && (
                <div className="cont-buscar1 row">
                  <div
                    className="identificacionr2 col-12"
                    onClick={this.ClickUpload.bind(this)}
                  >
                    <p className="subirtexto1">
                      Haz click para adjuntar tu archivo
                    </p>
                    <br />
                    <FeatherIcon icon="upload" size="30px" stroke="#999" />
                    <input
                      onChange={this.Upload.bind(this)}
                      style={{ display: "none" }}
                      id="upload-file"
                      type="file"
                      // multiple
                    />
                  </div>
                </div>
              )}
              {this.state.adjuntosOn && (
                <div className="preview row">
                  <div className="titulo-notificacion col-12">
                    <p className="titulosu1">Publicación ya Guardada.</p>
                  </div>
                  {this.state.irutas.map((item, i) => {
                    return (
                      <div className="cont-foto1">
                        <Card className="mb-4">
                          <CardHeader>
                            <div className="htitulo1 row">
                              Nombre Archivo: {item.variable2}
                            </div>
                            <div className="htitulo2 row">
                              Fecha Enviado: {item.variable4}
                            </div>
                          </CardHeader>
                          <CardBody>
                            <a
                              href={item.variable3}
                              target="popup"
                              onClick="window.open(this.href, this.target, 'width=350,height=420'); return false;"
                            >
                              {" "}
                              <img
                                src={item.variable3}
                                height="35px"
                                width="35px"
                              />{" "}
                            </a>
                          </CardBody>
                          <CardFooter>
                            <button
                              className="boton1"
                              onClick={() => {
                                this.eliarchivo(item.variable1, item.variable5);
                              }}
                            >
                              Eliminar
                            </button>
                          </CardFooter>
                        </Card>

                        {/* <span
                          className="cerrar"
                          onClick={this.eliarchivo.bind(
                            this,
                            item.variable1,
                            item.variable3
                          )}
                        >
                          X
                        </span>{" "}
                        {item.variable2} */}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdjuntarArchivosI;
