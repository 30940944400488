import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./consultaIT3.css";
import Alert from "../../modal/alert";

class SeccionesAAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idseccion: "",
      cedulaAlumno: "",
      idseccionA: "",
      trimestre: "",
      idmateria: "",
      anoEscolar: [moment().format("YYYY")],
      ianoEscolar: [],
      iseccionesA: [],
      iinasistencia: [],
      ialumnos: [],
      isecciones: [],
      imaterias: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: false,
      xtotales: "",
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      iterapeuta: [],
      terapeuta: "",
    };
  }

  limpiar = () => {
    this.setState({
      idseccionP: "",
      elimi: false,
      listaOn: false,
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      terapeuta: "",
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarAlumnos(e.target.value);
    //this.buscarSeccionesA(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.buscarAlumnos(e.target.value);
  }

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  imprimir = () => {
    let data = new FormData();
    data.append("cedulaTerapeuta", this.state.terapeuta);
    data.append("arregloPaciente", JSON.stringify(this.state.iinformeFechasS));
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteHorarioEspecialista.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  buscarI = () => {
    if (
      this.state.trimestre.length > 0 &&
      // this.state.idmateria.length > 0 &&
      this.state.idseccion.length > 0
    ) {
      let data = new FormData();
      data.append("idseccion", this.state.idseccion);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("trimestre", this.state.trimestre);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      if (this.state.cedulaAlumno === "") {
        data.append("boton", "buscarInasistenciaG");
      } else {
        data.append("boton", "buscarInasistencia");
      }

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/alumnoMaestro.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              xtotales: res.data.variable3,
            });
          } else {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              mensajealerta: true,
              tituloM: "Menu Consultar Inasistencias.",
              cuerpoM: res.data.variable3,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencias.",
        cuerpoM:
          "Debe seleccionar un Trimestre, un Alumno, un Grado y una Materia.",
      });
    }
  };

  eliminar = () => {
    if (this.state.idseccionA.length > 0) {
      let data = new FormData();
      data.append("idseccionesA", this.state.idseccionA);
      data.append("boton", "eliminarSeccionesA");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
          this.buscarSeccionesA(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Grados Alumnos.",
        cuerpoM: "Debe existir un registro para poder eliminar la Sección.",
      });
    }
  };

  buscarSeccionesA = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("anoEscolar", this.state.anoEscolar);
        data.append("cedulaAlumno", e);
        data.append("boton", "buscarSeccionesA");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xisecciones = res.data.variable3;
            let xiseccionesA = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                isecciones: xisecciones,
                iseccionesA: xiseccionesA,
              });
            } else {
              this.setState({
                iseccionesP: [],
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Grados Alumnos.",
          cuerpoM: "Debe existir un Alumno para realizar el proceso.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Seccion Alumnos.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  buscarSeccionesAG = () => {
    if (this.state.anoEscolar.length > 0) {
      let data = new FormData();
      data.append("boton", "buscarSeccionesAG");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xisecciones = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              isecciones: xisecciones,
            });
          } else {
            this.setState({
              isecciones: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencia.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  buscarSecciones = () => {
    let data = new FormData();
    data.append("boton", "buscarSecciones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/secciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiseccionesA = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iseccionesA: xiseccionesA,
          });
        } else {
          this.setState({
            iseccionesA: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosG");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Asignar Grados a Alumnos",
      cuerpoM:
        "Puede que el Grado Asignado ya tenga notas Asociadas, Desea Realmente Eliminar el Registro...",
      idseccionA: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  buscarIT = () => {
    let data = new FormData();
    data.append("cedulaTerapeuta", this.state.terapeuta);
    data.append("boton", "consultarInformeTerapeuta");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiinformeFechasS = res.data.variable2;
        let xiinformeFechas = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
            iinformeFechasS: xiinformeFechasS,
            iinformeFechas: xiinformeFechas,
          });
        } else {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
          });
        }
      });
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Paciente con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarPlanilla = () => {
    if (
      this.state.tbusqueda === "1" ||
      this.state.tbusqueda === "2" ||
      this.state.tbusqueda === "3" ||
      this.state.tbusqueda === "4"
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.setState({ grado: "0" });
        this.buscarCursosGrados();
        //this.buscarCursosGrados()
      }
      if (this.state.tbusqueda === "4") {
        this.buscarPorNombres3();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  buscarE = () => {
    let data = new FormData();
    data.append("boton", "Buscar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        
          let xterapeuta = res.data;
          this.setState({
            iterapeuta: xterapeuta,  
          });      
      });
  };

  componentDidMount() {
    this.buscarE();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CONSULTA HORARIO TERAPEUTAS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {!this.state.verplanillaOn && (
            <div className="contenedorp row">
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="terapeuta"
                    name="terapeuta"
                    value={this.state.terapeuta}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Seleccione Terapeuta --</option>
                    {this.state.iterapeuta.map((item, i) => {
                      return (
                        <option key={i} value={item.variable2}>
                          {item.variable3}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarIT.bind(this)}
                >
                  Consultar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Otra Consulta
                </button>
                <button
                  className={
                    this.state.iinformeFechasS.length > 0 ? "boton" : "botonOf"
                  }
                  onClick={
                    this.state.iinformeFechasS.length > 0
                      ? this.imprimir.bind(this)
                      : ""
                  }
                >
                  Imprimir Horario
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            </div>
          )}
          {!this.state.verplanillaOn && (
            <div className="row">
              {this.state.iinformeFechasS.map((item, i) => {
                return (
                  <div className="cont-diasterapia">
                    {item.variable11 === "1" && (
                      <div className="cont-diasterapia2 row">
                        {/* <div className="diasNombre col-11">
                          PACIENTE: {item.variable2}
                        </div> */}
                        <div className="diasH">HORARIO</div>
                        <div className="diasS">LUNES</div>
                        <div className="diasS">MARTES</div>
                        <div className="diasS">MIERCOLES</div>
                        <div className="diasS">JUEVES</div>
                        <div className="diasS">VIERNES</div>
                        <div className="diasS">SABADO</div>
                        <div className="diasS">DOMINGO</div>
                      </div>
                    )}
                    {item.variable11 !== "1" && (
                      <div className="group row">
                        <div className="XdiasH">{item.variable3}</div>
                        <div className="XdiasS">{item.variable4}</div>
                        <div className="XdiasS">{item.variable5}</div>
                        <div className="XdiasS">{item.variable6}</div>
                        <div className="XdiasS">{item.variable7}</div>
                        <div className="XdiasS">{item.variable8}</div>
                        <div className="XdiasS">{item.variable9}</div>
                        <div className="XdiasS">{item.variable10}</div>
                      </div>
                    )}
                    {/* {item.variable11 === "1" &&
                      this.state.iinformeFechas.map((item2, j) => {
                        return (
                          <div className="">
                            {item.variable1 === item2.variable1 && (
                              <div className="">
                                {item2.variable17 === 1 && (
                                  <div className="cont-diasterapia2 row">
                                    <div className="diasHt">TERAPIA</div>
                                    <div className="diasSt">F/INICIO</div>
                                    <div className="diasSt">P/EVALUACIÓN</div>
                                    <div className="diasSt">F/ENTREGA</div>
                                    <div className="diasSt">INF/AVANCE 1</div>
                                    <div className="diasSt">F/ENTREGA</div>
                                    <div className="diasSt">INF/AVANCE 2</div>
                                    <div className="diasSt">F/ENTREGA</div>
                                    <div className="diasSt">INF/AVANCE 3</div>
                                    <div className="diasSt">F/ENTREGA</div>
                                    <div className="diasEsp">ESPECIALISTA</div>
                                  </div>
                                )}

                                <div className="group row">
                                  <div className="XdiasHt">
                                    {item2.variable2}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable3}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable4}
                                  </div>
                                  {item2.variable6 !== "" && (
                                    <div className="XdiasSt">
                                      <a
                                        href={item2.variable6}
                                        target="__blank"
                                      >
                                        {item2.variable5}
                                      </a>
                                    </div>
                                  )}
                                  {item2.variable6 === "" && (
                                    <div className="XdiasSt">
                                      {item2.variable5}
                                    </div>
                                  )}

                                  <div className="XdiasSt">
                                    {item2.variable7}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable8}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable10}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable11}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable13}
                                  </div>
                                  <div className="XdiasSt">
                                    {item2.variable14}
                                  </div>
                                  <div className="XdiasEsp">
                                    {item2.variable16}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })} */}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SeccionesAAction;
