import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";

//componentes
import "./actividades.css";
import Alert from "../../modal/alert";

class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      fecha: "",
      textoevo: "",
      anoEscolar: "",
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      fechaHasta: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar:'',
      idactividad:'',
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      iactividades:[],
      imaterias: [],
      icursos: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      ianoEscolar: [],
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      fecha: "",
      textoevo: "",
      anoEscolar: "",
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcion: "",
      ocultar:'',
      idactividad:'',
      imaterias: [],
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarProfesor();
    if (index === "idprofesor") this.buscarCurso(e.target.value);
    if (index === "idcurso") this.buscarPlanE(e.target.value);
    if (index === "idmateria") this.consultarActividades(e.target.value);
    
  }



  eliminar = () => {
    if (this.state.idactividad.length > 0) {
      let data = new FormData();

      data.append("idactividad", this.state.idactividad);
      data.append("boton", "Eliminar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          let e='';
          this.consultarActividades(this.state.idmateria)
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Planificacion.",
        cuerpoM: "Debe existir un registro para poder eliminarlo.",
      });
    }
  };

  consultar(e) {
    if (e.length > 0) {
      this.setState({idactividad:e});
      let data = new FormData();
      data.append("idactividad", e);
      data.append("boton", "buscarActividadesI");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          
          this.setState({
            idmateria: res.data.variable2,
            idcurso : res.data.variable3,
            descripcion:res.data.variable4,
            ocultar: res.data.variable5,
            botonguardarOn:true,
            botonmodiOn:true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }
  }

  consultarActividades(e) {
    
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      if (e.length>0) data.append("idmateria", e);
      if (e.length===0) data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "buscarActividades");

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
        data: data,
      }).then((res) => {
          let xiactividades = res.data.variable2;
          this.setState({
            iactividades: xiactividades,
          });
      });
    
  }

  grabartexto() {
    if (
      this.state.descripcion.length > 0 &&
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.ocultar.length > 0) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("descripcion", this.state.descripcion);
      data.append("ocultar", this.state.ocultar);
      data.append("boton", "crearActividades");
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
          this.consultarActividades(this.state.idmateria)
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividades",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Actividades",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  }

  modificartexto() {
    if (
      this.state.descripcion.length > 0 &&
      this.state.anoEscolar.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.ocultar.length > 0) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idgrado", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("descripcion", this.state.descripcion);
      data.append("ocultar", this.state.ocultar);
      data.append("idactividad", this.state.idactividad);
      data.append("boton", "modificar");
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividad",
            cuerpoM: res.data.variable2,
            botonguardarOn: false,
            botonmodiOn: false,
            ocultar:'',
            descripcion:'',
            
          });
          this.consultarActividades(this.state.idmateria)
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu de Actividad",
            cuerpoM: res.data.variable2,
          });
        }
      });
    }else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Actividades",
        cuerpoM:
          "Existe al menos un campo sin registro, Verifique que todos los campos que tengan registros",
      });
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarFPI = (e) => {
    let form = new FormData();
    form.append("idfechaPlanificacion", e);
    form.append("boton", "buscarFPI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          tipoPlanificacion: res.data.variable2,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Planificacion",
          cuerpoM:
            "Debe comunicarse con la coordinacion e indicarle que el tipo de planificacion que usted intenta generar no tiene asignada el tipo de Planificacion (SEMANAL o MENSUAL).",
        });
      }
    });
  };

  buscarFP = (e) => {
    let form = new FormData();
    form.append("idgrado", e);
    form.append("boton", "buscarFP");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xifechaPlani = res.data.variable2;
      this.setState({
        ifechaPlani: xifechaPlani,
        idfechaPlani: "",
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      
        this.setState({ iprofesor: xiprofesor });
        //this.buscarCurso(item.variable1);
      
    });
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              idmateria: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Crear Actividad.",
      cuerpoM: "Realmente Quiere Eliminar la Actividad...",
      idactividad: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  handleEditorChange(textoevo, editor) {
    this.setState({ textoevo });
  }
  componentDidMount() {
    this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU DE ACTIVIDADES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Año Escolar --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="idprofesor"
                      name="idprofesor"
                      value={this.state.idprofesor}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option value="">--- Seleccione un Profesor ---</option>
                      {this.state.iprofesor.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idmateria"
                      name="idmateria"
                      value={this.state.idmateria}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Materia --</option>
                      {this.state.imaterias.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="botones col-12">
                  <div className="acciones">
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
              <div className="cont-listmedix row">
                <div className="listcxcx col-12">
                  <table className="tablacxcx" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc3">GRADO</th>
                        <th className="titucxc3">MATERIAS</th>
                        <th className="titucxc3">ASIGNACIONES.</th>
                        <th className="titucxc4">OCULTAR.</th>
                        
                      </tr>
                    </thead>

                    {this.state.iactividades.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          <td className="totalescxcx">{item.variable2+" "+item.variable3}</td>
                          <td className="totalescxcx">{item.variable4}</td>
                          <td className="totalescxcx">{item.variable5}</td>
                          <td className="totalescxcx">{item.variable6}</td>
                      
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Actividades);
