import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//componentes
import "./cxc.css";
import GenerarVeranito from "../veranito/veranito";
import PagosCanje from "../pagoCanje/pagoCanje";
import GenerarPagos from "../generarPagos/generarPagos";
import GenerarPagosM from "../generarPagosM/generarPagosM";
import DeudaActual from "../deudaActual/deudaActual";
import GenerarPagosD from "../pagosDirecto/generarPagosD";
import GenerarPagosConceptos from "../generarPagosConcepto/generarPagosC";
import ModificarMonto from "../modificarMonto2/generarPagosC";
import Alert from "../../modal/alert";

class CxC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identificacionp1: "",
      tokenVerificar: "",
      opnivel: "",
      acudiente: "",
      alumno: "",
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      fecha: "",
      monto: "",
      btnEnviarOn: false,
      ifechamedi: [],
      listaOn: false,
      mensajealerta: false,
      correo: "",
      meses: [],
      anos: [],
      nalumno: [],
      codigo3: "",
      codigo4: "",
      pantalla1On: false,
      fecharegistro: "",
      verplanillaOn: false,
      tbusqueda: "",
      identificacionp1: "",
      cedula: "",
      gradoAlumno:"",
      habersaldo: "",
      debesaldo: "",
      saldo: "",
      saldoAnterior: "",
      cuotaDisponible: "",
      procesoOn: false,
      flotadorPDOn: false,
      flotadorMOn: false,
      flotadorMMOn: false,
      flotadorDeudaOn: false,
      flotadorPCaOn: false,
      flotadorPCOn: false,
      flotadorVOn: false,
      flotadorREOn: false,
      recibos: [],
      icursos: [],
      igrados: [],
      cursoa: "",
      grado: "0",
      modificarSaldoOn: false,
      reversarPagoOn: false,
      idcxc: "",
      montoDebe: "",
      montoAbono: "",
      montoModificar: "",
      idcontrolPago: "",
      descripActual: "",
      descripNueva: "",
      status: "",
      datosChequeOn: false,
      concepto: "",
      origen: "",
      modificarMensualidadOn: false,
      mensualidad: "",
      inscripcion: "",
      idmensualidad: "",
      anioEscolar: "",
      madre: "",
      padre: "",
      fechaEscolar: [],
      ianoEscolar: [],
      anoEscolar: "",
      generarMes: false,
      nivel: "",
      enviarFacturaCorreoOn: false,
      numeroFactu: "",
      modificarDescriOn: false,
      montoNC:'',
    };
  }

  limpiar = () => {
    this.setState({
      identificacionp1: "",
      tokenVerificar: "",
      opnivel: "",
      acudiente: "",
      alumno: "",
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      fecha: "",
      monto: "",
      btnEnviarOn: false,
      ifechamedi: [],
      nalumno: [],
      listaOn: false,
      mensajealerta: false,
      correo: "",
      codigo3: "",
      codigo4: "",
      pantalla1On: false,
      fecharegistro: "",
      idpago: "",
      verplanillaOn: false,
      tbusqueda: "",
      identificacionp1: "",
      cedula: "",
      gradoAlumno:"",
      habersaldo: "",
      debesaldo: "",
      saldoAnterior: "",
      procesoOn: false,
      flotadorPDOn: false,
      flotadorMOn: false,
      flotadorMMOn: false,
      flotadorDeudaOn: false,
      flotadorPCaOn: false,
      flotadorPCOn: false,
      flotadorVOn: false,
      flotadorREOn: false,
      recibos: [],
      cursoa: "",
      grado: "0",
      modificarSaldoOn: false,
      reversarPagoOn: false,
      idcxc: "",
      montoDebe: "",
      montoAbono: "",
      montoModificar: "",
      idcontrolPago: "",
      descripActual: "",
      descripNueva: "",
      status: "",
      concepto: "",
      origen: "",
      modificarMensualidadOn: false,
      mensualidad: "",
      inscripcion: "",
      idmensualidad: "",
      madre: "",
      padre: "",
      cuotaDisponible: "",
      anoEscolar: "",
      generarMes: false,
      nivel: "",
      enviarFacturaCorreoOn: false,
      numeroFactu: "",
      modificarDescriOn: false,
      montoNC:'',
    });
  };

  cargarFechas = () => {
    let data = new FormData();
    data.append("boton", "cargarFechas");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xifechamedi1 = res.data.variable1;
      let xifechamedi2 = res.data.variable2;
      this.setState({
        meses: xifechamedi1,
        anos: xifechamedi2,
      });
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.value;
    let curso = this.state.icurso[index].variable1;
    //let concepto= this.state.icurso[index].variable2
    this.setState({
      curso: curso,
    });
  }

  onChange3(e) {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarCXC1(this.state.cedula, e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({
      mensajealerta: false,
      generarMes: false,
    });
  };
  generarPagoClose = () => {
    this.setState({
      flotadorMOn: false,
      flotadorMMOn: false,
      flotadorDeudaOn: false,
      flotadorPCaOn: false,
      flotadorPCOn: false,
      flotadorVOn: false,
      modificarSaldoOn: false,
      flotadorPDOn: false,
    });
    this.buscarCXC(this.state.cedula);
  };
  generarAbonosConcepto = () => {
    this.setState({ flotadorPCOn: true });
  };

  reversarPago = () => {
    if (this.state.reversarPagoOn === true) {
      this.setState({ reversarPagoOn: false });
    } else {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedula);
      data.append("boton", "buscarPMI");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xrecibos = res.data.variable2;
            this.setState({
              recibos: xrecibos,
              reversarPagoOn: true,
            });
          }
        });
    }
  };

  ejecutarReversarPago = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("item", e);
      data.append("boton", "ejecutarReversarPago");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/reportarPago2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              reversarPagoOn: false,
              mensajealerta: true,
              tituloM: "Menu Reversar Pago",
              cuerpoM: "Se Reverso el Pago Correctamente, gracias...",
            });
            this.buscarCXC(this.state.cedula);
          } else {
            this.setState({
              reversarPagoOn: false,
              mensajealerta: true,
              tituloM: "Menu Reversar Pago",
              cuerpoM: "Se Produjo un Error Verifique, gracias...",
            });
          }
        });
    }
  };

  generarPagoCanje = () => {
    this.setState({ flotadorPCaOn: true });
  };

  generarPago = () => {
    this.setState({ flotadorMOn: true });
  };
  generarPagoM = () => {
    this.setState({ flotadorMMOn: true });
  };
  deudaActual = () => {
    this.setState({ flotadorDeudaOn: true });
  };
  generarPagoD = () => {
    this.setState({ flotadorPDOn: true, procesoOn: false });
  };

  veranito = () => {
    this.setState({ flotadorVOn: true });
  };

  recibosEmitido = () => {
    if (this.state.flotadorREOn === true) {
      this.setState({ flotadorREOn: false });
    } else {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedula);
      data.append("boton", "consultarReciboG");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xrecibos = res.data.variable2;
            this.setState({
              recibos: xrecibos,
              flotadorREOn: true,
            });
          }
        });
    }
  };

  consultaRecibo = (e) => {
    if (e.length > 0) {
      this.setState({ flotadorREOn: false });
      let data = new FormData();
      data.append("numeroRecibo", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  aprobarPago = () => {
    let data = new FormData();

    data.append("claveUsuario", this.props.usuario1.password);
    data.append("idpago", this.state.idpago);
    data.append("boton", "aprobarPago");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/reportarPago2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            btnEnviarOn: true,
          });
        } else {
          this.setState({
            btnEnviarOn: false,
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                gradoAlumno: res.data.variable144,
              });
              this.buscarCXC(res.data.variable4);
              this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                gradoAlumno: res.data.variable144,
              });
              this.buscarCXC(res.data.variable4);
              this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarCursos = () => {
    axios
      .post(
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php/?boton=consultarCursos"
      )
      .then((res) => {
        if (res.data) {
          let xcursos = res.data;
          //console.log(res.data)
          this.setState({
            icursos: xcursos,
          });
        }
      });
  };
  buscargrado = (e) => {
    //console.log(e);
    let data = new FormData();
    data.append("boton", "buscarGrados2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/grado.php";
    axios.post(xurl, data, config).then((res) => {
      let xgrado = res.data;
      this.setState({
        igrados: [],
        igrados: xgrado,
      });
    });
  };

  componentDidMount() {
    this.buscarAnioEscolar();
    this.buscargrado();
  }

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          anioEscolar: res.data.variable2,
          fechaEscolar: res.data.variable3,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                nivel: res.data.variable134,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                gradoAlumno: res.data.variable144,
              });
              this.buscarCXC(res.data.variable4);
              this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                gradoAlumno: res.data.variable144,
              });
              this.buscarCXC(res.data.variable4);
              this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (
      (this.state.tbusqueda === "1" && this.props.usuario1.anioE !== "") ||
      (this.state.tbusqueda === "2" && this.props.usuario1.anioE !== "") ||
      (this.state.tbusqueda === "3" && this.props.usuario1.anioE !== "") ||
      (this.state.tbusqueda === "4" && this.props.usuario1.anioE !== "")
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.setState({ grado: "0" });
        this.buscarCursosGrados();
        //this.buscarCursosGrados()
      }
      if (this.state.tbusqueda === "4") {
        this.buscarPorNombres3();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  buscarCursosGrados = () => {
    let data = new FormData();
    data.append("grado", this.state.grado);
    data.append("boton", "buscarCursosGrados");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante, gracias...",
          });
        }
      });
  };

  buscarPorNombres3= () => {
    let data = new FormData();
    data.append("boton", "buscarNombres3");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  buscarCXC1 = (e, f) => {
    let data = new FormData();
    data.append("cedulaAlumno", e);
    data.append("anio", f);
    data.append("nivel", this.state.nivel);
    data.append("boton", "buscarCXCxAnio");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            saldo: res.data.variable3,
            debesaldo: res.data.variable4,
            habersaldo: res.data.variable5,
            saldoAnterior: res.data.variable6,
          });
        } else {
          this.setState({
            nalumno: [],
          });
        }
      });
  };

  buscarCXC = (e) => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append("cedulaAlumno", e);
    data.append("nivel", this.state.nivel);
    data.append("boton", "buscarCXC");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            saldo: res.data.variable3,
            debesaldo: res.data.variable4,
            habersaldo: res.data.variable5,
            saldoAnterior: res.data.variable6,
            nivel: res.data.variable7,
          });
        } else {
          this.setState({
            nalumno: [],
          });
        }
      });
  };

  generarInscripcion = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append("claveUsuario", this.props.usuario1.password);
    data.append("cedulaAlumno", this.state.cedula);
    data.append("boton", "generarInscripcion");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          // axios.post(
          //   "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago.php/?numeroRecibo=" +
          //     res.data.variable2
          // );

          this.buscarCXC(this.state.cedula);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Generar Inscripción.",
            cuerpoM: "Se cargo la Inscripción a la CxC, gracias...",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Generar Inscripción.",
            cuerpoM:
              "Ocurrio un problema, es probable que ya este generada la Inscripción a la CxC, Verifique por favor.",
          });
        }
      });
  };
  verificarMesaGenerar = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append("cedulaAlumno", this.state.cedula);
    data.append("boton", "consultarMesCrear");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Consultar Mes de Mensualidad a Crear.",
            cuerpoM: res.data.variable2,
            generarMes: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Consultar Mes de Mensualidad a Crear.",
            cuerpoM: res.data.variable2,
            generarMes: false,
          });
        }
      });
  };

  consultarAnioescolar = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append("cedulaAlumno", this.state.cedula);
    data.append("boton", "consultarAnioEscolar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Consultar Año Escolar Alumno.",
            cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Consultar Año Escolar Alumno.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  generarMensualidad = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE); 
    data.append("cedulaAlumno", this.state.cedula);
    data.append("claveUsuario", this.props.usuario1.password);
    data.append("boton", "generarMensualidadI");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          // axios.post(
          //   "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago.php/?numeroRecibo=" +
          //     res.data.variable3
          // );

          this.buscarCXC(this.state.cedula);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Generar Mensualidad General.",
            cuerpoM: "Se cargo la Mensualidad a la CxC, gracias...",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Generar Mensualidad General.",
            cuerpoM:
              "Ocurrio un problema para generar la Mensualidad a la CxC, Verifique por favor.",
          });
        }
      });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  proceso = (e) => {
    if (this.state.procesoOn) {
      this.setState({ procesoOn: false });
    } else {
      this.setState({
        procesoOn: true,
        idcxc: e,
      });
    }
  };

  edoCuenta = () => {
    if (this.state.cedula.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("cedulaAlumno", this.state.cedula);
      data.append("alumno", this.state.alumno);
      data.append("cursoa", this.state.cursoa);
      data.append("grado", this.state.grado);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/edoCuenta.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Cuentas por Cobrar",
        cuerpoM: "Debe haber un Alumno para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
    } else {
      this.setState({ datosChequeOn: true });
    }
  };

  descripcionPago = (e) => {
    //console.log(e)
    let data = new FormData();
    data.append("idcxc", e);
    data.append("boton", "descripcionPago");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            datosChequeOn: true,
            oppago: res.data.variable2,
            banco: res.data.variable3,
            referencia: res.data.variable4,
            concepto: res.data.variable5,
            monto: res.data.variable6,
            fecha: res.data.variable7,
            origen: res.data.variable8,
            // mensajealerta: true,
            // tituloM: 'Menu Movimiento de Pago.',
            // cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Movimiento de Pago.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  cerrarMensualidad = () => {
    if (this.state.modificarMensualidadOn) {
      this.setState({ modificarMensualidadOn: false });
    } else {
      this.setState({ modificarMensualidadOn: true });
    }
  };

  modificarMensu = () => {
    let data = new FormData();
    data.append("idmensualidad", this.state.idmensualidad);
    data.append("mensualidad", this.state.mensualidad);
    data.append("inscripcion", this.state.inscripcion);
    data.append("cuotaDisponible", this.state.cuotaDisponible);
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append("anioEscolar", this.state.anioEscolar);
    data.append("cedulaAlumno", this.state.cedula);

    data.append("boton", "modificarMensualidad");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            modificarMensualidadOn: false,
            mensajealerta: true,
            tituloM: "Menu Modificar Mensualidad e Inscripcion.",
            cuerpoM: "El registro se modifico con exito.",
          });
        } else {
          this.setState({
            modificarMensualidadOn: false,
            mensajealerta: true,
            tituloM: "Menu Modificar Mensualidad e Inscripcion.",
            cuerpoM: "No se pudo modificar el registro, verifique por favor.",
          });
        }
      });
  };

  modificarMensualidad = () => {
    let data = new FormData();
    data.append("anoEscolar", this.props.usuario1.anioE);
    data.append("cedulaAlumno", this.state.cedula);
    data.append("boton", "buscarMensualidad");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            modificarMensualidadOn: true,
            mensualidad: res.data.variable2,
            inscripcion: res.data.variable3,
            idmensualidad: res.data.variable4,
            cuotaDisponible: res.data.variable5,
          });
        } else {
          this.setState({
            modificarMensualidadOn: false,
            mensajealerta: true,
            tituloM: "Menu Modificar Mensualidad e Inscripcion.",
            cuerpoM:
              "El estudiante no tiene registros de mensualidad, verifique por favor.",
          });
        }
      });
  };
  handleGenerarMes = () => {
    this.setState({
      mensajealerta: false,
      generarMes: false,
    });
    this.generarMensualidad();
    //console.log('generarMensualidad')
  };

  modificarmonto = () => {
    let data = new FormData();
    data.append("idcxc", this.state.idcxc);
    data.append("boton", "buscarMonto");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            modificarSaldoOn: true,
            montoDebe: res.data.variable2,
            montoAbono: res.data.variable3,
            montoModificar: res.data.variable4,
            idcontrolPago: res.data.variable5,
            procesoOn: false,
          });
        } else {
          this.setState({
            procesoOn: false,
            mensajealerta: true,
            tituloM: "Menu Estado de Cuenta Alumno.",
            cuerpoM: "Ocurrio un problema, Verifique por favor.",
          });
        }
      });
  };

  eliMensualidad = () => {
    let data = new FormData();
    data.append("idcxc", this.state.idcxc);
    data.append("boton", "eliMensualidad");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Eliminar Registro CxC.",
            cuerpoM: res.data.variable2,
          });
          this.buscarCXC(this.state.cedula);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Eliminar Registro CxC.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  enviarFacturaCorreoOn = () => {
    if (this.state.enviarFacturaCorreoOn) {
      this.setState({ enviarFacturaCorreoOn: false, numeroFactu: "" });
    } else {
      this.setState({ enviarFacturaCorreoOn: true });
    }
  };

  modificarDescri = () => {
    if (this.state.modificarDescriOn) {
      this.setState({
        modificarDescriOn: false,
        descripActual: "",
        descripNueva: "",
        idcontrolPago: "",
      });
    } else {
      let data = new FormData();
      data.append("idcxc", this.state.idcxc);
      data.append("boton", "buscarDescrip");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable4);
          //console.log(res.data.variable3);
          if (res.data.variable1) {
            this.setState({
              descripActual: res.data.variable2,
              descripNueva: res.data.variable2,
              idcontrolPago: res.data.variable3,
            });
          }
        });
      this.setState({ modificarDescriOn: true, procesoOn: false });
    }
  };

  guardarDecrip = () => {
    let data = new FormData();
    data.append("idcxc", this.state.idcxc);
    data.append("idcontrolPago", this.state.idcontrolPago);
    data.append("descripNueva", this.state.descripNueva);
    data.append("boton", "guardarDescrip");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.buscarCXC(this.state.cedula);
          this.setState({
            modificarDescriOn: false,
            mensajealerta: true,
            tituloM: "Menu Estado de Cuenta Alumno.",
            cuerpoM: "Se Realizo la Modificacion con exito.",
            descripActual: "",
            descripNueva: "",
            idcontrolPago: "",
          });
        }
      });
  };

  enviarFacturaCorreo = () => {
    let data = new FormData();
    data.append("numeroRecibo", this.state.numeroFactu);
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Enviar Factura por Correo.",
          cuerpoM: "La Factura fue enviada por correo de forma correcta",
        });
      });
  };

   buscarNC = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("cedulaA", this.state.cedula);
    data.append("boton", "consultarNCPC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            nocre: xfacturasP,
            montoNC: res.data.variable3,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };


  ax = () => {};
  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CUENTAS POR COBRAR AÑO ESCOLAR {this.props.usuario1.anioE}. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              generarMes={this.state.generarMes}
              handleEliminar={this.handleGenerarMes}
            />
          )}

          {!this.state.verplanillaOn && (
            <div className="contenedorp row">
              <div className="titulo-planillai col-12">
                <p>
                  SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                  CORRESPONDIENTA A SU SELECCIÓN.{" "}
                </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Buscar por --</option>
                    <option value="1">CEDULA/PASAPORTE</option>
                    <option value="2">NOMBRE ESTUDIANTE</option>
                    {/* <option value="3">CURSO/GRADO</option>
                    <option value="4">TODOS LOS ESTUDIANTES</option> */}
                  </select>
                </div>
              </div>
              {this.state.tbusqueda === "3" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="grado"
                      name="grado"
                      value={this.state.grado}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccione Grado --</option>
                      {this.state.igrados.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda < "3" && (
                <div className="contenedorcedula1 row">
                  <input
                    className="form-control"
                    type="text"
                    name="identificacionp1"
                    id="identificacionp1"
                    autoComplete="off"
                    placeholder={
                      this.state.tbusqueda === "1"
                        ? "Identificación Alumno."
                        : "Nombre Alumno."
                    }
                    maxLength="300"
                    onChange={this.onChange.bind(this)}
                    value={this.state.identificacionp1.toUpperCase()}
                  />
                </div>
              )}
              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarPlanilla.bind(this)}
                >
                  Consultar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>

              {this.state.listaOn && (
                <div className="cont-tablamedi row">
                  <div className="tablamedi1 col-11">
                    <div className="cont-titulomedi col-12">
                      <span className="titulocasos">
                        Listado de estudiantes
                      </span>
                      <span
                        className="titulo-cerrarcasos"
                        onClick={this.cerrarLista.bind(this)}
                      >
                        X
                      </span>
                    </div>
                    {/* <span className="titulo-casos">Datos del Pago</span> */}
                    <div className="cont-listmedi row">
                      <div className="listmedi col-12">
                        <table className="tablame" border="1">
                          <tbody className="evocuerpo1">
                            {this.state.nalumno.map((item, i) => {
                              return (
                                <tr
                                  className={i % 2 === 0 ? "odd" : ""}
                                  key={item.variable1}
                                  id="tabla"
                                >
                                  <td
                                    className="renglon"
                                    width="10"
                                    onClick={this.consulta.bind(
                                      this,
                                      item.variable1
                                    )}
                                  >
                                    {item.variable2}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          {this.state.flotadorPDOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <GenerarPagosD
                  cedulaAlumno={this.state.cedula}
                  idcxc={this.state.idcxc}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.datosChequeOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <div className="contenedor-titulo row">
                  <div className="contenedor-cedula col-4">
                    <label className="labelcedulac">Banco.</label>

                    <input
                      className="form-control"
                      type="text"
                      name="banco"
                      id="banco"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.banco.toUpperCase()}
                    />
                  </div>
                  <div className="contenedor-cedula col-4">
                    <label className="labelcedulac">Referencia.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="referencia"
                      id="referencia"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.referencia.toUpperCase()}
                    />
                  </div>
                  <div className="contenedor-cedula col-2">
                    <label className="labelcedulac">Monto a Pagar.</label>

                    <input
                      className="form-control"
                      type="text"
                      name="monto"
                      id="monto"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.monto}
                    />
                  </div>
                  <div className="row">
                    <div className="contenedor-cedula col-5">
                      <label className="labelcedulac">Concepto.</label>

                      <input
                        className="form-control"
                        type="text"
                        name="concepto"
                        id="concepto"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.concepto.toUpperCase()}
                      />
                    </div>
                    <div className="contenedor-cedula col-3">
                      <label className="labelcedulac">Fecha Transacción.</label>
                      <input
                        className="form-control"
                        type="text"
                        name="fecha"
                        id="fecha"
                        // readOnly="readonly"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.fecha}
                      />
                    </div>
                    <div className="radio-identificacion col-3">
                      <div className="radio">
                        <label className="labelcedulac">Transacción.</label>
                        <label className="container">
                          Transferencia.
                          <input
                            className="opnivel1"
                            type="radio"
                            value="TRANSFERENCIA"
                            name="oppago"
                            checked={this.state.oppago === "TRANSFERENCIA"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="radio">
                        <label className="container">
                          Deposito.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="DEPOSITO"
                            name="oppago"
                            checked={this.state.oppago === "DEPOSITO"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="radio">
                        <label className="container">
                          Tarjeta.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="TARJETA"
                            name="oppago"
                            checked={this.state.oppago === "TARJETA"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="contenedorcedula1 col-12">
                    <button className="boton" onClick={this.datosCheque}>
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.enviarFacturaCorreoOn === true && (
            <div className="flotador4xf">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Enviar Recibo por Correo</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.enviarFacturaCorreoOn.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <div className="contenedorcedula1f col-2">
                      <input
                        className="form-control"
                        type="text"
                        name="numeroFactu"
                        id="numeroFactu"
                        autoComplete="off"
                        placeholder="Numero de Recibo."
                        maxLength="5"
                        onChange={this.onChange.bind(this)}
                        value={this.state.numeroFactu}
                      />
                    </div>
                    <div className="contenedorcedula1f col-2">
                      <button
                        className="boton"
                        onClick={this.enviarFacturaCorreo.bind(this)}
                      >
                        Aceptar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.modificarDescriOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <div className="contenedor-titulo row">
                  <div className="contenedor-cedula col-4">
                    <label className="labelcedulac">Descripcion.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="descripNueva"
                      id="descripNueva"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.descripNueva}
                    />
                  </div>
                  <div className="contenedorcedula1 col-12">
                    <button
                      className="boton"
                      onClick={this.guardarDecrip.bind(this)}
                    >
                      Modificar
                    </button>
                    <button
                      className="boton"
                      onClick={this.modificarDescri.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.modificarMensualidadOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <div className="contenedor-titulo row">
                  <div className="contenedor-cedula col-4">
                    <label className="labelcedulac">Mensualidad.</label>

                    <input
                      className="form-control"
                      type="text"
                      name="mensualidad"
                      id="mensualidad"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.mensualidad.toUpperCase()}
                    />
                  </div>
                  <div className="contenedor-cedula col-4">
                    <label className="labelcedulac">Inscripcion.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="inscripcion"
                      id="inscripcion"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.inscripcion.toUpperCase()}
                    />
                  </div>
                  <div className="contenedor-cedula col-4">
                    <label className="labelcedulac">Cuotas Disponibles.</label>

                    <input
                      className="form-control"
                      type="text"
                      name="cuotaDisponible"
                      id="cuotaDisponible"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.cuotaDisponible.toUpperCase()}
                    />
                  </div>

                  <div className="contenedorcedula1 col-12">
                    <button className="boton" onClick={this.modificarMensu}>
                      Modificar
                    </button>
                    <button className="boton" onClick={this.cerrarMensualidad}>
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.flotadorPCaOn === true && (
            <div className="flotador">
              <div className="flotador1">
                <PagosCanje
                  cedulaAlumno={this.state.cedula}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.flotadorMOn === true && (
            <div className="flotador">
              <div className="flotador1">
                <GenerarPagos
                  cedulaAlumno={this.state.cedula}
                  madre={this.state.madre}
                  padre={this.state.padre}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.flotadorMMOn === true && (
            <div className="flotadorM">
              <div className="flotador1">
                <GenerarPagosM
                  cedulaAlumno={this.state.cedula}
                  madre={this.state.madre}
                  padre={this.state.padre}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.flotadorDeudaOn === true && (
            <div className="flotadorM">
              <div className="flotador1">
                <DeudaActual
                  cedulaAlumno={this.state.cedula}
                  nombreAlumno={this.state.alumno}
                  madre={this.state.madre}
                  padre={this.state.padre}
                  saldoDeuda={this.state.saldo}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.flotadorPCOn === true && (
            <div className="flotador">
              <div className="flotador1">
                <GenerarPagosConceptos
                  cedulaAlumno={this.state.cedula}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.modificarSaldoOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <ModificarMonto
                  idcxc={this.state.idcxc}
                  montoDebe={this.state.montoDebe}
                  montoAbono={this.state.montoAbono}
                  montoModificar={this.state.montoModificar}
                  idcontrolPago={this.state.idcontrolPago}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.reversarPagoOn === true && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Pagos Realizados que Puede Reversar
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.reversarPago.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.recibos.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                              onClick={this.ejecutarReversarPago.bind(
                                this,
                                item.variable1
                              )}
                            >
                              <td className="renglon" width="10">
                                {item.variable2}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.procesoOn === true && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulo-cxc">
                    ELIJA EL PROCESO A REALIZAR PRESIONANDO UNO DE LOS DOS
                    BOTONES
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.proceso.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className="boton"
                      onClick={this.generarPagoD.bind(this)}
                    >
                      Pagos Directos
                    </button>
                    <button
                      className="boton"
                      onClick={this.modificarmonto.bind(this)}
                    >
                      Modificar Monto
                    </button>
                    {/* <button
                      className="boton"
                      onClick={this.modificarDescri.bind(this)}
                    >
                      Modificar Descripcion
                    </button> */}
                    {this.props.usuario1.nivel === "1" && (
                      <button
                        className="boton"
                        onClick={this.eliMensualidad.bind(this)}
                      >
                        Eliminar Registro
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.flotadorVOn === true && (
            <div className="flotador">
              <div className="flotador1">
                <GenerarVeranito
                  cedulaAlumno={this.state.cedula}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}
          {this.state.flotadorREOn === true && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Listado de Recibos Emitidos
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.recibosEmitido.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.recibos.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consultaRecibo.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable2}
                              </td>
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consultaRecibo.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable3}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc ">
                <p>ACUDIENTE: {this.state.acudiente}</p>
              </div>
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc1 col-10">
                <p>ESTUDIANTE: {this.state.alumno}</p>
              </div>
              <div className="titulo-anioescolar">
                <select
                  id="escolar"
                  name="escolar"
                  value={this.state.escolar}
                  onChange={this.onChange3.bind(this)}
                >
                  <option>-- Año Escolar --</option>
                  {this.state.fechaEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc ">
                <p>CEDULA: {this.state.cedula}</p>
              </div>
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc ">
                <p>CURSO: {this.state.gradoAlumno}</p>
              </div>
            </div>
          )}
          {this.state.montoNC>0 && (
            <div className="row">
              <div className="titulo-reprecxcNc">
                <p>Monto N/C Pendiente por Aplicar: {this.state.montoNC}</p>
              </div>
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="cont-listmedi row">
              <div className="listcxc col-12">
                <table className="tablacxc" border="1">
                  <thead>
                    <tr>
                      <th className="titucxc1">FECHA</th>
                      <th className="titucxc2">DESCRIPCIÓN</th>
                      <th className="titucxc3">DEBE</th>
                      <th className="titucxc3">HABER</th>
                      <th className="titucxc3">SALDO</th>
                    </tr>
                  </thead>
                  {this.state.saldoAnterior !== 0 && (
                    <tr className="odd">
                      <td></td>
                      <td className="renglon">SALDO AÑO ESCOLAR ANTERIORES:</td>
                      <td className="totalescxc1">{this.state.debesaldo}</td>
                      <td className="totalescxc1">{this.state.habersaldo}</td>
                      <td className="totalescxc1">
                        {this.state.saldoAnterior}
                      </td>
                    </tr>
                  )}
                  {this.state.nalumno.map((item, i) => {
                    return (
                      <tr
                        className={i % 2 === 0 ? "odd" : "odd"}
                        key={item.variable1}
                        id="tabla"
                        onClick={
                          this.props.usuario1.nivel !== "4" &&
                          this.props.usuario1.nivel !== "5"
                            ? this.proceso.bind(this, item.variable1)
                            : this.ax.bind()
                        }
                      >
                        <td className="totalescxc" width="10">
                          {item.variable2}
                        </td>
                        <td className="renglon" width="10">
                          {item.variable3}
                        </td>
                        <td className="totalescxc1" width="10">
                          {item.variable4}
                        </td>
                        <td
                          className="totalescxc1"
                          width="10"
                          id={item.variable1}
                          data-valor={item.variable5}
                          onContextMenu={this.handleClick}
                        >
                          {item.variable5}
                        </td>
                        <td className="totalescxc1" width="10">
                          {item.variable6}
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="cont-totalescxc">
                    <td className="totalescxc"></td>
                    <td className="totalescxc">******* TOTALES *******</td>
                    <td className="totalescxc1"></td>
                    <td className="totalescxc1"></td>
                    {this.state.saldo < 0 && (
                      <td className="totalescxc2">{this.state.saldo}</td>
                    )}
                    {this.state.saldo >= 0 && (
                      <td className="totalescxc3">{this.state.saldo}</td>
                    )}
                  </tr>
                </table>
              </div>
            </div>
          )}
          {this.state.verplanillaOn &&
            this.state.status === "S" && (
              <div className="contenedorcedula1 col-12">
                {/* <button className="boton" onClick={this.generarPago.bind(this)}>
                  Generar Pagos
                </button> */}
                {/* <button
                  className="boton"
                  onClick={this.generarAbonosConcepto.bind(this)}
                >
                  Abonos Internos
                </button> */}
                <button
                  className="boton"
                  onClick={this.recibosEmitido.bind(this)}
                >
                  Recibos Emitidos
                </button>
                <button className="boton" onClick={this.edoCuenta.bind(this)}>
                  Edo. Cuenta
                </button>
                <button className="boton" onClick={this.deudaActual.bind(this)}>
                  Deuda Actual
                </button>
                <button
                  className="boton"
                  onClick={this.consultarAnioescolar.bind(this)}
                >
                  Consultar Año Escolar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            )}
          
          {this.state.verplanillaOn &&
            this.state.status === "A" && (
              <div className="contenedorcedula1 col-12">
{/*                 
                <button
                  className="boton"
                  onClick={this.generarInscripcion.bind(this)}
                >
                  Generar Inscripción
                </button>
                <button
                  className="boton"
                  //onClick={this.generarMensualidad.bind(this)}
                  onClick={this.verificarMesaGenerar.bind(this)}
                >
                  Generar Mensualidad
                </button> */}
                {/* <button className="boton" onClick={this.generarPago.bind(this)}>
                  Generar Pagos
                </button>
                <button
                  className="boton"
                  onClick={this.generarPagoM.bind(this)}
                >
                  Generar Pagos Multiples
                </button>
                <button
                  className="boton"
                  onClick={this.generarPagoCanje.bind(this)}
                >
                  Generar Canje
                </button> */}
                {/* <button
                  className="boton"
                  onClick={this.generarAbonosConcepto.bind(this)}
                >
                  Abonos Internos
                </button> */}
                {/* <button className="boton" onClick={this.generarPagoD.bind(this)}>Pagos Directos</button> */}
                <button
                  className="boton"
                  onClick={this.recibosEmitido.bind(this)}
                >
                  Recibos Emitidos
                </button>
                <button
                  className="boton"
                  onClick={this.enviarFacturaCorreoOn.bind(this)}
                >
                  Enviar Recibo por Correo
                </button>
                <button
                  className="boton"
                  onClick={this.modificarMensualidad.bind(this)}
                >
                  Modificar Mensualidad
                </button>
                <button
                  className="boton"
                  onClick={this.reversarPago.bind(this)}
                >
                  Nota de Credito
                </button>
                <button className="boton" onClick={this.edoCuenta.bind(this)}>
                  Edo. Cuenta
                </button>
                <button className="boton" onClick={this.deudaActual.bind(this)}>
                  Deuda Actual
                </button>
                <button
                  className="boton"
                  onClick={this.consultarAnioescolar.bind(this)}
                >
                  Consultar Año Escolar
                </button>

                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            )}
          {this.state.verplanillaOn &&
            this.state.status === "A" &&
            this.props.usuario1.nivel === "4" && (
              <div className="contenedorcedula1 col-12">
                <button className="boton" onClick={this.edoCuenta.bind(this)}>
                  Edo. Cuenta
                </button>
                <button
                  className="boton"
                  onClick={this.consultarAnioescolar.bind(this)}
                >
                  Consultar Año Escolar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <button
                  className="boton"
                  onClick={this.recibosEmitido.bind(this)}
                >
                  Recibos Emitidos
                </button>
                <button className="boton" onClick={this.deudaActual.bind(this)}>
                  Deuda Actual
                </button>
                <button
                  className="boton"
                  onClick={this.enviarFacturaCorreoOn.bind(this)}
                >
                  Enviar Recibo por Correo
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            )}
          {this.state.verplanillaOn &&
            this.state.status === "A" &&
            this.props.usuario1.nivel === "5" && (
              <div className="contenedorcedula1 col-12">
                <button className="boton" onClick={this.edoCuenta.bind(this)}>
                  Edo. Cuenta
                </button>
                <button
                  className="boton"
                  onClick={this.consultarAnioescolar.bind(this)}
                >
                  Consultar Año Escolar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <button
                  className="boton"
                  onClick={this.recibosEmitido.bind(this)}
                >
                  Recibos Emitidos
                </button>
                <button className="boton" onClick={this.deudaActual.bind(this)}>
                  Deuda Actual
                </button>
                <button
                  className="boton"
                  onClick={this.enviarFacturaCorreoOn.bind(this)}
                >
                  Enviar Recibo por Correo
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            )}

          {this.state.verplanillaOn &&
            this.state.status === "A" &&
            this.props.usuario1.nivel === "9" && (
              <div className="contenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.generarInscripcion.bind(this)}
                >
                  Generar Inscripción
                </button>
                <button
                  className="boton"
                  //onClick={this.generarMensualidad.bind(this)}
                  onClick={this.verificarMesaGenerar.bind(this)}
                >
                  Generar Mensualidad
                </button>
                <button className="boton" onClick={this.edoCuenta.bind(this)}>
                  Edo. Cuenta
                </button>
                <button
                  className="boton"
                  onClick={this.consultarAnioescolar.bind(this)}
                >
                  Consultar Año Escolar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
                <button
                  className="boton"
                  onClick={this.recibosEmitido.bind(this)}
                >
                  Recibos Emitidos
                </button>
                <button className="boton" onClick={this.deudaActual.bind(this)}>
                  Deuda Actual
                </button>
                <button
                  className="boton"
                  onClick={this.enviarFacturaCorreoOn.bind(this)}
                >
                  Enviar Recibo por Correo
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxC);
