import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./descripcionE.css";
import Alert from "../../modal/alert";

class DescripcionEAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iddescripcionE: "",
      descripcionE: "",
      idescripcionE: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      descripcionE: "",
      iddescripcionE:'',
      elimi: false,
      listaOn: false,
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarDE(e.target.value);
  }
  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  guardar = () => {
    if (this.state.descripcionE.length > 0) {
      let data = new FormData();
      data.append("descripcionE", this.state.descripcionE); 
      data.append("boton", "guardarDE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarDE();
            this.limpiar();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Descripcion de Evaluacion.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Crear Descripcion de Evaluacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Descripcion de Evaluacion.",
        cuerpoM: "Debe escribir una descripcion de Evaluacion.",
      });
    }
  };

  eliminar = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("iddescripcionEva", e);
      data.append("boton", "eliminarDE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Descripcion de Evaluacion.",
            cuerpoM: res.data.variable2,
          });
          this.buscarDE();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Descripcion de Evaluacion.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Descripcion de Evaluacion.",
        cuerpoM:
          "Debe existir un registro para poder eliminar la Descripcion.",
      });
    }
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  
  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  eliminar1 = (e) => {
    this.setState({ 
        mensajealerta: true,
        tituloM: 'Menu Crear Secciones',
        cuerpoM: 'Puede que la Sección ya tenga notas Asociadas, Desea Realmente Eliminar el Registro...',            
        idseccion: e,
        elimi:true,
     })
}

  handleEliminar = () => {
    this.setState({ 
        mensajealerta: false,
        elimi: false,
     })
    this.eliminar()  
    //console.log('eliminar') 
}
  componentDidMount() {
    this.buscarDE();
    //this.buscarSalones();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CREAR SECCIONES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}

            />
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-6">
                <input
                  className="form-control"
                  type="text"
                  name="descripcionE"
                  id="descripcionE"
                  autoComplete="off"
                  placeholder="Descripcion de Evaluacion"
                  maxLength="100"
                  onChange={this.onChange.bind(this)}
                  value={this.state.descripcionE.toUpperCase()}
                />
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button className="boton" onClick={this.guardar.bind(this)}>
                Agregar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc3">DESCRIPCION</th>
                    <th className="titucxc3x">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.idescripcionE.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DescripcionEAction;
