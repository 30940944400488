import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//componentes
import "./verPagos.css";
import FeatherIcon from "feather-icons-react";
import Alert from "../../modal/alert";

class CxPAbonos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numeRecibo: "",
      iverPago: [],
    };
  }

  limpiar = () => {
    this.setState({
      numeRecibo: "",
      iverPago: [],
    });
  };

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  verPago = () => {
    let data = new FormData();
    data.append("nRecibo", this.state.numeRecibo);
    data.append("boton", "consultarPagos");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xiverPago = res.data.variable2;
          this.setState({
            iverPago: xiverPago,
          });
        } else {
          this.setState({
            iverPago: [],
          });
        }
      });
  };

  componentDidMount() {
    //this.buscarCursos();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc3 col-12">
          <div className="titulo-cxc ">
            <p>CONSULTA DE PAGOS REALIZADOS</p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          <div className="form-group row">
            <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="text"
                name="numeRecibo"
                id="numeRecibo"
                autoComplete="off"
                placeholder="Numero Recibo"
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.numeRecibo}
              />
            </div>
          </div>
          <div className="row">
            <div className="contenedorcedula2 col-12">
              <button className="boton" onClick={this.verPago}>
                Consultar Recibo
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc2">Documento/N.Control</th>
                    <th className="titucxc2">Fecha</th>
                    <th className="titucxc2X">Descripcion</th>
                    <th className="titucxc2">Monto</th>
                    <th className="titucxc2">Proveedor</th>
                    <th className="titucxc2">F/Recibo-N.Control</th>
                  </tr>
                </thead>
                {this.state.iverPago.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxc" width="10">
                        {item.variable1}
                      </td>
                      <td className="totalescxc" width="10">
                        {item.variable2}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable3}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable4}
                      </td>
                      <td className="totalescxc" width="10">
                        {item.variable8}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable7}{" / "}{item.variable5}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxPAbonos);
