import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./notaCredito.css";
import FeatherIcon from "feather-icons-react";
import Alert from "../../modal/alert";

class Facturacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcedulaP: "",
      fecha: moment().format("YYYY-MM-DD"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      externoOn: false,
      factuPendientesOn: false,
      clientes: [],
      facturasC: [],
      facturasCRe: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      nocre: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      otroProducto: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      verplanillaOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
      nrecibo: "",
      notascrediOn: false,
      alumno: "",
      representante: "",
      listadoOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      opcedulaP: "",
      fecha: moment().format("YYYY-MM-DD"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      factuPendientesOn: false,
      clientes: [],
      facturasC: [],
      facturasCRe: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      externoOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      otroProducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      verplanillaOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
      nrecibo: "",
      notascrediOn: false,
      alumno: "",
      representante: "",
      listadoOn: false,
    });
  };

  limpiar2 = () => {
    this.setState({
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      otroProducto: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
    });
  };

  cerrarLista2 = () => {
    //console.log(this.state.listaOn)
    if (this.state.factuPendientesOn) {
      this.setState({
        factuPendientesOn: false,
      });
    }
    if (!this.state.factuPendientesOn) {
      this.setState({
        factuPendientesOn: true,
      });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    if (this.state.oppago === "INTERNOS") {
      let index = e.target.value;
      let ceduA = this.state.clientes[index].variable2;
      this.setState({ cedulaAlumno: ceduA });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarClientesI(e.target.value);
    //this.consultarFactura(e.target.value)
    //console.log(e.target.value)
  }

  onChange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarProducto(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      loading: true,
      [e.target.name]: e.target.value,
    });
    this.consultarClientes("I");
    this.setState({ loading: false, verplanillaOn: true });
  }

  onChange4 = (e) => {
    // let element = e.target;
    // if (element.checked) {
    // console.log(e.target.value);
    // console.log(e.target.name);
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("idfacturaRe", e);
    data.append("boton", "guardarNCT");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura();
          //this.limpiar2();
        } else {
          this.setState({});
        }
      });
    //}
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  externoCli = () => {
    if (this.state.externoOn) {
      this.setState({ externoOn: false });
      this.limpiar();
    } else {
      this.setState({ externoOn: true });
    }
  };

  externoCliente = () => {
    if (this.state.tipoFac === "PRODUCTO") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
    if (this.state.tipoFac === "SERVICIOS") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  generarPagoClose = () => {
    this.setState({ modificarSaldoOn: false });
  };

  consulbuscarRecibotarFactura = async () => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("nrecibo", this.state.nrecibo);
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("boton", "consultarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasC: xfacturasP,
            representante: res.data.variable6,
            ruc: res.data.variable7,
            alumno: res.data.variable8,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Nota de Credito.",
            cuerpoM: res.data.variable3,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProducto = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idproducto: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidadActual: res.data.variable4,
            costo: res.data.variable5,
            precio: res.data.variable6,
            impuesto: res.data.variable8,
            cantidad: "1",
          });
        }
      });
  };

  consultarItenFactura = async () => {
    this.setState({
      itenFacturas: [],
      subtotal: "",
      timpuesto: "",
      totalg: "",
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("nrecibo", this.state.nrecibo);
    data.append("boton", "consultarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasC: xfacturasP,
            subtotal: res.data.variable3,
            timpuesto: res.data.variable4,
            totalg: res.data.variable5,
            representante: res.data.variable6,
            ruc: res.data.variable7,
            alumno: res.data.variable8,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  guardarFactura = () => {
    if (
      this.state.opcedulaP != "INTERNOS" &&
      parseFloat(this.state.totalg) > 0
    ) {
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El Monto a Pagar No Coincide con el Total Facturado.",
      });
    }
  };

  guardarFactura1 = () => {
    if (this.state.opcedulaP === "INTERNOS") {
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (this.state.tbusqueda1 === "1") {
      this.buscarPlanillaIndividual();
    }
    if (this.state.tbusqueda1 === "2") {
      this.buscarPorNombres();
    }
  };

  notascrediOn = () => {
    if (this.state.notascrediOn) {
      this.setState({ notascrediOn: false });
    } else {
      this.buscarNC();
      this.setState({ notascrediOn: true });
    }
  };

  listadoOn = () => {
    if (this.state.listadoOn) {
      this.setState({ listadoOn: false });
    } else {
      this.setState({ listadoOn: true });
    }
  };

  buscarNC = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("boton", "consultarNCP");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            nocre: xfacturasP,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFacturaRe = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("ruc", this.state.ruc);
    data.append("boton", "consultarFacturaGeneralPendiente");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasCRe: xfacturasP,
            factuPendientesOn: true,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFacturasIRe = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      data.append("tipoFact", this.state.tipoFac);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago4.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  limpiarNC = async () => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("boton", "limpiarNC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {});
  };
  GuardarNC1 = () => {};

  GuardarNC = async () => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("nrecibo", this.state.nrecibo);
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("arregloNC", JSON.stringify(this.state.facturasC));
    data.append("boton", "guardarNC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Nota de Credito",
            cuerpoM: "La Nota de Credito Se Guardo con Exito, gracias...",
          });
        }
      });
  };

  calcularSumaValorEntrada3 = (index, value, restaP) => {
    let cxcRe_ = this.state.facturasC; //Array.from(cxcRe);
    // console.log('index '+index);
    // console.log('valor '+value);
    // console.log('monto '+restaP);
    cxcRe_[index].variable10 = "";
    if (parseFloat(value) <= restaP) {
      cxcRe_[index].variable10 = value;
    }
    this.setState({ facturasC: cxcRe_ });
  };

  consultarNC = (e) => {
    //console.log(e);
    let data = new FormData();
    data.append("numeroNC", e);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboNotaCredito.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  listadoNc = () => {
    let data = new FormData();
    data.append("mes", this.state.codigo3);
    data.append("anio", this.state.codigo4);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/listadoNC.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  componentDidMount() {
    this.cargarFechas();
    this.limpiarNC();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>NOTA DE CREDITO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}
          {this.state.notascrediOn === true && (
            <div className="flotador4xf">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Listado de Notas de Credito Pendientes
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.notascrediOn.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc1">FECHA</th>
                          <th className="titucxc2">ALUMNO</th>
                          <th className="titucxc1">No. Recibo</th>
                          <th className="titucxc1">No. N/C</th>
                          <th className="titucxc1">MONTO</th>
                          <th className="titucxc1">M/PAGADO</th>
                          <th className="titucxc1">M/PENDIENTE</th>
                          <th className="titucxc1">
                            <FeatherIcon
                              icon="search"
                              size="30px"
                              stroke="#999"
                              color="red"
                            />
                          </th>
                        </tr>
                      </thead>
                      {this.state.nocre.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td className="totalescxc" width="10">
                              {item.variable2}
                            </td>
                            <td className="totalescxc" width="10">
                              {item.variable3}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable4}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable5}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable6}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable7}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable8}
                            </td>
                            <td className="titucxc3" width="10">
                              <FeatherIcon
                                icon="search"
                                size="30px"
                                stroke="#999"
                                color="red"
                                onClick={this.consultarNC.bind(
                                  this,
                                  item.variable5
                                )}
                              ></FeatherIcon>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.listadoOn === true && (
            <div className="flotador4xfNC">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Listado de Notas de Credito
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.listadoOn.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="row">
                  <div className="contenedorcedula1f col-5">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="cont-titulomedi col-12">
                    <button className="boton" onClick={this.listadoNc}>
                      Imprimir Listado
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="nrecibo"
                id="nrecibo"
                autoComplete="off"
                placeholder="No. Recibo."
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.nrecibo}
              />
            </div>
            <div className="contenedorcedula1 col-3">
              <button
                className="boton"
                onClick={this.consulbuscarRecibotarFactura}
              >
                Buscar
              </button>
              <button className="boton" onClick={this.limpiar}>
                Limpiar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>
          <div className="contenedorcedula1 col-12">
            <div className="contenedorcedula1f col-7">
              <input
                className="form-control"
                type="text"
                name="representante"
                id="representante"
                readOnly="readonly"
                autoComplete="off"
                placeholder="Representante."
                maxLength="125"
                onChange={this.onChange.bind(this)}
                value={this.state.representante}
              />
            </div>
            <div className="contenedorcedula1f col-3">
              <input
                className="form-control"
                type="text"
                name="ruc"
                id="ruc"
                readOnly="readonly"
                autoComplete="off"
                placeholder="Ruc/Pasaporte."
                maxLength="125"
                onChange={this.onChange.bind(this)}
                value={this.state.ruc}
              />
            </div>

            <div className="contenedorcedula1f col-3">
              <input
                className="form-control"
                type="text"
                name="alumno"
                id="alumno"
                readOnly="readonly"
                autoComplete="off"
                placeholder="Nombre Alumno."
                maxLength="125"
                onChange={this.onChange.bind(this)}
                value={this.state.alumno}
              />
            </div>
          </div>

          <div className="contenedorcedula1 col-12">
            <button
              className={this.state.guardarOn ? "botonOf" : "boton"}
              onClick={this.GuardarNC}
            >
              Ejecutar Nota/Credito
            </button>
            <button
              className={this.state.guardarOn ? "botonOf" : "boton"}
              onClick={this.notascrediOn}
            >
              Consultar Notas/Credito
            </button>
            <button
              className={this.state.guardarOn ? "botonOf" : "boton"}
              onClick={this.listadoOn}
            >
              Listado Notas/Credito
            </button>
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc2">PRODUCTO</th>
                    <th className="titucxc1">CANTIDAD</th>
                    <th className="titucxc1">P/UNITARIO</th>
                    <th className="titucxc1">ITBMS</th>
                    <th className="titucxc1">TOTAL</th>
                    <th className="titucxc1">MONTO</th>
                    <th className="titucxc1">N/CREDITO</th>
                  </tr>
                </thead>
                {this.state.facturasC.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxc" width="10">
                        {item.variable2}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable3}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable4}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable5}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable6}
                      </td>
                      {item.variable8 === "SI" ? (
                        <td className="totalescxc1" width="10">
                          <div className="contenedorcedula col-12">
                            <input
                              className="form-control"
                              type="text"
                              name={item.variable10}
                              id={item.variable10}
                              autoComplete="off"
                              step="any"
                              onChange={(e) =>
                                this.calcularSumaValorEntrada3(
                                  i,
                                  e.target.value,
                                  item.variable6
                                )
                              }
                              onKeyPress={(e) =>
                                this.calcularSumaValorEntrada3(
                                  i,
                                  e.target.value,
                                  item.variable6
                                )
                              }
                              //value={item.valorEntrada}
                              value={
                                parseFloat(item.variable10) >= 0
                                  ? item.variable10
                                  : ""
                              }
                            />{" "}
                          </div>
                        </td>
                      ) : (
                        <td className="totalescxc1" width="10">
                          {""}
                        </td>
                      )}
                      <td className="titucxc3" width="10">
                        {item.variable7 === "" && (
                          <input
                            className="chskfacRe"
                            type="checkbox"
                            name={item.variable1}
                            id={item.variable1}
                            value="SI"
                            checked={item.variable8 === "SI"}
                            onChange={this.onChange4.bind(this, item.variable1)}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Facturacion);
