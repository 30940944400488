import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
//componentes
import "./autorizarExpe.css";
import Alert from "../../modal/alert";
import PublicarNotas from "../publicarNotas/index";

class AutorizarBoletinAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idcurso: "",
      descripcionE: "",
      inputValue: "",
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      anoEscolar: moment().format("YYYY"),
      idtareas: "",
      nombreEvaluacion: "",
      nota: "",
      trimestre: "",
      idarchivos: "",
      ianoEscolar: [moment().format("YYYY")],
      imaterias: [],
      icursos: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      iactividades: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      activarNotaOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idmateria: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      inputValue: "",
      descripcionE: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      idtareas: "",
      nombreEvaluacion: "",
      nota: "",
      idarchivos: "",
      ievaluaciones: [],
      listaOn: false,
      modificarOn: false,
      activarNotaOn: false,
    });
  };

  limpiar2(e) {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idmateria: "",
      idcurso: "",
      idplanE: "",
      cedulaAlumno: "",
      nombreEvaluacion: "",
      nota: "",
      trimestre: "",
      idarchivos: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
    });
  }
  limpiar3() {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "trimestre") this.setState({ descripcionE: "" });
    if (index === "idcurso") this.buscarPlanE2(e.target.value);
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.imaterias.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : 0;
    //console.log(index)
    let xcantidadE = this.state.imaterias[index].variable3;
    let xidmateria = this.state.imaterias[index].variable4;
    //console.log("cantidad: "+xcantidadE)
    //console.log("idmateria: "+xidmateria)
    this.consultarActividades(xidmateria);
    this.setState({
      [e.target.name]: e.target.value,
      cantidadE: xcantidadE,
      idmateria: xidmateria,
    });
  };

  onChange4(e) {
    let element = e.target;
    
    //console.log(e.target.name)
    
    if (e.target.name!=="") {
      this.setState({ [e.target.name]: e.target.value });
      //console.log(e.target.value);
      console.log(e.target.name);
      let data = new FormData();
     
      data.append("cedulaAlumno", e.target.name);
      data.append("trimestre", this.state.trimestre);
      data.append("boton", "guardarAutorizacion");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/habilitarExpediente.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarAB();
          } else {
            this.setState({});
          }
        });
    }
  }

  

  
  

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  
  buscarG() {
    let data = new FormData();
    data.append("boton", "buscarG");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/backend/habilitarExpediente.php",
      data: data,
    }).then((res) => {
      let xigrados = res.data.variable2;
      this.setState({
        icursos: xigrados,
      });
    });
  }

  buscarAB() {
    let data = new FormData();
    data.append("idgrado", this.state.idcurso);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("trimestre", this.state.trimestre);
    data.append("boton", "buscarABG");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/backend/habilitarExpediente.php",
      data: data,
    }).then((res) => {
      let xigrados = res.data.variable2;
      this.setState({
        ialumnos: xigrados,
      });
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  notasTrabajo = (e) => {
    if (this.state.activarNotaOn) {
      this.setState({ activarNotaOn: false, idarchivos: "" });
    } else {
      this.setState({ activarNotaOn: true, idarchivos: e });
      //console.log(e);
    }
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  componentDidMount() {
    this.buscarG();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>NO PERMITIR LA VISUALIZACION DEL EXPEDIENTE.</p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
            </div>
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-12">
                  <button className="boton" onClick={this.buscarAB.bind(this)}>
                    Consultar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">NOMBRE DEL ALUMNO</th>
                    <th className="titucxc5">NO AUTORIZAR</th>
                  </tr>
                </thead>
                {this.state.ialumnos.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx" width="10">
                        <input
                          type="checkbox"
                          className="opvacunacionsi"
                          name={item.variable1}
                          id={item.variable1}
                          
                          checked={item.variable3 === "1"}
                          onChange={this.onChange4.bind(this)}
                        />
                      </td>
                    </tr>
                  );
                })}
                );
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(AutorizarBoletinAction);
