import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";
import moment from "moment";

//componentes
import "./deudaActual.css";
import Alert from "../../modal/alert";

class GenerarPagos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      referencia: "",
      banco: "",
      oppago: "TRANSFERENCIA",
      fecha: moment().format("YYYY-MM-DD"),
      fechaPago: "",
      monto: "",
      nombreper: "",
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      cedulaAlumno: "",
      concepto: "",
      oppagoP: "",
      loading: false,
      cxcM: [],
      restaP: 0,
      numeroRecibo: "",
      errorOn: false,
      imprimirOn: false,
      desde:'',
      hasta:'',
    };
  }

  limpiar = () => {
    this.setState({
      referencia: "",
      banco: "",
      oppago: "TRANSFERENCIA",
      fecha: moment().format("YYYY-MM-DD"),
      fechaPago: "",
      monto: "",
      nombreper: "",
      oppagoP: "",
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      cedulaAlumno: "",
      concepto: "",
      loading: false,
      cxcM: [],
      restaP: 0,
      numeroRecibo: "",
      errorOn: false,
      imprimirOn: false,
      desde:'',
      hasta:'',
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    let nomb = e.target.name;
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (nomb === "oppagoP" && e.target.value === "MADRE")
      this.setState({ nombreper: this.props.madre });
    if (nomb === "oppagoP" && e.target.value === "PADRE")
      this.setState({ nombreper: this.props.padre });
    if (nomb === "oppagoP" && e.target.value === "OTRO")
      this.setState({ nombreper: "" });
    if (nomb === "monto") this.setState({ restaP: e.target.value });
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  enviarFacturaCorreo = () => {
    let data = new FormData();
    data.append("numeroRecibo", this.state.numeroRecibo);
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago.php";
    axios
      .post(xurl, data, config)

      .then((res) => {});
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Enviar Factura por Correo.",
      cuerpoM: "La Factura fue enviada por correo de forma correcta",
    });
  };

  consultaRecibo = () => {
    let data = new FormData();
    data.append("numeroRecibo", this.state.numeroRecibo);

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago2.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  aprobarPago = async () => {
    if (this.state.nombreper.length > 0) {
      this.setState({
        loading: true,
      });
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsuario", this.props.usuario1.password);
      data.append("cedulaAlumno", this.props.cedulaAlumno);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("fechaPago", this.state.fechaPago);
      data.append("nombreper", this.state.nombreper);
      data.append("arregloCxCPM", JSON.stringify(this.state.cxcM));

      data.append("boton", "aprobarPagoMul");
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/reportarPago2.php";
      let response = await axios.post(xurl, data);
      console.log(response.data.variable2);
      if (response.data.variable1) {
        let xnreci = response.data.variable3;
        this.setState({ numeroRecibo: xnreci });
        // axios
        //   .post(
        //     "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago.php/?numeroRecibo=" +
        //       response.data.variable3
        //   )
        //   .then((res) => {});
        this.setState({ btnEnviarOn: true, loading: false });
      } else {
        this.setState({ btnEnviarOn: false, loading: false });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Aprobar Pagos.",
        cuerpoM:
          "Debe Incluir el nombre de la Persona que Realizó la Transferencia",
      });
    }
  };

  buscarCXCM = (e) => {
    let data = new FormData();
    //data.append("anoEscolar", this.props.usuario1.anioE);
    data.append("cedulaAlumno", this.props.cedulaAlumno);
    data.append("boton", "buscarCXCM");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xcxcM = res.data.variable2;
          this.setState({
            cxcM: xcxcM,
          });
          if (res.data.variable3 !== "") {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Deuda ",
              cuerpoM: res.data.variable3,
            });
          }
        } else {
          this.setState({
            nalumno: [],
            mensajealerta: true,
            tituloM: "Menu Busqueda de Deuda ",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };
  calcularSumaValorEntrada3 = (index, value, deudaItem) => {
    //console.log("valor " + value);
    //console.log(this.state.cxcM);
    // console.log(deudaItem);
    if (parseFloat(value) <= parseFloat(deudaItem)) {
      let valor_item = 0;

      if (parseFloat(this.state.cxcM[index].valorEntrada3) > 0) {
        valor_item = parseFloat(this.state.cxcM[index].valorEntrada3);
        this.state.restaP = parseFloat(this.state.restaP + valor_item).toFixed(
          2
        );
      } else {
        valor_item = 0;
        //console.log("valor sin item" + valor_item);
      }

      //if (parseFloat(value) <= this.state.restaP) {
      let cxcM_ = Array.from(this.state.cxcM);
      //console.log("valor de la celda dentro" + value);
      cxcM_[index].valorEntrada3 = value;
      this.setState({ cxcM: cxcM_ });
      //}
    }
    if (value.length === 0) {
      let cxcM_ = Array.from(this.state.cxcM);
      cxcM_[index].valorEntrada3 = value;
      this.setState({ cxcM: cxcM_ });
    }

    let valor_suma = 0;
    for (let x = 0; x < this.state.cxcM.length; x++) {
      if (this.state.cxcM[x].valorEntrada3 > 0) {
        valor_suma += parseFloat(this.state.cxcM[x].valorEntrada3);
      }
      //console.log(name_fvar)
    }
    let xrestaP = parseFloat(this.state.monto - valor_suma);
    this.state.restaP = parseFloat(xrestaP).toFixed(2);
    console.log(this.state.restaP);
    console.log(parseFloat(valor_suma).toFixed(2));
    if (xrestaP < -0.0001) {
      this.setState({
        errorOn: true,
        mensajealerta: true,
        tituloM: "Menu Pagos Multiple ",
        cuerpoM:
          "La suma de los montos es mayor que la cantidad indicada a pagar",
      });
    } else {
      this.setState({
        errorOn: false,
      });
    }
  };

  imprimirOn= () => {
    if (this.state.imprimirOn) {
      this.setState({ imprimirOn: false, });
    } else {
      this.setState({ imprimirOn: true });
    }
  }

  deudaAlumno = () => {
    this.setState({ imprimirOn: false, });
    let data = new FormData();
    data.append("desde", this.state.desde);
    data.append("hasta", this.state.hasta);
    data.append("cedulaAlumno", this.props.cedulaAlumno);
    data.append("nombreAlumno", this.props.nombreAlumno);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/deudaActual.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  componentDidMount() {
    this.buscarCXCM();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-pagoM col-12">
          <div className="titulo-pago ">
            <p>
              DEUDA ACTUAL DEL ALUMNO. {""}
              {this.props.saldoDeuda}{" "}
            </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}
          <div className="contenedor-titulo row">
            {!this.state.btnEnviarOn && (
              <div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-9">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc1M">FECHA</th>
                          <th className="titucxc2M">DESCRIPCIÓN</th>
                          <th className="titucxc3M">DEBE</th>
                          <th className="titucxc3M">PAGOS</th>
                        </tr>
                      </thead>
                      {this.state.cxcM.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td className="totalescxc" width="10">
                              {item.variable2}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable3}
                            </td>
                            <td className="totalescxc1" width="10">
                              {item.variable4}
                            </td>
                            <td className="totalescxc1" width="10"></td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
                {this.state.imprimirOn && (
                  <div className="contenedor-titulo row">
                    <div className="contenedor-cedula col-4">
                      <label className="labelcedulac">Desde.</label>

                      <input
                        className="form-control"
                        type="date"
                        name="desde"
                        id="desde"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.desde}
                      />
                    </div>
                    <div className="contenedor-cedula col-4">
                      <label className="labelcedulac">Hasta.</label>
                      <input
                        className="form-control"
                        type="date"
                        name="hasta"
                        id="hasta"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.hasta}
                      />
                    </div>
                    <div className="contenedorcedula1 col-12">
                      <button className="boton" onClick={this.deudaAlumno}>
                        Procesar
                      </button>
                      <button
                        className="boton"
                        onClick={this.props.generarPagoClose}
                      >
                        Salir
                      </button>
                    </div>
                  </div>
                )}
                {!this.state.imprimirOn && (
                  <div className="contenedorcedula1M col-12">
                    <button className="boton" onClick={this.imprimirOn}>
                      Imprimir
                    </button>
                    <button
                      className="boton"
                      onClick={this.props.generarPagoClose}
                    >
                      Salir
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(GenerarPagos);
