import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./donantes.css";
import Alert from "../../modal/alert";

class DonantesAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idcurso: "",
      descripcion: "",
      trimestre: "",
      trimestre2: "",
      cantidadE: "",
      iddonante: "",
      anoEscolar: "",
      anoEscolarN: moment().format("YYYY"),
      imaterias: [],
      icursos: [],
      idonantes: [],
      ianoEscolar: [],
      idescripcionE: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      asientoOn: false,
      planAnualOn: false,
      donante: "",
      ruc: "",
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      //anoEscolar: "",
      //idcurso: "",
      descripcion: "",
      trimestre: "",
      trimestre2: "",
      cantidadE: "",
      iddonante: "",
      idonantes: [],
      listaOn: false,
      modificarOn: false,
      asientoOn: false,
      planAnualOn: false,
      donante: "",
      ruc: "",
    });
  };

  limpiar2(e) {
    this.setState({ idcurso: "" });
    this.limpiar();
    this.buscarPlanE();
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarPlanE(e.target.value);
  }

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
        anoEscolar: "",
        trimestre: "",
        trimestre2: "",
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
      });
    }
  };

  cerrarListaAnual = () => {
    //console.log(this.state.listaOn)
    if (this.state.planAnualOn) {
      this.setState({
        planAnualOn: false,
        anoEscolar: "",
        trimestre: "",
      });
    }
    if (!this.state.planAnualOn) {
      this.setState({
        planAnualOn: true,
      });
    }
  };

  consultar(e) {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "buscarDonantesI");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              iddonante: res.data.variable2,
              donante: res.data.variable3,
              ruc: res.data.variable4,
              modificarOn: true,
            });
          } else {
          }
        });
    }
  }

  modificar = () => {
    if (this.state.iddonante.length > 0) {
      let data = new FormData();
      data.append("idmaestroE", this.state.iddonante);
      data.append("donante", this.state.donante);
      data.append("ruc", this.state.ruc);
      data.append("boton", "modificarDonante");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.buscarPlanE();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Donantes.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Donantes.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Maestro de Donantes.",
        cuerpoM: "Ocurrio un Error, verifique por Favor.",
      });
    }
  };

  guardar = () => {
    if (
      this.state.donante.length > 0 &&
      this.state.ruc.length > 0
    ) {
      let data = new FormData();
      data.append("donante", this.state.donante);
      data.append("ruc", this.state.ruc);
      data.append("boton", "guardarDonante");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            //this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe Ingresar un Donante y un Ruc o Cédula.",
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "eliminarDonantes");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Materias.",
            cuerpoM: res.data.variable2,
          });
          this.limpiar();
          this.buscarPlanE(this.state.idcurso);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Materias.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM:
          "Debe existir un registro para poder eliminar la MAteria Asignada.",
      });
    }
  };

  buscarPlanE = (e) => {
    let data = new FormData();
    data.append("boton", "buscarDonantes");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidonantes = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idonantes: xidonantes,
          });
        } else {
          this.setState({
            idonantes: [],
          });
        }
      });
  };

  
  
  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarPlanE();
    // this.buscarSalones();
    // this.buscarAnioEscolar();
    // this.buscarDE();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MAESTRO DE DONANTES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula col-4">
                <input
                  className="form-control"
                  type="text"
                  name="donante"
                  id="donante"
                  autoComplete="off"
                  placeholder="Nombre del Donante"
                  maxLength="100"
                  onChange={this.onChange.bind(this)}
                  value={this.state.donante}
                />
              </div>
              <div className="contenedorcedula col-3">
                <input
                  className="form-control"
                  type="text"
                  name="ruc"
                  id="ruc"
                  autoComplete="off"
                  placeholder="Ruc o Cédula"
                  maxLength="50"
                  onChange={this.onChange.bind(this)}
                  value={this.state.ruc.toUpperCase()}
                />
              </div>
            </div>

            <div className="xcontenedorcedula1x col-12">
              <button
                className={this.state.modificarOn ? "botonOf" : "boton"}
                onClick={this.state.modificarOn ? "" : this.guardar.bind(this)}
              >
                Guardar
              </button>
              <button
                className={this.state.modificarOn ? "boton" : "botonOf"}
                onClick={
                  this.state.modificarOn ? this.modificar.bind(this) : ""
                }
              >
                Modificar
              </button>
              <button className="boton" onClick={this.limpiar2.bind(this)}>
                Limpiar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">NOMBRE DONANTES.</th>
                    <th className="titucxc3">RUC/CEDULA.</th>
                    <th className="titucxc3xpe">
                      <span className="titulo-eliminar">
                        <span className="cerrarme5"></span>
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.idonantes.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme5"
                          onClick={this.consultar.bind(this, item.variable1)}
                        ></span>
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DonantesAction;
