import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import AsyncSelect from "react-select/async-creatable";

//componentes
import Reporte from "./reporte";
import "./registroCompras.css";
import FeatherIcon from "feather-icons-react";
import ModificarMonto from "../modificarMonto/generarPagosC";
import Alert from "../../modal/alert";
import Comprobante from "../comprobante/comprobante";

class CxPAbonos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idcompra: "",
      beneficiario: "",
      xbeneficiario: "",
      beneficiario2: "",
      banco: "",
      referencia: "",
      subtotal: "",
      impuesto: "",
      monto1: "",
      xmonto1: "",
      subtotalC: "",
      impuestoC: "",
      montoC: "",
      montoT: 0,
      tpago: "",
      fecha: "",
      fecha2: "",
      xfecha: "",
      fechaC: "",
      descripcion1: "",
      descripcionC: "",
      documento: "",
      monto2: "",
      mes: moment().format("MM"),
      anoEscolar: moment().format("YYYY"),
      registrarCOn: false,
      compras: [],
      comprasDetalle: [],
      ianoEscolar: [],
      xsaldo: "",
      reporteOn: false,
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      listaOn2: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      proveedores: [],
      cxpproveedor: [],
      nombrex: "",
      nombrex2: "",
      detalleOn: false,
      itenFacturasC: [],
      cuenta: [],
      cuentaContable: "",
      idcomprasDetalle: "",
      montoBanco: "",
      fechaBanco: "",
      chequeBeneficiario: "",
      nombreEvaluacion: "",
      nombreEvaluacion_selected: null,
      detalleD: [],
      detalleDesgloceOn: false,
      cuentaContable3: "",
      iddesgloce3: "",
      idcomprasD3: "",
      claseNormal: false,
    };
  }

  limpiar = () => {
    this.setState({
      idcompra: "",
      beneficiario: "",
      beneficiario2: "",
      xbeneficiario: "",
      banco: "",
      referencia: "",
      subtotal: "",
      impuesto: "",
      monto1: "",
      xmonto1: "",
      subtotalC: "",
      impuestoC: "",
      montoC: "",
      montoT: "",
      xsaldo: "",
      tpago: "",
      fecha: "",
      fecha2: "",
      xfecha: "",
      fechaC: "",
      descripcion1: "",
      descripcionC: "",
      documento: "",
      monto2: "",
      mes: moment().format("MM"),
      anoEscolar: moment().format("YYYY"),
      registrarCOn: false,
      reporteOn: false,
      btnEnviarOn: false,
      listaOn: false,
      listaOn2: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      proveedores: [],
      nombrex: "",
      nombrex2: "",
      detalleOn: false,
      itenFacturasC: [],
      cuentaContable: "",
      idcomprasDetalle: "",
      montoBanco: "",
      fechaBanco: "",
      chequeBeneficiario: "",
      detalleD: [],
      detalleDesgloceOn: false,
      cuentaContable3: "",
      iddesgloce3: "",
      idcomprasD3: "",
      claseNormal: false,
    });
  };

  limpiar2 = () => {
    this.setState({
      descripcion1: "",
      subtotal: "",
      impuesto: "",
      monto2: "",
      documento: "",
      beneficiario2: "",
      xmonto1: "",
      xfecha: "",
      xsaldo: "",
      fecha2: "",
      idcompra: "",
      comprasDetalle: [],
      reporteOn: false,
    });
  };
  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn2) {
      this.setState({
        listaOn2: false,
      });
    }
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn2) {
      this.setState({
        listaOn2: true,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    let index = e.target.getAttribute("id");
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "subtotal") {
      this.state.impuesto = (
        parseFloat(e.target.value) * 1.07 -
        parseFloat(e.target.value)
      ).toFixed(2);
      this.state.monto2 = (parseFloat(e.target.value) * 1.07).toFixed(2);
    }
    if (index === "impuesto") {
      let valor = 0;
      if (e.target.value !== "") valor = e.target.value;

      this.state.monto2 = (
        parseFloat(this.state.subtotal) + parseFloat(valor)
      ).toFixed(2);
    }
  }

  onChange2(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarCxP2(e.target.value);
  }
  onChange5(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarCxP(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange8(e) {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.value === "5") {
      this.controlNumeroBancoG();
    }
  }

  onChange7(e) {
    //console.log(e.target.name)
    let index = e.target.getAttribute("id");
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (index === "subtotalC") {
      this.state.impuestoC = (
        parseFloat(e.target.value) * 1.07 -
        parseFloat(e.target.value)
      ).toFixed(2);
      this.state.montoC = (parseFloat(e.target.value) * 1.07).toFixed(2);
    }
    if (index === "impuestoC") {
      let valor = 0;
      if (e.target.value !== "") valor = e.target.value;

      this.state.montoC = (
        parseFloat(this.state.subtotalC) + parseFloat(valor)
      ).toFixed(2);
    }
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  consultarDetalleC = (e) => {
    let data = new FormData();
    data.append("idbeneficiario", e);
    data.append("boton", "consultarDetalleCompras");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          comprasDetalle: xcompras,
          banco: res.data.variable3,
          referencia: res.data.variable4,
          montoBanco: res.data.variable5,
          fechaBanco: res.data.variable6,
          chequeBeneficiario: res.data.variable7,
          idcomprasDetalle: e,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          comprasDetalle: xcompras,
          banco: res.data.variable3,
          referencia: res.data.variable4,
          montoBanco: res.data.variable5,
          fechaBanco: res.data.variable6,
          chequeBeneficiario: res.data.variable7,
          idcomprasDetalle: e,
        });
      }
    });
  };

  controlNumeroBancoG = () => {
    let data = new FormData();
    data.append("boton", "controlNumeroBG");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          referencia: res.data.variable1,
        });
      });
  };

  consultarCxP = (e) => {
    let data = new FormData();
    data.append("codProve", e);
    data.append("fecha", this.state.anoEscolar + "-" + this.state.mes);
    data.append("boton", "consultarCompras");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          compras: xcompras,
          listaOn: false,
          beneficiario: e,
          nombrex: res.data.variable3,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          compras: xcompras,
          listaOn: false,
          beneficiario: e,
          nombrex: res.data.variable3,
        });
      }
    });
  };

  consultarCxP3 = (e) => {
    let data = new FormData();
    data.append("codProve", e);
    data.append("fecha", this.state.anoEscolar + "-" + this.state.mes);
    data.append("boton", "consultarCompras");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          // comprasDetalle: xcompras,
          listaOn2: false,
          beneficiario2: e,
          nombrex2: res.data.variable3,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          // comprasDetalle: xcompras,
          listaOn2: false,
          beneficiario2: e,
          nombrex2: res.data.variable3,
        });
      }
    });
  };

  consultarCxP2 = (e) => {
    if (this.state.beneficiario.length > 0) {
      let data = new FormData();
      data.append("codProve", this.state.beneficiario);
      data.append("fecha", this.state.anoEscolar + "-" + e);
      data.append("boton", "consultarCompras");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          let xcompras = res.data.variable2;
          this.setState({
            compras: xcompras,
          });
        } else {
          let xcompras = res.data.variable2;
          this.setState({
            compras: xcompras,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Detalle de Compras.",
        cuerpoM: "Debe seleccionar un Proveedor, Verifique por favor.",
      });
    }
  };

  eliminarDetalleC = (e) => {
    let data = new FormData();
    data.append("idcomprasD", e);
    data.append("boton", "eliminarDetalleCompras");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.consultarDetalleC(this.state.idcompra);
          this.setState({
            descripcion1: "",
            monto2: "",
            mensajealerta: true,
            tituloM: "Menu Detalle de Compras.",
            cuerpoM: "Se Elimino el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Detalle de Compras.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  eliminarCompra = (e) => {
    let data = new FormData();
    data.append("idcompras", e);
    data.append("boton", "eliminarCompras");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.consultarCxP(this.state.beneficiario);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Descripción de Compras.",
            cuerpoM: "Se Elimino el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Descripcion de Compras.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  guardarCxP = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("beneficiario", this.state.beneficiario);
    data.append("banco", this.state.banco);
    data.append("referencia", this.state.referencia);
    data.append("monto", this.state.monto1);
    data.append("tcompra", this.state.tpago);
    data.append("fecha", this.state.fecha);
    data.append("boton", "guardarCompra");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.consultarCxP(this.state.beneficiario);
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Detalle de Compras.",
            cuerpoM:
              "Se Guardo el Registro con exito, Recuerde Realizar El Desgloce Contable.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Detalle de Compras.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  datosCobro = (e, f, g, h, i) => {
    if (this.state.registrarCOn) {
      this.setState({ registrarCOn: false });
      this.limpiar2();
    } else {
      this.consultarDetalleC(e);
      this.setState({
        idcompra: e,
        registrarCOn: true,
        xbeneficiario: f,
        xmonto1: g,
        xfecha: h,
        xsaldo: i,
      });
    }
  };

  reporte = () => {
    if (this.state.reporteOn) {
      this.setState({ reporteOn: false });
    } else {
      this.setState({ reporteOn: true });
    }
  };

  consultarProveedores2 = () => {
    let data = new FormData();
    data.append("nombre", this.state.nombrex);
    data.append("boton", "consultarProveedorN");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
            listaOn: true,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProveedores3 = () => {
    let data = new FormData();
    data.append("nombre", this.state.nombrex2);
    data.append("boton", "consultarProveedorN");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
            listaOn2: true,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProveedores = () => {
    let data = new FormData();
    data.append("boton", "consultarProveedor");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  buscarOn = () => {
    this.consultarProveedores2();
    this.setState({
      beneficiario: "",
    });
  };
  buscarOn2 = () => {
    this.consultarProveedores3();
    this.setState({
      beneficiario2: "",
    });
  };

  detalle = () => {
    if (this.state.detalleOn) {
      this.setState({ detalleOn: false });
    } else {
      //this.consultarDesgloceCxP();
      if (
        this.state.beneficiario2.length > 0 &&
        this.state.nombrex2.length > 0
      ) {
        this.setState({ detalleOn: true });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Crear Compras.",
          cuerpoM: "Debe seleccionar un Proveedor, gracias",
        });
      }
    }
  };

  detalleD = () => {
    if (this.state.detalleDesgloceOn) {
      this.setState({
        detalleDesgloceOn: false,
        iddesgloce3: "",
        cuentaContable3: "",
        idcomprasD3: "",
        cuentaContable: "",
      });
    } else {
      //this.consultarDesgloceCxP();

      this.setState({ detalleDesgloceOn: true });
    }
  };

  consultarDesgloceCxP = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("idproveedor", this.state.beneficiario2);
    data.append("boton", "consultarDesgloceCxP");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        itenFacturasC: xcuentas,
        montoT: res.data.variable2,
      });
      //console.log(this.state.itenFacturasC)
    });
  };

  limpiarDesgloce = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("boton", "limpiarDesgloce");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {});
  };

  guardarDetalleCompras = () => {
    let paso = 0;
    if (parseFloat(this.state.montoT).toFixed(2) === this.state.monto2) {
      paso = 0;
    } else {
      paso = 1;
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Compras.",
        cuerpoM: "El monto del Pago no coincide con lo desglosado, gracias",
      });
    }
    if (paso == 0) {
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.password);
      data.append("idProveedor", this.state.beneficiario2);
      data.append("descripcion", this.state.descripcion1);
      data.append("documento", this.state.documento);
      data.append("subtotal", this.state.subtotal);
      data.append("impuesto", this.state.impuesto);
      data.append("monto", this.state.monto2);
      data.append("idcompras", this.state.idcompra);
      data.append("fecha", this.state.fecha2);
      data.append("boton", "guardarDetalleCompra");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable4);
          //console.log(res.data.variable3);
          if (res.data.variable1) {
            this.consultarDetalleC(this.state.idcompra);
            this.state.xsaldo =
              parseFloat(this.state.xsaldo) - parseFloat(this.state.monto2);
            this.setState({
              documento: "",
              beneficiario2: "",
              descripcion1: "",
              subtotal: "",
              impuesto: "",
              monto2: "",
              fecha2: "",
              itenFacturasC: [],
              mensajealerta: true,
              tituloM: "Menu Detalle de Compras.",
              cuerpoM: "Se Guardo el Registro con exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Detalle de Compras.",
              cuerpoM: "Ocurrio un error, Verifique por favor.",
            });
          }
        });
    }
  };

  guardarDesgloce = () => {
    if (
      this.state.cuentaContable.length > 0 &&
      this.state.descripcionC.length > 0 &&
      this.state.subtotalC.length > 0
    ) {
      let montoAcu = 0;
      montoAcu = parseFloat(this.state.montoT) + parseFloat(this.state.montoC);
      console.log(montoAcu);
      console.log(this.state.montoT);
      console.log(parseFloat(this.state.montoC));
      //if (parseFloat(this.state.monto2) >= montoAcu) {
      let data = new FormData();
      data.append("documento", this.state.documento);
      data.append("cuentaContable", this.state.cuentaContable);
      data.append("descripcion", this.state.descripcionC);
      data.append("subtotal", this.state.subtotalC);
      data.append("impuesto", this.state.impuestoC);
      data.append("monto", this.state.montoC);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("idproveedor", this.state.beneficiario2);
      data.append("fecha", this.state.fecha2);
      data.append("boton", "agregarDesgloce");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultarDesgloceCxP();
            this.setState({
              claseNormal: false,
              descripcionC: "",
              subtotalC: "",
              impuestoC: "",
              montoC: "",
              cuentaContable: "",
              // mensajealerta: true,
              // tituloM: "Menu Crear Cuentas por Pagar.",
              // cuerpoM: "Se Modifico el Registro con exito.",
            });
          } else {
            this.setState({
              claseNormal: false,
              mensajealerta: true,
              tituloM: "Menu Crear Cuentas por Pagar.",
              cuerpoM: "Ocurrio un error, Verifique por favor.",
            });
          }
        });
      // } else {
      //   this.setState({
      //     mensajealerta: true,
      //     tituloM: "Menu Crear Desgloce.",
      //     cuerpoM:
      //       "El monto que intanta agregar excede el monto de la factura, Verifique por favor.",
      //   });
      // }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Crear Desgloce.",
        cuerpoM:
          "Debe existir una Cuenta, una descripcion y un monto, Verifique por favor.",
      });
    }
  };

  agregarItemAsientoPorPagar2 = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("idcomprasD", this.state.idcomprasDetalle);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("montoBanco", this.state.montoBanco);
    data.append("fechaBanco", this.state.fechaBanco);
    data.append("chequeBeneficiario", this.state.chequeBeneficiario);
    data.append("boton", "guardarAsientosCompra");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //this.guardarCxP();
        //this.guardarDetalleCompras();
        this.setState({
          // asientoOn: true,
          // listaNominaOn: false,
          // botonAsientoOn: true,
          mensajealerta: true,
          tituloM: "Menu Crear Desgloce.",
          cuerpoM: "Se Crearon los Asientos contables con exito.",
          loading: false,
        });
        //this.detalle();
      });
  };

  agregarItemAsientoPorPagar = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("idcomprasD", this.state.idcomprasDetalle);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("montoBanco", this.state.montoBanco);
    data.append("fechaBanco", this.state.fechaBanco);
    data.append("chequeBeneficiario", this.state.chequeBeneficiario);
    data.append("boton", "agregarItemAsientoCompras");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          asientoOn: true,
          listaNominaOn: false,
          botonAsientoOn: true,
          loading: false,
        });
      });
  };

  controlNumero = () => {
    let data = new FormData();
    data.append("boton", "controlNumero");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          nControl: res.data.variable1,
        });
      });
  };

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  eliminarItenFactura = (e) => {
    let data = new FormData();
    data.append("iddesgloce", e);
    data.append("boton", "eliminarDesgloceCxP");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios.post(xurl, data, config).then((res) => {
      this.consultarDesgloceCxP();
    });
  };

  asientoContable = () => {
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
        listaNominaOn: true,
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
        listaNominaOn: false,
      });
    }
  };

  loadOptions = async (inputValue) => {
    //if (inputValue.length > 0) {
    let data = new FormData();
    data.append("inputValue", inputValue);
    //data.append("boton", "buscarNE");
    if (inputValue.length > 1) data.append("boton", "buscarNE");
    if (inputValue.length <= 1) data.append("boton", "buscarNE2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/contabilidad.php";
    let res = await axios.post(xurl, data, config);
    if (res.data.length > 0) {
      this.setState({ claseNormal: true });
      let arr = [];
      for (let x = 0; x < res.data.length; x++) {
        arr.push({
          label: res.data[x].variable6 + " - " + res.data[x].variable3,
          value: res.data[x].variable2,
        });
      }
      return arr;
    } else {
      this.setState({ claseNormal: false });
      //   const arr = [{ value: "-1", label: "Crear Evaluación()" }];
      //   return arr;
    }
    //}
  };

  onSelectChange = (e) => {
    console.log(e);
    this.setState({ nombreEvaluacion_selected: e });
    if (e !== null) {
      this.setState({
        cuentaContable: e.value,
        evaluacionNombre: e.label,
      });
    }
  };

  detalleDesgloce = (e) => {
    let data = new FormData();
    data.append("idcomprasD", e);
    data.append("boton", "consultarDetalleDesgloce");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
    axios.post(xurl, data, config).then((res) => {
      let xdetalleD = res.data.variable2;
      this.setState({
        detalleD: xdetalleD,
        detalleDesgloceOn: true,
        idcomprasD3: e,
      });
    });
  };

  asientoContable = () => {
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
        listaNominaOn: true,
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
        listaNominaOn: false,
      });
    }
  };

  modificaDesgloce = (e, f) => {
    this.setState({
      iddesgloce3: e,
      cuentaContable3: f,
    });
  };

  ModificardetalleDesgloceConta = () => {
    if (
      this.state.cuentaContable3.length > 0 &&
      this.state.cuentaContable.length > 0
    ) {
      let data = new FormData();
      data.append("idcomprasDesgloce", this.state.iddesgloce3);
      data.append("cuentaAnterior", this.state.cuentaContable3);
      data.append("cuentaNueva", this.state.cuentaContable);
      data.append("boton", "modificarDetalleDesgloceConta");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/registroCompra.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.detalleDesgloce(this.state.idcomprasD3);
          this.setState({
            iddesgloce3: "",
            cuentaContable3: "",
            cuentaContable: "",
            mensajealerta: true,
            tituloM: "Menu Modificar Detalle Desgloce.",
            cuerpoM: "Se Modifico con exito los registros.",
          });
        } else {
          this.setState({
            iddesgloce3: "",
            cuentaContable3: "",
            cuentaContable: "",
            mensajealerta: true,
            tituloM: "Menu Modificar Detalle Desgloce.",
            cuerpoM: "Ocurrio un error verifique por favor.",
          });
        }
      });
    } else {
      this.setState({
        iddesgloce3: "",
        cuentaContable3: "",
        cuentaContable: "",
        mensajealerta: true,
        tituloM: "Menu Modificar Detalle Desgloce.",
        cuerpoM: "Debe seleccionar la cuenta a modificar y la cuenta nueva.",
      });
    }
  };

  componentDidMount() {
    this.controlNumero();
    this.cargarCuentas();
    this.limpiarDesgloce();
    this.buscarAnioEscolar();
    //this.consultarProveedores();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc3 col-12">
          <div className="titulo-cxc "></div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.reporteOn === true && (
            <div className="flotador3x">
              <div className="flotador1">
                <Reporte reporteClose={this.reporte} />
              </div>
            </div>
          )}

          {this.state.listaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Listado de Proveedores</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrarLista.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.proveedores.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consultarCxP.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable3}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.registrarCOn === true && (
            <div className="flotador4x">
              <div className="flotador1">
                {this.state.asientoOn && (
                  <div className="flotadorPe">
                    <div className="flotadorPe1">
                      <Comprobante
                        claveUsuario={this.props.usuario1.password}
                        comprobanteClose={this.asientoContable}
                      />
                    </div>
                  </div>
                )}
                {this.state.detalleOn === true && (
                  <div className="flotador3xMC">
                    <div className="flotador1">
                      <div className="form-group row">
                        <div className="contenedorcedula1 col-6">
                          <AsyncSelect
                            id="cuentaContable"
                            name="cuentaContable"
                            className="nombreEvaluacion"
                            cacheOptions
                            defaultOptions
                            isClearable
                            classNamePrefix="Nombre de la Cuenta"
                            loadOptions={(inputValue) =>
                              this.loadOptions(inputValue)
                            }
                            onChange={this.onSelectChange}
                            //onCreateOption={this.onCreate}
                            value={this.state.nombreEvaluacion_selected}
                          />
                        </div>
                        {/* <div className="contenedorcedula1 col-6">
                          <select
                            id="cuentaContable"
                            name="cuentaContable"
                            value={this.state.cuentaContable}
                            onChange={this.onChange.bind(this)}
                          >
                            <option>Cuenta Contable</option>

                            {this.state.cuenta.map((item, i) => {
                              return (
                                <option key={i} value={item.variable2}>
                                  {item.variable6 + " - " + item.variable3}{" "}
                                </option>
                              );
                            })}
                          </select>
                        </div> */}
                        <div className="contenedorcedula col-2">
                          <input
                            className="form-control"
                            type="date"
                            name="fecha2"
                            id="fecha2"
                            autoComplete="off"
                            readOnly="readonly"
                            onChange={this.onChange.bind(this)}
                            value={this.state.fecha2}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="contenedorcedula1 col-4">
                          <input
                            className="form-control"
                            type="text"
                            name="descripcionC"
                            id="descripcionC"
                            autoComplete="off"
                            placeholder="Descripcion Item"
                            maxLength="60"
                            onChange={this.onChange.bind(this)}
                            value={this.state.descripcionC.toUpperCase()}
                          />
                        </div>
                        <div className="contenedorcedula1 col-2">
                          <input
                            className="form-control"
                            type="text"
                            name="subtotalC"
                            id="subtotalC"
                            autoComplete="off"
                            placeholder="Sub-Total"
                            maxLength="10"
                            onChange={this.onChange7.bind(this)}
                            value={this.state.subtotalC}
                          />
                        </div>
                        <div className="contenedorcedula1 col-2">
                          <input
                            className="form-control"
                            type="text"
                            name="impuestoC"
                            id="impuestoC"
                            autoComplete="off"
                            placeholder="Impuesto"
                            maxLength="10"
                            onChange={this.onChange7.bind(this)}
                            value={this.state.impuestoC}
                          />
                        </div>
                        <div className="contenedorcedula1 col-2">
                          <input
                            className="form-control"
                            type="text"
                            name="montoC"
                            id="montoC"
                            autoComplete="off"
                            placeholder="Monto"
                            maxLength="10"
                            onChange={this.onChange7.bind(this)}
                            value={this.state.montoC}
                          />
                        </div>
                        <button
                          className="boton"
                          onClick={this.guardarDesgloce}
                        >
                          Aceptar Item
                        </button>
                      </div>
                      <div clasName="form-group row">
                        <div className="cont-listmedi row">
                          <div className="listcxc col-12">
                            <table className="tablacxc" border="1">
                              {this.state.claseNormal === false && (
                                <thead>
                                  <tr>
                                    <th className="titucxc2">CUENTA</th>
                                    {/* <th className="titucxc1">FECHA</th> */}
                                    <th className="titucxc2">DESCRIPCION</th>
                                    <th className="titucxc1">P/UNITARIO</th>
                                    <th className="titucxc1">ITBMS</th>
                                    <th className="titucxc1">TOTAL</th>
                                    <th className="titucxc1">
                                      <FeatherIcon
                                        icon="delete"
                                        size="30px"
                                        stroke="#999"
                                        color="red"
                                      />
                                    </th>
                                  </tr>
                                </thead>
                              )}
                              {this.state.claseNormal === true && (
                                <tr>
                                  <th className="titucxc2">CUENTA</th>
                                  {/* <th className="titucxc1">FECHA</th> */}
                                  <th className="titucxc2">DESCRIPCION</th>
                                  <th className="titucxc1">P/UNITARIO</th>
                                  <th className="titucxc1">ITBMS</th>
                                  <th className="titucxc1">TOTAL</th>
                                  <th className="titucxc1">
                                    <FeatherIcon
                                      icon="delete"
                                      size="30px"
                                      stroke="#999"
                                      color="red"
                                    />
                                  </th>
                                </tr>
                              )}
                              {this.state.itenFacturasC.map((item, i) => {
                                return (
                                  <tr
                                    className={i % 2 === 0 ? "odd" : "odd"}
                                    key={item.variable1}
                                    id="tabla"
                                  >
                                    <td className="totalescxc" width="10">
                                      {item.variable2}
                                    </td>
                                    {/* <td className="renglon" width="10">
                                {item.variable3}
                              </td> */}
                                    <td className="totalescxc1" width="10">
                                      {item.variable4}
                                    </td>
                                    <td className="totalescxc1" width="10">
                                      {item.variable5}
                                    </td>
                                    <td className="totalescxc1" width="10">
                                      {item.variable6}
                                    </td>
                                    <td className="totalescxc1" width="10">
                                      {item.variable7}
                                    </td>
                                    <td className="titucxc3" width="10">
                                      <FeatherIcon
                                        icon="delete"
                                        size="30px"
                                        stroke="#999"
                                        color="red"
                                        onClick={this.eliminarItenFactura.bind(
                                          this,
                                          item.variable1
                                        )}
                                      ></FeatherIcon>
                                    </td>
                                  </tr>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="fechasLC col-12">
                          {/* <button
                            className="boton"
                            onClick={this.agregarItemAsientoPorPagar}
                          >
                            Asiento Contable
                          </button>*/}

                          <button
                            className="boton"
                            onClick={this.guardarDetalleCompras}
                          >
                            Guardar Factura
                          </button>

                          <button className="boton" onClick={this.detalle}>
                            SALIR
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.listaOn2 && (
                  <div className="cont-tablamedi row">
                    <div className="tablamedi1 col-11">
                      <div className="cont-titulomedi col-12">
                        <span className="titulocasos">
                          Listado de Proveedores
                        </span>
                        <span
                          className="titulo-cerrarcasos"
                          onClick={this.cerrarLista.bind(this)}
                        >
                          X
                        </span>
                      </div>
                      {/* <span className="titulo-casos">Datos del Pago</span> */}
                      <div className="cont-listmedi row">
                        <div className="listmedi col-12">
                          <table className="tablame" border="1">
                            <tbody className="evocuerpo1">
                              {this.state.proveedores.map((item, i) => {
                                return (
                                  <tr
                                    className={i % 2 === 0 ? "odd" : ""}
                                    key={item.variable1}
                                    id="tabla"
                                  >
                                    <td
                                      className="renglon"
                                      width="10"
                                      onClick={this.consultarCxP3.bind(
                                        this,
                                        item.variable1
                                      )}
                                    >
                                      {item.variable3}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.detalleDesgloceOn === true && (
                  <div className="flotador3xMC">
                    <div className="flotador1">
                      <div className="form-group row">
                        <div className="contenedorcedula1 col-4">
                          <input
                            className="form-control"
                            type="text"
                            name="cuentaContable3"
                            id="cuentaContable3"
                            autoComplete="off"
                            readOnly="readonly"
                            placeholder="Codigo A Modificqar"
                            maxLength="60"
                            onChange={this.onChange.bind(this)}
                            value={this.state.cuentaContable3}
                          />
                        </div>
                        <div className="contenedorcedula1 col-6">
                          <AsyncSelect
                            id="cuentaContable"
                            name="cuentaContable"
                            className="nombreEvaluacion"
                            cacheOptions
                            defaultOptions
                            isClearable
                            classNamePrefix="Nombre de la Cuenta"
                            loadOptions={(inputValue) =>
                              this.loadOptions(inputValue)
                            }
                            onChange={this.onSelectChange}
                            //onCreateOption={this.onCreate}
                            value={this.state.nombreEvaluacion_selected}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="fechasLC col-12">
                          <button
                            className="boton"
                            onClick={this.ModificardetalleDesgloceConta}
                          >
                            Modificar Desgloce Contable
                          </button>

                          <button className="boton" onClick={this.detalleD}>
                            SALIR
                          </button>
                        </div>
                      </div>
                      <div clasName="form-group row">
                        <div className="cont-listmedi row">
                          <div className="listcxc col-12">
                            <table className="tablacxc" border="1">
                              <thead>
                                <tr>
                                  <th className="titucxc2">CUENTA</th>
                                  <th className="titucxc1">FECHA</th>
                                  <th className="titucxc2">DESCRIPCION</th>
                                  <th className="titucxc1">P/UNITARIO</th>
                                  <th className="titucxc1">ITBMS</th>
                                  <th className="titucxc1">TOTAL</th>
                                </tr>
                              </thead>
                              {this.state.detalleD.map((item, i) => {
                                return (
                                  <tr
                                    className={i % 2 === 0 ? "odd" : "odd"}
                                    key={item.variable1}
                                    id="tabla"
                                  >
                                    <td
                                      className="totalescxc"
                                      width="10"
                                      onClick={this.modificaDesgloce.bind(
                                        this,
                                        item.variable1,
                                        item.variable2
                                      )}
                                    >
                                      {item.variable2}
                                    </td>
                                    <td
                                      className="renglon"
                                      width="10"
                                      onClick={this.modificaDesgloce.bind(
                                        this,
                                        item.variable1,
                                        item.variable2
                                      )}
                                    >
                                      {item.variable7}
                                    </td>
                                    <td
                                      className="totalescxc1"
                                      width="10"
                                      onClick={this.modificaDesgloce.bind(
                                        this,
                                        item.variable1,
                                        item.variable2
                                      )}
                                    >
                                      {item.variable3}
                                    </td>
                                    <td
                                      className="totalescxc1"
                                      width="10"
                                      onClick={this.modificaDesgloce.bind(
                                        this,
                                        item.variable1,
                                        item.variable2
                                      )}
                                    >
                                      {item.variable4}
                                    </td>
                                    <td
                                      className="totalescxc1"
                                      width="10"
                                      onClick={this.modificaDesgloce.bind(
                                        this,
                                        item.variable1,
                                        item.variable2
                                      )}
                                    >
                                      {item.variable5}
                                    </td>
                                    <td
                                      className="totalescxc1"
                                      width="10"
                                      onClick={this.modificaDesgloce.bind(
                                        this,
                                        item.variable1,
                                        item.variable2
                                      )}
                                    >
                                      {item.variable6}
                                    </td>
                                  </tr>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="titulo-cxc ">
                  <p>
                    DETALLE DE COMPRA: {this.state.xbeneficiario} SALDO DE{" "}
                    {this.state.xsaldo} FECHA {this.state.xfecha}.
                  </p>
                </div>
                <div className="form-group row">
                  <div className="contenedorcedula1k col-2">
                    <span className="ncontrol">
                      Control Egreso {this.state.nControl}
                    </span>
                  </div>
                  <div className="contenedorcedula1k col-5">
                    <input
                      className="form-control"
                      type="text"
                      name="nombrex2"
                      id="nombrex2"
                      autoComplete="off"
                      placeholder="Nombre Proveedor"
                      maxLength="150"
                      onChange={this.onChange.bind(this)}
                      value={this.state.nombrex2}
                    />
                  </div>
                  <div className="contenedorcedula1 col-1">
                    <button className="boton" onClick={this.buscarOn2}>
                      BUSCAR
                    </button>
                  </div>
                  {/* <div className="fechas col-8">
                    <select
                      id="beneficiario2"
                      name="beneficiario2"
                      value={this.state.beneficiario2}
                      onChange={this.onChange5.bind(this)}
                    >
                      <option>-- Seleccionar Proveedor --</option>
                      {this.state.proveedores.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2 + " - " + item.variable3}{" "}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}
                </div>
                <div className="form-group row">
                  <div className="contenedorcedula col-3">
                    <input
                      className="form-control"
                      type="text"
                      name="documento"
                      id="documento"
                      autoComplete="off"
                      placeholder="Documento"
                      maxLength="100"
                      onChange={this.onChange.bind(this)}
                      value={this.state.documento.toUpperCase()}
                    />
                  </div>
                  <div className="contenedorcedula col-8">
                    <input
                      className="form-control"
                      type="text"
                      name="descripcion1"
                      id="descripcion1"
                      autoComplete="off"
                      placeholder="Descripcion"
                      maxLength="100"
                      onChange={this.onChange.bind(this)}
                      value={this.state.descripcion1.toUpperCase()}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="contenedorcedula col-2">
                    <input
                      className="form-control"
                      type="text"
                      name="subtotal"
                      id="subtotal"
                      autoComplete="off"
                      placeholder="Sub-Total"
                      maxLength="10"
                      onChange={this.onChange.bind(this)}
                      value={this.state.subtotal}
                    />
                  </div>
                  <div className="contenedorcedula col-2">
                    <input
                      className="form-control"
                      type="text"
                      name="impuesto"
                      id="impuesto"
                      autoComplete="off"
                      placeholder="Impuesto"
                      maxLength="10"
                      onChange={this.onChange.bind(this)}
                      value={this.state.impuesto}
                    />
                  </div>
                  <div className="contenedorcedula col-2">
                    <input
                      className="form-control"
                      type="text"
                      name="monto2"
                      id="monto2"
                      autoComplete="off"
                      placeholder="Monto"
                      maxLength="10"
                      onChange={this.onChange.bind(this)}
                      value={this.state.monto2.toUpperCase()}
                    />
                  </div>
                  <div className="contenedorcedula col-3">
                    <input
                      className="form-control"
                      type="date"
                      name="fecha2"
                      id="fecha2"
                      // readOnly="readonly"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.fecha2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="contenedorcedula2 col-12">
                    <button className="boton" onClick={this.detalle}>
                      Desgloce Factura
                    </button>
                    <button
                      className="boton"
                      onClick={this.agregarItemAsientoPorPagar}
                    >
                      Asiento Contable
                    </button>

                    <button
                      className={
                        this.state.botonAsientoOn === true ? "boton" : "botonOf"
                      }
                      onClick={
                        this.state.botonAsientoOn === true
                          ? this.agregarItemAsientoPorPagar2
                          : ""
                      }
                    >
                      Guardar Asiento
                    </button>
                    <button
                      className="boton"
                      onClick={this.limpiar2.bind(this)}
                    >
                      Limpiar
                    </button>
                    <button
                      className="boton"
                      onClick={this.datosCobro.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc2X">BENEFICIARIO</th>
                          <th className="titucxc1">DOCUMENTO</th>
                          <th className="titucxc1">NO. CONTOL</th>
                          <th className="titucxc1">SUBTOTAL</th>
                          <th className="titucxc1">IMPUESTO</th>
                          <th className="titucxc1">TOTAL</th>
                          <th className="titucxc3">
                            <FeatherIcon
                              icon="delete"
                              size="30px"
                              stroke="#999"
                              color="red"
                            />
                          </th>
                        </tr>
                      </thead>
                      {this.state.comprasDetalle.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="renglon"
                              width="10"
                              onClick={this.detalleDesgloce.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable2}
                            </td>
                            <td
                              className="totalescxc1"
                              width="10"
                              onClick={this.detalleDesgloce.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable3}
                            </td>
                            <td
                              className="totalescxc1"
                              width="10"
                              onClick={this.detalleDesgloce.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable9}
                            </td>
                            <td
                              className="totalescxc1"
                              width="10"
                              onClick={this.detalleDesgloce.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable4}
                            </td>
                            <td
                              className="totalescxc1"
                              width="10"
                              onClick={this.detalleDesgloce.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable5}
                            </td>
                            <td
                              className="totalescxc1"
                              width="10"
                              onClick={this.detalleDesgloce.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable6}
                            </td>
                            <td className="titucxc3" width="10">
                              <FeatherIcon
                                icon="delete"
                                size="30px"
                                stroke="#999"
                                color="red"
                                onClick={this.eliminarDetalleC.bind(
                                  this,
                                  item.variable1
                                )}
                              ></FeatherIcon>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula1k col-2">
              <span className="ncontrol">
                Control Egreso {this.state.nControl}
              </span>
            </div>
            <div className="contenedorcedula1k col-5">
              <input
                className="form-control"
                type="text"
                name="nombrex"
                id="nombrex"
                autoComplete="off"
                placeholder="Nombre Proveedor"
                maxLength="50"
                onChange={this.onChange.bind(this)}
                value={this.state.nombrex}
              />
            </div>
            <div className="contenedorcedula1 col-1">
              <button className="boton" onClick={this.buscarOn}>
                BUSCAR
              </button>
            </div>
            {/* <div className="fechas col-8">
              <select
                id="beneficiario"
                name="beneficiario"
                value={this.state.beneficiario}
                onChange={this.onChange5.bind(this)}
              >
                <option>-- Seleccionar Proveedor --</option>
                {this.state.proveedores.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2 + " - " + item.variable3}{" "}
                    </option>
                  );
                })}
              </select>
            </div> */}
          </div>
          <div className="form-group row">
            <div className="contenedorcedula col-3">
              <select
                id="tpago"
                name="tpago"
                value={this.state.tpago}
                onChange={this.onChange.bind(this)}
              >
                <option>-- Seleccione Tipo Pago --</option>
                <option value="1"> Transferencia.</option>
                <option value="2"> Efectivo.</option>
                <option value="3"> Cheque.</option>
              </select>
            </div>
            <div className="contenedorcedula col-2">
              <select
                id="banco"
                name="banco"
                value={this.state.banco}
                onChange={this.onChange8.bind(this)}
              >
                <option>-- Seleccionar Banco --</option>
                <option value="1"> Banco BAC</option>
                <option value="2"> Banco BANESCO</option>
                <option value="5"> Banco GENERAL</option>
                <option value="3"> Efectivo</option>
                <option value="4"> Otros</option>
              </select>
            </div>
            <div className="contenedorcedula col-2">
              <input
                className="form-control"
                type="text"
                name="referencia"
                id="referencia"
                autoComplete="off"
                placeholder="Referencia"
                maxLength="200"
                onChange={this.onChange.bind(this)}
                value={this.state.referencia}
              />
            </div>

            <div className="contenedorcedula col-3">
              <input
                className="form-control"
                type="date"
                name="fecha"
                id="fecha"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="monto1"
                id="monto1"
                autoComplete="off"
                placeholder="Monto"
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.monto1}
              />
            </div>
            <div className="contenedorcedula col-6">
              <span className="espacio-span">
                {" "}
                Año a Consultar
                <select
                  id="anoEscolar"
                  name="anoEscolar"
                  value={this.state.anoEscolar}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Año Escolar --</option>
                  {this.state.ianoEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </span>
              <span>
                {" "}
                Mes a Consultar
                <select
                  id="mes"
                  name="mes"
                  value={this.state.mes}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Mes Seleccionado --</option>
                  <option value="01"> Enero.</option>
                  <option value="02"> Febrero.</option>
                  <option value="03"> Marzo.</option>
                  <option value="04"> Abril.</option>
                  <option value="05"> Mayo.</option>
                  <option value="06"> Junio.</option>
                  <option value="07"> Julio.</option>
                  <option value="08"> Agosto.</option>
                  <option value="09"> Septiembre.</option>
                  <option value="10"> Octubre.</option>
                  <option value="11"> Noviembre.</option>
                  <option value="12"> Diciembre.</option>
                </select>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="contenedorcedula2 col-12">
              <button
                className={this.state.guardarOn ? "botonOf" : "boton"}
                onClick={this.state.guardarOn ? "" : this.guardarCxP}
              >
                Guardar
              </button>
              {/* <button
                className={this.state.guardarOn ? "boton" : "botonOf"}
                disabled={!this.state.guardarOn}
                onClick={this.state.guardarOn ? this.modificarCxP : ""}
              >
                Modificar
              </button> */}
              <button className="boton" onClick={this.reporte}>
                Reporte
              </button>
              <button className="boton" onClick={this.limpiar.bind(this)}>
                Limpiar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>
          {/* {this.state.modificarSaldoOn === true && (
            <div className="flotador1x">
              <div className="flotador1">
                <ModificarMonto
                  idcxc={this.state.idcxc}
                  montoDebe={this.state.montoDebe}
                  montoAbono={this.state.montoAbono}
                  montoModificar={this.state.montoModificar}
                  idcontrolPago={this.state.idcontrolPago}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )} */}
          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">FECHA</th>
                    <th className="titucxc1">NO. CONTOL</th>
                    <th className="titucxc2X">BENEFICIARIO</th>
                    <th className="titucxc1">MONTO</th>
                    <th className="titucxc1">ABONO</th>
                    <th className="titucxc1">SALDO</th>
                    <th className="titucxc3">
                      <FeatherIcon
                        icon="search"
                        size="30px"
                        stroke="#999"
                        color="blue"
                      />
                    </th>
                    <th className="titucxc3" width="10">
                      <FeatherIcon
                        icon="delete"
                        size="30px"
                        stroke="#999"
                        color="red"
                      ></FeatherIcon>
                    </th>
                  </tr>
                </thead>
                {this.state.compras.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxc" width="10">
                        {item.variable2}
                      </td>
                      <td className="totalescxc" width="10">
                        {item.variable7}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable3}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable4}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable5}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable6}
                      </td>
                      <td className="titucxc3" width="10">
                        <FeatherIcon
                          icon="search"
                          size="30px"
                          stroke="#999"
                          color="blue"
                          onClick={this.datosCobro.bind(
                            this,
                            item.variable1,
                            item.variable3,
                            item.variable4,
                            item.variable2,
                            item.variable6
                          )}
                        ></FeatherIcon>
                      </td>
                      <td className="titucxc3" width="10">
                        <FeatherIcon
                          icon="delete"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.eliminarCompra.bind(
                            this,
                            item.variable1
                          )}
                        ></FeatherIcon>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxPAbonos);
