import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
//componentes
import "./nomina.css";
import Alert from "../../modal/alert";
import ReporteV from "./reporteVacacionesE";

function Vacaciones(props) {
  const [listaOn, setlistaOn] = React.useState(false);
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [activarOn, setactivarOn] = React.useState(false);
  const [flotadorNOn, setflotadorNOn] = React.useState(false);
  const [personal, setpersonal] = React.useState([]);
  const [anio, setanio] = React.useState([]);
  const [mes, setmes] = React.useState([]);
  const [nominaPersonal, setnominaPersonal] = React.useState([]);
  const [tipoNomina, settipoNomina] = React.useState(0);
  const [titulo1, settitulo1] = React.useState("");
  const [titulo2, settitulo2] = React.useState("");
  const [titulo3, settitulo3] = React.useState("");
  const [titulo4, settitulo4] = React.useState("");
  const [titulo5, settitulo5] = React.useState("");
  const [titulo6, settitulo6] = React.useState("");
  const [titulo7, settitulo7] = React.useState("");
  const [titulo8, settitulo8] = React.useState("");
  const [titulo9, settitulo9] = React.useState("");
  const [titulo10, settitulo10] = React.useState("");
  const [titulo11, settitulo11] = React.useState("");
  const [fechaIngreso, setfechaIngreso] = React.useState("");
  const [monto1, setmonto1] = React.useState(0);
  const [monto2, setmonto2] = React.useState(0);
  const [monto3, setmonto3] = React.useState(0);
  const [monto4, setmonto4] = React.useState(0);
  const [monto5, setmonto5] = React.useState(0);
  const [monto6, setmonto6] = React.useState(0);
  const [monto7, setmonto7] = React.useState(0);
  const [monto8, setmonto8] = React.useState(0);
  const [monto9, setmonto9] = React.useState(0);
  const [monto10, setmonto10] = React.useState(0);
  const [monto11, setmonto11] = React.useState(0);
  const [montoT, setmontoT] = React.useState(0);
  const [montoN, setmontoN] = React.useState(0);
  const [montosedu, setmontosedu] = React.useState(0);
  const [montosso, setmontosso] = React.useState(0);
  const [montoF, setmontoF] = React.useState(0);
  const [trimestre, settrimestre] = React.useState(0);
  const [anopagar, setanopagar] = React.useState("");
  const [mesapagar, setmesapagar] = React.useState("");
  const [sso, setsso] = React.useState(0);
  const [seguroE, setseguroE] = React.useState(0);

  const [data, setData] = React.useState({
    cedulaNomina: "",
  });
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");

  const limpiar = () => {
    settitulo1("");
    settitulo2("");
    settitulo3("");
    settitulo4("");
    settitulo5("");
    settitulo6("");
    settitulo7("");
    settitulo8("");
    settitulo9("");
    settitulo10("");
    settitulo11("");
    setmonto1(0);
    setmonto2(0);
    setmonto3(0);
    setmonto4(0);
    setmonto5(0);
    setmonto6(0);
    setmonto7(0);
    setmonto8(0);
    setmonto9(0);
    setmonto10(0);
    setmonto11(0);
    setmontoT(0);
    setmontoN(0);
    setmontosso(0);
    setmontosedu(0);
    setmontoF(0);
    settipoNomina(0);
    setfechaIngreso("");
    setmensajealerta(false);
    setactivarOn(false);
    setflotadorNOn(false);

    let data_ = Object.assign({}, data);
    data_ = {
      cedulaNomina: 0,
    };
    setData(data_);
  };

  const cerrarLista = () => {
    //console.log(listaOn)
    if (listaOn) {
      setlistaOn(false);
    } else {
      setlistaOn(true);
      buscarNomina();
    }
  };

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
    data_[index] = e.target.value;
    setData(data_);
    if (index === "trimestre") {
      settrimestre(e.target.value);
      limpiar();
    }
    if (index === "anopagar") {
      setanopagar(e.target.value);
      limpiar();
    }
  };

  const handleClose = () => {
    setmensajealerta(false);
  };

  const buscarNomina = () => {
    let form = new FormData();
    form.append("anoapagar", anopagar);
    form.append("boton", "buscarVacaciones");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        let xnominaPersonal = res.data.variable2;
        setnominaPersonal(xnominaPersonal);
      } else {
        let xnominaPersonal = res.data.variable2;
        setnominaPersonal(xnominaPersonal);
        setmensajealerta(true);
        settituloM("Menu Generar Vacaciones.");
        setcuerpoM(res.data.variable3);
      }
    });
  };

  const buscarPersonalI = (e) => {
    if (anopagar.length > 0) {
      if (e.target.value.length > 0) {
        limpiar();
        let rcedu = e.target.value;
        let form = new FormData();
        form.append("cedula", rcedu);
        form.append("boton", "buscarPersonalI");
        let config = {
          headers: { "Content-Type": "text/html" },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
        axios.post(xurl, form, config).then((res) => {
          if (res.data.variable1) {
            settipoNomina(res.data.variable8);
            setsso(res.data.variable16);
            setseguroE(res.data.variable18);
            setfechaIngreso(res.data.variable6);
            let data_ = Object.assign({}, data);
            data_["cedulaNomina"] = rcedu;
            setData(data_);
          }
        });
      } else {
        setmensajealerta(true);
        settituloM("Menu Generar Vacaciones.");
        setcuerpoM(
          "No se encontro la tabla de Vacaciones, Favor comunicarse con el Ing. Hender perozo"
        );
      }
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Nomina.");
      setcuerpoM("Debe elegir un Año, Gracias");
    }
  };

  const buscarPersonal = () => {
    let form = new FormData();
    form.append("dedonde", "crearVacaciones");
    form.append("boton", "buscarPersonal");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xpersonal = res.data.variable1;
      setpersonal(xpersonal);
    });
  };

  const guardarVacaciones = () => {
    if (data.cedulaNomina.length > 0) {
      let form = new FormData();
      form.append("cedulaNomina", data.cedulaNomina);
      form.append("anoapagar", anopagar);
      form.append("montoT", montoT);
      form.append("montoN", montoN);
      form.append("montosso", montosso);
      form.append("montosedu", montosedu);
      form.append("montoF", montoF);
      form.append("monto1", monto1);
      form.append("monto2", monto2);
      form.append("monto3", monto3);
      form.append("monto4", monto4);
      form.append("monto5", monto5);
      form.append("monto6", monto6);
      form.append("monto7", monto7);
      form.append("monto8", monto8);
      form.append("monto9", monto9);
      form.append("monto10", monto10);
      form.append("monto11", monto11);
      form.append("titulo1", titulo1);
      form.append("titulo2", titulo2);
      form.append("titulo3", titulo3);
      form.append("titulo4", titulo4);
      form.append("titulo5", titulo5);
      form.append("titulo6", titulo6);
      form.append("titulo7", titulo7);
      form.append("titulo8", titulo8);
      form.append("titulo9", titulo9);
      form.append("titulo10", titulo10);
      form.append("titulo11", titulo11);

      form.append("boton", "guardarVacaciones");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setmensajealerta(true);
          settituloM("Menu Generar Vacaciones.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Generar Vacaciones.");
          setcuerpoM(res.data.variable2);
        }
      });
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Vacaciones.");
      setcuerpoM("Debe existir un Empleado");
    }
  };

  const consultaRecibo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idvacaciones", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboVacacionesEmpleados.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  const buscarDatosNomina = (e) => {
    let form = new FormData();
    form.append("cedulaNomina", data.cedulaNomina);
    form.append("tipoNomina", tipoNomina);
    form.append("fechaIngreso", fechaIngreso);
    form.append("anoapagar", anopagar);
    form.append("sso", sso);
    form.append("seguroE", seguroE);
    form.append("boton", "vacaciones");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setmonto1(parseFloat(res.data.variable2).toFixed(2));
        setmonto2(parseFloat(res.data.variable3).toFixed(2));
        setmonto3(parseFloat(res.data.variable4).toFixed(2));
        setmonto4(parseFloat(res.data.variable5).toFixed(2));
        setmonto5(parseFloat(res.data.variable6).toFixed(2));
        setmonto6(parseFloat(res.data.variable7).toFixed(2));
        setmonto7(parseFloat(res.data.variable8).toFixed(2));
        setmonto8(parseFloat(res.data.variable9).toFixed(2));
        setmonto9(parseFloat(res.data.variable10).toFixed(2));
        setmonto10(parseFloat(res.data.variable11).toFixed(2));
        setmonto11(parseFloat(res.data.variable12).toFixed(2));
        setmontoT(parseFloat(res.data.variable13).toFixed(2));
        setmontoN(parseFloat(res.data.variable14).toFixed(2));
        setmontosso(parseFloat(res.data.variable15).toFixed(2));
        setmontosedu(parseFloat(res.data.variable16).toFixed(2));
        setmontoF(parseFloat(res.data.variable17).toFixed(2));
        settitulo1(res.data.variable18);
        settitulo2(res.data.variable19);
        settitulo3(res.data.variable20);
        settitulo4(res.data.variable21);
        settitulo5(res.data.variable22);
        settitulo6(res.data.variable23);
        settitulo7(res.data.variable24);
        settitulo8(res.data.variable25);
        settitulo9(res.data.variable26);
        settitulo10(res.data.variable27);
        settitulo11(res.data.variable28);
      } else {
        setmensajealerta(true);
        settituloM("Menu Vacaciones.");
        setcuerpoM(res.data.variable2);
      }
    });
  };

  const cargarFechas = () => {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        //let xdia = res.data.variable3;
        //dia: xdia,
        setmes(xmes);
        setanio(xanio);
      }
    });
  };
  const eliminarNomina = (e) => {
    let form = new FormData();
    form.append("idnomina", e);
    form.append("boton", "eliminarNominaVacaciones"); 
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setmensajealerta(true);
        settituloM("Menu Nomina Decimo.");
        setcuerpoM(res.data.variable2);
        buscarNomina();
      } else {
        setmensajealerta(true);
        settituloM("Menu Nomina Decimo.");
        setcuerpoM(res.data.variable2);
      }
    });
  };

  const activarReporte3 = () => {
    if (flotadorNOn) {
      setflotadorNOn(false);
    } else {
      setflotadorNOn(true);
    }
  };

  React.useEffect(() => {
    //console.log(props.cedulaempleado);
    buscarPersonal();
    cargarFechas();
  }, []);
  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        {/* <div className="titulo-cxc ">
          <p>CREAR NOMINA DE EMPLEADOS. </p>
        </div> */}
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        {flotadorNOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <ReporteV personalClose={activarReporte3} />
            </div>
          </div>
        )}
        {listaOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">
                  {/* Nomina Creada a la {quincena} Quincena {mesapagar}-{anopagar} */}
                </span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    cerrarLista();
                  }}
                >
                  X
                </span>
              </div>
              <span className="titulo-nomina"></span>

              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomi" border="1">
                    <thead>
                      <tr>
                        <th className="xtitunomina1">item.</th>
                        <th className="xtitunomina2">Nombre Empleado.</th>
                        <th className="xtitunomina3">Sal./Acumu.</th>
                        <th className="xtitunomina3">Sal./Prom.</th>
                        <th className="xtitunomina1">S.S.O. 9.75%</th>
                        <th className="xtitunomina1">S.EDU. 1.25%</th>
                        <th className="xtitunomina3">Total Pagar</th>
                        <th className="xtitunomina1">
                          <span className="cerrarme3"></span>
                        </th>
                      </tr>
                    </thead>

                    <tbody className="evocuerpo1">
                      {nominaPersonal.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable1}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable2}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable3}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable4}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable5}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable6}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                consultaRecibo(item.variable1);
                              }}
                            >
                              {item.variable7}
                            </td>
                            <td width="20">
                              <span
                                className="cerrarme1"
                                onClick={() => {
                                  eliminarNomina(item.variable1);
                                }}
                              ></span>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="cont-listmedi row">
          <div className="cont-personal col-4">
            <label className="titulo-salario2">
              Seleccione un Empleado.
              <select
                id="cedulaNomina"
                name="cedulaNomina"
                value={
                  typeof data.cedulaNomina !== "undefined"
                    ? data.cedulaNomina
                    : ""
                }
                onChange={buscarPersonalI}
              >
                <option value="0">-- Seleccione Empleado --</option>
                {personal.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2 + " ==> " + item.variable3}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Año a Pagar.
              <select
                id="anopagar"
                name="anopagar"
                value={typeof anopagar !== "undefined" ? anopagar : ""}
                onChange={onChange}
              >
                <option value="0">Año a Pagar</option>
                {anio.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable1}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>

        <div className="cont-personal col-12">
          <button className="botonN" onClick={buscarDatosNomina}>
            Generar Vacaciones
          </button>
        </div>

        <div className="cont-listmedi row">
          <div className="titulo-cxc2 col-10 ">
            <p className="confi">RECIBO DE PAGO VACACIONES. </p>
          </div>
        </div>
        <div className="cont-listmedi row">
          <div className="cont-personal col-4">
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo1}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto1"
                  id="monto1"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto1 !== "undefined" ? monto1 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo2}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto2"
                  id="monto2"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto2 !== "undefined" ? monto2 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo3}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto3"
                  id="monto3"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto3 !== "undefined" ? monto3 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo4}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto4"
                  id="monto4"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto4 !== "undefined" ? monto4 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo5}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto5"
                  id="monto5"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto5 !== "undefined" ? monto5 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo6}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto6"
                  id="monto6"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto6 !== "undefined" ? monto6 : ""}
                />
              </div>
            </div>
          </div>
          <div className="cont-personal col-4">
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo7}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto7"
                  id="monto7"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto7 !== "undefined" ? monto7 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo8}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto8"
                  id="monto8"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto8 !== "undefined" ? monto8 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo9}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto9"
                  id="monto9"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto9 !== "undefined" ? monto9 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo10}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto10"
                  id="monto510"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto10 !== "undefined" ? monto10 : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">{titulo11}</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="monto11"
                  id="monto11"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof monto11 !== "undefined" ? monto11 : ""}
                />
              </div>
            </div>
          </div>

          <div className="cont-personal col-4">
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">Salario Acumulado.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montoT"
                  id="montoT"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montoT !== "undefined" ? montoT : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">Salario Promedio.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montoN"
                  id="montoN"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montoN !== "undefined" ? montoN : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">S.Social {sso}%.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montosso"
                  id="montosso"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montosso !== "undefined" ? montosso : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">
                  S.Educativo {seguroE}%.
                </label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montosedu"
                  id="montosedu"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montosedu !== "undefined" ? montosedu : ""}
                />
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">Total a Pagar.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montoF"
                  id="montoF"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montoF !== "undefined" ? montoF : ""}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="contenedorcedula1 col-12">
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? guardarVacaciones : ""}
          >
            Guardar Vacaciones
          </button>
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? cerrarLista : ""}
          >
            Consultar Vacaciones
          </button>
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? activarReporte3 : ""}
          >
            Reporte General
          </button>
          <button className="boton" onClick={limpiar}>
            Limpiar Datos
          </button>
          <button className="boton" onClick={props.personalClose}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Vacaciones);
