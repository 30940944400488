import axios from "axios";
import moment from "moment";
const config = {
  headers: { "Content-Type": "text/html" },
};

// Busca los datos del profesor

export const BuscarProfesor = async (password) => {
  let form = new FormData();
  form.append("clave", password);
  form.append("boton", "buscarPersonal3");

  const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca los alumnos relacionados al profesor

export const BuscarAlumno = async (profesor, val) => {
  let form = new FormData();
  form.append("idprofesor", profesor);
  form.append("anoEscolar", moment().format("YYYY"));
  form.append("boton", "alumnosChat");
  form.append("nombreAlumno", val);
  let xurl = "https://institutobilinguelasnaciones.com/terapias/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca los chats de un profesor

export const BuscarChats = async (profesor) => {
  let form = new FormData();
  form.append("idprofesor", profesor);
  form.append("boton", "buscarChatEnviar");
  let xurl = "https://institutobilinguelasnaciones.com/terapias/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca si el profesor tiene mensajes sin leer

export const buscarCantidadChatSinLeer = async (profesor) => {
  let form = new FormData();
  form.append("idprofesor", profesor);
  form.append("boton", "buscarCantidadChatSinLeer");
  let xurl = "https://institutobilinguelasnaciones.com/terapias/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca los mensajes de un chat

export const BuscarMensajesChat = async (id_sala) => {
  let form = new FormData();
  form.append("idsala", id_sala);
  form.append("boton", "buscarChatMensaje");
  let xurl = "https://institutobilinguelasnaciones.com/terapias/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};
// Marca sala como leido y los mensajes de un chat

export const MarcarSalaLeido = async (id_sala) => {
  let form = new FormData();
  form.append("idsala", id_sala);
  form.append("boton", "cambiarEstadoMensaje");
  let xurl = "https://institutobilinguelasnaciones.com/terapias/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Crea los mensajes de un chat o El en si no existe

export const CrearMensajesChat = async (
  idprofesor,
  cedulaAlumno,
  idmateria,
  mensaje
) => {
  let form = new FormData();
  form.append("idprofesor", idprofesor);
  form.append("cedulaAlumno", cedulaAlumno);
  form.append("idmateria", idmateria);
  form.append("mensaje", mensaje);
  form.append("boton", "guardarChatEnviado");
  let xurl = "https://institutobilinguelasnaciones.com/terapias/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};
