import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

//componentes
import "./reporte.css";
import Alert from "../../modal/alert";
import AsignacionesCorregidas from "../asignacionesCorregidas/asignacionesC";

class Reporte extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      ianoEscolar: [],
      icursos: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      idcurso:'',
      trimestre:'',
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      verplanillaOn: false,
      asignacionesEOn: false,
      nalumno: [],
    };
  }

  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  limpiar = () => {
    this.setState({
      fecha: "",
      ifechamedi: [],
      icursos: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      idcurso:'',
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      verplanillaOn: false,
      asignacionesEOn: false,
      nalumno: [],
      trimestre:'',
    });
  };

  buscarTipoReporte = () => {
    // if (this.state.tbusqueda === "1") this.morosoxSalon();
    // if (this.state.tbusqueda === "2") this.morosoSoloAlumno();
    if (this.state.tbusqueda === "3") this.imprimirBoletin();
    // if (this.state.tbusqueda === "4") this.ventasxMesTabla();
    // if (this.state.tbusqueda === "5") this.alumnosxSeccion();
    // if (this.state.tbusqueda === "6") this.canjesMes();
    // if (this.state.tbusqueda === "7") this.veranitoXEdad();
    // if (this.state.tbusqueda === "8") this.gradoSeccion();
    // if (this.state.tbusqueda === "9") this.gradosinSeccion();
    // if (this.state.tbusqueda === "10") this.contactoRepresentante();
    // if (this.state.tbusqueda === "11") this.datosMeduca();
    // if (this.state.tbusqueda === "12") this.reporteMensualidad();
    // if (this.state.tbusqueda === "13") this.reporteConsultaHabilitar();
    if (this.state.tbusqueda === "14") this.asignacionesEvaluadas();
  };

  asignacionesEvaluadas = () => {
    if (this.state.asignacionesEOn) {
      this.setState({ asignacionesEOn: false });
    } else {
      this.setState({ asignacionesEOn: true });
    }
  };

  reporteConsultaHabilitar = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reportePlanificacionXHabilitar.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  reporteMensualidad = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteMontoMensualidad.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  contactoRepresentante = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteContactoRepresentante.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  datosMeduca = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteMeduca.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  gradosinSeccion = () => {
    let data = new FormData();
    data.append("anoEscolar", this.state.anoEscolar);
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteAlumnosSinSeccion.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  gradoSeccion = () => {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteGradoS.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  veranitoXEdad = () => {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/veranitoXEdad.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  morosoxSalon = () => {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/morosoxSalon.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  morosoSoloAlumno = () => {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/morosoNombres.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  alumnosxSeccion = () => {
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/alumnosxSeccion.php",
      responseType: "arraybuffer",
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  ventasxMesTabla() {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/recibosxMes.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          let xalumno = res.data.variable2;
          this.setState({
            verplanillaOn: true,
            nalumno: xalumno,
          });
        }
      });
    }
  }

  recibosxMes = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/recibosxMes.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  imprimirBoletin = () => {
    if (this.state.idcurso.length > 0 && this.state.trimestre.length > 0 && this.state.anoEscolar.length>0) {
      let data = new FormData();
      data.append("idcurso", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("trimestre", this.state.trimestre);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/boletin.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  canjesMes = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/canjesMes.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  consultaRecibo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  cerrarRecibo = () => {
    if (this.state.verplanillaOn) {
      this.setState({ verplanillaOn: false });
    } else {
      this.setState({ verplanillaOn: true });
    }
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let iprofesor = res.data.variable1;
      iprofesor.map((item, i) => {
        this.setState({ idprofesor: item.variable1 });
        this.buscarCurso(item.variable1);
      });
    });
  };
  componentDidMount() {
    this.cargarFechas();
    this.buscarProfesor();
  }

  ax = () => {};
  render() {
    //console.log(this.state.token)
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago1 col-12">
          <div className="titulo-pago ">
            <p>MENU PARA GENERAR REPORTES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.asignacionesEOn && (
            <div className="flotador1x">
              <div className="flotador1">
                <AsignacionesCorregidas
                  salir={() => {
                    this.setState({ asignacionesEOn: false });
                  }}
                />
              </div>
            </div>
          )}

          {!this.state.tokenVerificarOn && (
            <div className="contenedorp row">
              <div className="titulo-planillai col-12">
                <p>SELECCIONE EL TIPO DE REPORTE A EJECUTAR. </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Buscar Reporte por --</option>
                    <option value="3">Generar Boletines.</option>
                    <option value="14">Asignaciones Evaluadas.</option>
                  </select>
                </div>
              </div>
              {this.state.tbusqueda === "3" && (
                <div className="row">
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- TRIMESTRE --</option>
                      <option value='1'>1er. Trimestre</option>
                      <option value='2'>2do. Trimestre</option>
                      <option value='3'>3er. Trimestre</option>
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "4" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="codigo3"
                      name="codigo3"
                      value={this.state.codigo3}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Mes--</option>
                      {this.state.mes.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      id="codigo4"
                      name="codigo4"
                      value={this.state.codigo4}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>--Año--</option>
                      {this.state.anio.map((item, i) => {
                        return (
                          <option key={item.variable1} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda === "9" && (
                <div className="row">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="anoEscolar"
                      name="anoEscolar"
                      value={this.state.anoEscolar}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- SELECCIONE EL AÑO ESCOLAR --</option>
                      {this.state.ianoEscolar.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable1}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarTipoReporte.bind(this)}
                >
                  Consultar
                </button>
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Reporte);
