import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";

//componentes
import './cxcCli.css';
import GenerarPagos from './pagoCxCCli';
import Alert from '../../modal/alert';

class CxCCli extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identificacionp1:'',
            tokenVerificar:'',
            opnivel:'',
            acudiente:'',
            alumno:'',
            referencia:'',
            banco:'',
            oppago:'',
            nombreper:'',
            fecha:'',
            monto:'',
            btnEnviarOn:false,
            ifechamedi:[],
            listaOn:false,
            mensajealerta:false,
            correo:'',
            meses:[],
            anos:[],
            nalumno:[],
            codigo3:'',
            codigo4:'',
            pantalla1On:false,
            fecharegistro:'',
            verplanillaOn:false,
            tbusqueda:'',
            identificacionp1:'',
            cedula:'',
            habersaldo:'',
            debesaldo:'',
            saldo:'',
            saldoAnterior:'', 
            procesoOn: false,
            flotadorPDOn: false,
            flotadorMOn: false,
            flotadorPCOn: false,
            flotadorVOn: false,
            flotadorREOn:false,
            recibos:[],
            icursos:[],
            igrados:[],
            clientes:[],
            cursoa:'',
            grado:'0',
            modificarSaldoOn:false,
            reversarPagoOn:false,
            idcxc:'',
            montoDebe:'',
            montoAbono:'',
            montoModificar:'',
            idcontrolPago:'',
            status:'',
            datosChequeOn:false,
            concepto:'',
            origen:'',
            modificarMensualidadOn:false,
            mensualidad:'',
            inscripcion:'',
            idmensualidad:'',
            datosCxCCliOn:false,
            descripcion:'',
            montosaldo:'',
            notaCreditoOn:false,
        }
       
      
    }

    limpiar =() =>{
        this.setState({
            identificacionp1:'',
            tokenVerificar:'',
            opnivel:'',
            acudiente:'',
            alumno:'',
            referencia:'',
            banco:'',
            oppago:'',
            nombreper:'',
            fecha:'',
            monto:'',
            btnEnviarOn:false,
            ifechamedi:[],
            nalumno:[],
            listaOn:false,
            mensajealerta:false,
            correo:'',
            codigo3:'',
            codigo4:'',
            pantalla1On:false,
            fecharegistro:'',
            idpago:'',
            verplanillaOn:false,
            tbusqueda:'',
            identificacionp1:'',
            cedula:'',
            habersaldo:'',
            debesaldo:'',
            saldoAnterior:'',
            procesoOn: false,
            flotadorPDOn: false,
            flotadorMOn: false,
            flotadorPCOn: false,
            flotadorVOn: false,
            flotadorREOn:false,
            recibos:[],
            cursoa:'',
            grado:'0',
            modificarSaldoOn:false,
            reversarPagoOn:false,
            idcxc:'',
            montoDebe:'',
            montoAbono:'',
            montoModificar:'',
            idcontrolPago:'',
            status:'',
            concepto:'',
            origen:'',
            modificarMensualidadOn:false,
            mensualidad:'',
            inscripcion:'',
            idmensualidad:'',
            datosCxCCliOn:false,
            descripcion:'',
            montosaldo:'',
            notaCreditoOn:false,
        })
    }

    cargarFechas=()=>{
        let data = new FormData();
        data.append('boton', 'cargarFechas');
        let config = {
            headers: {
                'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php';
        axios.post(xurl, data, config)
        .then(res => {
            //console.log("paso "+res.data.variable2)
            let xifechamedi1 = res.data.variable1
            let xifechamedi2 = res.data.variable2
            this.setState({
                meses:xifechamedi1,
                anos:xifechamedi2,
            });
         })
    } 

    cerrarLista=()=>{
        //console.log(this.state.listaOn)
        if (this.state.listaOn){
            this.setState({
                listaOn:false,
            })
        }
        if (!this.state.listaOn) {
            this.setState({
                listaOn:true,
            })
        }
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }
    
    onChange2(e) {
        let index = e.target.value
        let curso = this.state.icurso[index].variable1
        //let concepto= this.state.icurso[index].variable2
        this.setState({
            curso: curso     
        })
    }

    onChange3(e) {
        //console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value             
        })
        this.calcularEdad(e.target.value)
    }

    onChange4(e) {
        //console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value             
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }
    generarPagoClose = () =>{
        this.setState({ flotadorMOn: false, flotadorPCOn: false, flotadorVOn: false, modificarSaldoOn:false, flotadorPDOn: false,})
        this.buscarCxCCli();
    }
    generarAbonosConcepto = () => {
        this.setState({ flotadorPCOn: true })
    }

    reversarPago = () => {
        
        let data = new FormData();
        data.append('idcxc', this.state.idcxc ); 
        data.append('boton', 'notaCredito');
        let config = {
            headers: {'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxcCli.php';
        axios.post(xurl, data, config)

        .then(res => {
            if (res.data.variable1) {
                this.buscarCxCCli()
                this.setState({
                    notaCreditoOn: false
                });
            }
        })
    

    }

    ejecutarReversarPago = (e) => {
        if(e.length>0){
            let data = new FormData();
            data.append('item', e );
            data.append('boton', 'ejecutarReversarPago');
            let config = {
                headers: {'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php';
            axios.post(xurl, data, config)

            .then(res => {
                if (res.data.variable1) {
                    this.setState({
                        notaCreditoOn: false,
                        mensajealerta: true,
                        tituloM: 'Menu Nota de Credito',
                        cuerpoM: 'Se Realizo la N/C Correctamente, gracias...',                    
                    });
                    this.buscarCXC(this.state.cedula)
                }else{
                    this.setState({
                        notaCreditoOn: false,
                        mensajealerta: true,
                        tituloM: 'Menu Nota de Credito',
                        cuerpoM: 'Se Produjo un Error Verifique, gracias...',                    
                    });

                }
            })
        }

    }

    generarPago=()=>{
        this.setState({ flotadorMOn: true })
    }

    recibosEmitido=()=>{
        if(this.state.flotadorREOn===true){
            this.setState({ flotadorREOn:false, })
        } else {
            let data = new FormData();
            data.append('cedulaAlumno', this.state.cedula );
            data.append('boton', 'consultarReciboG');
            let config = {
                headers: {'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxc.php';
            axios.post(xurl, data, config)

            .then(res => {
                if (res.data.variable1) {
                    let xrecibos = res.data.variable2
                    this.setState({
                        recibos:xrecibos,
                        flotadorREOn: true,
                    });
                }
            })
        }
    }

    consultaRecibo=(e)=>{
        if (e.length>0){
            this.setState({flotadorREOn: false,});
            let data = new FormData();
            data.append('numeroRecibo', e);     
            axios({
                method:'post',
                url:'https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago2.php',
                responseType:'arraybuffer',
                data: data
            })
                .then(function(response) {
                let blob = new Blob([response.data], { type: 'application/pdf' } )
                var fileURL = URL.createObjectURL(blob);
                window.open(fileURL);                
            })   
        }
    }

    aprobarPago =()=>{

        let data = new FormData();
        
        data.append('claveUsuario', this.props.usuario1.password); 
        data.append('idpago', this.state.idpago); 
        data.append('boton', 'aprobarPago');
        let config = {
            headers: {
                'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php';
        axios.post(xurl, data, config)

            .then(res => {
                if (res.data.variable1) {
                    this.setState({
                        btnEnviarOn:true
                    });
                } else {
                    this.setState({
                        btnEnviarOn:false
                    });

                }
            })
    }
    
    consulta=(e)=>{
        
        if (e.length>0) {
            
            let data = new FormData();
            data.append('idAlumno', e); 
            data.append('boton', 'buscarPlanillaIndividual1');
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/maternal.php';
            axios.post(xurl, data, config)

            .then(res => {
                console.log(res.data.variable133)
                if (res.data.variable136) {
                    if (res.data.variable129==='MADRE'){
                        this.setState({
                            alumno: res.data.variable1+' '+res.data.variable2,
                            cedula:res.data.variable4,
                            acudiente:res.data.variable13+' '+res.data.variable14,
                            status:res.data.variable133,
                            verplanillaOn:true,
                            cursoa: res.data.variable39,
                            grado: res.data.variable40,
                        });
                        this.buscarCXC(res.data.variable4)

                   }else{
                        this.setState({
                            alumno: res.data.variable1+' '+res.data.variable2,
                            cedula:res.data.variable4,
                            acudiente:res.data.variable25+' '+res.data.variable26,
                            status:res.data.variable133,
                            verplanillaOn:true,
                        });
                        this.buscarCXC(res.data.variable4)
                   }
                }else{
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Busqueda de Planillas',
                        cuerpoM: 'La cédula que intenta consultar no existe, gracias...',
                    })
                }
            })
        }
    }

    buscarCursos=()=>{
        axios.post('https://institutobilinguelasnaciones.com/terapias/backend/maternal.php/?boton=consultarCursos')
        .then(res => { 
            if (res.data) {               
                let xcursos = res.data
                //console.log(res.data)
                this.setState({
                    icursos :xcursos 
                });
            }  
        })
    }
    buscargrado=(e)=>{
        //console.log(e);
        let data = new FormData();
        data.append('boton', 'buscarGrados2');
        let config = {
            headers: {'Content-Type': 'text/html'}};
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/grado.php';
        axios.post(xurl, data, config)
        .then(res => { 
            let xgrado = res.data
            this.setState({
                igrados: [],
                igrados:xgrado,
            });               
        })
    }

    consultarClientes=()=>{
        //this.setState({loading:true})
        let data = new FormData();
        data.append('boton', 'consultarClienteE'); 
        let config = {
            headers: {
                'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/factura.php';
        axios.post(xurl, data, config)

        .then(res => {
            if (res.data.variable1) {
                //this.consultarProveedores()
                let xrutas = res.data.variable2
                this.setState({
                    clientes:xrutas,
                });
            }
        })
        this.setState({
            //loading:false,
        });

    }


    buscarCxCCli=()=>{
        let data = new FormData();
        data.append('idcliente', this.state.tbusqueda );  
        data.append('boton', 'buscarCXCCli');

        let config = {
            headers: {
              'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxcCli.php';
        axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data.variable3);
                //console.log(res.data.variable2);
                if (res.data.variable1) {
                    let xnalumno = res.data.variable2
                    this.setState({
                        nalumno:xnalumno,
                        saldo:res.data.variable3,
                        cedula :res.data.variable4,
                        nombreper:res.data.variable5,
                        verplanillaOn:true,
                        
                    });
                } else {
                    this.setState({
                        verplanillaOn:true,
                        cedula :res.data.variable4,
                        nombreper:res.data.variable5,
                        nalumno:[],
                    });
                }
            })

    }

    datosCxCCli=()=>{
        if (this.state.datosCxCCliOn===true){
            this.setState({ datosCxCCliOn:false, })
        }else{
            this.setState({ datosCxCCliOn:true, })
        }

    }

    generarCxCCli = () =>{
        let data = new FormData();
        data.append('cedulaAlumno', this.state.cedula ); 
        data.append('descripcion', this.state.descripcion ); 
        data.append('monto', this.state.monto ); 
        data.append('boton', 'generarCxCCli'); 

        let config = {
            headers: {
              'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxcCli.php';
        axios.post(xurl, data, config)
            .then(res => {
                if (res.data.variable1) {
                    this.buscarCxCCli();
                    this.setState({
                        datosCxCCliOn:false,
                        mensajealerta: true,
                        tituloM: 'Menu Generar CxC Clientes.',
                        cuerpoM: 'Se genero la CxC a el cliente, gracias...',
                    });
                    
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Generar CxC Clientes.',
                        cuerpoM: 'Ocurrio un problema para generar la CxC, Verifique por favor.',
                    });

                }
            })

    }

    handleClick=(e)=> {
        //console.log(e.target.id);
        let idCXC=0;
        idCXC=e.target.getAttribute("data-valor");
        //console.log(idCXC);

        e.preventDefault();
        if (idCXC>0){
            //console.log(e.target.id);
            this.descripcionPago(e.target.id)

        }
   }

    proceso=(e,f)=>{        
        if (parseFloat(f)<=0){
            if (this.state.modificarSaldoOn){
                this.setState({modificarSaldoOn:false});
            }else {
                this.setState({
                    modificarSaldoOn:true,
                    idcxc:e,
                });
            }
        }else{
            if (this.state.notaCreditoOn){
                this.setState({notaCreditoOn:false});
            }else {
                this.setState({
                    notaCreditoOn:true,
                    idcxc:e,
                });
            }

        }
    }

    proceso2=()=>{
        if (this.state.notaCreditoOn){
            this.setState({notaCreditoOn:false});
        }else {
            this.setState({
                notaCreditoOn:true,
            });
        }
    }
    proceso3=()=>{
        if (this.state.modificarSaldoOn){
            this.setState({modificarSaldoOn:false});
        }else {
            this.setState({
                modificarSaldoOn:true,
            });
        }
 }

    edoCuenta=()=>{
        if (this.state.cedula.length>0){
            let data = new FormData();       
            data.append('cedulaAlumno', this.state.cedula);
            data.append('alumno', this.state.alumno);
            data.append('cursoa', this.state.cursoa);
            data.append('grado', this.state.grado);
                            
            axios({
                method:'post',
                url:'https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/edoCuenta.php',
                responseType:'arraybuffer',
                data: data
            })
                .then(function(response) {
                let blob = new Blob([response.data], { type: 'application/pdf' } )
                var fileURL = URL.createObjectURL(blob);
                window.open(fileURL);
            })
        }else{
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu de Cuentas por Cobrar',
                cuerpoM: 'Debe haber un Alumno para realizar el Reporte gracias...',
                listaOn:false,
            })
        }    
    }

    datosCheque=()=>{
        if(this.state.datosChequeOn){
            this.setState({datosChequeOn:false})
        }else{
            this.setState({datosChequeOn:true})
        }
    }

    descripcionPago=(e)=>{
        //console.log(e)
        let data = new FormData();
        data.append('idcxc', e);  
        data.append('boton', 'descripcionPago'); 
        let config = {
            headers: {'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxc.php';
        axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data.variable4);
                //console.log(res.data.variable3);
                if (res.data.variable1) {
                    this.setState({
                        datosChequeOn:true,
                        oppago:res.data.variable2,
                        banco:res.data.variable3,
                        referencia:res.data.variable4,
                        concepto:res.data.variable5,
                        monto:res.data.variable6,
                        fecha:res.data.variable7,
                        origen:res.data.variable8,
                        // mensajealerta: true,
                        // tituloM: 'Menu Movimiento de Pago.',
                        // cuerpoM: res.data.variable2,
                    });
                    
                } else {
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Movimiento de Pago.',
                        cuerpoM: res.data.variable2,
                    });

                }
            })

    }    

    cerrarMensualidad=()=>{
        if(this.state.modificarMensualidadOn){
            this.setState({modificarMensualidadOn:false})
        }else{
            this.setState({modificarMensualidadOn:true})
        }
    }

    modificarMensu=()=>{
        let data = new FormData();
        data.append('idmensualidad', this.state.idmensualidad);  
        data.append('mensualidad', this.state.mensualidad);  
        data.append('inscripcion', this.state.inscripcion);  
        data.append('boton', 'modificarMensualidad'); 
        let config = {
            headers: {'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxc.php';
        axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data.variable4);
                //console.log(res.data.variable3);
                if (res.data.variable1) {
                    this.setState({
                        modificarMensualidadOn:false,
                        mensajealerta: true,
                        tituloM: 'Menu Modificar Mensualidad e Inscripcion.',
                        cuerpoM: 'El registro se modifico con exito.',
                     });
                    
                } else {
                    this.setState({
                        modificarMensualidadOn:false,
                        mensajealerta: true,
                        tituloM: 'Menu Modificar Mensualidad e Inscripcion.',
                        cuerpoM: 'No se pudo modificar el registro, verifique por favor.',
                    });

                }
            })

    }    

    modificarMensualidad=()=>{
        let data = new FormData();
        data.append('cedulaAlumno', this.state.cedula);  
        data.append('boton', 'buscarMensualidad'); 
        let config = {
            headers: {'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxc.php';
        axios.post(xurl, data, config)

            .then(res => {
                //console.log(res.data.variable4);
                //console.log(res.data.variable3);
                if (res.data.variable1) {
                    this.setState({
                        modificarMensualidadOn:true,
                        mensualidad:res.data.variable2,
                        inscripcion:res.data.variable3,
                        idmensualidad:res.data.variable4,
                     });
                    
                } else {
                    this.setState({
                        modificarMensualidadOn:false,
                        mensajealerta: true,
                        tituloM: 'Menu Modificar Mensualidad e Inscripcion.',
                        cuerpoM: 'El estudiante no tiene registros de mensualidad, verifique por favor.',
                    });

                }
            })

    }    

    modificarmonto=()=>{

        let data = new FormData();
        data.append('idcxc', this.state.idcxc);
        data.append('monto', this.state.montosaldo); 
        data.append('boton', 'modificarMonto');
        let config = {
            headers: {'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/cxcCli.php';
        axios.post(xurl, data, config)

        .then(res => {
        // console.log('respuesta ',res)
            if (res.data.variable1) {
                this.buscarCxCCli()
                this.setState({
                    modificarSaldoOn:false,
                    montosaldo:'',
                    mensajealerta: true,
                    tituloM: 'Menu Modificar Monto.',
                    cuerpoM: 'Se modifico el monto, Gracias...',
            });
            } else {
                this.setState({
                    mensajealerta: true,
                    tituloM: 'Menu Modificar Monto.',
                    cuerpoM: 'Ocuriio un erro, Verifique por favor.',
                });

            }
        })
    }    

    ax=()=>{

    }

    componentDidMount(){
        //this.buscarCursos();
        this.consultarClientes();
    }

    render(){
        return(
            <div className="principal-pago row">
                <div className="contenedor-cxc col-12">
                    <div className="titulo-cxc ">
                        <p>CUENTAS POR COBRAR CLIENTE EXTERNO. </p>
                    </div>
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    }

                    {!this.state.verplanillaOn && 
                        <div className="contenedorp row">
                            <div className="row">
                                <div className="fechas col-12">                                    
                                    <select id="tbusqueda" name="tbusqueda" value={this.state.tbusqueda} onChange={this.onChange.bind(this)}>
                                        <option>-- Seleccionar Cliente --</option>
                                        {this.state.clientes.map((item, i)=>{
                                                return <option  key={i} value={item.variable1}>{item.variable2+' - '+item.variable3} </option>
                                        })} 
                                    </select>
                                </div>
                            </div>    
                            <div className="xcontenedorcedula1 col-12">
                                <button className="boton" onClick={this.buscarCxCCli.bind(this)}>Consultar</button>
                                <Link to="/"><button className="boton">Salir</button></Link>
                            </div>
                        </div>
                    }

                    {this.state.datosChequeOn ===true &&
                        <div className="flotador1x">
                            <div className="flotador1">
                                <div className="contenedor-titulo row">
                                    <div className="contenedor-cedula col-4">
                                            <label className="labelcedulac">Banco.</label>   
                                        
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="banco"
                                                    id="banco"
                                                    autoComplete="off"
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.state.banco.toUpperCase()}
                                                />  
                                                        
                                    </div>
                                    <div className="contenedor-cedula col-4">
                                            <label className="labelcedulac">Referencia.</label>   
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="referencia"
                                                    id="referencia"
                                                    autoComplete="off"
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.state.referencia.toUpperCase()}
                                                />  
                                    </div>
                                    <div className="contenedor-cedula col-2">
                                            <label className="labelcedulac">Monto a Pagar.</label>   
                                        
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="monto"
                                                    id="monto"
                                                    autoComplete="off"
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.state.monto}
                                                />                
                                    </div>
                                    <div className="row">
                                        <div className="contenedor-cedula col-5">
                                                <label className="labelcedulac">Concepto.</label>   
                                            
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="concepto"
                                                        id="concepto"
                                                        autoComplete="off"
                                                        onChange={this.onChange.bind(this)}
                                                        value={this.state.concepto.toUpperCase()}
                                                    />  
                                                            
                                        </div>
                                        <div className="contenedor-cedula col-3">
                                                <label className="labelcedulac">Fecha Transacción.</label>   
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="fecha"
                                                        id="fecha"
                                                        // readOnly="readonly"
                                                        autoComplete="off"
                                                        onChange={this.onChange.bind(this)}
                                                        value={this.state.fecha}
                                                    />  
                                        </div>
                                        <div className="radio-identificacion col-3">
                                            <div className="radio">
                                                <label className="labelcedulac">Transacción.</label>   
                                                <label className="container">Transferencia.
                                                    <input 
                                                        className='opnivel1' 
                                                        type="radio" 
                                                        value="TRANSFERENCIA"
                                                        name="oppago"
                                                        checked={this.state.oppago === 'TRANSFERENCIA'}
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label className="container">Deposito. 
                                                    <input 
                                                        className='opnivel2' 
                                                        type="radio" 
                                                        value="DEPOSITO"
                                                        name="oppago" 
                                                        checked={this.state.oppago === 'DEPOSITO'}
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            <div className="radio">
                                                <label className="container">Tarjeta. 
                                                    <input 
                                                        className='opnivel2' 
                                                        type="radio" 
                                                        value="TARJETA"
                                                        name="oppago" 
                                                        checked={this.state.oppago === 'TARJETA'} 
                                                        onChange={this.onChange.bind(this)}
                                                    />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>        
                                   
                                    <div className="contenedorcedula1 col-12">
                                        <button className="boton" onClick={this.datosCheque}>Salir</button>
                                    </div>        
                                    
                                </div>                 
                            
                            </div>
                        </div>
                    }

                    {this.state.datosCxCCliOn ===true &&
                        <div className="flotador1xc">
                            <div className="flotador1c">
                                <div className="contenedor-titulo row">
                                    <div className="contenedor-cedula col-8">
                                            <label className="labelcedulac">Descripcion.</label>   
                                        
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="descripcion"
                                                    id="descripcion"
                                                    autoComplete="off"
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.state.descripcion.toUpperCase()}
                                                />  
                                                        
                                    </div>
                                    <div className="contenedor-cedula col-3">
                                            <label className="labelcedulac">Monto.</label>   
                                        
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="monto"
                                                    id="monto"
                                                    autoComplete="off"
                                                    onChange={this.onChange.bind(this)}
                                                    value={this.state.monto}
                                                />                
                                    </div>
                                   
                                    <div className="contenedorcedula1 col-12">
                                        <button className="boton" onClick={this.generarCxCCli}>Generar CxC</button>
                                        <button className="boton" onClick={this.datosCheque}>Salir</button>
                                    </div>        
                                    
                                </div>                 
                            
                            </div>
                        </div>
                    }

                    {this.state.flotadorMOn ===true &&
                        <div className="flotador">
                            <div className="flotador1">
                                <GenerarPagos
                                    cedulaAlumno={this.state.cedula}
                                    cliente={this.state.nombreper}
                                    generarPagoClose={this.generarPagoClose}
                                />
                            </div>
                        </div>
                    }

                    {this.state.modificarSaldoOn ===true &&
                        <div className="flotador1xm">
                            <div className="flotador1m">
                                <div className="contenedor-cedula col-4">
                                    <label className="labelcedulac">Monto A Modificar.</label>   
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="montosaldo"
                                        id="montosaldo"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)} 
                                        value={this.state.montosaldo}
                                    />                
                                </div>
                                <div className="contenedorcedula1 col-12">
                                    <button className="boton" onClick={this.modificarmonto}>Modificar Monto</button>
                                    <button className="boton" onClick={this.proceso3}>Salir</button>
                                </div>        

                            </div>
                        </div>
                    }

                    {this.state.notaCreditoOn ===true &&
                        <div className="flotador1xm">
                            <div className="flotador1m">
                                <div className="contenedorcedula1 col-12">
                                    <button className="boton" onClick={this.reversarPago}>Aplicar N/C</button>
                                    <button className="boton" onClick={this.proceso2}>Salir</button>
                                </div>        

                            </div>
                        </div>
                    }


                    {this.state.flotadorREOn ===true &&
                        <div className="cont-tablamedi row">
                            <div className="tablamedi1 col-11">
                                <div className="cont-titulomedi col-12">
                                    <span className="titulocasos">Listado de Recibos Emitidos</span>
                                    <span className="titulo-cerrarcasos" onClick={this.recibosEmitido.bind(this)}>X</span>
                                </div>
                                {/* <span className="titulo-casos">Datos del Pago</span> */}
                                <div className="cont-listmedi row">
                                    <div className="listmedi col-12">
                                        <table className="tablame" border="1">
                                            <tbody className="evocuerpo1">
                                                {this.state.recibos.map((item, i)=>{
                                                        return<tr className={i%2 === 0 ? "odd" : ""}  key={item.variable1} id="tabla">
                                                            <td className="renglon" width="10" onClick={this.consultaRecibo.bind(this,item.variable1)}>{item.variable2}</td>
                                                            <td className="renglon" width="10" onClick={this.consultaRecibo.bind(this,item.variable1)}>{item.variable1}</td>
                                                        </tr>                                
                                                })}
                                            </tbody>
                                        </table>
                                    </div>    
                                </div>
                            </div>  
                        </div>
                    }

                    {this .state.verplanillaOn &&
                        <div className="row">
                            <div className="titulo-reprecxc ">
                                <p>CLIENTE: {this.state.cedula +" - "+ this.state.nombreper}</p>
                            </div> 
                        </div>
                    }
                    {this .state.verplanillaOn &&
                    <div className="cont-listmedi row">
                        <div className="listcxc col-12">
                            <table className="tablacxc" border="1">
                                <thead>
                                    <tr>
                                        <th className="titucxc1">FECHA</th>
                                        <th className="titucxc2">DESCRIPCIÓN</th>
                                        <th className="titucxc3">DEBE</th>
                                        <th className="titucxc3">HABER</th>
                                        <th className="titucxc3">SALDO</th>
                                    </tr>
                                </thead>
                                {this.state.nalumno.map((item, i)=>{
                                    return<tr className={i%2 === 0 ? "odd" : "odd"}  key={item.variable1} id="tabla" onClick={this.proceso.bind(this,item.variable1, item.variable5)}>
                                            <td className="totalescxc" width="10">{item.variable2}</td>
                                            <td className="renglon" width="10">{item.variable3}</td>
                                            <td className="totalescxc1" width="10" >{item.variable4}</td>
                                            <td className="totalescxc1" width="10" id={item.variable1} data-valor={item.variable5} onContextMenu={this.handleClick}>{item.variable5}</td>
                                            <td className="totalescxc1" width="10">{item.variable6}</td>
                                            </tr>
                                })}
                                <tr className="cont-totalescxc"> 
                                    <td className="totalescxc"></td>
                                    <td className="totalescxc">******* TOTALES *******</td>
                                    <td className="totalescxc1"></td>
                                    <td className="totalescxc1"></td>
                                    {this.state.saldo<0 &&
                                        <td className="totalescxc2">{this.state.saldo}</td>
                                    }
                                    {this.state.saldo>=0 &&
                                        <td className="totalescxc3">{this.state.saldo}</td>
                                    }
                                </tr>
                            </table>
                        </div>    
                    </div>
                    }

                    {this.state.verplanillaOn &&
                           // this.props.usuario1.nivel !=='4' &&
                                <div className="contenedorcedula1 col-12"> 
                                    <button className="boton" onClick={this.datosCxCCli.bind(this)}>Generar CxC</button>
                                    <button className="boton" onClick={this.generarPago.bind(this)}>Generar Pagos</button>
                                    <button className="boton" onClick={this.limpiar.bind(this)}>Limpiar</button>
                                    <Link to="/"><button className="boton">Salir</button></Link>
                                </div>        
                    }

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token
    }
}
export default connect(mapStateToProps,)(CxCCli);