import React, { Component } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import RingLoader from 'react-spinners/ScaleLoader';
 

//componentes
import './generarPagosC.css';
import Alert from '../../modal/alert';

class GenerarPagosC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referencia:'',
            banco:'',
            oppago:'TRANSFERENCIA',
            fecha:'',
            fechaPago:'',
            monto:'',
            btnEnviarOn:false,
            listaOn:false,
            mensajealerta:false,
            cedulaAlumno:'',
            concepto:'',
            concepto2:'',
            iconcepto:[],
            loading:false,
        }
       
      
    }

    limpiar =() =>{
        this.setState({
            referencia:'',
            banco:'',
            oppago:'TRANSFERENCIA',
            fecha:'',
            fechaPago:'',
            monto:'',
            btnEnviarOn:false,
            listaOn:false,
            mensajealerta:false,
            cedulaAlumno:'',
            concepto:'',
            concepto2:'',
            loading:false,
        })
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }
    onChange2(e) {
        this.setState({[e.target.name]: e.target.value})
        let index = e.target.value
        let value = this.state.iconcepto[index].variable1
        let xconcepto= this.state.iconcepto[index].variable2
        this.setState({concepto2: xconcepto})
    }
    
    onChange3(e) {
        //console.log(e.target.value)
        this.setState({
            [e.target.name]: e.target.value             
        })
        this.calcularEdad(e.target.value)
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
    }

    aprobarPago =()=>{
        this.setState({
            loading:true,
        });
        let data = new FormData();
        data.append("anoEscolar", this.props.usuario1.anioE)
        data.append('claveUsuario', this.props.usuario1.password); 
        data.append('cedulaAlumno', this.props.cedulaAlumno); 
        data.append('banco', this.state.banco); 
        data.append('referencia', this.state.referencia); 
        data.append('monto', this.state.monto); 
        data.append('fecha', this.state.fecha); 
        data.append('fechaPago', this.state.fechaPago); 
        data.append('concepto', this.state.concepto2); 
        data.append('oppago', this.state.oppago); 
        data.append('origen', 'PC'); //PC= pago concepto 
        data.append('boton', 'aprobarPago4');
        let config = {
            headers: {
                'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php';
        axios.post(xurl, data, config)

            .then(res => {
               // console.log('respuesta ',res)
                if (res.data.variable1) {
                    this.setState({
                        btnEnviarOn:true,
                        loading:false,
                    });
                } else {
                    this.setState({
                        btnEnviarOn:false,
                        loading:false,
                    });

                }
            })
    }

    buscarConceptos=()=>{

        let data = new FormData();
        data.append('boton', 'buscarConceptos');

        let config = {
            headers: {
              'Content-Type': 'text/html'}
        };
        const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/conceptos.php';
        axios.post(xurl, data, config)

        .then(res => {
            let xiconcepto = res.data.variable2
        if (res.data.variable1) {
                this.setState({
                    iconcepto:xiconcepto,
                });
                
            } else {
                this.setState({
                    iconcepto:[],
                });

            }
        })
    }

    componentDidMount(){
        this.buscarConceptos()
    }


    render(){
        return(
            <div className="principal-pago row">
                <div className="contenedor-pago col-12">
                    <div className="titulo-pago ">
                        <p>PAGOS REALIZADOS POR LA ADMINISTRACION. </p>
                    </div>
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    
                    }
                    {this.state.loading &&
                        <div className="cont-spinner row">
                            <div className='spinner col-6'>
                            <RingLoader
                                clasName="spinner"
                                sizeUnit={"px"}
                                size={160}
                                width={19}
                                radius={20}
                                height={160}
                                color={'#48e120'}
                                loading={this.state.loading}
                            />
                            </div>
                        </div>
                    }
                    <div className="contenedor-titulo row">
                        <div className="contenedor-cedula col-4">
                                <label className="labelcedulac">Conceptos.</label>   
                                <select id="concepto" name="concepto" value={this.state.concepto} onChange={this.onChange2.bind(this)}>
                                        <option>-- Conceptos --</option>
                                        {this.state.iconcepto.map((item, i)=>{
                                                return <option  key={i} value={i}>{item.variable2}</option>
                                        })}
                                    </select>
                        </div>
                        <div className="contenedor-cedula col-4">
                                <label className="labelcedulac">Referencia.</label>   
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="referencia"
                                        id="referencia"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.referencia.toUpperCase()}
                                    />  
                        </div>
                        <div className="contenedor-cedula col-2">
                                <label className="labelcedulac">Monto a Pagar.</label>   
                            
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="monto"
                                        id="monto"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.monto}
                                    />                
                        </div>
                        <div className="row">
                        <div className="contenedor-cedula col-2">
                                <label className="labelcedulac">Fecha Transacción.</label>   
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="fecha"
                                        id="fecha"
                                        // readOnly="readonly"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.fecha}
                                    />  
                        </div>
                        <div className="contenedor-cedula col-2">
                                <label className="labelcedulac">Fecha de Pago.</label>   
                                    <input
                                        className="form-control"
                                        type="date"
                                        name="fechaPago"
                                        id="fechaPago"
                                        // readOnly="readonly"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                        value={this.state.fechaPago}
                                    />  
                        </div>
                        </div>        
                        {!this.state.btnEnviarOn &&
                        <div className="contenedorcedula1 col-12">
                            <button className="boton" onClick={this.aprobarPago.bind(this)}>Aprobar Pago</button>
                            <button className="boton" onClick={this.limpiar.bind(this)}>Limpiar</button>
                            <button className="boton" onClick={this.props.generarPagoClose}>Salir</button>
                        </div>        
                        }
                    </div>                 
                    
                    {this.state.btnEnviarOn &&
                        <div className="titulo-pago col-12">
                            <p>EL PROCESO SE COMPLETO DE FORMA EXITOSA.</p>
                            <button className="boton" onClick={this.props.generarPagoClose}>Continuar</button>
                        </div>
                    }    
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token
    }
}
export default connect(mapStateToProps,)(GenerarPagosC);