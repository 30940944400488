import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
//componentes
import "./habitoActitudes.css";
import Alert from "../../modal/alert";
import PublicarNotas from "../publicarNotas/index";

class EvaluacionAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idcurso: "",
      descripcionE: "",
      inputValue: "",
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      anoEscolar: moment().format("YYYY"),
      idtareas: "",
      nombreEvaluacion: "",
      nota: "",
      trimestre: "",
      idarchivos: "",
      ianoEscolar: [moment().format("YYYY")],
      imaterias: [],
      icursos: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      iactividades: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      activarNotaOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idmateria: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      inputValue: "",
      descripcionE: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      idtareas: "",
      nombreEvaluacion: "",
      nota: "",
      idarchivos: "",
      ievaluaciones: [],
      listaOn: false,
      modificarOn: false,
      activarNotaOn: false,
    });
  };

  limpiar2(e) {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idmateria: "",
      idcurso: "",
      idplanE: "",
      cedulaAlumno: "",
      nombreEvaluacion: "",
      nota: "",
      trimestre: "",
      idarchivos: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
    });
  }
  limpiar3() {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "trimestre") {
      this.setState({ descripcionE: "" });
      this.buscarTrimestre(e.target.value);
    }
    if (index === "idcurso") this.buscarPlanE2(e.target.value);
    if (index === "idprofesor") this.buscarCurso(e.target.value);
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.imaterias.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : 0;
    //console.log(index)
    let xcantidadE = this.state.imaterias[index].variable3;
    let xidmateria = this.state.imaterias[index].variable4;
    //console.log("cantidad: "+xcantidadE)
    //console.log("idmateria: "+xidmateria)
    this.consultarActividades(xidmateria);
    this.setState({
      [e.target.name]: e.target.value,
      cantidadE: xcantidadE,
      idmateria: xidmateria,
    });
  };
  onChange4(e) {
    if (this.props.usuario1.nivel === "3") {
      let element = e.target;
      if (element.checked) {
        this.setState({ [e.target.name]: e.target.value });
        // console.log(e.target.value);
        // console.log(e.target.name);
        let data = new FormData();
        data.append("idcurso", this.state.idcurso);
        data.append("idprofesor", this.state.idprofesor);
        data.append("trimestre", this.state.trimestre);
        data.append("anoEscolar", this.state.anoEscolar);
        data.append("tipoHabitos", e.target.name);
        data.append("descripcion", e.target.value);
        data.append("cedulaAlumno", this.state.cedulaAlumno);
        data.append("boton", "guardarHabitos");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/habitoActitudes.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            if (res.data.variable1) {
              this.consultar();
            } else {
              this.setState({});
            }
          });
      }
    }
  }

  buscarTrimestre = (e) => {
    let data = new FormData();
    data.append("codigo", e);
    data.append("boton", "consultarActivo");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/activarTrimestre.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
      } else {
        this.setState({
          trimestre: "",
          mensajealerta: true,
          tituloM: "Menu Control de Asistencia.",
          cuerpoM:
            "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
        });
      }
    });
  };

  consultar() {
    this.limpiar3();
    let data = new FormData();
    data.append("idcurso", this.state.idcurso);
    data.append("idprofesor", this.state.idprofesor);
    data.append("trimestre", this.state.trimestre);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("boton", "buscarHabito");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/habitoActitudes.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xialumno = res.data.variable2;
          xialumno.map((item, i) => {
            if (item.variable1 === "responsabilidad")
              this.setState({ responsabilidad: item.variable2 });
            if (item.variable1 === "ordenyaseo")
              this.setState({ ordenyaseo: item.variable2 });
            if (item.variable1 === "organizacion")
              this.setState({ organizacion: item.variable2 });
            if (item.variable1 === "confianza")
              this.setState({ confianza: item.variable2 });
            if (item.variable1 === "iniciativa")
              this.setState({ iniciativa: item.variable2 });
            if (item.variable1 === "cooperacion")
              this.setState({ cooperacion: item.variable2 });
            if (item.variable1 === "respeto")
              this.setState({ respeto: item.variable2 });
          });
        } else {
          this.setState({
            trimestre: "",
            idcurso: "",
            cedulaAlumno: "",
            mensajealerta: true,
            tituloM: "Menu Control de Asistencia.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  }

  modificar = () => {
    if (this.state.idmateria.length > 0 && this.state.idcurso.length > 0) {
      let data = new FormData();
      data.append("idmaestroE", this.state.idplanEvaluacion);
      data.append("cantidad", this.state.cantidadE);
      data.append("descripcion", this.state.descripcionE);
      data.append("boton", "modificarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  guardar = () => {
    if (this.state.nota > 0 && this.state.nota <= 5) {
      if (
        this.state.idmateria.length > 0 &&
        this.state.idplanE.length > 0 &&
        this.state.idcurso.length > 0 &&
        this.state.cedulaAlumno.length > 0 &&
        this.state.descripcionE.length > 0 &&
        this.state.trimestre.length > 0 &&
        this.state.nota.length > 0
      ) {
        let data = new FormData();
        data.append("cedulaAlumno", this.state.cedulaAlumno);
        data.append("idprofesor", this.state.idprofesor);
        data.append("idplanE", this.state.idplanE);
        data.append("idmateria", this.state.idmateria);
        data.append("idgrado", this.state.idcurso);
        data.append("idtituloNota", this.state.descripcionE);
        data.append("nota", this.state.nota);
        data.append("fecha", this.state.fechaE);
        data.append("cantidadE", this.state.cantidadE);
        data.append("idactividad", this.state.idtareas);
        data.append("anoEscolar", this.state.anoEscolar);
        data.append("trimestre", this.state.trimestre);
        data.append("nombreEvaluacion", this.state.nombreEvaluacion);

        data.append("boton", "guardarEvaluacion");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            if (res.data.variable1) {
              //this.limpiar2();
              this.buscarEvaluaciones(this.state.cedulaAlumno);
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Asignar Notas.",
                cuerpoM: res.data.variable2,
              });
            } else {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Asignar Notas.",
                cuerpoM: res.data.variable2,
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe seleccionar un Grado y una Materia.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "El valor de la Nota debe estar entre 1 y 5.",
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "eliminarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Notas.",
            cuerpoM: res.data.variable2,
          });
          //this.limpiar();
          this.buscarEvaluaciones(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Notas.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM:
          "Debe existir un registro para poder eliminar la MAteria Asignada.",
      });
    }
  };

  buscarEvaluaciones = (e) => {
    if (
      e.length > 0 &&
      this.state.idprofesor.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.idcurso.length > 0
    ) {
      let data = new FormData();
      data.append("cedulaAlumno", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idplanE", this.state.idplanE);
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idcurso);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "buscarEvaluaciones");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xievaluaciones = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              ievaluaciones: xievaluaciones,
            });
          } else {
            this.setState({
              ievaluaciones: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM:
          "Debe Seleccionar un Profesor, un Grado y una Materia, gracias.",
      });
    }
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    let data = new FormData();
    data.append("idmateria", this.state.idmateria);
    data.append("idgrado", this.state.idcurso);
    data.append("trimestre", this.state.trimestre);
    data.append("iddescripcion", e);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarMaestroE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          cantidadE: res.data.variable2,
          idplanE: res.data.variable3,
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: res.data.variable2,
        });
      }
    });
  };

  buscarPlanE2 = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE2");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          let xialumnos = res.data.variable3;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              ialumnos: xialumnos,
              idmateria: "",
              cedulaAlumno: "",
              descripcionE: "",
              trimestre: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  buscarSalones = () => {
    let data = new FormData();
    data.append("boton", "buscarSalones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;

        this.setState({
          icursos: xicursos,
        });
      });
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  buscarProfesor = () => {
    if (this.props.usuario1.nivel === "3") {
      let form = new FormData();
      form.append("clave", this.props.usuario1.password);
      form.append("boton", "buscarPersonal3");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
      axios.post(xurl, form, config).then((res) => {
        let iprofesor = res.data.variable1;
        iprofesor.map((item, i) => {
          this.setState({ idprofesor: item.variable1 });
          this.buscarCurso(item.variable1);
        });
      });
    }
    if (this.props.usuario1.nivel !== "3") {
      let form = new FormData();
      form.append("boton", "buscarSoloprofesor");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/nomina2.php";
      axios.post(xurl, form, config).then((res) => {
        let iprofesor = res.data.variable1;
        this.setState({ iprofesor: iprofesor });
      });
    }
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  notasTrabajo = (e) => {
    if (this.state.activarNotaOn) {
      this.setState({ activarNotaOn: false, idarchivos: "" });
    } else {
      this.setState({ activarNotaOn: true, idarchivos: e });
      //console.log(e);
    }
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  componentDidMount() {
    this.buscarDE();
    this.buscarProfesor();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>HABITOS Y ACTITUDES.</p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.activarNotaOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <PublicarNotas
                  idarchivos={this.state.idarchivos}
                  handleClose={this.notasTrabajo}
                />
              </div>
            </div>
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              {this.props.usuario1.nivel !== "3" && (
                <div className="contenedorcedula1f col-4">
                  <select
                    id="idprofesor"
                    name="idprofesor"
                    value={this.state.idprofesor}
                    onChange={this.onChange2.bind(this)}
                  >
                    <option>-- Seleccionar Profesor --</option>
                    {this.state.iprofesor.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
              <div className="contenedorcedula1f col-2">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>
              <div className="contenedorcedula1 col-3">
                <select
                  id="cedulaAlumno"
                  name="cedulaAlumno"
                  value={this.state.cedulaAlumno}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Alumno --</option>
                  {this.state.ialumnos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-12">
                  <button className="boton" onClick={this.consultar.bind(this)}>
                    Consultar
                  </button>
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">HÁBITOS Y ACTITUDES</th>
                    <th className="titucxc5">X – No satisface</th>
                    <th className="titucxc5">S – Satisfactorio</th>
                    <th className="titucxc5">R – Regular</th>
                  </tr>
                </thead>
                <tr className="odd" key="1" id="tabla">
                  <td className="totalescxcx">RESPONSABILIDAD</td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="responsabilidad"
                      id="responsabilidad"
                      value="X"
                      checked={this.state.responsabilidad === "X"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="responsabilidad"
                      id="responsabilidad"
                      value="S"
                      checked={this.state.responsabilidad === "S"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="responsabilidad"
                      id="responsabilidad"
                      value="R"
                      checked={this.state.responsabilidad === "R"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                </tr>
                <tr className="odd" key="1" id="tabla">
                  <td className="totalescxcx">ORDEN Y ASEO</td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="ordenyaseo"
                      id="ordenyaseo"
                      value="X"
                      checked={this.state.ordenyaseo === "X"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="ordenyaseo"
                      id="ordenyaseo"
                      value="S"
                      checked={this.state.ordenyaseo === "S"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="ordenyaseo"
                      id="ordenyaseo"
                      value="R"
                      checked={this.state.ordenyaseo === "R"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                </tr>
                <tr className="odd" key="1" id="tabla">
                  <td className="totalescxcx">ORGANIZACIÓN DEL TRABAJO</td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="organizacion"
                      id="organizacion"
                      value="X"
                      checked={this.state.organizacion === "X"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="organizacion"
                      id="organizacion"
                      value="S"
                      checked={this.state.organizacion === "S"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="organizacion"
                      id="organizacion"
                      value="R"
                      checked={this.state.organizacion === "R"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                </tr>
                <tr className="odd" key="1" id="tabla">
                  <td className="totalescxcx">AUTOD. Y CONF. EN SÍ MISMO</td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="confianza"
                      id="confianza"
                      value="X"
                      checked={this.state.confianza === "X"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="confianza"
                      id="confianza"
                      value="S"
                      checked={this.state.confianza === "S"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="confianza"
                      id="confianza"
                      value="R"
                      checked={this.state.confianza === "R"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                </tr>
                <tr className="odd" key="1" id="tabla">
                  <td className="totalescxcx">INICIATIVA</td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="iniciativa"
                      id="iniciativa"
                      value="X"
                      checked={this.state.iniciativa === "X"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="iniciativa"
                      id="iniciativa"
                      value="S"
                      checked={this.state.iniciativa === "S"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="iniciativa"
                      id="iniciativa"
                      value="R"
                      checked={this.state.iniciativa === "R"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                </tr>
                <tr className="odd" key="1" id="tabla">
                  <td className="totalescxcx">COOPERACIÓN</td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="cooperacion"
                      id="cooperacion"
                      value="X"
                      checked={this.state.cooperacion === "X"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="cooperacion"
                      id="cooperacion"
                      value="S"
                      checked={this.state.cooperacion === "S"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="cooperacion"
                      id="cooperacion"
                      value="R"
                      checked={this.state.cooperacion === "R"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                </tr>
                <tr className="odd" key="1" id="tabla">
                  <td className="totalescxcx">RESPETO A LA PROPIEDAD AJENA</td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="respeto"
                      id="respeto"
                      value="X"
                      checked={this.state.respeto === "X"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="respeto"
                      id="respeto"
                      value="S"
                      checked={this.state.respeto === "S"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                  <td className="totalescxcx" width="10">
                    <input
                      type="radio"
                      className="opvacunacionsi"
                      name="respeto"
                      id="respeto"
                      value="R"
                      checked={this.state.respeto === "R"}
                      onChange={this.onChange4.bind(this)}
                    />
                  </td>
                </tr>
                );
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(EvaluacionAction);
