import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { BuscarProfesor } from "../../API/Core";
import FilterField from "./FilterField";
import { Row, Col } from "reactstrap";
import ListaChats from "./ListaChats";
import Mensajes from "./Mensajes";
//componentes
function Chat(props) {
  const [IdProfesor, setIdProfesor] = React.useState(null);
  const [chatSelected, setChatSelected] = React.useState(null);
  const [refreshChats, setRefreshChats] = React.useState(0);

  const InitData = async () => {
    try {
      let response = await BuscarProfesor(props.usuario1.password);
      setIdProfesor(response.data.variable1[0].variable1);
    } catch (err) {
      console.log(err);
    }
  };

  const Seleccionar_Alumno = (alumno) => {
    if (alumno !== null) {
      setChatSelected({
        id: null,
        profesor: String(IdProfesor),
        alumno: alumno.cedula,
        nombre_alumno: alumno.nombre,
        materia: alumno.materia,
      });
    }
  };

  const Seleccionar_Chat = (data) => {
    setChatSelected(data);
  };

  React.useEffect(() => {
    InitData();
  }, []);

  return (
    <div>
      {IdProfesor !== null && (
        <Container>
          <Sidebar>
            <Row className="mt-3">
              <Col>
                {
                  <FilterField
                    id_profesor={IdProfesor}
                    onChange={Seleccionar_Alumno}
                  />
                }
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <ListaChats
                  refreshChats={refreshChats} 
                  selected={chatSelected}
                  id_profesor={IdProfesor}
                  onClick={Seleccionar_Chat}
                />
              </Col>
            </Row>
          </Sidebar>
          <MessagesContent>
            <Mensajes
              updatListChats={() => setRefreshChats(refreshChats + 1)}
              selected={chatSelected}
            />
          </MessagesContent>
        </Container>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(Chat);

const Container = styled.div`
  height: 80vh;
`;

const Sidebar = styled.aside`
  width: 275px;
  border-right: solid 1px #eee;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  float: left;
`;

const MessagesContent = styled.section`
  width: calc(100% - 275px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  float: left;
`;
