import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./facturacion.css";
import FeatherIcon from "feather-icons-react";
import CxPAbonos from "../cxpAbonos/cxpAbonos";
import Alert from "../../modal/alert";

class Facturacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcedulaP: "",
      fecha: moment().format("YYYY-MM-DD"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      externoOn: false,
      factuPendientesOn: false,
      clientes: [],
      facturasC: [],
      facturasCRe: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      otroProducto: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      verplanillaOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
    };
  }

  limpiar = () => {
    this.setState({
      opcedulaP: "",
      fecha: moment().format("YYYY-MM-DD"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      factuPendientesOn: false,
      clientes: [],
      facturasC: [],
      facturasCRe: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      externoOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      otroProducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      verplanillaOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
    });
  };

  limpiar2 = () => {
    this.setState({
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      otroProducto: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
    });
  };

  cerrarLista2 = () => {
    //console.log(this.state.listaOn)
    if (this.state.factuPendientesOn) {
      this.setState({
        factuPendientesOn: false,
      });
    }
    if (!this.state.factuPendientesOn) {
      this.setState({
        factuPendientesOn: true,
      });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    if (this.state.oppago === "INTERNOS") {
      let index = e.target.value;
      let ceduA = this.state.clientes[index].variable2;
      this.setState({ cedulaAlumno: ceduA });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarClientesI(e.target.value);
    //this.consultarFactura(e.target.value)
    //console.log(e.target.value)
  }

  onChange1(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarProducto(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      loading: true,
      [e.target.name]: e.target.value,
    });
    this.consultarClientes("I");
    this.setState({ loading: false, verplanillaOn: true });
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      // loading: true,
      [e.target.name]: e.target.value,
      externoOn: true,
    });
  }

  externoCli = () => {
    if (this.state.externoOn) {
      this.setState({ externoOn: false });
      this.limpiar();
    } else {
      this.setState({ externoOn: true });
    }
  };

  externoCliente = () => {
    if (this.state.tipoFac === "PRODUCTO") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
    if (this.state.tipoFac === "SERVICIOS") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  generarPagoClose = () => {
    this.setState({ modificarSaldoOn: false });
  };

  activarFactura = () => {
    if (this.state.facturacionOn) {
      this.setState({ facturacionOn: false, datosChequeOn: false });
    } else {
      this.consultarItenFactura();
      this.consultarInventario();
      this.setState({
        facturacionOn: true,
      });
    }
  };

  consultarClientes = (e) => {
    //this.setState({loading:true})
    let data = new FormData();
    if (e === "I") data.append("boton", "consultarClienteI");
    if (e === "E") data.append("boton", "consultarClienteE");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            clientes: xrutas,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarClientesI = (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("idcliente", e);
    data.append("tipoCliente", this.state.opcedulaP);
    data.append("boton", "clienteI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          this.setState({
            cliente: res.data.variable2,
            ruc: res.data.variable3,
            fecha: res.data.variable4,
            cedulaAlumno: res.data.variable5,
          });
          this.consultarFactura(res.data.variable3);
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFactura = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("codigoCliente", e);
    data.append("boton", "consultarFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasC: xfacturasP,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProducto = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idproducto: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidadActual: res.data.variable4,
            costo: res.data.variable5,
            precio: res.data.variable6,
            impuesto: res.data.variable8,
            cantidad: "1",
          });
        }
      });
  };

  consultarItenFactura = async () => {
    this.setState({
      itenFacturas: [],
      subtotal: "",
      timpuesto: "",
      totalg: "",
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("boton", "consultarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            itenFacturas: xrutas,
            subtotal: res.data.variable3,
            timpuesto: res.data.variable4,
            totalg: res.data.variable5,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  facturarIten = () => {
    let paso = 0;
    // if (
    //   parseFloat(this.state.cantidad) > parseFloat(this.state.cantidadActual)
    // ) {
    //   paso = 1;
    //   this.setState({
    //     mensajealerta: true,
    //     tituloM: "Menu Facturacion.",
    //     cuerpoM:
    //       "La cantidad que intenta facturar es mayor que la encontrada en el inventario, la cantidad actual es de:" +
    //       this.state.cantidadActual,
    //   });
    // }
    if (parseFloat(this.state.precio) < parseFloat(this.state.costo)) {
      paso = 1;
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El precio es menor que el costo, Verifique por Favor.",
      });
    }

    if (paso === 0) {
      let data = new FormData();
      data.append("idproducto", this.state.idproducto);
      data.append(
        "nombreProducto",
        this.state.nombrepro + " " + this.state.otroProducto
      );
      data.append("cantidad", this.state.cantidad);
      data.append("precioU", this.state.precio);
      data.append("itebi", this.state.itebi);
      data.append("precioT", this.state.precioT);
      data.append("itebi", this.state.itebi);
      data.append("precioT", this.state.precioT);
      data.append("impuesto", this.state.impuesto);
      data.append("cantidadActual", this.state.cantidadActual);
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("boton", "facturarIten");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultarItenFactura();
            this.limpiar2();
          }
        });
    }
  };

  eliminarItenFactura = (e) => {
    let data = new FormData();
    data.append("iditenfactura", e);
    data.append("boton", "eliminarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura();
        }
      });
  };

  guardarFactura = () => {
    if (
      this.state.opcedulaP != "INTERNOS" &&
      parseFloat(this.state.totalg) > 0
    ) {
      let data = new FormData();
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El Monto a Pagar No Coincide con el Total Facturado.",
      });
    }
  };

  guardarFactura1 = () => {
    if (this.state.opcedulaP === "INTERNOS") {
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("oppago", this.state.oppago);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
    } else {
      this.setState({
        datosChequeOn: true,
        monto: this.state.totalg,
      });
    }
  };

  consultarFacturasI = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      data.append("tipoFact", this.state.tipoFac);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago3.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  eliminarFacturasI = (e) => {
    let data = new FormData();
    data.append("nfactura", e);
    data.append("boton", "notaCredito");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/factura.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar2();
          this.consultarFactura(this.state.ruc);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: "La Factura se Elimino con Exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM:
              "Ocurrio un error con la Eliminacion de la Factura, verifique por favor.",
          });
        }
      });
    console.log(e);
  };

  reporteLibroV = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/libroVentas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  libroV = () => {
    if (this.state.libroVOn) {
      this.setState({ libroVOn: false });
    } else {
      this.setState({ libroVOn: true });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (this.state.tbusqueda1 === "1") {
      this.buscarPlanillaIndividual();
    }
    if (this.state.tbusqueda1 === "2") {
      this.buscarPorNombres();
    }
  };

  consultarFacturaRe = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("ruc", this.state.ruc);
    data.append("boton", "consultarFacturaGeneralPendiente");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasCRe: xfacturasP,
            factuPendientesOn: true,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFacturasIRe = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      data.append("tipoFact", this.state.tipoFac);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago4.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  pasarItenFactura = async (e) => {
    let data = new FormData();
    data.append("nFactura", e);
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("boton", "pasarItenFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Aprobar Factura",
            cuerpoM: "Los datos de la factura fueron incluidos",
            factuPendientesOn: false,
          });
          this.activarFactura();
        }
      });
    this.setState({
      //loading:false,
    });
  };

  eliminarFacturasIRe = (e) => {
    let data = new FormData();
    data.append("nfactura", e);
    data.append("boton", "notaCredito");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: "La Factura se Elimino con Exito.",
            factuPendientesOn:false,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM:
              "Ocurrio un error Verifique que la Factura No este Aprobada.",
          });
        }
      });
    console.log(e);
  };

  componentDidMount() {
    this.consultarItenFactura();
    this.consultarInventario();
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU DE FACTURACION. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.libroVOn === true && (
            <div className="flotador3xM">
              <div className="flotador1">
                <div className="titulo-cxc ">
                  <p>LIBRO DE VENTAS. </p>
                </div>
                <div className="fechas col-12">
                  <select
                    id="codigo3"
                    name="codigo3"
                    value={this.state.codigo3}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Mes--</option>
                    {this.state.mes.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    id="codigo4"
                    name="codigo4"
                    value={this.state.codigo4}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>--Año--</option>
                    {this.state.anio.map((item, i) => {
                      return (
                        <option key={item.variable1} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="fechasL col-12">
                  <button className="boton" onClick={this.reporteLibroV}>
                    IMPRIMIR
                  </button>
                  <button className="boton" onClick={this.libroV}>
                    SALIR
                  </button>
                </div>
              </div>
            </div>
          )}

          {this.state.verplanillaOn && (
            <div className="flotador4xfFf">
              <div className="flotador1">
                <div className="contenedorp row">
                  <div className="titulo-planillai col-12">
                    <p>
                      SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                      CORRESPONDIENTA A SU SELECCIÓN.{" "}
                    </p>
                  </div>
                  <div className="row">
                    <div className="fechas col-12">
                      <select
                        id="tbusqueda1"
                        name="tbusqueda1"
                        value={this.state.tbusqueda1}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Buscar por --</option>
                        <option value="1">CEDULA/PASAPORTE</option>
                        <option value="2">NOMBRE ESTUDIANTE</option>
                      </select>
                    </div>
                  </div>

                  <div className="contenedorcedula1 row">
                    <input
                      className="form-control"
                      type="text"
                      name="identificacionp1"
                      id="identificacionp1"
                      autoComplete="off"
                      placeholder={
                        this.state.tbusqueda1 === "1"
                          ? "Identificación Alumno."
                          : "Nombre Alumno."
                      }
                      maxLength="300"
                      onChange={this.onChange.bind(this)}
                      value={this.state.identificacionp1.toUpperCase()}
                    />
                  </div>

                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className="boton"
                      onClick={this.buscarPlanilla.bind(this)}
                    >
                      Consultar
                    </button>
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                  </div>

                  {this.state.listaOn && (
                    <div className="cont-tablamedi row">
                      <div className="tablamedi1 col-11">
                        <div className="cont-titulomedi col-12">
                          <span className="titulocasos">
                            Listado de estudiantes
                          </span>
                          <span
                            className="titulo-cerrarcasos"
                            onClick={this.cerrarLista.bind(this)}
                          >
                            X
                          </span>
                        </div>
                        {/* <span className="titulo-casos">Datos del Pago</span> */}
                        <div className="cont-listmedi row">
                          <div className="listmedi col-12">
                            <table className="tablame" border="1">
                              <tbody className="evocuerpo1">
                                {this.state.nalumno.map((item, i) => {
                                  return (
                                    <tr
                                      className={i % 2 === 0 ? "odd" : ""}
                                      key={item.variable1}
                                      id="tabla"
                                    >
                                      <td
                                        className="renglon"
                                        width="10"
                                        onClick={this.consulta.bind(
                                          this,
                                          item.variable1
                                        )}
                                      >
                                        {item.variable2}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {this.state.facturacionOn === true && (
            <div className="flotador3xf">
              <div className="flotador1f">
                <div className="cont-factura col-12">
                  <div className="titulo-cxc ">
                    {this.state.tipoFac === "PRODUCTO" ? (
                      <p>FACTURACION DE PRODUCTOS.</p>
                    ) : (
                      <p>FACTURACION DE SERVICIOS.</p>
                    )}
                  </div>
                  <div className="cont-campos">
                    <div className="campos-factura">
                      <div className="contenedorcedula1f1 col-4">
                        <select
                          id="idproducto"
                          name="idproducto"
                          value={this.state.idproducto}
                          onChange={this.onChange1.bind(this)}
                        >
                          {this.state.tipoFac === "SERVICIOS" ? (
                            <option>-- Seleccionar Servicios --</option>
                          ) : (
                            <option>-- Seleccionar Producto --</option>
                          )}

                          {this.state.inventario.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="otroProducto"
                          id="otroProducto"
                          // readOnly="readonly"
                          autoComplete="off"
                          placeholder="Descripcion."
                          maxLength="25"
                          onChange={this.onChange.bind(this)}
                          value={this.state.otroProducto.toUpperCase()}
                        />
                      </div>
                      <div className="contenedorcedula1f col-1">
                        <input
                          className="form-control"
                          type="text"
                          name="cantidad"
                          id="cantidad"
                          // readOnly="readonly"
                          autoComplete="off"
                          placeholder={
                            this.state.tipoFac === "SERVICIOS"
                              ? "Item."
                              : "Cantidad."
                          }
                          maxLength="5"
                          onChange={this.onChange.bind(this)}
                          value={this.state.cantidad.toUpperCase()}
                        />
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="precio"
                          id="precio"
                          // readOnly="readonly"
                          autoComplete="off"
                          placeholder={
                            this.state.tipoFac === "SERVICIOS"
                              ? "Sub-Total."
                              : "Precio."
                          }
                          maxLength="7"
                          onChange={this.onChange.bind(this)}
                          value={this.state.precio.toUpperCase()}
                        />
                      </div>
                      {this.state.tipoFac === "SERVICIOS" && (
                        <div className="contenedorcedula1f col-1">
                          <input
                            className="form-control"
                            type="text"
                            name="itebi"
                            id="itebi"
                            // readOnly="readonly"
                            autoComplete="off"
                            placeholder="Itebi."
                            maxLength="5"
                            onChange={this.onChange.bind(this)}
                            value={this.state.itebi.toUpperCase()}
                          />
                        </div>
                      )}
                      {this.state.tipoFac === "SERVICIOS" && (
                        <div className="contenedorcedula1f col-2">
                          <input
                            className="form-control"
                            type="text"
                            name="precioT"
                            id="precioT"
                            // readOnly="readonly"
                            autoComplete="off"
                            placeholder="Total."
                            maxLength="7"
                            onChange={this.onChange.bind(this)}
                            value={this.state.precioT.toUpperCase()}
                          />
                        </div>
                      )}
                    </div>
                    <div className="cont-totales">
                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.facturarIten}
                      >
                        Agregar Items
                      </button>
                      <button
                        className={
                          parseFloat(this.state.totalg) > 0 &&
                          this.state.ruc.length > 0
                            ? "boton"
                            : "botonOf"
                        }
                        onClick={
                          parseFloat(this.state.totalg) > 0 &&
                          this.state.ruc.length > 0
                            ? this.datosCheque.bind(this)
                            : ""
                        }
                      >
                        Pagar
                      </button>
                      {this.state.opcedulaP === "EXTERNOS" && (
                        <button
                          className={
                            parseFloat(this.state.totalg) > 0 &&
                            this.state.monto.length > 0
                              ? "boton"
                              : "botonOf"
                          }
                          onClick={
                            parseFloat(this.state.totalg) > 0 &&
                            this.state.monto.length > 0
                              ? this.guardarFactura
                              : ""
                          }
                        >
                          Guardar
                        </button>
                      )}
                      {this.state.opcedulaP === "INTERNOS" && (
                        <button
                          className={
                            parseFloat(this.state.totalg) > 0
                              ? "boton"
                              : "botonOf"
                          }
                          onClick={
                            parseFloat(this.state.totalg) > 0
                              ? this.guardarFactura1
                              : ""
                          }
                        >
                          Guardar
                        </button>
                      )}
                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.activarFactura}
                      >
                        Salir
                      </button>
                    </div>
                  </div>
                  <div className="cont-listmedi row">
                    <div className="listcxc col-12">
                      <table className="tablacxc" border="1">
                        <thead>
                          <tr>
                            <th className="titucxc2">PRODUCTO</th>
                            <th className="titucxc1">CANTIDAD</th>
                            <th className="titucxc1">P/UNITARIO</th>
                            <th className="titucxc1">ITBMS</th>
                            <th className="titucxc1">TOTAL</th>
                            <th className="titucxc1">
                              <FeatherIcon
                                icon="delete"
                                size="30px"
                                stroke="#999"
                                color="red"
                              />
                            </th>
                          </tr>
                        </thead>
                        {this.state.itenFacturas.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : "odd"}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td className="totalescxc" width="10">
                                {item.variable2}
                              </td>
                              <td className="renglon" width="10">
                                {item.variable3}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable4}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable5}
                              </td>
                              <td className="totalescxc1" width="10">
                                {item.variable6}
                              </td>
                              <td className="titucxc3" width="10">
                                <FeatherIcon
                                  icon="delete"
                                  size="30px"
                                  stroke="#999"
                                  color="red"
                                  onClick={this.eliminarItenFactura.bind(
                                    this,
                                    item.variable1
                                  )}
                                ></FeatherIcon>
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                  <div className="cont-totales">
                    <div className="campos-totales">
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="subtotal"
                          id="subtotal"
                          readOnly="readonly"
                          autoComplete="off"
                          placeholder="Sub-Total."
                          maxLength="5"
                          onChange={this.onChange.bind(this)}
                          value={this.state.subtotal}
                        />
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="timpuesto"
                          id="timpuesto"
                          readOnly="readonly"
                          autoComplete="off"
                          placeholder="ITBMS."
                          maxLength="5"
                          onChange={this.onChange.bind(this)}
                          value={this.state.timpuesto}
                        />
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="totalg"
                          id="totalg"
                          readOnly="readonly"
                          autoComplete="off"
                          placeholder="Total."
                          maxLength="5"
                          onChange={this.onChange.bind(this)}
                          value={this.state.totalg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            {/* <div className="container col-12"> */}
            <div className="radio">
              <label className="container">
                Internos
                <input
                  type="radio"
                  value="INTERNOS"
                  name="opcedulaP"
                  checked={this.state.opcedulaP === "INTERNOS"}
                  onChange={this.onChange3.bind(this)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="radio">
              <label className="container">
                Externos
                <input
                  type="radio"
                  value="EXTERNOS"
                  name="opcedulaP"
                  checked={this.state.opcedulaP === "EXTERNOS"}
                  onChange={this.onChange4.bind(this)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            {/* </div> */}
            <div className="fechas col-4">
              <select
                id="tbusqueda"
                name="tbusqueda"
                value={this.state.tbusqueda}
                onChange={this.onChange2.bind(this)}
              >
                {this.state.opcedulaP === "EXTERNOS" && (
                  <option>-- Seleccionar Cliente --</option>
                )}
                {this.state.clientes.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2 + " - " + item.variable3}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
            {/* </div> */}
          </div>

          <div className="form-group row">
            <div className="contenedorcedula1 col-5">
              <input
                className="form-control"
                type="text"
                name="cliente"
                id="cliente"
                readOnly="readonly"
                autoComplete="off"
                placeholder="Cliente."
                maxLength="200"
                onChange={this.onChange.bind(this)}
                value={this.state.cliente.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="text"
                name="ruc"
                id="ruc"
                readOnly="readonly"
                autoComplete="off"
                placeholder="Ruc/Cedula"
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.ruc.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="date"
                name="fecha"
                id="fecha"
                //readOnly="readonly"
                placeholder="Fecha"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha}
              />
            </div>
          </div>

          {this.state.externoOn === true && (
            <div className="flotador4xfF">
              <div className="flotador1">
                <div className="contenedor-titulo row">
                  <div className="radio-identificacion col-3">
                    <div className="radio">
                      <label className="container">
                        PRODUCTO
                        <input
                          className="opnivel2"
                          type="radio"
                          value="PRODUCTO"
                          name="tipoFac"
                          checked={this.state.tipoFac === "PRODUCTO"}
                          onChange={this.onChange.bind(this)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="radio">
                      <label className="container">
                        SERVICIOS.
                        <input
                          className="opnivel2"
                          type="radio"
                          value="SERVICIOS"
                          name="tipoFac"
                          checked={this.state.tipoFac === "SERVICIOS"}
                          onChange={this.onChange.bind(this)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>

                  <div className="contenedorcedula1 col-12">
                    <button className="boton" onClick={this.externoCliente}>
                      Aceptar
                    </button>
                    <button className="boton" onClick={this.externoCli}>
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.factuPendientesOn === true && (
            <div className="flotador4xf">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Listado de Facturas por Aprobar
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrarLista2.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc1">FECHA</th>
                          <th className="titucxc1">FACTURA</th>
                          <th className="titucxc2">NOMBRE</th>
                          <th className="titucxc1">TOTAL</th>
                          <th className="titucxc1">FORMA DE PAGO</th>
                          {/* <th className="titucxc1">TOTAL</th> */}
                          <th className="titucxc1">
                            <FeatherIcon
                              icon="search"
                              size="30px"
                              stroke="#999"
                              color="red"
                            />
                            &nbsp;&nbsp;&nbsp;
                            <FeatherIcon
                              icon="delete"
                              size="30px"
                              stroke="#999"
                              color="red"
                            />
                          </th>
                        </tr>
                      </thead>
                      {this.state.facturasCRe.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              className="totalescxc"
                              width="10"
                              onClick={
                                this.state.ruc.length > 0
                                  ? this.pasarItenFactura.bind(
                                      this,
                                      item.variable2
                                    )
                                  : ""
                              }
                            >
                              {item.variable6}
                            </td>
                            <td className="totalescxc" width="10">
                              {item.variable2}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable9}
                            </td>
                            <td className="totalescxc1" width="10">
                              {item.variable5}
                            </td>
                            <td className="totalescxc1" width="10">
                              {item.variable4}
                            </td>
                            {/* <td className="totalescxc1" width="10">
                              {item.variable5}
                            </td> */}

                            <td className="titucxc3" width="10">
                              <FeatherIcon
                                icon="search"
                                size="30px"
                                stroke="#999"
                                color="red"
                                onClick={this.consultarFacturasIRe.bind(
                                  this,
                                  item.variable2
                                )}
                              ></FeatherIcon>{" "}
                              &nbsp;&nbsp;&nbsp;{" "}
                              <FeatherIcon
                                icon="delete"
                                size="30px"
                                stroke="#999"
                                color="red"
                                onClick={this.eliminarFacturasIRe.bind(
                                  this,
                                  item.variable2
                                )}
                              ></FeatherIcon>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.datosChequeOn === true && (
            <div className="flotador4xf">
              <div className="flotador1f">
                <div className="contenedor-titulo row">
                  <div className="contenedor-cedula col-4">
                    <label className="labelcedulac">Banco.</label>

                    <input
                      className="form-control"
                      type="text"
                      name="banco"
                      id="banco"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.banco.toUpperCase()}
                    />
                  </div>
                  <div className="contenedor-cedula col-4">
                    <label className="labelcedulac">Referencia.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="referencia"
                      id="referencia"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.referencia.toUpperCase()}
                    />
                  </div>
                  <div className="contenedor-cedula col-2">
                    <label className="labelcedulac">Monto a Pagar.</label>

                    <input
                      className="form-control"
                      type="text"
                      name="monto"
                      id="monto"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.monto}
                    />
                  </div>
                  <div className="contenedor-cedula col-2">
                    <label className="labelcedulac">Factura.</label>
                    <input
                      className="form-control"
                      type="text"
                      name="nfactura"
                      id="nfactura"
                      autoComplete="off"
                      onChange={this.onChange.bind(this)}
                      value={this.state.nfactura.toUpperCase()}
                    />
                  </div>
                  <div className="row">
                    <div className="contenedor-cedula col-5">
                      <label className="labelcedulac">Concepto.</label>

                      <input
                        className="form-control"
                        type="text"
                        name="concepto"
                        id="concepto"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.concepto.toUpperCase()}
                      />
                    </div>
                    <div className="contenedor-cedula col-3">
                      <label className="labelcedulac">Fecha Transacción.</label>
                      <input
                        className="form-control"
                        type="date"
                        name="fecha"
                        id="fecha"
                        // readOnly="readonly"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                        value={this.state.fecha}
                      />
                    </div>
                    <div className="radio-identificacion col-3">
                      <div className="radio">
                        <label className="labelcedulac">Transacción.</label>
                        <label className="container">
                          Transferencia.
                          <input
                            className="opnivel1"
                            type="radio"
                            value="TRANSFERENCIA"
                            name="oppago"
                            checked={this.state.oppago === "TRANSFERENCIA"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="radio">
                        <label className="container">
                          Deposito.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="DEPOSITO"
                            name="oppago"
                            checked={this.state.oppago === "DEPOSITO"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="radio">
                        <label className="container">
                          Tarjeta.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="TARJETA"
                            name="oppago"
                            checked={this.state.oppago === "TARJETA"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      <div className="radio">
                        <label className="container">
                          Efectivo.
                          <input
                            className="opnivel2"
                            type="radio"
                            value="EFECTIVO"
                            name="oppago"
                            checked={this.state.oppago === "EFECTIVO"}
                            onChange={this.onChange.bind(this)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="contenedorcedula1 col-12">
                    <button className="boton" onClick={this.datosCheque}>
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="contenedorcedula1 col-12">
            <button
              className={this.state.guardarOn ? "botonOf" : "boton"}
              onClick={this.activarFactura}
            >
              Facturar
            </button>
            {/* <button className={this.state.totalg > 0 ?"boton":"botonOf"} onClick={this.datosCheque.bind(this)}>Datos Cheque</button>
                        <button className={this.state.totalg > 0 ?"boton":"botonOf"} onClick={this.guardarFactura}>Guardar</button> */}
            {/* <button className={this.state.guardarOn?"boton":"botonOf"} disabled={!this.state.guardarOn} onClick={this.eliminarCxP}>Eliminar</button> */}
            <button className="boton" onClick={this.limpiar.bind(this)}>
              Otro Cliente
            </button>
            <button className="boton" onClick={this.libroV.bind(this)}>
              Libro Ventas
            </button>
            <button
              className="boton"
              onClick={this.consultarFacturaRe.bind(this)}
            >
              Facturas por Aprobar
            </button>
            <Link to="/">
              <button className="boton">Salir</button>
            </Link>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">FECHA</th>
                    <th className="titucxc1">RECIBO</th>
                    <th className="titucxc1">FACTURA</th>
                    <th className="titucxc1">N/C</th>
                    <th className="titucxc1">MONTO</th>
                    <th className="titucxc1">ITBMS</th>
                    <th className="titucxc1">TOTAL</th>
                    <th className="titucxc1">ANULADO</th>
                    <th className="titucxc1">
                      <FeatherIcon
                        icon="search"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                      &nbsp;&nbsp;&nbsp;
                      <FeatherIcon
                        icon="delete"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                    </th>
                  </tr>
                </thead>
                {this.state.facturasC.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxc" width="10">
                        {item.variable6}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable2}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable8}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable9}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable3}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable4}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable5}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable7}
                      </td>
                      <td className="titucxc3" width="10">
                        <FeatherIcon
                          icon="search"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.consultarFacturasI.bind(
                            this,
                            item.variable2
                          )}
                        ></FeatherIcon>{" "}
                        &nbsp;&nbsp;&nbsp;{" "}
                        <FeatherIcon
                          icon="delete"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.eliminarFacturasI.bind(
                            this,
                            item.variable2
                          )}
                        ></FeatherIcon>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Facturacion);
