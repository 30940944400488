import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//componentes
import "./cxp.css";
import FeatherIcon from "feather-icons-react";

import CxPAbonos from "../cxpAbonos/cxpAbonos";
import CxPAbonosM from "../cxpAbonosMultiples/cxpAbonosM";
import Alert from "../../modal/alert";

class CxPFacturasPagas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idcxp: "",
      fecha: "",
      subtotal: "",
      impuesto: "",
      monto: "",
      saldo: "",
      banco: "",
      oferta: "NO",
      referencia: "",
      documento: "",
      descripcion1: "",
      tbusqueda: "",
      guardarOn: false,
      multipleOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      modificarSaldoMOn: false,
      proveedores: [],
      cxpproveedor: [],
      reporteOn: false,
      libroCOn: false,
      codigo3: "",
      codigo4: "",
    };
  }

  limpiar = () => {
    this.setState({
      idcxp: "",
      fecha: "",
      monto: "",
      saldo: "",
      banco: "",
      oferta: "NO",
      referencia: "",
      documento: "",
      descripcion1: "",
      subtotal: "",
      impuesto: "",
      guardarOn: false,
      multipleOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      modificarSaldoMOn: false,
      reporteOn: false,
      libroCOn: false,
      codigo3: "",
      codigo4: "",
    });
  };

  limpiar2 = () => {
    this.setState({
      idcxp: "",
      subtotal: "",
      impuesto: "",
      fecha: "",
      monto: "",
      saldo: "",
      documento: "",
      descripcion1: "",
      tbusqueda: "",
      guardarOn: false,
      multipleOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      modificarSaldoMOn: false,
      cxpproveedor: [],
      reporteOn: false,
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange1(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        oferta: "SI",
      });
    } else {
      this.setState({
        oferta: "NO",
      });
    }
  }

  onChange(e) {
    //console.log(e.target.name)
    let index = e.target.getAttribute("id");
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (index === "subtotal") {
      this.state.impuesto = (
        parseFloat(e.target.value) * 1.07 -
        parseFloat(e.target.value)
      ).toFixed(2);
      this.state.monto = (parseFloat(e.target.value) * 1.07).toFixed(2);
    }
    if (index === "impuesto") {
      let valor = 0;
      if (e.target.value !== "") valor = e.target.value;

      this.state.monto = (
        parseFloat(this.state.subtotal) + parseFloat(valor)
      ).toFixed(2);
    }
  }

  onChange2(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarCxP(e.target.value);
    //console.log(e.target.value)
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  reporte = () => {
    if (this.state.reporteOn) {
      this.setState({ reporteOn: false });
    } else {
      this.setState({ reporteOn: true });
    }
  };

  generarPagoClose = () => {
    this.setState({ modificarSaldoOn: false });
    this.limpiar();
    this.consultarCxP(this.state.tbusqueda);
  };

  generarPagoCloseM = () => {
    this.setState({ modificarSaldoMOn: false });
    this.limpiar();
    this.consultarCxP(this.state.tbusqueda);
  };

  consultarCxPI = (e) => {
    let data = new FormData();
    data.append("idcxp", e);
    data.append("boton", "consultarCxPI");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.setState({
            idcxp: res.data.variable2,
            documento: res.data.variable3,
            subtotal: res.data.variable7,
            impuesto: res.data.variable8,
            monto: res.data.variable4,
            descripcion1: res.data.variable5,
            fecha: res.data.variable6,
            guardarOn: true,
          });
        }
      });
  };

  consultarCxPDM = () => {
    if (this.state.modificarSaldoMOn) {
      this.setState({ modificarSaldoMOn: false });
    } else {
      this.setState({
        modificarSaldoMOn: true,
      });
    }
  };

  consultarCxPD = (e, f, g, h) => {
    if (this.state.modificarSaldoOn) {
      this.setState({ modificarSaldoOn: false });
    } else {
      this.setState({
        modificarSaldoOn: true,
        idcxp: e,
        documento: f,
        monto: g,
        saldo: h,
      });
    }
  };

  consultarProveedores = () => {
    let data = new FormData();
    data.append("boton", "consultarProveedor");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/proveedores.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            proveedores: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarCxP = (e) => {
    let data = new FormData();
    data.append("idproveedor", e);
    data.append("tipoC", this.props.tipoC);
    data.append("boton", "consultarCxP");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xfacturasP = res.data.variable2;
          this.setState({
            cxpproveedor: xfacturasP,
            multipleOn: true,
          });
        } else {
          this.setState({
            multipleOn: false,
            cxpproveedor: [],
          });
        }
      });
  };

  eliminarCxP = () => {
    let data = new FormData();
    data.append("idcxp", this.state.idcxp);
    data.append("boton", "eliminar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.consultarCxP(this.state.tbusqueda);
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Se Elimino el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  modificarCxP = () => {
    let data = new FormData();
    data.append("idcxp", this.state.idcxp);
    data.append("documento", this.state.documento);
    data.append("monto", this.state.monto);
    data.append("impuesto", this.state.impuesto);
    data.append("subtotal", this.state.subtotal);
    data.append("descripcion", this.state.descripcion1);
    data.append("fecha", this.state.fecha);
    data.append("boton", "modificar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxp.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.consultarCxP(this.state.tbusqueda);
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Se Modifico el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Crear Cuentas por Pagar.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
    } else {
      this.setState({ datosChequeOn: true });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  componentDidMount() {
    //this.buscarCursos();
    this.consultarProveedores();
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
        <div className="titulo-cxc ">
                  <p>CXP FACTURAS PAGADAS. </p>
                </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.modificarSaldoOn === true && (
            <div className="flotador3x">
              <div className="flotador1">
                <CxPAbonos
                  idcxp={this.state.idcxp}
                  documento={this.state.documento}
                  monto={this.state.monto}
                  saldo={this.state.monto}
                  tipoC={this.props.tipoC}
                  generarPagoClose={this.generarPagoClose}
                />
              </div>
            </div>
          )}

          {this.state.modificarSaldoMOn === true && (
            <div className="flotador3xM">
              <div className="flotador1">
                <CxPAbonosM
                  idcxpM={this.state.tbusqueda}
                  generarPagoClose={this.generarPagoCloseM}
                />
              </div>
            </div>
          )}

          <div className="contenedorp row">
            <div className="fechas col-12">
              <select
                id="tbusqueda"
                name="tbusqueda"
                value={this.state.tbusqueda}
                onChange={this.onChange2.bind(this)}
              >
                <option>-- Seleccionar Proveedor --</option>
                {this.state.proveedores.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {/* {item.variable2} */}
                      {/* <column>{item.variable2}</column>
                      <column>{item.variable3}</column> */}
                      {item.variable2 + " - " + item.variable3}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="documento"
                id="documento"
                autoComplete="off"
                placeholder="No. Documento"
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.documento.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="subtotal"
                id="subtotal"
                autoComplete="off"
                placeholder="Sub-Total"
                maxLength="10"
                readOnly={this.props.tipoC === "FP" ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={this.state.subtotal}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="impuesto"
                id="impuesto"
                autoComplete="off"
                placeholder="Impuesto"
                maxLength="10"
                readOnly={this.props.tipoC === "FP" ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={this.state.impuesto}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="monto"
                id="monto"
                autoComplete="off"
                placeholder="Monto"
                maxLength="10"
                readOnly={this.props.tipoC === "FP" ? "readonly" : ""}
                onChange={this.onChange.bind(this)}
                value={this.state.monto}
              />
            </div>
            <div className="contenedorcedula1 col-3">
              <input
                className="form-control"
                type="date"
                name="fecha"
                id="fecha"
                // readOnly="readonly"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
                value={this.state.fecha}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="contenedorcedula1 col-4">
              <input
                className="form-control"
                type="text"
                name="descripcion1"
                id="descripcion1"
                autoComplete="off"
                placeholder="Descripcion"
                maxLength="200"
                onChange={this.onChange.bind(this)}
                value={this.state.descripcion1.toUpperCase()}
              />
            </div>
          </div>

          <div className="contenedorcedula1 col-12">
            <button
              className={this.state.guardarOn ? "boton" : "botonOf"}
              disabled={!this.state.guardarOn}
              onClick={this.modificarCxP}
            >
              Modificar
            </button>
            <button
              className={this.state.guardarOn ? "boton" : "botonOf"}
              disabled={!this.state.guardarOn}
              onClick={this.eliminarCxP}
            >
              Eliminar
            </button>
            <button className="boton" onClick={this.limpiar2.bind(this)}>
              Limpiar
            </button>
            <button className="boton" onClick={this.props.generarPagoClose} >Salir</button>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">FECHA</th>
                    <th className="titucxc3">DOCUMENTO</th>
                    <th className="titucxc3">MONTO</th>
                    <th className="titucxc3">ABONO</th>
                    <th className="titucxc3">SALDO</th>
                    <th className="titucxc3">
                      <FeatherIcon
                        icon="search"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                    </th>
                  </tr>
                </thead>
                {this.state.cxpproveedor.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxc"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable2}
                      </td>
                      <td
                        className="renglon"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable4}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {item.variable5}
                      </td>
                      <td
                        className="totalescxc1"
                        width="10"
                        onClick={this.consultarCxPI.bind(this, item.variable1)}
                      >
                        {parseFloat(item.variable4) -
                          parseFloat(item.variable5)}
                      </td>

                      <td className="titucxc3" width="10">
                        <FeatherIcon
                          icon="search"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.consultarCxPD.bind(
                            this,
                            item.variable1,
                            item.variable3,
                            item.variable4,
                            parseFloat(item.variable4) -
                              parseFloat(item.variable5)
                          )}
                        ></FeatherIcon>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxPFacturasPagas);
