import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import FeatherIcon from "feather-icons-react";
//componentes
import "./formularioColabora.css";
import Alert from "../../modal/alert";

function Empleados(props) {
  const [personal, setpersonal] = React.useState([]);
  const [irutas, setirutas] = React.useState([]);

  const [listaOn, setlistaOn] = React.useState(false);
  const [btnnoauto2On, setbtnnoauto2On] = React.useState("NO");
  const [btnnoauto1On, setbtnnoauto1On] = React.useState("NO");
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  const [activarOn, setactivarOn] = React.useState(false);
  const [paso, setpaso] = React.useState(false);
  const [flotadorPOn, setflotadorPOn] = React.useState(false);

  const [mensaje, setmensaje] = React.useState("");
  const [data, setData] = React.useState({
    cedula: "",
    nombre: "",
    profesion: "",
    cargo: "",
    fechaNaci: "",
    edad: "",
    direccion: "",
    nombreD1: "",
    edadD1: "",
    parentescoD1: "",
    nombreD2: "",
    edadD2: "",
    parentescoD2: "",
    nombreD3: "",
    edadD3: "",
    parentescoD3: "",
    nacionalidad: "",
    edocivil: "",
    cargo: "",
    salario: "",
    horario: "",
    titular: "",
    tcuenta: "",
    banco1: "",
    cuenta: "",
    tcontrato: "",
    fechaInicio: "",
    fechaEgreso: "",
    correo: "",
    hojavida:'',
    hojavida:'',
    cedulaC:'',
    foto:'',
    permisoT:'',
    permisoM:'',
    saludF:'',
    saludM:'',
    recordP:'',
    licenciatura:'',
    postgrado:'',
    maestria:'',
    creditos:'',
    estudiando:'',


    tiponomina: "",
    tiponominaA: "",
    status: "",
    statusA: "",

    salarioBaseMensual: "",
    horasDiaria: "",
    diasSemanal: "",
    salarioHora: "",
    salarioDia: "",
    salarioQuincenal: "",
    sso: "9.75",
    ssoDecimo: "7.25",
    seguroEducativo: "1.25",
  });
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");
  const [xsso, setxsso] = React.useState("9.75");
  const [xssoDecimo, setxssoDecimo] = React.useState("7.25");
  const [xseguroEducativo, setxseguroEducativo] = React.useState("1.25");

  const limpiar = () => {
    setmensajealerta(false);
    setactivarOn(false);
    let data_ = Object.assign({}, data);
    data_ = {
      sso: "9.75",
      ssoDecimo: "7.25",
      seguroEducativo: "1.25",
    };
    setData(data_);
  };

  const cerrarLista = () => {
    //console.log(listaOn)
    if (listaOn) {
      setlistaOn(false);
    }
    if (!listaOn) {
      setlistaOn(true);
    }
  };
  const cerrarLista2 = () => {
    //console.log(listaOn)
    if (paso) {
      setpaso(false);
    }
    if (!paso) {
      setpaso(true);
    }
  };
  const cerrarLista3 = () => {
    //console.log(listaOn)
    if (flotadorPOn) {
      setflotadorPOn(false);
    }
    if (!flotadorPOn) {
      setflotadorPOn(true);
    }
  };

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
    data_[index] = e.target.value;
    setData(data_);
    // if (index === "descripcion") buscarCorreosInternos(e.target.value);
  };

  const onChange2 = (e) => {
    console.log(e.target.value)
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
     data_[index] = e.target.value;
    setData(data_);
    
    // if (e.target.checked) {
    //   this.setState({
    //     [e.target.name]: true,
    //   });
    // } else {
    //   this.setState({
    //     [e.target.name]: false,
    //   });
    // }
  }

  const handleClose = () => {
    setmensajealerta(false);
  };

  const buscarPersonal = () => {
    let form = new FormData();
    form.append("boton", "buscarPersonal");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/colaborador.php";
    axios.post(xurl, form, config).then((res) => {
      //setactivarOn(true);
      let xpersonal = res.data.variable1;
      setpersonal(xpersonal);
      //setpersonal(xpersonal);
    });
  };

  const buscarPersonalI = (e) => {
    let form = new FormData();
    form.append("idpersonalC", e);
    form.append("boton", "buscarPersonalI");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/colaborador.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setactivarOn(true);
        cerrarLista();
        let data_ = Object.assign({}, data);
        data_["cedula"] = res.data.variable2;
        data_["nombre"] = res.data.variable3;
        data_["fechaNaci"] = res.data.variable4;
        data_["edad"] = res.data.variable5;
        data_["direccion"] = res.data.variable6;
        data_["nombreD1"] = res.data.variable7;
        data_["edadD1"] = res.data.variable8;
        data_["parentescoD1"] = res.data.variable9;
        data_["nombreD2"] = res.data.variable10;
        data_["edadD2"] = res.data.variable11;
        data_["parentescoD2"] = res.data.variable12;
        data_["nombreD3"] = res.data.variable13;
        data_["edadD3"] = res.data.variable14;
        data_["parentescoD3"] = res.data.variable15;
        data_["nacionalidad"] = res.data.variable16;
        data_["edocivil"] = res.data.variable17;
        data_["cargo"] = res.data.variable18;
        data_["salario"] = res.data.variable19;
        data_["horario"] = res.data.variable20;
        data_["cuenta"] = res.data.variable21;
        data_["banco1"] = res.data.variable22;
        data_["tcuenta"] = res.data.variable23;
        data_["titular"] = res.data.variable24;
        data_["tcontrato"] = res.data.variable25;
        data_["fechaInicio"] = res.data.variable26;
        data_["correo"] = res.data.variable27;
        data_["fechaEgreso"] = res.data.variable28;
        setData(data_);
      } else {
        setmensajealerta(true);
        settituloM("Menu Colaboradores.");
        setcuerpoM(res.data.variable2);
      }
      //setpersonal(xpersonal);
    });
  };

  const guardarEmpleado = (e) => {
    if (data.cedula.length > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("nombre", data.nombre);
      form.append("fechaNaci", data.fechaNaci);
      form.append("edad", data.edad);
      form.append("correo", data.correo);
      form.append("direccion", data.direccion);
      form.append("nombreD1", data.nombreD1);
      form.append("edadD1", data.edadD1);
      form.append("parentescoD1", data.parentescoD1);
      form.append("nombreD2", data.nombreD2);
      form.append("edadD2", data.edadD2);
      form.append("parentescoD2", data.parentescoD2);
      form.append("nombreD3", data.nombreD3);
      form.append("edadD3", data.edadD3);
      form.append("parentescoD3", data.parentescoD3);
      form.append("nacionalidad", data.nacionalidad);
      form.append("edocivil", data.edocivil);
      form.append("cuenta", data.cuenta);
      form.append("banco1", data.banco1);
      form.append("tcuenta", data.tcuenta);
      form.append("titular", data.titular);
      form.append("cargo", data.cargo);
      form.append("salario", data.salario);
      form.append("horario", data.horario);
      form.append("tcontrato", data.tcontrato);
      form.append("fechaInicio", data.fechaInicio);
      form.append("fechaEgreso", data.fechaEgreso);
      form.append("boton", "guardarColaborador");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/colaborador.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setactivarOn(true);
          setmensajealerta(true);
          buscarPersonal();
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Empleado.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const modificarEmpleado = () => {
    if (data.cedula.length > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("nombre", data.nombre);
      form.append("fechaNaci", data.fechaNaci);
      form.append("edad", data.edad);
      form.append("correo", data.correo);
      form.append("direccion", data.direccion);
      form.append("nombreD1", data.nombreD1);
      form.append("edadD1", data.edadD1);
      form.append("parentescoD1", data.parentescoD1);
      form.append("nombreD2", data.nombreD2);
      form.append("edadD2", data.edadD2);
      form.append("parentescoD2", data.parentescoD2);
      form.append("nombreD3", data.nombreD3);
      form.append("edadD3", data.edadD3);
      form.append("parentescoD3", data.parentescoD3);
      form.append("nacionalidad", data.nacionalidad);
      form.append("edocivil", data.edocivil);
      form.append("cuenta", data.cuenta);
      form.append("banco1", data.banco1);
      form.append("tcuenta", data.tcuenta);
      form.append("titular", data.titular);
      form.append("cargo", data.cargo);
      form.append("salario", data.salario);
      form.append("horario", data.horario);
      form.append("tcontrato", data.tcontrato);
      form.append("fechaInicio", data.fechaInicio);
      form.append("fechaEgreso", data.fechaEgreso);
      form.append("boton", "modificarColaborador");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/colaborador.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable1);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          setmensajealerta(true);
          settituloM("Menu Colaboradores.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Colaboradores.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const eliminarEmpleado = () => {
    if (data.cedula.length > 0) {
      let form = new FormData();
      form.append("cedula", data.cedula);
      form.append("boton", "eliminarColaborador");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/colaborador.php";
      axios.post(xurl, form, config).then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          limpiar();
          buscarPersonal();
          setmensajealerta(true);
          settituloM("Menu Colaborador.");
          setcuerpoM(res.data.variable2);
        } else {
          setmensajealerta(true);
          settituloM("Menu Colaborador.");
          setcuerpoM(res.data.variable2);
        }
      });
    }
  };

  const calcular = () => {
    // let sdia = Number.parseFloat(
    //   data.salarioBaseMensual / (data.diasSemanal * 4)
    // ).toFixed(2);
    let sdia = Number.parseFloat(data.salarioBaseMensual / 26).toFixed(2);

    // let sqince = Number.parseFloat(sdia * (data.diasSemanal * 2)).toFixed(2);
    let sqince = Number.parseFloat(data.salarioBaseMensual / 2).toFixed(2);

    //let shora = Number.parseFloat(sdia / data.horasDiaria).toFixed(2);
    let shora = Number.parseFloat(sdia / data.horasDiaria).toFixed(2);

    let data_ = Object.assign({}, data);
    data_["salarioQuincenal"] = sqince;
    data_["salarioDia"] = sdia;
    data_["salarioHora"] = shora;
    setData(data_);
  };

  const botonnoautorizado = () => {
    if (btnnoauto1On === "SI") {
      setbtnnoauto1On("NO");
    } else {
      setbtnnoauto1On("SI");
    }
  };

  const botonnoautorizado2 = () => {
    //console.log(this.state.btnemOn)
    if (btnnoauto2On === "SI") {
      setbtnnoauto2On("NO");
    } else {
      setbtnnoauto2On("SI");
    }
  };

  const consultarAdjuntos = () => {
    //console.log(e.target.files)
    setloading(true);
    const form_data = new FormData();

    form_data.append("cedula", data.cedula);
    form_data.append("boton", "Consultar");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/archivo2.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        setloading(false);
        let xrutas = res.data.variable3;
        if (res.data.variable1) {
          setirutas(xrutas);
        } else {
          setmensaje("El Colaborador no tiene Documentos Consignados");
        }
        setpaso(true);
        //console.log(res);
      });
  };

  const eliarchivo = (e) => {
    //console.log(e);
    setloading(true);

    const form_data = new FormData();
    form_data.append("cedula", data.cedula);
    form_data.append("archivoArriba", e);
    form_data.append("boton", "Eliminar");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/archivo2.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        setloading(false);
        let xrutas = res.data.variable3;
        if (res.data.variable1) {
          setirutas(xrutas);
        } else {
          setirutas(xrutas);
          setmensaje(
            "Es posible que uno o mas archivos no fueron guardados en el servidor"
          );
        }
        //console.log(res);
      });
  };

  const Upload = (e) => {
    //console.log(e.target.files)
    setloading(true);
    const form_data = new FormData();

    let name_fvar = "";
    for (let x = 0; x < e.target.files.length; x++) {
      name_fvar = "file_" + String(x + 1);
      form_data.append(name_fvar, e.target.files[x]);
      //console.log(name_fvar)
    }
    form_data.append("cedula", data.cedula);
    form_data.append("boton", "Guardar");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/archivo2.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        setloading(false);
        if (res.data.variable1) {
          // alert ('Los ArDocumentos fueron enviados exitosamente');
          let xrutas = res.data.variable3;

          setirutas(xrutas);
        } else {
          if (res.data.variable2.length > 0) {
            setpaso(true);
            setmensaje(res.data.variable2);
          } else {
            setpaso(true);
            setmensaje(
              "Es posible que uno o mas archivos no fueron guardados en el servidor"
            );
          }
        }
        //console.log(res);
      });
  };

  const ClickUpload = (e) => {
    document.getElementById("upload-file").click();
  };

  React.useEffect(() => {
    //console.log(props.cedulaempleado);
    buscarPersonal();
    //buscarPersonal();
  }, []);
  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        <div className="titulo-cxc ">
          <p>FORMULARIO COLABORADORES. </p>
        </div>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}
        {loading === true && (
          <div className="cont-spinner row">
            <div className="spinner col-6">
              <RingLoader
                clasName="spinner"
                sizeUnit={"px"}
                size={160}
                width={19}
                radius={20}
                height={160}
                color={"#48e120"}
                loading={loading}
              />
            </div>
          </div>
        )}
        {flotadorPOn === true && (
          <div className="flotadorPe">
            <div className="flotadorPe1">
              <div className="titulo-notificacion col-12">
                <p>COTEJAR DOCUMENTOS DEL COLABORADOR.</p>
              </div>
              <div className="cont-check row">
                <div className="cont-opcion22c">
                  <label className="opcion2c">
                    Hoja de Vida.
                  </label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="hojavida"
                    id="hojavida"
                    checked={data.hojavida}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Foto.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="foto"
                    id="foto"
                    checked={data.foto}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Copia Cédula.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="cedulaC"
                    id="cedulaC"
                    checked={data.cedulaC}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Permiso Trabajo.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="permisoT"
                    id="permisoT"
                    checked={data.permisoT}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Permiso Migracion.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="permisoM"
                    id="permisoM"
                    checked={data.permisoM}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Certificación Salud Física.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="saludF"
                    id="saludF"
                    checked={data.saludF}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Certificación Salud Mental.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="saludM"
                    id="saludM"
                    checked={data.saludM}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">
                    Record Policivo.
                  </label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="recordP"
                    id="recordP"
                    checked={data.recordP}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Certificado Nota.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="certificadoN"
                    id="certificadoN"
                    checked={data.certificadoN}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Docu. Licenciatura.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="licenciatura"
                    id="licenciatura"
                    checked={data.licenciatura}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opscion22c">
                  <label className="opcion2c">Docu. Postgrado.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="postgrado"
                    id="postgrado"
                    checked={data.postgrado}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Docu. Maestria.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="maestria"
                    id="maestria"
                    checked={data.maestria}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Docu. Creditos.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="creditos"
                    id="creditos"
                    checked={data.creditos}
                    onChange={onChange2}
                  />
                </div>
                <div className="cont-opcion22c">
                  <label className="opcion2c">Cursando Estudios.</label>
                  <input
                    className="form-control"
                    type="checkbox"
                    name="estudiando"
                    id="estudiando"
                    checked={data.estudiando}
                    onChange={onChange2}
                  />
                </div>
              </div>
              <div className="botones col-12">
                <div className="acciones">
                  <button
                    className="boton"
                    onClick={cerrarLista3}
                  >
                    Guardar
                  </button>
                  <button className="boton" onClick={cerrarLista3}>
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {paso === true && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-buscar1 row">
                <div
                  className="identificacionr2 col-12"
                  onClick={() => {
                    ClickUpload();
                  }}
                >
                  <p className="subirtexto1">
                    Haz click para adjuntar tus archivos
                  </p>
                  <br />
                  <FeatherIcon icon="upload" size="30px" stroke="#999" />
                  <input
                    onChange={Upload}
                    style={{ display: "none" }}
                    id="upload-file"
                    type="file"
                    multiple
                  />
                </div>
              </div>
              <div className="contenido1 row">
                <div className="form-groupef1 col-12">
                  <div className="preview row">
                    <div className="titulo-notificacion col-12">
                      <p>Archivos ya Guardados en el servidor.</p>
                    </div>
                    {irutas.map((item, i) => {
                      return (
                        <div className="cont-foto">
                          <span
                            className="cerrar"
                            onClick={() => {
                              eliarchivo(item.variable5);
                            }}
                          >
                            X
                          </span>{" "}
                          <a
                            href={
                              "https://institutobilinguelasnaciones.com/terapias/backend/" +
                              item.variable4
                            }
                            target="__blank"
                          >
                            {" "}
                            <img
                              src={
                                "https://institutobilinguelasnaciones.com/terapias/backend/" +
                                item.variable4
                              }
                              key={item.variable5}
                              alt={item.variable5}
                              className="fotos"
                              name={item.variable5}
                            />{" "}
                          </a>
                        </div>
                      );
                    })}
                  </div>

                  <div className="row">
                    <div className="titulo-notificacion col-12">
                      <p>{mensaje}</p>
                    </div>
                    <div className="accionesb col-12">
                      <button className="boton" onClick={cerrarLista3}>
                        Cotejar
                      </button>
                      <button className="boton" onClick={cerrarLista2}>
                        Salir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {listaOn && (
          <div className="cont-tablanomina row">
            <div className="tablanomina1 col-7">
              <div className="cont-titulomedi col-12">
                <span className="titulocasos">Seleccione a un Colaborador</span>
                <span
                  className="titulo-cerrarcasos"
                  onClick={() => {
                    cerrarLista();
                  }}
                >
                  X
                </span>
              </div>
              <div className="cont-listnomi row">
                <div className="listnomi col-12">
                  <table className="tablanomi" border="1">
                    <thead className="theadcreartabla">
                      <tr className="trcreartabla">
                        {/* <th className="xtitunomina1">item.</th> */}
                        <th className="xtitunomina2">Documento.</th>
                        <th className="xtitunomina2">Nombre Colaborador.</th>
                        {/* <th className="xtitunomina1">Monto H/E.</th>
                        <th className="xtitunomina3">S.Quin.</th>
                        <th className="xtitunomina1">Dias N/T.</th>
                        <th className="xtitunomina1">Monto D/N/T.</th>
                        <th className="xtitunomina1">Horas N/T.</th>
                        <th className="xtitunomina1">Monto H/N/T.</th>
                        <th className="xtitunomina1">S.S.O.</th>
                        <th className="xtitunomina1">S.Edu.</th>
                        <th className="xtitunomina1">I.S.L.R.</th>
                        <th className="xtitunomina3">Sub-Total.</th>
                        <th className="xtitunomina3">Monto P.</th>
                        <th className="xtitunomina1">P.Pres.</th>
                        <th className="xtitunomina3">Total a Pagar.</th> */}
                        {/* <th className="xtitunomina1">
                          <span className="cerrarme3"></span>
                        </th> */}
                      </tr>
                    </thead>

                    <tbody className="evocuerpo1">
                      {personal.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              width="20"
                              onClick={() => {
                                buscarPersonalI(item.variable1);
                              }}
                            >
                              {item.variable2}
                            </td>
                            <td
                              width="20"
                              onClick={() => {
                                buscarPersonalI(item.variable1);
                              }}
                            >
                              {item.variable3}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="cont-listmedi row">
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Cedula Colaborador.
              <input
                className="form-control"
                type="text"
                name="cedula"
                id="cedula"
                readOnly={!activarOn ? "" : "readonly"}
                autoComplete="off"
                placeholder="Cedula Empleado"
                maxLength="15"
                onChange={onChange}
                value={
                  typeof data.cedula !== "undefined"
                    ? data.cedula.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-3">
            <label className="titulo-salario2">
              Nombre Colaborador.
              <input
                className="form-control"
                type="text"
                name="nombre"
                id="nombre"
                autoComplete="off"
                placeholder="Nombre del Empleado"
                maxLength="150"
                onChange={onChange}
                value={
                  typeof data.nombre !== "undefined"
                    ? data.nombre.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-3">
            <label className="titulo-salario2">
              Fecha Naci.
              <input
                className="form-control"
                type="date"
                name="fechaNaci"
                id="fechaNaci"
                autoComplete="off"
                maxLength="10"
                onChange={onChange}
                value={
                  typeof data.fechaNaci !== "undefined" ? data.fechaNaci : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-1">
            <label className="titulo-salario2">
              Edad.
              <input
                className="form-control"
                type="text"
                name="edad"
                id="edad"
                autoComplete="off"
                placeholder="Edad"
                maxLength="3"
                onChange={onChange}
                value={typeof data.edad !== "undefined" ? data.edad : ""}
              />
            </label>
          </div>
          <div className="cont-personal col-3">
            <label className="titulo-salario2">
              Correo.
              <input
                className="form-control"
                type="text"
                name="correo"
                id="correo"
                autoComplete="off"
                placeholder="Correo."
                maxLength="50"
                onChange={onChange}
                value={
                  typeof data.correo !== "undefined"
                    ? data.correo.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
        </div>
        <div className="cont-listmedi row">
          <div className="cont-personal col-3">
            <input
              className="form-control"
              type="text"
              name="direccion"
              id="direccion"
              autoComplete="off"
              placeholder="Dirección."
              maxLength="50"
              onChange={onChange}
              value={
                typeof data.direccion !== "undefined"
                  ? data.direccion.toUpperCase()
                  : ""
              }
            />
          </div>

          <div className="cont-personal col-3">
            <label className="titulo-salario2">
              {/* Nombre Dependiente 1. */}
              <input
                className="form-control"
                type="text"
                name="nombreD1"
                id="nombreD1"
                autoComplete="off"
                placeholder="Nombre Dependiente 1."
                maxLength="100"
                onChange={onChange}
                value={
                  typeof data.nombreD1 !== "undefined"
                    ? data.nombreD1.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-1">
            <label className="titulo-salario2">
              {/* Edad Dependiente. */}
              <input
                className="form-control"
                type="text"
                name="edadD1"
                id="edadD1"
                autoComplete="off"
                placeholder="Edad."
                maxLength="3"
                onChange={onChange}
                value={
                  typeof data.edadD1 !== "undefined"
                    ? data.edadD1.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-3">
            <label className="titulo-salario2">
              {/* Parentesco. */}
              <input
                className="form-control"
                type="text"
                name="parentescoD1"
                id="parentescoD1"
                autoComplete="off"
                placeholder="Parentesco."
                maxLength="25"
                onChange={onChange}
                value={
                  typeof data.parentescoD1 !== "undefined"
                    ? data.parentescoD1.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="accionesb col-1">
            {btnnoauto1On === "SI" ? (
              <button className="botonem" onClick={botonnoautorizado}>
                -
              </button>
            ) : (
              <button className="botonem" onClick={botonnoautorizado}>
                +
              </button>
            )}
          </div>
        </div>
        {btnnoauto1On === "SI" && (
          <div className="cont-listmedi row">
            <div className="cont-personal col-3">
              <label className="titulo-salario2">
                {/* Nombre Dependiente 2. */}
                <input
                  className="form-control"
                  type="text"
                  name="nombreD2"
                  id="nombreD2"
                  autoComplete="off"
                  placeholder="Nombre Dependiente 2."
                  maxLength="100"
                  onChange={onChange}
                  value={
                    typeof data.nombreD2 !== "undefined"
                      ? data.nombreD2.toUpperCase()
                      : ""
                  }
                />
              </label>
            </div>
            <div className="cont-personal col-1">
              <label className="titulo-salario2">
                {/* Edad Dependiente. */}
                <input
                  className="form-control"
                  type="text"
                  name="edadD2"
                  id="edadD2"
                  autoComplete="off"
                  placeholder="Edad."
                  maxLength="3"
                  onChange={onChange}
                  value={
                    typeof data.edadD2 !== "undefined"
                      ? data.edadD2.toUpperCase()
                      : ""
                  }
                />
              </label>
            </div>
            <div className="cont-personal col-3">
              <label className="titulo-salario2">
                {/* Parentesco. */}
                <input
                  className="form-control"
                  type="text"
                  name="parentescoD2"
                  id="parentescoD2"
                  autoComplete="off"
                  placeholder="Parentesco."
                  maxLength="25"
                  onChange={onChange}
                  value={
                    typeof data.parentescoD2 !== "undefined"
                      ? data.parentescoD2.toUpperCase()
                      : ""
                  }
                />
              </label>
            </div>
            <div className="accionesb col-1">
              {btnnoauto2On === "SI" ? (
                <button className="botonem" onClick={botonnoautorizado2}>
                  -
                </button>
              ) : (
                <button className="botonem" onClick={botonnoautorizado2}>
                  +
                </button>
              )}
            </div>
          </div>
        )}
        {btnnoauto2On === "SI" && (
          <div className="cont-listmedi row">
            <div className="cont-personal col-3">
              <label className="titulo-salario2">
                {/* Nombre Dependiente 3. */}
                <input
                  className="form-control"
                  type="text"
                  name="nombreD3"
                  id="nombreD3"
                  autoComplete="off"
                  placeholder="Nombre Dependiente 3."
                  maxLength="100"
                  onChange={onChange}
                  value={
                    typeof data.nombreD3 !== "undefined"
                      ? data.nombreD3.toUpperCase()
                      : ""
                  }
                />
              </label>
            </div>
            <div className="cont-personal col-1">
              <label className="titulo-salario2">
                {/* Edad Dependiente. */}
                <input
                  className="form-control"
                  type="text"
                  name="edadD3"
                  id="edadD3"
                  autoComplete="off"
                  placeholder="Edad."
                  maxLength="3"
                  onChange={onChange}
                  value={
                    typeof data.edadD3 !== "undefined"
                      ? data.edadD3.toUpperCase()
                      : ""
                  }
                />
              </label>
            </div>
            <div className="cont-personal col-3">
              <label className="titulo-salario2">
                {/* Parentesco. */}
                <input
                  className="form-control"
                  type="text"
                  name="parentescoD3"
                  id="parentescoD3"
                  autoComplete="off"
                  placeholder="Parentesco."
                  maxLength="30"
                  onChange={onChange}
                  value={
                    typeof data.parentescoD3 !== "undefined"
                      ? data.parentescoD3.toUpperCase()
                      : ""
                  }
                />
              </label>
            </div>
          </div>
        )}
        <div className="cont-listmedi row">
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Nacionalidad.
              <input
                className="form-control"
                type="text"
                name="nacionalidad"
                id="nacionalidad"
                autoComplete="off"
                placeholder="Nacionalidad."
                maxLength="30"
                onChange={onChange}
                value={
                  typeof data.nacionalidad !== "undefined"
                    ? data.nacionalidad.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Estado Civil.
              <select
                id="edocivil"
                name="edocivil"
                value={data.edocivil}
                onChange={onChange}
              >
                <option value="0">-- Estado Civil --</option>
                <option value="1">Soltero(a).</option>
                <option value="2">Casado(a).</option>
                <option value="3">Unido(a).</option>
                <option value="4">Viudo(a).</option>
              </select>
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario">
              Número de Cuenta
              <input
                className="form-control"
                type="text"
                name="cuenta"
                id="cuenta"
                autoComplete="off"
                placeholder="Cuenta del Banco"
                maxLength="30"
                onChange={onChange}
                value={
                  typeof data.cuenta !== "undefined"
                    ? data.cuenta.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario">
              Nombre del Banco
              <input
                className="form-control"
                type="text"
                name="banco1"
                id="banco1"
                autoComplete="off"
                placeholder="Nombre del Banco"
                maxLength="30"
                onChange={onChange}
                value={
                  typeof data.banco1 !== "undefined"
                    ? data.banco1.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario">
              Tipo de Cuenta
              <input
                className="form-control"
                type="text"
                name="tcuenta"
                id="tcuenta"
                autoComplete="off"
                placeholder="Tipo de Cuenta"
                maxLength="30"
                onChange={onChange}
                value={
                  typeof data.tcuenta !== "undefined"
                    ? data.tcuenta.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario">
              Titular Cuenta
              <input
                className="form-control"
                type="text"
                name="titular"
                id="titular"
                autoComplete="off"
                placeholder="Titular Cuenta"
                maxLength="30"
                onChange={onChange}
                value={
                  typeof data.titular !== "undefined"
                    ? data.titular.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
        </div>
        <div className="cont-listmedi row">
          <div className="titulo-antecedentesS col-12">
            <p>USO ADMINISTRATIVO </p>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Cargo.
              <input
                className="form-control"
                type="text"
                name="cargo"
                id="cargo"
                autoComplete="off"
                placeholder="Cargo."
                maxLength="50"
                onChange={onChange}
                value={
                  typeof data.cargo !== "undefined"
                    ? data.cargo.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Salario.
              <input
                className="form-control"
                type="text"
                name="salario"
                id="salario"
                autoComplete="off"
                placeholder="Salario."
                maxLength="50"
                onChange={onChange}
                value={
                  typeof data.salario !== "undefined"
                    ? data.salario.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Horario.
              <input
                className="form-control"
                type="text"
                name="horario"
                id="horario"
                autoComplete="off"
                placeholder="Horario."
                maxLength="50"
                onChange={onChange}
                value={
                  typeof data.horario !== "undefined"
                    ? data.horario.toUpperCase()
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario2">
              Tipo de Contrato.
              <select
                id="tcontrato"
                name="tcontrato"
                value={data.tcontrato}
                onChange={onChange}
              >
                <option value="0">-- Estado Civil --</option>
                <option value="1">Administrativo</option>
                <option value="2">Honorario</option>
                <option value="3">Definido</option>
                <option value="4">Indefinido</option>
              </select>
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario">
              Fecha Inicio
              <input
                className="form-control"
                type="date"
                name="fechaInicio"
                id="fechaInicio"
                autoComplete="off"
                maxLength="10"
                onChange={onChange}
                value={
                  typeof data.fechaInicio !== "undefined"
                    ? data.fechaInicio
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-2">
            <label className="titulo-salario">
              Fecha Egreso.
              <input
                className="form-control"
                type="date"
                name="fechaEgreso"
                id="fechaEgreso"
                autoComplete="off"
                maxLength="10"
                onChange={onChange}
                value={
                  typeof data.fechaEgreso !== "undefined"
                    ? data.fechaEgreso
                    : ""
                }
              />
            </label>
          </div>
        </div>
        <div className="contenedorcedula1 col-12">
          <button
            className={!activarOn ? "boton" : "botoncp"}
            onClick={!activarOn ? guardarEmpleado : ""}
          >
            Guardar Colaborador
          </button>
          <button
            className={activarOn ? "boton" : "botoncp"}
            onClick={activarOn ? modificarEmpleado : ""}
          >
            Modificar Colaborador
          </button>
          <button
            className={activarOn ? "boton" : "botoncp"}
            onClick={activarOn ? eliminarEmpleado : ""}
          >
            Eliminar Colaborador
          </button>
          <button className="boton" onClick={limpiar}>
            Limpiar Datos
          </button>
          <button className="boton" onClick={cerrarLista}>
            Buscar Colaborador
          </button>
          <button
            className={activarOn ? "boton" : "botoncp"}
            onClick={activarOn ? consultarAdjuntos : ""}
          >
            Requisitos Colaborador
          </button>
          <button className="boton" onClick={props.personalClose}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Empleados);
