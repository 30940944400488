import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import FeatherIcon from "feather-icons-react";

//componentes
import "./cita.css";
import Alert from "../../modal/alert";
import logo1 from "../../imagenes/Logo.jpg";
import Modal from "../modal2/modal";

class CitasAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idseccion: "",
      cedulaAlumno: "",
      idseccionA: "",
      trimestre: "",
      idmateria: "",
      anoEscolar: [moment().format("YYYY")],
      ianoEscolar: [],
      iseccionesA: [],
      iinasistencia: [],
      ialumnos: [],
      isecciones: [],
      imaterias: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: false,
      xtotales: "",
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      iterapeuta: [],
      terapeuta: "",

      lunesI: 1,
      martesI: 2,
      miercolesI: 3,
      juevesI: 4,
      viernesI: 5,
      sabadoI: 6,
      domingoI: 7,
      fechaSeleccionada: new Date(),
      fechaInicio: new Date(),
      icasos: [],
      imedicos: [],
      casos: "",
      casos2: "",
      medicos: "",
      tipoFecha: "1",
      fechaIni: "",
      fechaFin: "",
      fechaDescansoI: "",
      fechaDescansoF: "",
      verHorarioOn: false,
      ihorario: [],
      dia1: "",
      dia2: "",
      dia3: "",
      dia4: "",
      dia5: "",
      dia6: "",
      diaI: "",
      diaF: "",
      incluirdatosOn: false,
      alumno: "",
      horaS: "",
      fechaT: moment().format("YYYY-MM-DD"),
    };
  }

  limpiar = () => {
    this.setState({
      idseccionP: "",
      elimi: false,
      listaOn: false,
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      terapeuta: "",
      lunesI: 1,
      martesI: 2,
      miercolesI: 3,
      juevesI: 4,
      viernesI: 5,
      sabadoI: 6,
      domingoI: 7,
      fechaSeleccionada: new Date(),
      fechaInicio: new Date(),
      casos: "",
      casos2: "",
      medicos: "",
      tipoFecha: "1",
      fechaIni: "",
      fechaFin: "",
      fechaDescansoI: "",
      fechaDescansoF: "",
      verHorarioOn: false,
      ihorario: [],
      dia1: "",
      dia2: "",
      dia3: "",
      dia4: "",
      dia5: "",
      dia6: "",
      diaI: "",
      diaF: "",
      incluirdatosOn: false,
      alumno: "",
      horaS: "",
      fechaT: moment().format("YYYY-MM-DD"),
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "casos") {
      this.terapeutasDisponibles(e.target.value);
    }
    if (e.target.name === "medicos") {
      this.consultarHorarioEspecialista(e.target.value);
    }
    if (e.target.name === "tipoFecha") {
      if (e.target.value === "2") this.valorInicial(e.target.value);
    }
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarAlumnos(e.target.value);
    //this.buscarSeccionesA(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.buscarAlumnos(e.target.value);
  }

  terapeutasDisponibles = (e) => {
    let data = new FormData();
    data.append("idespecialidad", e);
    data.append("boton", "consultaTerapeutasEspecialista");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximedicos = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            medicos: "",
            imedicos: [],
            imedicos: ximedicos,
          });
        } else {
          this.setState({
            medicos: "",
            imedicos: [],
          });
        }
      });
  };

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  imprimir = () => {
    let data = new FormData();
    data.append("cedulaTerapeuta", this.state.terapeuta);
    data.append("arregloPaciente", JSON.stringify(this.state.iinformeFechasS));
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteHorarioEspecialista.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  buscarI = () => {
    if (
      this.state.trimestre.length > 0 &&
      // this.state.idmateria.length > 0 &&
      this.state.idseccion.length > 0
    ) {
      let data = new FormData();
      data.append("idseccion", this.state.idseccion);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("trimestre", this.state.trimestre);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      if (this.state.cedulaAlumno === "") {
        data.append("boton", "buscarInasistenciaG");
      } else {
        data.append("boton", "buscarInasistencia");
      }

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/alumnoMaestro.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              xtotales: res.data.variable3,
            });
          } else {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              mensajealerta: true,
              tituloM: "Menu Consultar Inasistencias.",
              cuerpoM: res.data.variable3,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencias.",
        cuerpoM:
          "Debe seleccionar un Trimestre, un Alumno, un Grado y una Materia.",
      });
    }
  };

  eliminar = () => {
    if (this.state.idseccionA.length > 0) {
      let data = new FormData();
      data.append("idseccionesA", this.state.idseccionA);
      data.append("boton", "eliminarSeccionesA");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
          this.buscarSeccionesA(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Grados Alumnos.",
        cuerpoM: "Debe existir un registro para poder eliminar la Sección.",
      });
    }
  };

  buscarSeccionesA = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("anoEscolar", this.state.anoEscolar);
        data.append("cedulaAlumno", e);
        data.append("boton", "buscarSeccionesA");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xisecciones = res.data.variable3;
            let xiseccionesA = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                isecciones: xisecciones,
                iseccionesA: xiseccionesA,
              });
            } else {
              this.setState({
                iseccionesP: [],
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Grados Alumnos.",
          cuerpoM: "Debe existir un Alumno para realizar el proceso.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Seccion Alumnos.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosG");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Asignar Grados a Alumnos",
      cuerpoM:
        "Puede que el Grado Asignado ya tenga notas Asociadas, Desea Realmente Eliminar el Registro...",
      idseccionA: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  buscarIT = () => {
    let data = new FormData();
    data.append("cedulaTerapeuta", this.state.terapeuta);
    data.append("boton", "consultarInformeTerapeuta");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiinformeFechasS = res.data.variable2;
        let xiinformeFechas = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
            iinformeFechasS: xiinformeFechasS,
            iinformeFechas: xiinformeFechas,
          });
        } else {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
          });
        }
      });
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Paciente con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
                verplanillaOn: false,
                incluirdatosOn: true,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
                verplanillaOn: false,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };
  cerrarLista2 = () => {
    //console.log(this.state.listaOn)
    if (this.state.verplanillaOn) {
      this.setState({
        verplanillaOn: false,
      });
    }
    if (!this.state.verplanillaOn) {
      this.setState({
        verplanillaOn: true,
      });
    }
  };
  incluirdatos = () => {
    //console.log(this.state.listaOn)
    if (this.state.incluirdatosOn) {
      this.setState({
        incluirdatosOn: false,
      });
    }
    if (!this.state.incluirdatosOn) {
      this.setState({
        incluirdatosOn: true,
      });
    }
  };

  buscarPlanilla = () => {
    if (
      this.state.tbusqueda === "1" ||
      this.state.tbusqueda === "2" ||
      this.state.tbusqueda === "3" ||
      this.state.tbusqueda === "4"
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.setState({ grado: "0" });
        this.buscarCursosGrados();
        //this.buscarCursosGrados()
      }
      if (this.state.tbusqueda === "4") {
        this.buscarPorNombres3();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  buscarE = () => {
    let data = new FormData();
    data.append("boton", "Buscar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);

        let xterapeuta = res.data;
        this.setState({
          iterapeuta: xterapeuta,
        });
      });
  };

  onClickPrevNextLabel = ({ activeStartDate, value, view }) => {
    // console.log("vv:", activeStartDate, value, view);
    // console.log(value);
    this.setState({
      fechaSeleccionada: activeStartDate,
    });
  };

  onClickMonth = (e) => {
    this.setState({
      fechaSeleccionada: e,
    });

    // console.log(e);
    // console.log('mes');
  };
  valorInicial = (e) => {
    if (e === "2") {
      let xfecha = new Date(moment(this.state.fechaSeleccionada).format());

      let xdia1 = new Date(
        moment(xfecha, "DD-MM-YYYY").add(-(xfecha.getDay() - 1), "day")
      );
      let xdia2 = new Date(moment(xdia1, "DD-MM-YYYY").add(1, "day"));
      let xdia3 = new Date(moment(xdia2, "DD-MM-YYYY").add(1, "day"));
      let xdia4 = new Date(moment(xdia3, "DD-MM-YYYY").add(1, "day"));
      let xdia5 = new Date(moment(xdia4, "DD-MM-YYYY").add(1, "day"));
      let xdia6 = new Date(moment(xdia5, "DD-MM-YYYY").add(1, "day"));
      this.state.diaI = xdia1;
      this.state.diaF = xdia6;
      this.setState({
        dia1: xdia1.getDate(),
        dia2: xdia2.getDate(),
        dia3: xdia3.getDate(),
        dia4: xdia4.getDate(),
        dia5: xdia5.getDate(),
        dia6: xdia6.getDate(),
        fechaSeleccionada: xdia1,
      });
    }

    if (e === "1") {
      let xfecha = new Date(moment(this.state.fechaSeleccionada).format());
      //      let xfecha = new Date(e);
      let xdia1 = new Date(moment(xfecha, "DD-MM-YYYY"));
      this.state.diaI = xdia1;
      this.setState({
        diaF: "",
        dia1: xdia1.getDate(),
        dia2: "",
        dia3: "",
        dia4: "",
        dia5: "",
        dia6: "",
        fechaSeleccionada: xdia1,
      });
    }
  };

  derecha = () => {
    if (this.state.tipoFecha === "2") {
      let xfecha = new Date(
        moment(this.state.fechaSeleccionada).add(7, "day").format()
      );

      let xdia1 = new Date(
        moment(xfecha, "DD-MM-YYYY").add(-(xfecha.getDay() - 1), "day")
      );
      let xdia2 = new Date(moment(xdia1, "DD-MM-YYYY").add(1, "day"));
      let xdia3 = new Date(moment(xdia2, "DD-MM-YYYY").add(1, "day"));
      let xdia4 = new Date(moment(xdia3, "DD-MM-YYYY").add(1, "day"));
      let xdia5 = new Date(moment(xdia4, "DD-MM-YYYY").add(1, "day"));
      let xdia6 = new Date(moment(xdia5, "DD-MM-YYYY").add(1, "day"));
      this.state.diaI = xdia1;
      this.state.diaF = xdia6;
      this.setState({
        dia1: xdia1.getDate(),
        dia2: xdia2.getDate(),
        dia3: xdia3.getDate(),
        dia4: xdia4.getDate(),
        dia5: xdia5.getDate(),
        dia6: xdia6.getDate(),
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }
    if (this.state.tipoFecha === "1") {
      let xfecha = new Date(
        moment(this.state.fechaSeleccionada).add(1, "day").format()
      );

      let xdia1 = new Date(moment(xfecha, "DD-MM-YYYY"));
      this.state.diaI = xdia1;
      this.setState({
        diaF: "",
        dia1: xdia1.getDate(),
        dia2: "",
        dia3: "",
        dia4: "",
        dia5: "",
        dia6: "",
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }
  };
  izquierda = () => {
    // console.log(this.state.fechaSeleccionada);
    // console.log(this.state.fechaSeleccionada.getMonth());
    // console.log(this.state.fechaSeleccionada.getDay());
    // console.log(this.state.fechaSeleccionada.getDate());
    // let xfecha = new Date(
    //   moment(this.state.fechaSeleccionada).add(-7, "day").format()
    // );
    // console.log(xfecha);
    // console.log(xfecha.getMonth());
    // console.log(xfecha.getDay());
    // console.log(xfecha.getDate());
    //console.log(this.state.tipoFecha);
    if (this.state.tipoFecha === "2") {
      let xfecha = new Date(
        moment(this.state.fechaSeleccionada).add(-7, "day").format()
      );

      let xdia1 = new Date(
        moment(xfecha, "DD-MM-YYYY").add(-(xfecha.getDay() - 1), "day")
      );
      let xdia2 = new Date(moment(xdia1, "DD-MM-YYYY").add(1, "day"));
      let xdia3 = new Date(moment(xdia2, "DD-MM-YYYY").add(1, "day"));
      let xdia4 = new Date(moment(xdia3, "DD-MM-YYYY").add(1, "day"));
      let xdia5 = new Date(moment(xdia4, "DD-MM-YYYY").add(1, "day"));
      let xdia6 = new Date(moment(xdia5, "DD-MM-YYYY").add(1, "day"));
      this.state.diaI = xdia1;
      this.state.diaF = xdia6;
      this.setState({
        dia1: xdia1.getDate(),
        dia2: xdia2.getDate(),
        dia3: xdia3.getDate(),
        dia4: xdia4.getDate(),
        dia5: xdia5.getDate(),
        dia6: xdia6.getDate(),
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }
    if (this.state.tipoFecha === "1") {
      let xfecha = new Date(
        moment(this.state.fechaSeleccionada).add(-1, "day").format()
      );

      let xdia1 = new Date(moment(xfecha, "DD-MM-YYYY"));
      this.state.diaI = xdia1;
      this.setState({
        diaF: "",
        dia1: xdia1.getDate(),
        dia2: "",
        dia3: "",
        dia4: "",
        dia5: "",
        dia6: "",
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }
  };
  onClickDay = (e) => {
    // console.log(e);
    // console.log(e.getMonth());
    // console.log(e.getDay());
    // console.log(e.getDate());
    if (this.state.tipoFecha === "2") {
      let xfecha = new Date(e);
      let xdia1 = new Date(
        moment(xfecha, "DD-MM-YYYY").add(-(e.getDay() - 1), "day")
      );
      let xdia2 = new Date(moment(xdia1, "DD-MM-YYYY").add(1, "day"));
      let xdia3 = new Date(moment(xdia2, "DD-MM-YYYY").add(1, "day"));
      let xdia4 = new Date(moment(xdia3, "DD-MM-YYYY").add(1, "day"));
      let xdia5 = new Date(moment(xdia4, "DD-MM-YYYY").add(1, "day"));
      let xdia6 = new Date(moment(xdia5, "DD-MM-YYYY").add(1, "day"));
      this.setState({
        diaI: xdia1,
        diaF: xdia6,
        dia1: xdia1.getDate(),
        dia2: xdia2.getDate(),
        dia3: xdia3.getDate(),
        dia4: xdia4.getDate(),
        dia5: xdia5.getDate(),
        dia6: xdia6.getDate(),
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }

    if (this.state.tipoFecha === "1") {
      let xfecha = new Date(e);
      let xdia1 = new Date(moment(xfecha, "DD-MM-YYYY"));
      this.state.diaI = xdia1;
      this.setState({
        diaF: "",
        dia1: xdia1.getDate(),
        dia2: "",
        dia3: "",
        dia4: "",
        dia5: "",
        dia6: "",
        fechaSeleccionada: xdia1,
      });
      this.consultarHorarioEspecialista(this.state.medicos);
    }

    // let data = new FormData();
    // data.append("idcaso", this.state.casos);
    // data.append("especialista", this.state.terapeuta);
    // data.append("dia", e.getDay());
    // data.append("fechaY", e.getFullYear());
    // data.append("fechaM", e.getMonth());
    // data.append("fechaD", e.getDate());
    // data.append("boton", "ConsultarDisponibilidadHora");
    // let config = {
    //   headers: { "Content-Type": "text/html" },
    // };
    // const xurl =
    //   "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    // axios
    //   .post(xurl, data, config)

    //   .then((res) => {
    //     let xhorasDias = res.data;
    //     this.setState({
    //       ihoraCita: xhorasDias,
    //       fechaCita: "paso",
    //     });
    //   });
  };

  asociarEvaluacion = () => {
    let data = new FormData();
    data.append("boton", "Consultar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xcasos = res.data;
        this.setState({
          icasos: xcasos,
        });
      });
  };

  consultarHorarioEspecialista = (e) => {
    let data = new FormData();
    data.append("cedulaEspecialista", e);
    data.append("dia", this.state.fechaSeleccionada.getDay());
    data.append("tbusqueda", this.state.tipoFecha);
    data.append("boton", "consultarHorarioEspecialista");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xihorario = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            ihorario: [],
            verHorarioOn: true,
            ihorario: res.data.variable2,
          });
        } else {
          this.setState({
            verHorarioOn: false,
            ihorario: res.data.variable2,
          });
        }
      });
  };
  consultarPaciente = (e, f, g) => {
    if (f !== "") {
      console.log("tiene datos " + f);
    } else {
      let xfechaT = "";
      let xdia = "";
      if (g >= xdia) {
        xdia = moment(this.state.diaI).format("DD");
        xfechaT = new Date(
          moment(this.state.diaI, "DD-MM-YYYY").add(g - xdia, "day")
        );
      }
      if (g < xdia) {
        xdia = moment(this.state.diaF).format("DD");
        xfechaT = new Date(
          moment(this.state.diaF, "DD-MM-YYYY").add(-(xdia - g), "day")
        );
      }

      this.setState({
        horaS: e.substring(0, 2),
        fechaT: moment(xfechaT).format("YYYY-MM-DD"),
        casos2: this.state.casos,
        verplanillaOn: true,
      });
    }
  };

  guardarCita = () => {};

  componentDidMount() {
    this.buscarE();
    this.asociarEvaluacion();
    this.valorInicial(this.state.tipoFecha);
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MAESTRO DE CITAS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {this.state.verplanillaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>
                      SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                      CORRESPONDIENTA A SU SELECCIÓN.{" "}
                    </p>
                  </div>
                  <div className="row">
                    <div className="fechas col-12">
                      <select
                        id="tbusqueda"
                        name="tbusqueda"
                        value={this.state.tbusqueda}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Buscar por --</option>
                        <option value="1">CEDULA/PASAPORTE</option>
                        <option value="2">NOMBRE PACIENTE</option>
                        {/* <option value="3">CURSO/GRADO</option>
                    <option value="4">TODOS LOS PACIENTES</option> */}
                      </select>
                    </div>
                  </div>

                  {this.state.tbusqueda < "3" && (
                    <div className="contenedorcedula1 row">
                      <input
                        className="form-control"
                        type="text"
                        name="identificacionp1"
                        id="identificacionp1"
                        autoComplete="off"
                        placeholder={
                          this.state.tbusqueda === "1"
                            ? "Identificación Paciente."
                            : "Nombre Paciente."
                        }
                        maxLength="300"
                        onChange={this.onChange.bind(this)}
                        value={this.state.identificacionp1.toUpperCase()}
                      />
                    </div>
                  )}
                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className="boton"
                      onClick={this.buscarPlanilla.bind(this)}
                    >
                      Consultar
                    </button>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Otra Consulta
                    </button>

                    <button
                      className="boton"
                      onClick={this.cerrarLista2.bind(this)}
                    >
                      Salir
                    </button>
                  </div>

                  {this.state.listaOn && (
                    <div className="cont-tablamedi row">
                      <div className="tablamedi1 col-11">
                        <div className="cont-titulomedi col-12">
                          <span className="titulocasos">
                            Listado de Pacientes
                          </span>
                          <span
                            className="titulo-cerrarcasos"
                            onClick={this.cerrarLista.bind(this)}
                          >
                            X
                          </span>
                        </div>
                        {/* <span className="titulo-casos">Datos del Pago</span> */}
                        <div className="cont-listmedi row">
                          <div className="listmedi col-12">
                            <table className="tablame" border="1">
                              <tbody className="evocuerpo1">
                                {this.state.nalumno.map((item, i) => {
                                  return (
                                    <tr
                                      className={i % 2 === 0 ? "odd" : ""}
                                      key={item.variable1}
                                      id="tabla"
                                    >
                                      <td
                                        className="renglon"
                                        width="10"
                                        onClick={this.consulta.bind(
                                          this,
                                          item.variable1
                                        )}
                                      >
                                        {item.variable2}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {this.state.incluirdatosOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>
                      {this.state.cedulaAlumno}
                      {" - "}
                      {this.state.alumno}
                    </p>
                  </div>
                  <div className="row">
                    <div className="contenedor-cedula col-12">
                      <span className="fecha2">Fecha: {" "}{moment(this.state.fechaT).format("DD-MM-YYYY")}</span>
                      <span className="fecha2">Hora: {" "}{this.state.horaS}:00</span>
                      {/* <span className="fecha2">{this.state.casos2}</span> */}
                      
                      
                    </div>
                  </div>

                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className="boton"
                      onClick={this.guardarCita.bind(this)}
                    >
                      Guardar
                    </button>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Otra Consulta
                    </button>

                    <button
                      className="boton"
                      onClick={this.incluirdatos.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            {/* <div className="col-3"></div> */}

            <div className="col-3">
              <Calendar
                name="fechaSeleccionada"
                activeStartDate={this.state.fechaSeleccionada}
                // prevLabel={false}
                //prev2Label={false}
                //next2Label={false}
                // nextLabel={false}
                selectRange={false}
                showNavigation={true}
                showNeighboringMonth={true}
                onClickDay={(day) => {
                  this.onClickDay(day);
                }}
                //onActiveStartDateChange={this.onClickPrevNextLabel.bind(this)}
                //onClickDay={this.onClickDay.bind(this)}
                //onChange={this.onClickPrevLabel.bind(this)}
                onClickMonth={this.onClickMonth.bind(this)}
                value={this.state.fechaSeleccionada}
              />
              <div className="cont-forma row">
                <div className="col-5">
                  <div className="">
                    <select
                      id="tipoFecha"
                      name="tipoFecha"
                      value={this.state.tipoFecha}
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="1">Por Dia</option>
                      <option value="2">Por Semana</option>
                    </select>
                  </div>
                </div>

                <div className="cont-Boton col-2">
                  <div className="btn-group" role="group">
                    <button
                      className="botonff1"
                      onClick={this.izquierda.bind(this)}
                    >
                      {"<"}
                    </button>
                    <button
                      className="botonff2"
                      onClick={this.derecha.bind(this)}
                    >
                      {">"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-4">
                  <select
                    id="casos"
                    name="casos"
                    value={this.state.casos}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-Seleccione Especialidad-</option>
                    {this.state.icasos.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4">
                  <select
                    id="medicos"
                    name="medicos"
                    value={this.state.medicos}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-Seleccione Especialista-</option>
                    {this.state.imedicos.map((item, i) => {
                      return (
                        <option
                          className={item.variable3 === 1 ? "dispo1" : ""}
                          key={i}
                          value={item.variable1}
                        >
                          {item.variable2}
                          {/* {item.variable3} */}

                          {/* {item.variable3 === "1" &&
                            
                            <span className="dispo1">{item.variable2}</span>
                          }
                          {item.variable3 !== "1" && (
                            <span className="dispo2">{item.variable2}</span>
                          )} */}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="xcontenedorcedula1col-4">
                  {/* <div className="xcontenedorcedula1 col-12"> */}
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Otra Consulta
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                  {/* </div> */}
                </div>
              </div>

              {this.state.ihorario.length > 0 && (
                <div class="fluid row">
                  <div class="col-12 align-self-start">
                    {this.state.tipoFecha === "2" && (
                      <p className="mostrarFechas">
                        {moment(this.state.diaI).format("DD-MM-YYYY")}
                        {" - "}
                        {moment(this.state.diaF).format("DD-MM-YYYY")}
                      </p>
                    )}
                    {this.state.tipoFecha === "1" && (
                      <p className="mostrarFechas">
                        {moment(this.state.diaI).format("DD-MM-YYYY")}
                      </p>
                    )}
                  </div>
                  {this.state.tipoFecha === "1" && (
                    <div
                      id="detalleseleccion"
                      name="detalleseleccion"
                      class="este"
                    >
                      {this.state.ihorario.map((item, i) => {
                        return (
                          <div className="cont-diasterapia">
                            {i === 0 && (
                              // <div className="cont-diasterapia2 row">
                              <div className="padre row">
                                <div className="diasH">HORA</div>
                                <div className="diasSD">
                                  {moment(this.state.diaI).format("dddd LL")}
                                </div>
                              </div>
                            )}
                            {item.variable2 === "LIBRE" && (
                              <div className="padre row">
                                <div className="XdiasHl">{item.variable1}</div>
                                <div className="XdiasSlD">{item.variable2}</div>
                              </div>
                            )}
                            {item.variable2 !== "LIBRE" && (
                              <div className="padre row">
                                <div className="XdiasH">{item.variable1}</div>
                                <div
                                  className="XdiasSDC"
                                  onClick={this.consultarPaciente.bind(
                                    this,
                                    item.variable1,
                                    item.variable2
                                  )}
                                >
                                  <div className="counter">
                                    {item.variable2 !== "" ? (
                                      <span>
                                        <FeatherIcon
                                          className="ayuda"
                                          icon="help-circle"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="confirmado"
                                          icon="edit-3"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="enconsulta"
                                          icon="check"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="dolar"
                                          icon="dollar-sign"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="cancelado"
                                          icon="x"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="noasistio"
                                          icon="x-circle"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="espera"
                                          icon="clock"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="nocobrado"
                                          icon="user-minus"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="donacion"
                                          icon="user-check"
                                          size="10px"
                                          stroke="#999"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="counter2">
                                    {item.variable2}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {this.state.tipoFecha === "2" && (
                    <div
                      id="detalleseleccion"
                      name="detalleseleccion"
                      class="este"
                    >
                      {this.state.ihorario.map((item, i) => {
                        return (
                          <div className="cont-diasterapia">
                            {i === 0 && (
                              // <div className="cont-diasterapia2 row">
                              <div className="padre row">
                                <div className="diasH">HORA</div>
                                <div className="diasS">
                                  LUNES {this.state.dia1}
                                </div>
                                <div className="diasS">
                                  MARTES {this.state.dia2}
                                </div>
                                <div className="diasS">
                                  MIERCOLES {this.state.dia3}
                                </div>
                                <div className="diasS">
                                  JUEVES {this.state.dia4}
                                </div>
                                <div className="diasS">
                                  VIERNES {this.state.dia5}
                                </div>
                                <div className="diasS">
                                  SABADO {this.state.dia6}
                                </div>
                              </div>
                            )}
                            {item.variable2 === "LIBRE" && (
                              <div className="padre row">
                                <div className="XdiasHl">{item.variable1}</div>
                                <div className="XdiasSl">{item.variable2}</div>
                                <div className="XdiasSl">{item.variable3}</div>
                                <div className="XdiasSl">{item.variable4}</div>
                                <div className="XdiasSl">{item.variable5}</div>
                                <div className="XdiasSl">{item.variable6}</div>
                                <div className="XdiasSl">{item.variable7}</div>
                              </div>
                            )}
                            {item.variable2 !== "LIBRE" && (
                              <div className="padre row">
                                <div className="XdiasH">{item.variable1}</div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={this.consultarPaciente.bind(
                                    this,
                                    item.variable1,
                                    item.variable2,
                                    this.state.dia1
                                  )}
                                >
                                  <div className="counter">
                                    {item.variable2 !== "" ? (
                                      <span>
                                        <FeatherIcon
                                          className="ayuda"
                                          icon="help-circle"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="confirmado"
                                          icon="edit-3"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="enconsulta"
                                          icon="check"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="dolar"
                                          icon="dollar-sign"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="cancelado"
                                          icon="x"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="noasistio"
                                          icon="x-circle"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="espera"
                                          icon="clock"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="nocobrado"
                                          icon="user-minus"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="donacion"
                                          icon="user-check"
                                          size="10px"
                                          stroke="#999"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="counter2">
                                    {item.variable2}
                                  </div>
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={this.consultarPaciente.bind(
                                    this,
                                    item.variable1,
                                    item.variable2,
                                    this.state.dia2
                                  )}
                                >
                                  <div className="counter">
                                    {item.variable3 !== "" ? (
                                      <span>
                                        <FeatherIcon
                                          className="ayuda"
                                          icon="help-circle"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="confirmado"
                                          icon="edit-3"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="enconsulta"
                                          icon="check"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="dolar"
                                          icon="dollar-sign"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="cancelado"
                                          icon="x"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="noasistio"
                                          icon="x-circle"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="espera"
                                          icon="clock"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="nocobrado"
                                          icon="user-minus"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="donacion"
                                          icon="user-check"
                                          size="10px"
                                          stroke="#999"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="counter2">
                                    {item.variable3}
                                  </div>
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={this.consultarPaciente.bind(
                                    this,
                                    item.variable1,
                                    item.variable2,
                                    this.state.dia3
                                  )}
                                >
                                  <div className="counter">
                                    {item.variable4 !== "" ? (
                                      <span>
                                        <FeatherIcon
                                          className="ayuda"
                                          icon="help-circle"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="confirmado"
                                          icon="edit-3"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="enconsulta"
                                          icon="check"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="dolar"
                                          icon="dollar-sign"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="cancelado"
                                          icon="x"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="noasistio"
                                          icon="x-circle"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="espera"
                                          icon="clock"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="nocobrado"
                                          icon="user-minus"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="donacion"
                                          icon="user-check"
                                          size="10px"
                                          stroke="#999"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="counter2">
                                    {item.variable4}
                                  </div>
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={this.consultarPaciente.bind(
                                    this,
                                    item.variable1,
                                    item.variable2,
                                    this.state.dia4
                                  )}
                                >
                                  <div className="counter">
                                    {item.variable5 !== "" ? (
                                      <span>
                                        <FeatherIcon
                                          className="ayuda"
                                          icon="help-circle"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="confirmado"
                                          icon="edit-3"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="enconsulta"
                                          icon="check"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="dolar"
                                          icon="dollar-sign"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="cancelado"
                                          icon="x"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="noasistio"
                                          icon="x-circle"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="espera"
                                          icon="clock"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="nocobrado"
                                          icon="user-minus"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="donacion"
                                          icon="user-check"
                                          size="10px"
                                          stroke="#999"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="counter2">
                                    {item.variable5}
                                  </div>
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={this.consultarPaciente.bind(
                                    this,
                                    item.variable1,
                                    item.variable2,
                                    this.state.dia5
                                  )}
                                >
                                  <div className="counter">
                                    {item.variable6 !== "" ? (
                                      <span>
                                        <FeatherIcon
                                          className="ayuda"
                                          icon="help-circle"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="confirmado"
                                          icon="edit-3"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="enconsulta"
                                          icon="check"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="dolar"
                                          icon="dollar-sign"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="cancelado"
                                          icon="x"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="noasistio"
                                          icon="x-circle"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="espera"
                                          icon="clock"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="nocobrado"
                                          icon="user-minus"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="donacion"
                                          icon="user-check"
                                          size="10px"
                                          stroke="#999"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="counter2">
                                    {item.variable6}
                                  </div>
                                </div>
                                <div
                                  className="XdiasSDC2"
                                  onClick={this.consultarPaciente.bind(
                                    this,
                                    item.variable1,
                                    item.variable2,
                                    this.state.dia6
                                  )}
                                >
                                  <div className="counter">
                                    {item.variable7 !== "" ? (
                                      <span>
                                        <FeatherIcon
                                          className="ayuda"
                                          icon="help-circle"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="confirmado"
                                          icon="edit-3"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="enconsulta"
                                          icon="check"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="dolar"
                                          icon="dollar-sign"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="cancelado"
                                          icon="x"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="noasistio"
                                          icon="x-circle"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="espera"
                                          icon="clock"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="nocobrado"
                                          icon="user-minus"
                                          size="10px"
                                          stroke="#999"
                                        />{" "}
                                        <FeatherIcon
                                          className="donacion"
                                          icon="user-check"
                                          size="10px"
                                          stroke="#999"
                                        />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="counter2">
                                    {item.variable7}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CitasAction;
