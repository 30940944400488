import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./inventario.css";
import Alert from "../../modal/alert";
import FeatherIcon from "feather-icons-react";

class Inventario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajealerta: false,
      cuentaContable: "",
      idinventario: "",
      nombrepro: "",
      costo: "",
      cantidad: "",
      precio1: "",
      stockminimo: "",
      impuesto: "",
      flotadorCOn: false,
      irutas: [],
      inventario: [],
      cuenta: [],
      nombreImagen: "",
      guardarOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      mensajealerta: false,
      idinventario: "",
      cuentaContable: "",
      nombrepro: "",
      costo: "",
      cantidad: "",
      precio1: "",
      stockminimo: "",
      impuesto: "",
      flotadorCOn: false,
      // irutas:[],
      nombreImagen: "",
      guardarOn: false,
    });
  };

  cargarFechas = () => {
    let data = new FormData();
    data.append("boton", "cargarFechas");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/reportarPago.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xifechamedi1 = res.data.variable1;
      let xifechamedi2 = res.data.variable2;
      this.setState({
        meses: xifechamedi1,
        anos: xifechamedi2,
      });
    });
  };

  cargarCuentas = () => {
    let data = new FormData();
    data.append("boton", "buscarCodigosContablesAsientos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/contabilidad.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xcuentas = res.data.variable1;
      this.setState({
        cuenta: xcuentas,
      });
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.value;
    let curso = this.state.icurso[index].variable1;
    //let concepto= this.state.icurso[index].variable2
    this.setState({
      curso: curso,
    });
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  Upload = (e) => {
    //console.log(e.target.files)
    this.setState({
      loading: true,
    });
    const form_data = new FormData();

    let name_fvar = "";
    for (let x = 0; x < e.target.files.length; x++) {
      name_fvar = "file_" + String(x + 1);
      form_data.append(name_fvar, e.target.files[x]);
      //console.log(name_fvar)
    }

    form_data.append("idinventario", this.state.idinventario);
    form_data.append("nombreImagen", this.state.nombreImagen);
    form_data.append("boton", "GuardarAdjuntos");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          // alert ('Los ArDocumentos fueron enviados exitosamente');
          this.setState({
            nombreImagen: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Inventario.",
            cuerpoM: "No se pudo Guardar la Imagen",
          });
        }
        //console.log(res);
      });
  };

  ClickUpload = (e) => {
    document.getElementById("upload-file").click();
  };

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  guardar = () => {
    //console.log(e);
    if (this.state.nombrepro.length > 0) {
      let impu = "";
      if (this.state.impuesto === "1") impu = "SI";
      if (this.state.impuesto === "2") impu = "NO";
      let data = new FormData();
      data.append("nombrepro", this.state.nombrepro);
      //data.append("cantidad", this.state.cantidad);
      data.append("cantidad", '1000');
      data.append("costo", this.state.costo);
      data.append("precio", this.state.precio1);
      data.append("stockminimo", this.state.stockminimo);
      data.append("impuesto", impu);
      data.append("cuentaContable", this.state.cuentaContable);
      data.append("boton", "guardar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          this.setState({
            loading: false,
          });
          if (res.data.variable1) {
            this.consultarInventario();
            this.setState({
              guardarOn: true,
              mensajealerta: true,
              tituloM: "Menu Inventario.",
              cuerpoM: "El producto se guardo correctamente",
            });
          } else {
            this.setState({
              guardarOn: true,
              mensajealerta: true,
              tituloM: "Menu Inventario.",
              cuerpoM: "El producto no se pudo guardar, verifique por favor",
            });
          }
        });
    }
  };

  modificar = () => {
    //console.log(e);
    if (this.state.nombrepro.length > 0) {
      let data = new FormData();
      data.append("idinventario", this.state.idinventario);
      data.append("nombrepro", this.state.nombrepro);
      data.append("cantidad", this.state.cantidad);
      data.append("costo", this.state.costo);
      data.append("precio", this.state.precio1);
      data.append("stockminimo", this.state.stockminimo);
      data.append("impuesto", this.state.impuesto);
      data.append("cuentaContable", this.state.cuentaContable);
      data.append("boton", "modificar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultarInventario();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inventario.",
              cuerpoM: "El producto se modifico con  exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inventario.",
              cuerpoM: "El producto No se pudo Modificar.",
            });
          }
        });
    }
  };

  eliminar = () => {
    //console.log(e);
    if (this.state.nombrepro.length > 0) {
      let data = new FormData();
      data.append("idinventario", this.state.idinventario);
      data.append("nombreImagen", this.state.nombreImagen);
      data.append("boton", "eliminar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.consultarInventario();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inventario.",
              cuerpoM: "El producto se Elimino con exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inventario.",
              cuerpoM: "El producto No se pudo Eliminar.",
            });
          }
        });
    }
  };

  eliarchivo = (e, i) => {
    //console.log(e);
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("archivoArriba", e);
    data.append("boton", "eliminarAdjuntosInternos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/correos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          this.consultarAdjuntos();
        } else {
          this.setState({
            paso: true,
            mensaje: "Hubo un Error los Archivos no Fueron Adjuntados",
          });
        }
        //console.log(res);
      });
  };

  consultaIndividual = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar();
          this.setState({
            guardarOn: true,
            idinventario: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidad: res.data.variable4,
            costo: res.data.variable5,
            precio1: res.data.variable6,
            stockminimo: res.data.variable7,
            impuesto: res.data.variable8,
            nombreImagen: res.data.variable9,
            cuentaContable: res.data.variable10,
          });
        } else {
          this.setState({
            guardarOn: false,
            mensajealerta: true,
            tituloM: "Menu Inventario.",
            cuerpoM: "El producto no se encontro, verifique por favor",
          });
        }
        //console.log(res);
      });
  };

  componentDidMount() {
    this.cargarCuentas();
    this.consultarInventario();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MAESTRO DE INVENTARIO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="form-group row">
            <div className="contenedorcedula1 col-5">
              <input
                className="form-control"
                type="text"
                name="nombrepro"
                id="nombrepro"
                autoComplete="off"
                placeholder="Nombre del Producto"
                maxLength="300"
                onChange={this.onChange.bind(this)}
                value={this.state.nombrepro.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="cantidad"
                id="cantidad"
                autoComplete="off"
                placeholder="Cantidad."
                maxLength="5"
                // readOnly="readonly"
                onChange={this.onChange.bind(this)}
                value={this.state.cantidad.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="costo"
                id="costo"
                autoComplete="off"
                placeholder="Costo."
                maxLength="10"
                onChange={this.onChange.bind(this)}
                value={this.state.costo.toUpperCase()}
              />
            </div>
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="precio1"
                id="precio1"
                autoComplete="off"
                placeholder="Precio."
                maxLength="10"
                readOnly={this.props.usuario1.password !== "Cjlp197211" && this.props.usuario1.password !== "13011970" ? "readonly":""}
                onChange={this.onChange.bind(this)}
                value={this.state.precio1.toUpperCase()}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="contenedorcedula1 col-2">
              <input
                className="form-control"
                type="text"
                name="stockminimo"
                id="stockminimo"
                autoComplete="off"
                placeholder="Stock Minimo"
                maxLength="3"
                onChange={this.onChange.bind(this)}
                value={this.state.stockminimo.toUpperCase()}
              />
            </div>

            <div className="contenedorimpuesto col-2">
              <select
                id="impuesto"
                name="impuesto"
                value={this.state.impuesto}
                onChange={this.onChange.bind(this)}
              >
                <option>-- Paga Impuesto --</option>
                <option value="1">SI</option>
                <option value="2">NO</option>
              </select>
            </div>
            <div className="contenedorcedula1 col-1">
              <select
                id="cuentaContable"
                name="cuentaContable"
                value={this.state.cuentaContable}
                onChange={this.onChange.bind(this)}
              >
                <option>Cuenta Contable</option>

                {this.state.cuenta.map((item, i) => {
                  return (
                    <option key={i} value={item.variable2}>
                      {item.variable6 + " - " + item.variable3}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="form-group row">
            {this.state.guardarOn === true && (
              <div className="contenido1 row">
                <div className="form-groupef1 col-12">
                  <div className="cont-buscar1 row">
                    <div
                      className="identificacionr2 col-12"
                      onClick={this.ClickUpload.bind(this)}
                    >
                      <p className="subirtexto1">Subir Imagen</p>
                      <FeatherIcon icon="upload" size="20px" stroke="#999" />
                      <input
                        onChange={this.Upload.bind(this)}
                        style={{ display: "none" }}
                        id="upload-file"
                        type="file"
                        // multiple
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.guardarOn === true && (
              <div className="form-group row">
                <div className="contenedorcedula3 col-12">
                  <input
                    className="form-control"
                    type="text"
                    name="nombreImagen"
                    id="nombreImagen"
                    autoComplete="off"
                    placeholder="No hay imagen para este Producto."
                    maxLength="500"
                    readOnly="readonly"
                    onChange={this.onChange.bind(this)}
                    value={this.state.nombreImagen.toUpperCase()}
                  />
                </div>
              </div>
            )}
            <div className="contenedorcedula3 col-12">
              {this.props.usuario1.nivel !== "5" && (
                <button
                  className={this.state.guardarOn ? "botonOf" : "boton"}
                  onClick={this.guardar.bind(this)}
                  disabled={this.state.guardarOn}
                >
                  Guardar
                </button>
              )}
              {this.props.usuario1.nivel !== "5" && (
                <button
                  className={this.state.guardarOn ? "boton" : "botonOf"}
                  onClick={this.modificar.bind(this)}
                  disabled={!this.state.guardarOn}
                >
                  Modificar
                </button>
              )}
              {this.props.usuario1.nivel !== "5" && (
                <button
                  className={this.state.guardarOn ? "boton" : "botonOf"}
                  onClick={this.eliminar.bind(this)}
                  disabled={!this.state.guardarOn}
                >
                  Eliminar
                </button>
              )}
              <button className="boton" onClick={this.limpiar}>
                Limpiar
              </button>
              {/* <button className={this.state.guardarOn?"boton":"botonOf"} onClick={this.Upload.bind(this)} disabled={!this.state.guardarOn}>Eliminar</button> */}
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="ititucxc1">DESCRIPCIÓN</th>
                    <th className="ititucxc2">CANTIDAD</th>
                    <th className="ititucxc2">COSTO</th>
                    <th className="ititucxc2">PRECIO</th>
                    <th className="ititucxc3">CUENTA</th>
                    {/* <th className="titucxc3"><FeatherIcon icon="search" size="20px" stroke="#999" /></th> */}
                  </tr>
                </thead>
                {this.state.inventario.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                      onClick={this.consultaIndividual.bind(
                        this,
                        item.variable1
                      )}
                    >
                      <td className="rtotalescxc" width="10">
                        {item.variable2}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable3}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable4}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable5}
                      </td>
                      <td className="totalescxc2" width="10">
                        {/* <img className="imagen" src={item.variable6} alt="" /> */}
                        {item.variable7}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Inventario);
