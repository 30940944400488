import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";


//componentes
import './Grado.css';
import Alert from '../../modal/alert';

class GradoAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            botonOn:true,
            scurso: null,
            mensajealerta:false,
            codigo1:'',
            codigo2:'',
            descripcion:'',
            salonCurso:'',
            guardarOn:false,
            icursos:[],
            igrados:[],
            iseccion:[],
            cantAlumnos:'',
            modificarOn:false,
        }

    }

    limpiar=()=>{
        this.setState({
             botonOn:true,
            mensajealerta:false,
            codigo1:'',
            codigo2:'',
            descripcion:'',
            salonCurso:'',
            guardarOn:false,
            cantAlumnos:'',
            modificarOn:false,

        })   
    }

    onChange(e) {
        //console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.value
             
        })
    }

    onChange1(e) {
        // console.log(this.state.nana)
        // console.log(e.target.checked)
        if (e.target.checked){
            this.setState({
                oferta: 'SI',
            })
        }else {
            this.setState({
                oferta: 'NO',
            })

        }
      }
      onChange2=(e)=> {
        let iddescrip = e.target.value
        let itemfind = this.state.iseccion.find(e => e.variable1 === iddescrip)
        let index = typeof itemfind !== 'undefined' ? this.state.iseccion.indexOf(itemfind) : 0
        console.log(index)
        let saloncurso =  this.state.iseccion[index].variable2
        
        // console.log(iddescrip)
        // console.log(saloncurso)
        this.setState({
            salonCurso: saloncurso,
            descripcion: iddescrip,    
            scurso:index 
        })
        
    }


    guardar=()=>{

        if (this.state.descripcion.length>0) {
            
            let data = new FormData();
            data.append('salonCurso', this.state.salonCurso);
            data.append('descripcion', this.state.descripcion);
            data.append('codigo', this.state.codigo2);
            data.append('boton', 'agregarGrados');
    
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/grado.php';
            axios.post(xurl, data, config)
    
            .then(res => {
                if (res.data.variable1) {
                    this.limpiar();
                    this.buscarGrados();
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Maestro de Grados',
                        cuerpoM: 'Se Agrego correctamente',
                    })
                } else {
                    //console.log(res.data.variable2);
                    this.setState({
                        mensajealerta: true,
                        tituloM: 'Menu Maestro de Grados',
                        cuerpoM: 'No se pudo agregar, verifique Gracias...',
                        })
                }
            })            
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Maestro de Grados',
                cuerpoM: 'Debe haber una descripcion como minimo para poder Agregarlo, verifique Gracias...',
             })
        }

    }

    consultar=()=>{
        //console.log(this.state.listaOn)
        if (this.state.listaOn){
            this.setState({
                listaOn:false,
            })
        }
        if (!this.state.listaOn) {
            this.setState({
                listaOn:true,
            })
        }
    }
    consulta=(e)=>{
        if (e.length>0) {
            //console.log(e)
            axios.post('https://institutobilinguelasnaciones.com/terapias/backend/grado.php/?codigogrado='+ e +'&boton=consultarGradosIndividual')
            .then(res => {
                    if (res.data) {
                       
                        this.setState({
                            modificarOn:true,
                            listaOn:false,
                            codigo1:e,
                            codigo2: res.data.variable1,
                            descripcion: res.data.variable2,
                            salonCurso: res.data.variable3,
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Grados',
                            cuerpoM: 'No se encontro el Grado que se desea consultar, verifique Gracias...',
                         })
                    }
            })
        }
    }

    modificar=()=>{
        if (this.state.codigo1.length>0 && this.state.codigo2.length>0) {
            let data = new FormData();
            data.append('codigogrado', this.state.codigo1);
            data.append('codigocurso', this.state.codigo2);
            data.append('descripcion', this.state.descripcion);
            data.append('salonCurso', this.state.salonCurso);
            data.append('boton', 'modificarGrados');
    
            let config = {
                headers: {
                  'Content-Type': 'text/html'}
            };
            const xurl = 'https://institutobilinguelasnaciones.com/terapias/backend/grado.php';
            axios.post(xurl, data, config)            
            .then(res => {
                    if (res.data.variable1) {
                        this.limpiar();
                        this.buscarGrados();
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Grados',
                            cuerpoM: 'Se Modifico correctamente el Grado',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Grados',
                            cuerpoM: 'No se pudo Modificar el Grado, verifique Gracias...',
                         })
                    }
            })
        }else {
            this.setState({
                mensajealerta: true,
                tituloM: 'Menu Maestro de Grados',
                cuerpoM: 'Debe haber un Curso y un Grado para poder Modificar, verifique Gracias...',
             })
        }

    }
    eliminar=(e)=>{
        if (e.length>0) {
            axios.post('https://institutobilinguelasnaciones.com/terapias/backend/grado.php/?codigo='+ e +'&boton=eliminarGrados')
            .then(res => {
                    if (res.data.variable1) {
                        this.buscarGrados();
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Grados',
                            cuerpoM: 'Se Elimino correctamente el Grado',
                        })
                    } else {
                        this.setState({
                            mensajealerta: true,
                            tituloM: 'Menu Maestro de Grados',
                            cuerpoM: 'No se pudo Eliminar el Grado, verifique Gracias...',
                         })
                    }
            })
        }
    }


    buscarCursos=()=>{
        axios.post('https://institutobilinguelasnaciones.com/terapias/backend/maternal.php/?boton=consultarCursos')
        .then(res => { 
            if (res.data) {               
                let xcursos = res.data
                //console.log(res.data)
                this.setState({
                    icursos :xcursos 
                });
            }  
        })
    }
    buscarGrados=()=>{
        axios.post('https://institutobilinguelasnaciones.com/terapias/backend/grado.php/?boton=consultarGrados')
        .then(res => { 
            console.log(res)
            if (res.data) {               
                let xgrados = res.data
                //console.log(res.data)
                this.setState({
                    igrados:xgrados
                });
            }  
        })
    }
    buscarSalon=()=>{
        axios.post('https://institutobilinguelasnaciones.com/terapias/backend/grado.php/?boton=buscarGrados2')
        .then(res => { 
            console.log(res)
            if (res.data) {               
                let xseccion = res.data
                //console.log(res.data)
                this.setState({
                    iseccion:xseccion
                });
            }  
        })
    }

    handleClose = () => {
        this.setState({ mensajealerta: false })
      }

    componentDidMount(){
       this.buscarCursos();
       this.buscarGrados();
       this.buscarSalon();

    }

    render(){
        //console.log(this.state.oferta)
        return(
            <div className="cont-casos row">
                <div className="cont-casos-formulario col-12">
                    {this.state.mensajealerta === true &&
                      <Alert
                          tituloMensaje={this.state.tituloM}
                          cuerpoMensaje={this.state.cuerpoM}
                          open={this.state.mensajealerta}
                          handleClose={this.handleClose}
                      />
                    }
                    <div className="row">
                        <div className="cont-titulocasos col-12">    
                            <span className="titulocasos">Maestro de Grados Primaria</span>
                            <Link to="/"><span className="titulo-cerrarcasos">X</span></Link>
                        </div>

                        <div className="cont-datoscasos col-12">
                            <div className="row">
                                <div className="turno col-6">                                    
                                    <select id="codigo2" name="codigo2" value={this.state.codigo2} onChange={this.onChange.bind(this)}>
                                        <option>--Cursos/Horarios--</option>
                                        {this.state.icursos.map((item, i)=>{
                                                return <option  key={item.variable1} value={item.variable1}>{item.variable2}</option>
                                        })}
                                    </select>
                                </div>
    
                                <div className="turno col-6">
                                    {/* <label className="opcion" >Descripción Grado.</label> */}
                                    <select id="descripcion" name="descripcion" value={this.state.descripcion} onChange={this.onChange2.bind(this)}>
                                        <option value='0'>-- Seleccione Grado --</option>
                                        {this.state.iseccion.map((item, i)=>{ 
                                                return <option  key={i} value={item.variable1}>{item.variable2}</option>
                                        })}
                                    </select>
                                
                                    {/* <input
                                        className="form-control"
                                        type="text"
                                        name="descripcion"
                                        id="descripcion"
                                        autoComplete="off"
                                        value={this.state.descripcion}
                                        onChange={this.onChange.bind(this)}
                                    /> */}
                                </div>
                            </div>
                            <div className="botonescasos row">
                                    <div className="accionescasos col-12">
                                        <button className={this.state.guardarOn?"botoncasosOff":"botoncasos"}  disabled={this.state.guardarOn} onClick={this.guardar.bind(this)}>Agregar</button>
                                        <button className="botoncasos" onClick={this.consultar.bind(this)}>Consultar</button>
                                        <button className={this.state.modificarOn?"botoncasos":"botoncasosOff"} disabled={!this.state.modificarOn} onClick={this.modificar.bind(this)}>Modificar</button>
                                        {/* <button className={this.state.guardarOn?"botoncasos":"botoncasosOff"} disabled={!this.state.guardarOn} onClick={this.eliminar.bind(this)}>Eliminar</button> */}
                                        <button className="botoncasos" onClick={this.limpiar.bind(this)}>Limpiar</button>
                                        <Link to="/"><button className="botoncasos">Salir</button></Link>
                                    </div>
                                </div>
                        </div>
                        {this.state.listaOn &&
                        <div className="cont-tablamedi row">
                            <div className="tablamedi1 col-7">
                                <div className="cont-titulomedi col-12">
                                    <span className="titulocasos">Listado de Grados</span>
                                    <span className="titulo-cerrarcasos" onClick={this.consultar.bind(this)}>X</span>
                                </div>
                                <span className="titulo-casos">Codigo</span>
                                <span className="titulo-casos">Descripcion</span>
                                <span className="titulo-eliminar"><span className='cerrarme3'></span>= Eliminar</span>
                                <div className="cont-listmedi row">
                                    <div className="listmedi col-12">
                                        <table className="tablame" border="1">
                                            <tbody className="evocuerpo1">
                                                {this.state.igrados.map((item, i)=>{
                                                     return<tr className={i%2 === 0 ? "odd" : ""}  key={item.variable1} id="tabla"><td width="20" onClick={this.consulta.bind(this,item.variable1)}>{item.variable1}</td>
                                                         <td width="20" onClick={this.consulta.bind(this,item.variable1)}>{item.variable2}</td>                                
                                                         <td width="20"><span className='cerrarme1' onClick={this.eliminar.bind(this,item.variable1)}></span> </td></tr>                                
                                                })}
                                            </tbody>
                                        </table>
                                    </div>    
                                </div>
                            </div>  
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}export default GradoAction;    