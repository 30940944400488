import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./notaCreditoM.css";
import FeatherIcon from "feather-icons-react";
import Alert from "../../modal/alert";
import { element } from "prop-types";

class NotaCreditoM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opcedulaP: "",
      verplanillaOn: false,
      fecha: moment().format("YYYY-MM-DD"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      externoOn: false,
      clientes: [],
      facturasC: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      nocre: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      otroProducto: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
      montoOtros: "",
      cxcPendientesOn: "",
      cxcRe: [],
      transferencia: false,
      deposito: false,
      tarjetaVisa: false,
      tarjetaClave: false,
      efectivo: false,
      cheque: false,
      cxc: false,
      ncredito: false,
      mtransferencia: "",
      mdeposito: "",
      mtarjetaVisa: "",
      mtarjetaClave: "",
      mefectivo: "",
      mcheque: "",
      mncredito: "",
      botonTransOn: false,
      botonDepoOn: false,
      botontarjetaVOn: false,
      botontarjetaCOn: false,
      botonEfecOn: false,
      botonChequeOn: false,
      botonNCOn: false,
      montoAbono: 0,
      pagoncOn: false,
      representante: "",
      alumno: "",
      iitemFactura: [],
    };
  }

  limpiar = () => {
    this.setState({
      opcedulaP: "",
      verplanillaOn: false,
      fecha: moment().format("YYYY-MM-DD"),
      ruc: "",
      cliente: "",
      tbusqueda: "",
      tbusqueda1: "",
      guardarOn: false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      clientes: [],
      facturasC: [],
      inventario: [],
      itenFacturas: [],
      mes: [],
      anio: [],
      libroVOn: false,
      loading: false,
      cedulaAlumno: "",
      facturacionOn: false,
      externoOn: false,
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      otroProducto: "",
      nombrepro: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      subtotal: "",
      timpuesto: "",
      totalg: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      nfactura: "",
      tipoFac: "",
      codigo3: "",
      codigo4: "",
      libroVOn: false,
      nalumno: [],
      cedulaAlumno: "",
      identificacionp1: "",
      montoOtros: "",
      cxcPendientesOn: "",
      cxcRe: [],
      transferencia: false,
      deposito: false,
      tarjetaVisa: false,
      tarjetaClave: false,
      efectivo: false,
      cheque: false,
      cxc: false,
      ncredito: false,
      mtransferencia: "",
      mdeposito: "",
      mtarjetaVisa: "",
      mtarjetaClave: "",
      mefectivo: "",
      mcheque: "",
      mncredito: "",
      botonTransOn: false,
      botonDepoOn: false,
      botontarjetaVOn: false,
      botontarjetaCOn: false,
      botonEfecOn: false,
      botonChequeOn: false,
      botonNCOn: false,
      montoAbono: 0,
      pagoncOn: false,
      representante: "",
      alumno: "",
      iitemFactura: [],
    });
  };

  limpiar2 = () => {
    this.setState({
      precio: "",
      itebi: "",
      precioT: "",
      cantidad: "",
      idproducto: "",
      nombrepro: "",
      otroProducto: "",
      cantidadActual: "",
      costo: "",
      impuesto: "",
      datosChequeOn: false,
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      monto: "",
      concepto: "",
      transferencia: false,
      deposito: false,
      tarjetaVisa: false,
      tarjetaClave: false,
      efectivo: false,
      cheque: false,
      cxc: false,
      ncredito: false,
      mtransferencia: "",
      mdeposito: "",
      mtarjetaVisa: "",
      mtarjetaClave: "",
      mefectivo: "",
      mcheque: "",
      mncredito: "",
      botonTransOn: false,
      botonDepoOn: false,
      botontarjetaVOn: false,
      botontarjetaCOn: false,
      botonEfecOn: false,
      botonChequeOn: false,
      botonNCOn: false,
      montoAbono: 0,
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    if (this.state.oppago === "INTERNOS") {
      let index = e.target.value;
      let ceduA = this.state.clientes[index].variable2;
      this.setState({ cedulaAlumno: ceduA });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarClientesI(e.target.value);
    //this.consultarFactura(e.target.value)
    //console.log(e.target.value)
  }

  // onChange2(e) {
  //   let index = e.target.getAttribute("id");
  //   console.log(e.target.value);
  //   console.log(index);

  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  // }

  onChange1(e) {
    let texto = e.target.options[e.target.selectedIndex].text;
    //console.log(texto);
    this.setState({
      [e.target.name]: e.target.value,
      idproducto: e.target.value,
      nombrepro: texto,
      cantidad: "1",
    });
    //this.consultarProducto(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      loading: true,
      [e.target.name]: e.target.value,
    });
    this.consultarClientes("I");
    this.setState({ loading: false, verplanillaOn: true });
  }
  onChange6(e) {
    //console.log(e.target.value)
    this.setState({
      // loading: true,
      [e.target.name]: e.target.value,
      externoOn: true,
    });
  }

  onChange4 = (e) => {
    // let element = e.target;
    // if (element.checked) {
    // console.log(e.target.value);
    // console.log(e.target.name);
    let data = new FormData();
    data.append("idfacturaRe", e);
    data.append("boton", "guardarPendiente");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura();
          //this.limpiar2();
        } else {
          this.setState({});
        }
      });
    //}
    //console.log({"copia:":listaCorreo_, "global":listaCorreo})
  };

  onChange5(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      if (e.target.name == "transferencia") {
        this.setState({
          botonTransOn: !this.state.botonTransOn,
        });
      }
      if (e.target.name == "deposito") {
        this.setState({
          botonDepoOn: !this.state.botonDepoOn,
        });
      }
      if (e.target.name == "tarjetaVisa") {
        this.setState({
          botontarjetaVOn: !this.state.botontarjetaVOn,
        });
      }
      if (e.target.name == "tarjetaClave") {
        this.setState({
          botontarjetaCOn: !this.state.botontarjetaCOn,
        });
      }
      if (e.target.name == "efectivo") {
        this.setState({
          botonEfecOn: !this.state.botonEfecOn,
        });
      }
      if (e.target.name == "cheque") {
        this.setState({
          botonChequeOn: !this.state.botonChequeOn,
        });
      }
      if (e.target.name == "ncredito") {
        this.setState({
          botonNCOn: !this.state.botonNCOn,
        });
      }
      this.setState({
        [e.target.name]: true,
      });
    } else {
      if (e.target.name == "transferencia") {
        this.setState({
          botonTransOn: !this.state.botonTransOn,
        });
      }
      if (e.target.name == "deposito") {
        this.setState({
          botonDepoOn: !this.state.botonDepoOn,
        });
      }
      if (e.target.name == "tarjetaVisa") {
        this.setState({
          botontarjetaVOn: !this.state.botontarjetaVOn,
        });
      }
      if (e.target.name == "tarjetaClave") {
        this.setState({
          botontarjetaCOn: !this.state.botontarjetaCOn,
        });
      }
      if (e.target.name == "efectivo") {
        this.setState({
          botonEfecOn: !this.state.botonEfecOn,
        });
      }
      if (e.target.name == "cheque") {
        this.setState({
          botonChequeOn: !this.state.botonChequeOn,
        });
      }
      if (e.target.name == "ncredito") {
        this.setState({
          botonNCOn: !this.state.botonNCOn,
        });
      }
      this.setState({
        [e.target.name]: false,
      });
    }
  }

  externoCli = () => {
    if (this.state.externoOn) {
      this.setState({ externoOn: false });
      this.limpiar();
    } else {
      this.setState({ externoOn: true });
    }
  };

  pagoNc = () => {
    if (this.state.pagoncOn) {
      this.setState({ pagoncOn: false });
      this.limpiar();
    } else {
      this.buscarNC();
      this.setState({ pagoncOn: true });
    }
  };

  pagarNotaCredito = () => {
    if (this.state.mncredito.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("referencia", this.state.concepto);
      data.append("banco", this.state.banco);
      data.append("transferencia", this.state.transferencia);
      data.append("deposito", this.state.deposito);
      data.append("efectivo", this.state.efectivo);
      data.append("cheque", this.state.cheque);
      data.append("numeroNcredito", this.state.mncredito);
      data.append("fecha", this.state.fecha);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarReciboNC");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Pago de Nota de Credito.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Pago de Nota de Credito.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  };

  externoCliente = () => {
    if (this.state.tipoFac === "PRODUCTO") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
    if (this.state.tipoFac === "SERVICIOS") {
      this.setState({
        loading: true,
        externoOn: false,
      });
      this.consultarClientes("E");
      this.setState({ loading: false });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  generarPagoClose = () => {
    this.setState({ modificarSaldoOn: false });
  };

  activarFactura = () => {
    if (this.state.facturacionOn) {
      this.setState({ facturacionOn: false, datosChequeOn: false });
    } else {
      this.consultarItenFactura();
      this.consultarInventario();
      this.setState({
        facturacionOn: true,
      });
    }
  };

  consultarClientes = (e) => {
    //this.setState({loading:true})
    let data = new FormData();
    if (e === "I") data.append("boton", "consultarClienteI");
    if (e === "E") data.append("boton", "consultarClienteE");
    //data.append("boton", "consultarClienteI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xrutas = res.data.variable2;
          this.setState({
            clientes: xrutas,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarClientesI = (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("idcliente", e);
    data.append("tipoCliente", this.state.opcedulaP);
    data.append("boton", "clienteI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          this.setState({
            cliente: res.data.variable2,
            ruc: res.data.variable3,
            fecha: res.data.variable4,
            cedulaAlumno: res.data.variable5,
          });
          this.consultarFactura(res.data.variable3);
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFactura = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("codigoCliente", e);
    data.append("boton", "consultarFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            facturasC: xfacturasP,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProducto = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idproducto: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidadActual: res.data.variable4,
            costo: res.data.variable5,
            precio: res.data.variable6,
            impuesto: res.data.variable8,
            cantidad: "1",
          });
        }
      });
  };

  consultarItenFactura = async () => {
    this.setState({
      itenFacturas: [],
      subtotal: "",
      timpuesto: "",
      totalg: "",
    });
    let data = new FormData();
    data.append("nrecibo", this.state.nfactura);
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("boton", "consultarNcManual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            itenFacturas: xrutas,
            subtotal: res.data.variable3,
            timpuesto: res.data.variable4,
            totalg: res.data.variable5,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  buscarRecibo = () => {
    let data = new FormData();
    data.append("nrecibo", this.state.nfactura);
    data.append("boton", "buscarReciboNc");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura2();
          this.setState({
            representante: res.data.variable2,
            ruc: res.data.variable3,
            alumno: res.data.variable4,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Nota de Credito Manual.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  facturarIten = () => {
    let paso = 0;
    // if (
    //   parseFloat(this.state.cantidad) > parseFloat(this.state.cantidadActual)
    // ) {
    //   paso = 1;
    //   this.setState({
    //     mensajealerta: true,
    //     tituloM: "Menu Facturacion.",
    //     cuerpoM:
    //       "La cantidad que intenta facturar es mayor que la encontrada en el inventario, la cantidad actual es de:" +
    //       this.state.cantidadActual,
    //   });
    // }
    if (parseFloat(this.state.precio) < 0) {
      paso = 1;
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El Monto debe ser mayor a cero, Verifique por Favor.",
      });
    }

    if (paso === 0) {
      let data = new FormData();
      data.append("idproducto", this.state.idproducto);
      data.append(
        "nombreProducto",
        this.state.nombrepro + " " + this.state.otroProducto
      );
      data.append("cantidad", this.state.cantidad);
      data.append("precioU", this.state.precio);
      data.append("itebi", this.state.itebi);
      data.append("precioT", this.state.precioT);
      data.append("impuesto", this.state.impuesto);
      data.append("cantidadActual", this.state.cantidadActual);
      data.append("claveUsu", this.props.usuario1.cedulaU);
      data.append("nrecibo", this.state.nfactura);
      data.append("boton", "facturarItenManual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.consultarItenFactura();
            this.limpiar2();
          }
        });
    }
  };

  facturarItenCxCRE = (d, e, f, g) => {
    let data = new FormData();
    data.append("idproducto", "cxcre");
    data.append("nombreProducto", d);
    data.append("cantidad", "1");
    data.append("precioU", e);
    data.append("itebi", "0");
    data.append("precioT", e);
    data.append("impuesto", "");
    data.append("cantidadActual", "");
    data.append("idcontrolPago", f);
    data.append("deuda", g);

    data.append("boton", "facturarItenCxC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({ cxcPendientesOn: false });
          this.consultarItenFactura();
          //this.limpiar2();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  eliminarItenFactura = (e) => {
    let data = new FormData();
    data.append("iditenfactura", e);
    data.append("boton", "eliminarItenNcMabual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultarItenFactura();
        }
      });
  };

  guardarFactura = () => {
    if (
      this.state.opcedulaP != "INTERNOS" &&
      parseFloat(this.state.totalg) === parseFloat(this.state.monto)
    ) {
      let data = new FormData();
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("transferencia", this.state.transferencia);
      data.append("mtransferencia", this.state.mtransferencia);
      data.append("deposito", this.state.deposito);
      data.append("mdeposito", this.state.mdeposito);
      data.append("tarjetaVisa", this.state.tarjetaVisa);
      data.append("mtarjetaVisa", this.state.mtarjetaVisa);
      data.append("tarjetaClave", this.state.tarjetaClave);
      data.append("mtarjetaClave", this.state.mtarjetaClave);
      data.append("efectivo", this.state.efectivo);
      data.append("mefectivo", this.state.mefectivo);
      data.append("cheque", this.state.cheque);
      data.append("mcheque", this.state.mcheque);
      data.append("ncredito", this.state.ncredito);
      data.append("numeroNcredito", this.state.mncredito);
      data.append("cxc", this.state.cxc);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("montoOtros", this.state.montoOtros);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "La Factura se Guardo con Exito.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: "Ocurrio un error con la Factura.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Facturacion.",
        cuerpoM: "El Monto a Pagar No Coincide con el Total Facturado.",
      });
    }
  };

  guardarFactura1 = () => {
    if (this.state.opcedulaP === "INTERNOS") {
      let data = new FormData();
      data.append("anoEscolar", this.props.usuario1.anioE);
      data.append("claveUsu", this.props.usuario1.password);
      data.append("ruc", this.state.ruc);
      data.append("cliente", this.state.cliente);
      data.append("subtotal", this.state.subtotal);
      data.append("timpuesto", this.state.timpuesto);
      data.append("totalg", this.state.totalg);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("transferencia", this.state.transferencia);
      data.append("mtransferencia", this.state.mtransferencia);
      data.append("deposito", this.state.deposito);
      data.append("mdeposito", this.state.mdeposito);
      data.append("tarjetaVisa", this.state.tarjetaVisa);
      data.append("mtarjetaVisa", this.state.mtarjetaVisa);
      data.append("tarjetaClave", this.state.tarjetaClave);
      data.append("mtarjetaClave", this.state.mtarjetaClave);
      data.append("efectivo", this.state.efectivo);
      data.append("mefectivo", this.state.mefectivo);
      data.append("cheque", this.state.cheque);
      data.append("mcheque", this.state.mcheque);
      data.append("ncredito", this.state.ncredito);
      data.append("numeroNcredito", this.state.mncredito);
      data.append("cxc", this.state.cxc);
      data.append("monto", this.state.monto);
      data.append("concepto", this.state.concepto);
      data.append("fecha", this.state.fecha);
      data.append("nfactura", this.state.nfactura);
      data.append("montoOtros", this.state.montoOtros);
      data.append("tipoF", this.state.opcedulaP);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "guardarFactura");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.activarFactura();
            this.limpiar2();
            this.consultarFactura(this.state.ruc);
            this.setState({
              totalg: "",
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Facturacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.consultarItenFactura()
      this.setState({ 
        datosChequeOn: false 
      });
    } else {
      //this.buscarNC();
      this.setState({
        datosChequeOn: true,
        monto: this.state.totalg,
      });
    }
  };

  buscarNC = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("cedulaA", this.state.cedulaAlumno);
    data.append("boton", "consultarNCPC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            nocre: xfacturasP,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  consultarFacturasI = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("numeroRecibo", e);
      data.append("tipoFact", this.state.tipoFac);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago4.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  eliminarFacturasI = (e) => {
    let data = new FormData();
    data.append("nfactura", e);
    data.append("boton", "notaCredito");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/facturaRe.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar2();
          this.consultarFactura(this.state.ruc);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM: "La Factura se Elimino con Exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Facturacion.",
            cuerpoM:
              "Ocurrio un error Verifique que la Factura No este Aprobada.",
          });
        }
      });
    console.log(e);
  };

  reporteLibroV = () => {
    if (this.state.codigo3.length > 0 && this.state.codigo4.length > 0) {
      let data = new FormData();
      data.append("mes", this.state.codigo3);
      data.append("anio", this.state.codigo4);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/libroVentas.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM:
          "Debe colocar un mes y un Año para realizar el Reporte gracias...",
        libroCOn: false,
      });
    }
  };

  libroV = () => {
    if (this.state.libroVOn) {
      this.setState({ libroVOn: false });
    } else {
      this.setState({ libroVOn: true });
    }
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Estudiante con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                madre: res.data.variable13 + " " + res.data.variable14,
                padre: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                tbusqueda: res.data.variable35,
                verplanillaOn: false,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
                clientes: res.data.variable137,
              });
              this.consultarClientesI(res.data.variable135);
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPlanilla = () => {
    if (this.state.tbusqueda1 === "1") {
      this.buscarPlanillaIndividual();
    }
    if (this.state.tbusqueda1 === "2") {
      this.buscarPorNombres();
    }
  };

  cerrarLista2 = () => {
    if (this.state.cxcPendientesOn) {
      this.setState({ cxcPendientesOn: false });
    } else {
      this.setState({ cxcPendientesOn: true });
    }
  };

  deudaCxC = () => {
    let data = new FormData();
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("boton", "buscarCXCRE");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xcxcRe = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            cxcPendientesOn: true,
            cxcRe: xcxcRe,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Deuda ",
            cuerpoM: "El La cédula que intenta consultar no existe, gracias...",
            cxcPendientesOn: false,
          });
        }
      });
  };

  calcularSumaValorEntrada3 = (index, value, restaP) => {
    //console.log("value " + value);
    //console.log("deuda " + restaP);

    // if (value <= restaP) {
    let cxcRe_ = this.state.cxcRe; //Array.from(cxcRe);
    cxcRe_[index].valorEntrada3 = 0;
    cxcRe_[index].valorEntrada3 = value;
    this.setState({ cxcRe: cxcRe_ });
    //}
  };

  GuardarNC = async () => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("nrecibo", this.state.nfactura);
    data.append("claveUsu", this.props.usuario1.cedulaU);
    data.append("boton", "guardarNCManual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.limpiar();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Nota de Credito",
            cuerpoM: "La Nota de Credito Se Guardo con Exito, gracias...",
          });
        }else{
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Nota de Credito",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  consultarItenFactura2 = async () => {
    
    let data = new FormData();
    data.append("nrecibo", this.state.nfactura);
    data.append("boton", "consultarItemFactura");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xrutas = res.data.variable2;
          this.setState({
            iitemFactura: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };


  componentDidMount() {
    //this.consultarItenFactura();
    //this.consultarInventario();
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MENU DE NOTAS DE CREDITO MANUAL. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.datosChequeOn === true && (
            <div className="flotador3xf">
              <div className="flotador1f">
                <div className="cont-factura col-12">
                  <div className="titulo-cxc ">
                    <p>INGRESAR EL NUMERO DEL RECIBO.</p>
                  </div>
                  <div className="cont-campos">
                    <div className="campos-factura">
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="nfactura"
                          id="nfactura"
                          // readOnly="readonly"
                          autoComplete="off"
                          placeholder="Número Recibo."
                          maxLength="25"
                          onChange={this.onChange.bind(this)}
                          value={this.state.nfactura}
                        />
                      </div>
                      <button className="boton" onClick={this.buscarRecibo}>
                        Buscar Recibo
                      </button>
                    </div>
                  </div>
                  <div className="contenedorcedula1 col-12">
                    <div className="contenedorcedula1f col-7">
                      <input
                        className="form-control"
                        type="text"
                        name="representante"
                        id="representante"
                        readOnly="readonly"
                        autoComplete="off"
                        placeholder="Representante."
                        maxLength="125"
                        onChange={this.onChange.bind(this)}
                        value={this.state.representante}
                      />
                    </div>
                    <div className="contenedorcedula1f col-3">
                      <input
                        className="form-control"
                        type="text"
                        name="ruc"
                        id="ruc"
                        readOnly="readonly"
                        autoComplete="off"
                        placeholder="Ruc/Pasaporte."
                        maxLength="125"
                        onChange={this.onChange.bind(this)}
                        value={this.state.ruc}
                      />
                    </div>

                    <div className="contenedorcedula1f col-3">
                      <input
                        className="form-control"
                        type="text"
                        name="alumno"
                        id="alumno"
                        readOnly="readonly"
                        autoComplete="off"
                        placeholder="Nombre Alumno."
                        maxLength="125"
                        onChange={this.onChange.bind(this)}
                        value={this.state.alumno}
                      />
                    </div>
                  </div> 
                  <div className="contenedorcedula1 col-12">
                    <button className="boton" onClick={this.datosCheque}>
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {this.state.facturacionOn === true && (
            <div className="flotador3xf">
              <div className="flotador1f"> */}
          <div className="cont-factura col-12">
            <div className="titulo-cxc ">
              <p>NOTA DE CREDITO MANUAL.</p>
            </div>
            <div className="cont-campos">
              <div className="campos-factura">
                <div className="contenedorcedula1f1 col-4">
                  <select
                    id="idproducto"
                    name="idproducto"
                    value={this.state.idproducto}
                    onChange={this.onChange1.bind(this)}
                  >
                    <option>-- Seleccionar Producto --</option>

                    {this.state.iitemFactura.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}{" "}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1f col-2">
                  <input
                    className="form-control"
                    type="text"
                    name="otroProducto"
                    id="otroProducto"
                    // readOnly="readonly"
                    autoComplete="off"
                    placeholder="Descripcion."
                    maxLength="25"
                    onChange={this.onChange.bind(this)}
                    value={this.state.otroProducto.toUpperCase()}
                  />
                </div>
                <div className="contenedorcedula1f col-1">
                  <input
                    className="form-control"
                    type="text"
                    name="cantidad"
                    id="cantidad"
                    // readOnly="readonly"
                    autoComplete="off"
                    placeholder={
                      this.state.tipoFac === "SERVICIOS" ? "Item." : "Cantidad."
                    }
                    maxLength="5"
                    onChange={this.onChange.bind(this)}
                    value={this.state.cantidad.toUpperCase()}
                  />
                </div>
                <div className="contenedorcedula1f col-2">
                  <input
                    className="form-control"
                    type="text"
                    name="precio"
                    id="precio"
                    // readOnly="readonly"
                    autoComplete="off"
                    placeholder={
                      this.state.tipoFac === "SERVICIOS"
                        ? "Sub-Total."
                        : "Precio."
                    }
                    maxLength="7"
                    onChange={this.onChange.bind(this)}
                    value={this.state.precio.toUpperCase()}
                  />
                </div>
                {this.state.tipoFac === "SERVICIOS" && (
                  <div className="contenedorcedula1f col-1">
                    <input
                      className="form-control"
                      type="text"
                      name="itebi"
                      id="itebi"
                      // readOnly="readonly"
                      autoComplete="off"
                      placeholder="Itebi."
                      maxLength="5"
                      onChange={this.onChange.bind(this)}
                      value={this.state.itebi.toUpperCase()}
                    />
                  </div>
                )}
                {this.state.tipoFac === "SERVICIOS" && (
                  <div className="contenedorcedula1f col-2">
                    <input
                      className="form-control"
                      type="text"
                      name="precioT"
                      id="precioT"
                      // readOnly="readonly"
                      autoComplete="off"
                      placeholder="Total."
                      maxLength="7"
                      onChange={this.onChange.bind(this)}
                      value={this.state.precioT.toUpperCase()}
                    />
                  </div>
                )}
              </div>
              <div className="cont-totales">
                {this.state.representante.length > 0 && (
                  <button
                    className={this.state.guardarOn ? "botonOf" : "boton"}
                    onClick={this.facturarIten}
                  >
                    Agregar Items
                  </button>
                )}
                <button className="boton" onClick={this.datosCheque.bind(this)}>
                  Indicar Recibo
                </button>
                {this.state.representante.length > 0 && (
                  <button className="boton" onClick={this.GuardarNC.bind(this)}>
                    Guardar
                  </button>
                )}
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>
            </div>
            <div className="cont-listmedi row">
              <div className="listcxc col-12">
                <table className="tablacxc" border="1">
                  <thead>
                    <tr>
                      <th className="titucxc2">PRODUCTO</th>
                      <th className="titucxc3">CANTIDAD</th>
                      <th className="titucxc1">P/UNITARIO</th>
                      <th className="titucxc3">ITBMS</th>
                      <th className="titucxc1">TOTAL</th>

                      <th className="titucxc1">
                        <FeatherIcon
                          icon="delete"
                          size="30px"
                          stroke="#999"
                          color="red"
                        />
                      </th>
                    </tr>
                  </thead>
                  {this.state.itenFacturas.map((item, i) => {
                    return (
                      <tr
                        className={i % 2 === 0 ? "odd" : "odd"}
                        key={item.variable1}
                        id="tabla"
                      >
                        <td className="totalescxc" width="10">
                          {item.variable2}
                        </td>
                        <td className="renglon" width="10">
                          {item.variable3}
                        </td>
                        <td className="totalescxc1" width="10">
                          {item.variable4}
                        </td>
                        <td className="totalescxc1" width="10">
                          {item.variable5}
                        </td>
                        <td className="totalescxc1" width="10">
                          {item.variable6}
                        </td>
                      
                        <td className="titucxc3" width="10">
                          <FeatherIcon
                            icon="delete"
                            size="30px"
                            stroke="#999"
                            color="red"
                            onClick={this.eliminarItenFactura.bind(
                              this,
                              item.variable1
                            )}
                          ></FeatherIcon>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
            <div className="cont-totales">
              <div className="campos-totales">
                <div className="contenedorcedula1f col-2">
                  <input
                    className="form-control"
                    type="text"
                    name="subtotal"
                    id="subtotal"
                    readOnly="readonly"
                    autoComplete="off"
                    placeholder="Sub-Total."
                    maxLength="5"
                    onChange={this.onChange.bind(this)}
                    value={this.state.subtotal}
                  />
                </div>
                <div className="contenedorcedula1f col-2">
                  <input
                    className="form-control"
                    type="text"
                    name="timpuesto"
                    id="timpuesto"
                    readOnly="readonly"
                    autoComplete="off"
                    placeholder="ITBMS."
                    maxLength="5"
                    onChange={this.onChange.bind(this)}
                    value={this.state.timpuesto}
                  />
                </div>
                <div className="contenedorcedula1f col-2">
                  <input
                    className="form-control"
                    type="text"
                    name="totalg"
                    id="totalg"
                    readOnly="readonly"
                    autoComplete="off"
                    placeholder="Total."
                    maxLength="5"
                    onChange={this.onChange.bind(this)}
                    value={this.state.totalg}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </div>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(NotaCreditoM);
