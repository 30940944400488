import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async-creatable";
import FeatherIcon from "feather-icons-react";
//componentes
import "./evaluacionD.css";
import Alert from "../../modal/alert";
import PublicarNotas from "../publicarNotas/index";
import { CrearMensajesChat } from "../../API/Core";
import { buscarCantidadChatISinLeer } from "../../API/CoreI";
import { saveCliente, saveToken, saveBotonMensaje, saveBotonMensajeI } from "../../accion/accion";
import socket from "../socketio/socketio";

class EvaluacionAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idcurso: "",
      descripcionE: "",
      //inputValue:'',
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      anoEscolar: moment().format("YYYY"),
      idtareas: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nombreEvaluacion_selected: null,
      nota: "",
      trimestre: "",
      idarchivos: "",
      ianoEscolar: [moment().format("YYYY")],
      imaterias: [],
      icursos: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      iactividades: [],
      inombreEvaluacion: [],
      ialumnosAd: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      activarNotaOn: false,
      valueSelect: null,
      claseNormal: false,
      codigoEvaluacion: "",
      nombreEvaluacion2: "",
      cedulaAlumnoAd: "",
      activarMensaje: false,
      cuerpo: "",
      fechaMe: "",
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      nombreEvaluacion_selected: null,
      idcurso: "",
      //idprofesor: "",
      descripcionE: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      nombreAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      idtareas: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nota: "",
      idarchivos: "",
      ievaluaciones: [],
      ialumnosAd: [],
      listaOn: false,
      modificarOn: false,
      activarNotaOn: false,
      valueSelect: null,
      claseNormal: false,
      codigoEvaluacion: "",
      nombreEvaluacion2: "",
      cedulaAlumnoAd: "",
      activarMensaje: false,
      cuerpo: "",
      fechaMe: "",
    });
  };

  limpiar2(e) {
    this.setState({
      idmateria: "",
      idcurso: "",
      //idprofesor: "",
      idplanE: "",
      cedulaAlumno: "",
      nombreAlumno: "",
      nombreEvaluacion: "",
      evaluacionNombre: "",
      nombreEvaluacion_selected: null,
      nota: "",
      trimestre: "",
      idarchivos: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
      ialumnosAd: [],
      valueSelect: null,
      codigoEvaluacion: "",
      nombreEvaluacion2: "",
      cedulaAlumnoAd: "",
      activarMensaje: false,
      cuerpo: "",
      fechaMe: "",
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") {
      this.limpiar2();
      this.buscarProfesor();
    }
    if (index === "trimestre") {
      this.setState({
        cedulaAlumno: "",
        descripcionE: "",
        ievaluaciones: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        codigoEvaluacion: "",
        nombreEvaluacion2: "",
        nombreEvaluacion_selected: null,
      });
      this.buscarTrimestre(e.target.value);
    }
    if (index === "idcurso") {
      this.setState({
        cedulaAlumno: "",
        cedulaAlumnoAd: "",
        descripcionE: "",
        trimestre: "",
        idmateria: "",
        ievaluaciones: [],
        ialumnosAd: [],
        inombreEvaluacion: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        codigoEvaluacion: "",
        nombreEvaluacion2: "",
        nombreEvaluacion_selected: null,
      });
      this.buscarPlanE2(e.target.value);
      this.buscarAlumnosDiagnostico(e.target.value);
    }
    if (index === "cedulaAlumnoAd") {
      this.setState({
        cedulaAlumno: "",
        descripcionE: "",
        ievaluaciones: [],
        inombreEvaluacion: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        codigoEvaluacion: "",
        nombreEvaluacion2: "",
        nombreEvaluacion_selected: null,
      });
    }
    if (index === "descripcionE") {
      this.setState({
        cedulaAlumno: "",
        ievaluaciones: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        codigoEvaluacion: "",
        nombreEvaluacion2: "",
        nombreEvaluacion_selected: null,
      });
      this.buscarPlanE(e.target.value);
      this.buscarNombreEva(e.target.value);
    }
    if (index === "idmateria") {
      this.setState({
        cedulaAlumno: "",
        descripcionE: "",
        trimestre: "",
        ievaluaciones: [],
        nombreEvaluacion: "",
        evaluacionNombre: "",
        nota: "",
        nombreAlumno: "",
        nombreEvaluacion_selected: null,
      });
    }

    if (index === "nombreEvaluacion") {
      let descripcion =
        e.target.options[e.target.selectedIndex].dataset.descripcion;
      let texto = e.target.options[e.target.selectedIndex].text;
      let valor = e.target.options[e.target.selectedIndex].value;

      if (descripcion === "1") {
        this.setState({
          codigoEvaluacion: valor,
          nombreEvaluacion2: texto,
        });
      }

      if (descripcion === "0") {
        this.onCreate2(texto, valor);
      }
    }
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.imaterias.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : 0;
    //console.log(index)
    let xcantidadE = this.state.imaterias[index].variable3;
    let xidmateria = this.state.imaterias[index].variable4;
    //console.log("cantidad: "+xcantidadE)
    //console.log("idmateria: "+xidmateria)
    this.consultarActividades(xidmateria);
    this.setState({
      [e.target.name]: e.target.value,
      cantidadE: xcantidadE,
      idmateria: xidmateria,
    });
  };

  buscarTrimestre = (e) => {
    let data = new FormData();
    data.append("codigo", e);
    data.append("boton", "consultarActivo");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/activarTrimestre.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
      } else {
        this.setState({
          trimestre: "",
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM:
            "El Trimestre Seleccionado esta CERRADO, debe seleccionar un Trimestre Valido gracias.",
        });
      }
    });
  };

  consultar(e) {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "buscarPlanEI");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              idcurso: res.data.variable3,
              idmateria: res.data.variable4,
              descripcionE: res.data.variable5,
              cantidadE: res.data.variable6,
              idplanEvaluacion: res.data.variable7,
              modificarOn: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan Evaluacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  }

  modificar = () => {
    if (this.state.idmateria.length > 0 && this.state.idcurso.length > 0) {
      let data = new FormData();
      data.append("idmaestroE", this.state.idplanEvaluacion);
      data.append("cantidad", this.state.cantidadE);
      data.append("descripcion", this.state.descripcionE);
      data.append("boton", "modificarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  guardar = () => {
    let fpaso = 0;
    if (this.state.nota > 0 && this.state.nota <= 5) {
      if (this.state.nota === '1' && this.state.cuerpo.length === 0) {
        fpaso = 1;
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM:
            "Ya que la Nota es Igual a 1 Debe Enviar un Mensaje e Indicar la Fecha a el Acudiente",
        });
      }
      if (this.state.nota === '1' && this.state.fechaMe.length === 0) {
        fpaso = 1;
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM:
            "Ya que la Nota es Igual a 1 Debe Enviar un Mensaje e Indicar la Fecha a el Acudiente",
        });
      }

      if (fpaso === 0) {
        if (
          this.state.idmateria.length > 0 &&
          this.state.idplanE.length > 0 &&
          this.state.idcurso.length > 0 &&
          this.state.cedulaAlumno.length > 0 &&
          this.state.descripcionE.length > 0 &&
          this.state.trimestre.length > 0 &&
          this.state.nota.length > 0
        ) {
          let data = new FormData();
          data.append("cedulaAlumno", this.state.cedulaAlumno);
          data.append("idprofesor", this.state.idprofesor);
          data.append("idplanE", this.state.idplanE);
          data.append("idmateria", this.state.idmateria);
          data.append("idgrado", this.state.idcurso);
          data.append("idtituloNota", this.state.descripcionE);
          data.append("nota", this.state.nota);
          data.append("fecha", this.state.fechaE);
          data.append("cantidadE", this.state.cantidadE);
          data.append("idactividad", this.state.idtareas);
          data.append("anoEscolar", this.state.anoEscolar);
          data.append("trimestre", this.state.trimestre);
          data.append("nombreEvaluacion", this.state.codigoEvaluacion);
          data.append("evaluacionNombre", this.state.nombreEvaluacion2);
          data.append("boton", "guardarEvaluacion");

          let config = {
            headers: {
              "Content-Type": "text/html",
            },
          };
          const xurl =
            "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
          axios
            .post(xurl, data, config)

            .then((res) => {
              if (res.data.variable1) {
                //this.limpiar2();
                this.buscarEvaluaciones(this.state.cedulaAlumno);
                this.setState({
                  cuerpo: "",
                  fechaMe: "",
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable2,
                  cedulaAlumno: "",
                  nombreAlumno: "",
                  nota: "",
                });
              } else {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable2,
                });
              }
            });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Notas.",
            cuerpoM: "Debe seleccionar un Grado y una Materia.",
          });
        }
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "El valor de la Nota debe estar entre 1 y 5.",
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "eliminarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Notas.",
            cuerpoM: res.data.variable2,
          });
          //this.limpiar();
          this.buscarEvaluaciones(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Notas.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM:
          "Debe existir un registro para poder eliminar la MAteria Asignada.",
      });
    }
  };

  buscarEvaluaciones = () => {
    if (
      this.state.idprofesor.length > 0 &&
      this.state.descripcionE.length > 0 &&
      this.state.codigoEvaluacion.length > 0 &&
      this.state.cedulaAlumnoAd.length > 0 &&
      this.state.idcurso.length > 0
    ) {
      this.setState({ ievaluaciones: [] });
      let data = new FormData();

      data.append("nombreEvaluacion2", this.state.nombreEvaluacion);
      data.append("nombreEvaluacion", this.state.codigoEvaluacion);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idplanE", this.state.idplanE);
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idcurso);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("descripcionE", this.state.descripcionE);
      data.append("trimestre", this.state.trimestre);
      data.append("cedulaAlumno", this.state.cedulaAlumnoAd);
      data.append("boton", "buscarEvaluaciones2D");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xievaluaciones = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              ievaluaciones: xievaluaciones,
              claseNormal: false,
            });
          } else {
            this.setState({
              ievaluaciones: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM:
          "Debe Seleccionar todas las opciones que se le pide para realizar la consulta, gracias.",
      });
    }
  };

  buscarCurso = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("idprofesor", e);
        data.append("boton", "buscarCurso");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xicursos = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                icursos: xicursos,
              });
            } else {
              this.setState({
                icursos: [],
              });
              if (res.data.variable3.length > 0) {
                this.setState({
                  mensajealerta: true,
                  tituloM: "Menu Asignar Notas.",
                  cuerpoM: res.data.variable3,
                });
              }
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: "Debe Seleccionar un Profesor, gracias.",
        });
      }
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  buscarPlanE = (e) => {
    let data = new FormData();
    data.append("idmateria", this.state.idmateria);
    data.append("idgrado", this.state.idcurso);
    data.append("trimestre", this.state.trimestre);
    data.append("iddescripcion", e);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarMaestroE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          cantidadE: res.data.variable2,
          idplanE: res.data.variable3,
          valueSelect: null,
        });
      } else {
        this.setState({
          cantidadE: "",
          idplanE: "",
          descripcionE: "",
          mensajealerta: true,
          tituloM: "Menu Asignar Notas.",
          cuerpoM: res.data.variable2,
          valueSelect: null,
        });
      }
    });
  };

  buscarNombreEva = (e) => {
    if (parseInt(e) > 0) {
      let data = new FormData();
      data.append("tipoEvaluacion", e);
      data.append("idgrado", this.state.idcurso);
      data.append("idprofesor", this.state.idprofesor);
      data.append("idmateria", this.state.idmateria);
      data.append("trimestre", this.state.trimestre);
      data.append("cedulaAlumno", this.state.cedulaAlumnoAd);
      data.append("boton", "buscarEvaProTipoEva");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/dosificacionesAd.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              inombreEvaluacion: ximaterias,
            });
          } else {
            this.setState({
              inombreEvaluacion: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM:
          "Debe Seleccionar todas las opciones anteriores a esta para ejecutar la consulta, gracias.",
      });
    }
  };

  buscarPlanE2 = (e) => {
    if (this.state.idprofesor.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("idprofesor", this.state.idprofesor);
      data.append("boton", "buscarPlanE2");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximaterias = res.data.variable2;
          let xialumnos = res.data.variable3;
          if (res.data.variable1) {
            this.setState({
              imaterias: ximaterias,
              ialumnos: xialumnos,
              idmateria: "",
              cedulaAlumno: "",
              descripcionE: "",
              trimestre: "",
            });
          } else {
            this.setState({
              imaterias: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM: "Debe Seleccionar un Profesor, gracias.",
      });
    }
  };

  buscarSalones = () => {
    let data = new FormData();
    data.append("boton", "buscarSalones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;

        this.setState({
          icursos: xicursos,
        });
      });
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarPersonal3");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let iprofesor = res.data.variable1;
      iprofesor.map((item, i) => {
        this.setState({ idprofesor: item.variable1 });
        this.buscarCurso(item.variable1);
        this.initData3(item.variable1);
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  consultarActividades(e) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idgrado", this.state.idcurso);
    if (e.length > 0) data.append("idmateria", e);
    if (e.length === 0) data.append("idmateria", this.state.idmateria);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "buscarActividades");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/terapias/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        iactividades: xiactividades,
      });
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  notasTrabajo = (e) => {
    if (this.state.activarNotaOn) {
      this.setState({ activarNotaOn: false, idarchivos: "" });
    } else {
      this.setState({ activarNotaOn: true, idarchivos: e });
      //console.log(e);
    }
  };

  buscarAlumnosDiagnostico = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosGD");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnosAd: xialumnos,
      });
    });
  };

  buscarAlumno = (a, b) => {
    // console.log(a);
    // console.log(b);
    if (b.length === 0) {
      let data = new FormData();
      data.append("cedulaAlumno", a);
      data.append("boton", "buscarPlanillaIndividual");

      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php",
        data: data,
      }).then((res) => {
        if (res.data.variable136) {
          this.setState({
            nombreAlumno: res.data.variable1 + " " + res.data.variable2,
            cedulaAlumno: a,
          });
        } else {
          this.setState({
            nombreAlumno: "",
            cedulaAlumno: "",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM:
          "El Alumno ya tiene una Nota, si quiere cambiarla Debe eliminarla y luego agregar la Nota.",
      });
    }
  };

  loadOptions = async (inputValue) => {
    //if (inputValue.length > 0) {
    let data = new FormData();
    data.append("idprofesor", this.state.idprofesor);
    data.append("idmateria", this.state.idmateria);
    data.append("descripcionE", this.state.descripcionE);
    data.append("trimestre", this.state.trimestre);
    data.append("idcurso", this.state.idcurso);
    data.append("inputValue", inputValue);
    if (inputValue.length > 1) data.append("boton", "buscarNE");
    if (inputValue.length <= 1) data.append("boton", "buscarNE2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/actividades2.php";
    let res = await axios.post(xurl, data, config);
    if (res.data.length > 0) {
      this.setState({ claseNormal: true });
      let arr = [];
      for (let x = 0; x < res.data.length; x++) {
        arr.push({
          label: res.data[x].variable1,
          value: res.data[x].variable2,
        });
      }
      return arr;
    } else {
      this.setState({ claseNormal: false });

      //   const arr = [{ value: "-1", label: "Crear Evaluación()" }];
      //   return arr;
      // }
    }
  };

  onCreate = (e) => {
    //console.log(e);
    if (
      this.state.idprofesor.length > 0 &&
      this.state.descripcionE.length > 0 &&
      this.state.idmateria.length > 0 &&
      e.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idmateria", this.state.idmateria);
      data.append("idtituloNota", this.state.descripcionE);
      data.append("trimestre", this.state.trimestre);
      data.append("evaluacionNombre", e);
      data.append("boton", "guardarMaestroNombreEvaluacion");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            nombreEvaluacion: res.data.variable2,
            evaluacionNombre: e,
            label: e,
            value: res.data.variable2,
            nombreEvaluacion_selected: { label: e, value: res.data.variable2 },
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Notas.",
            cuerpoM: "El Nombre de la Evaluación no se pudo Crear.",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM:
          "Debe haber un profefor Seleccionado, una Materia y elejir la Descripción de la Evaluación para crear un Nombre de Evaluación.",
      });
    }
  };

  onCreate2 = (e, f) => {
    //console.log(e);
    if (
      this.state.idprofesor.length > 0 &&
      this.state.descripcionE.length > 0 &&
      this.state.idmateria.length > 0 &&
      e.length > 0
    ) {
      let data = new FormData();
      data.append("idprofesor", this.state.idprofesor);
      data.append("idmateria", this.state.idmateria);
      data.append("idtituloNota", this.state.descripcionE);
      data.append("trimestre", this.state.trimestre);
      data.append("iddosificacionP", f);
      data.append("evaluacionNombre", e);
      data.append("boton", "guardarMaestroNombreEvaluacionD");
      axios({
        method: "post",
        url: "https://institutobilinguelasnaciones.com/terapias/backend/evaluacion.php",
        data: data,
      }).then((res) => {
        if (res.data.variable1) {
          this.setState({
            codigoEvaluacion: res.data.variable2,
            nombreEvaluacion2: e,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Notas.",
            cuerpoM: "El Nombre de la Evaluación no se pudo Crear.",
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Notas.",
        cuerpoM:
          "Debe haber un profefor Seleccionado, una Materia y elejir la Descripción de la Evaluación para crear un Nombre de Evaluación.",
      });
    }
  };

  onSelectChange = (e) => {
    console.log(e);
    this.setState({ nombreEvaluacion_selected: e });
    if (e !== null) {
      this.setState({
        nombreEvaluacion: e.value,
        evaluacionNombre: e.label,
      });
    }
  };

  activarMensaje = () => {
    if (this.state.activarMensaje) {
      this.setState({ activarMensaje: false });
    } else this.setState({ activarMensaje: true });
  };

  enviarMensajex = () => {
    let data = new FormData();
    data.append("fechaMe", this.state.fechaMe);
    data.append("cuerpo", this.state.cuerpo);
    data.append("cedulaAlumno", this.state.cedulaAlumnoAd);
    data.append("idprofesor", this.state.idprofesor);
    data.append("idmateria", this.state.idmateria);
    data.append("idgrado", this.state.idcurso);
    data.append("trimestre", this.state.trimestre);
    data.append("iddescripcionE", this.state.descripcionE);
    data.append("iddosificacionP", this.state.nombreEvaluacion);

    data.append("boton", "enviarCorreoNota");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/correos.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log(res.data.variable4);
      //console.log(res.data.variable3);
      if (res.data.variable1) {
        // this.setState({
        //   mensajealerta: true,
        //   tituloM: "Menu Enviar Correo.",
        //   cuerpoM:
        //     "El Correo fue Enviado Correctamente.",
        // });
      } else {
        // this.setState({
        //   mensajealerta: true,
        //   tituloM: "Menu Enviar Correo.",
        //   cuerpoM:
        //     "El Correo No fue Enviado, Verifique por Favor.",
        // });
      }
    });
  };

  enviarMensaje = async () => {
    if (this.state.fechaMe.length > 0) {
      let idprofesor = this.state.idprofesor;
      let cedulaAlumno = this.state.cedulaAlumnoAd;
      let idmateria = this.state.idmateria;
      socket.emit("Profesores", {
        profesor: idprofesor,
        alumno: cedulaAlumno,
        materia: idmateria,
      });

      let mensaje_ =
        this.state.cuerpo + ", Nueva Fecha Programada: " + this.state.fechaMe;
      let response = await CrearMensajesChat(
        idprofesor,
        cedulaAlumno,
        idmateria,
        mensaje_
      );
      if (response.data.variable1) {
        this.enviarMensajex();
        this.setState({
          activarMensaje: false,
          mensajealerta: true,
          tituloM: "Menu Enviar Mensaje Chat.",
          cuerpoM: "El Mensaje fue Enviado Correctamente.",
        });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Enviar Mensaje Chat.",
          cuerpoM:
            "El Mensaje No fue Enviado, Por favor presione el boton enviar nuevamente.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Enviar Mensaje Chat.",
        cuerpoM: "Debe Indicar la Fecha, Por favor Verifique.",
      });
    }
  };

  initData3 = async (e) => {
    let response = await buscarCantidadChatISinLeer(e);
    if (response.data.variable1) {
      this.props.saveBotonMensajeI(true);
    } else {
      this.props.saveBotonMensajeI(false);
    }
  };

  verificarVersion = () => {
    let data = new FormData();
    data.append("version", "1.010");
    data.append("boton", "consultarVersion");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultarIP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Principal",
            cuerpoM:
              "Se Reiniciara el Sistema en 5 segundos ya que La Version Actual es Obsoleta, gracias.",
          });
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }
      });
  };

  componentDidMount() {
    this.verificarVersion();
    this.buscarDE();
    this.buscarProfesor();
  }

  render() {
    console.log(this.state.descripcionE);
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>ASIGNAR NOTAS A ESTUDIANTES CON DIAGNOSTICO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.activarNotaOn === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <PublicarNotas
                  idarchivos={this.state.idarchivos}
                  handleClose={this.notasTrabajo}
                />
              </div>
            </div>
          )}

          {this.state.activarMensaje === true && (
            <div className="flotadorT">
              <div className="flotador1f">
                <div className="cont-listmedi row">
                  <div className="cont-personal col-12">
                    <div className="titulo-cxc ">
                      <p>ENVIAR MENSAJE AL REPRESENTANTE. </p>
                    </div>
                    <div className="contenedor-cedula row">
                      <div className="contenedorcedula1 col-12">
                        <textarea
                          className="form-control"
                          name="cuerpo"
                          id="cuerpo"
                          rows="7"
                          cols="100"
                          placeholder="Cuerpo del Mensaje."
                          onChange={this.onChange.bind(this)}
                          value={this.state.cuerpo}
                        />
                      </div>
                      <div className="contenedorcedula1 col-3">
                        <span>Fecha Nueva Evaluación</span>
                        <input
                          className="form-control"
                          type="date"
                          name="fechaMe"
                          id="fechaMe"
                          autoComplete="off"
                          onChange={this.onChange.bind(this)}
                          value={this.state.fechaMe}
                        />
                      </div>
                    </div>
                    <button
                      className="boton"
                      onClick={this.enviarMensaje.bind(this)}
                    >
                      Enviar Correo
                    </button>
                    <button
                      className="boton"
                      onClick={this.activarMensaje.bind(this)}
                    >
                      Salir
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-4">
                <select
                  id="cedulaAlumnoAd"
                  name="cedulaAlumnoAd"
                  value={this.state.cedulaAlumnoAd}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Alumno --</option>

                  {this.state.ialumnosAd.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                  {/* <option value="">TODOS LOS ALUMNOS</option> */}
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                <select
                  id="idmateria"
                  name="idmateria"
                  value={this.state.idmateria}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Materia --</option>
                  {this.state.imaterias.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1 col-2">
                <select
                  id="trimestre"
                  name="trimestre"
                  value={this.state.trimestre}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Trimestre --</option>
                  <option value="1">1ER. TRIMESTRE</option>
                  <option value="2">2DO. TRIMESTRE</option>
                  <option value="3">3ER. TRIMESTRE</option>
                </select>
              </div>
            </div>
            {/* <div className="cont-personal col-12"> */}
            <div className="titulo-reprecxc row">
              <div className="contenedorcedula1x col-3">
                <select
                  id="descripcionE"
                  name="descripcionE"
                  value={this.state.descripcionE}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Descripcion Evaluacion --</option>
                  {this.state.idescripcionE.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1x col-9">
                <select
                  id="nombreEvaluacion"
                  name="nombreEvaluacion"
                  value={this.state.nombreEvaluacion}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Nombre Evaluacion --</option>
                  {this.state.inombreEvaluacion.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.variable1}
                        data-descripcion={item.variable3}
                      >
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className="xcontenedorcedula1x col-5">
                  <AsyncSelect
                    id="nombreEvaluacion"
                    name="nombreEvaluacion"
                    className="nombreEvaluacion"
                    //cacheOptions
                    defaultOptions
                    isClearable
                    classNamePrefix="select"
                    loadOptions={(inputValue) => this.loadOptions(inputValue)}
                    onChange={this.onSelectChange}
                    onCreateOption={this.onCreate}
                    value={this.state.nombreEvaluacion_selected}
                  />
                </div> */}
              {/* </div> */}
            </div>
            {/* <div className="cont-personal col-12"> */}
            <div className="titulo-reprecxc row">
              <div className="xcontenedorcedula1x col-6">
                <input
                  className="form-control"
                  type="text"
                  name="nombreAlumno"
                  id="nombreAlumno"
                  autoComplete="off"
                  placeholder="Nombre Alumno:"
                  //onChange={this.onChange.bind(this)}
                  value={this.state.nombreAlumno}
                />
              </div>
              <div className="xcontenedorcedula1x col-2">
                <input
                  className="form-control"
                  type="text"
                  name="nota"
                  id="nota"
                  autoComplete="off"
                  placeholder="Nota"
                  maxLength="4"
                  onChange={this.onChange.bind(this)}
                  value={this.state.nota.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula1 col-3">
                <input
                  className="form-control"
                  type="date"
                  name="fechaE"
                  id="fechaE"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.fechaE.toUpperCase()}
                />
              </div>
              {this.state.nota === "1" && (
                <div className="contenedormail col-1">
                  <FeatherIcon
                    icon="mail"
                    size="20"
                    stroke="#888"
                    onClick={this.activarMensaje.bind(this)}
                  />
                </div>
              )}
            </div>
            {/* </div> */}
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-12">
                  <button
                    className="boton"
                    onClick={this.buscarEvaluaciones.bind(this)}
                  >
                    Consultar
                  </button>

                  <button
                    className={
                      this.state.nombreAlumno === "" ? "botonOf" : "boton"
                    }
                    onClick={
                      this.state.nombreAlumno === ""
                        ? ""
                        : this.guardar.bind(this)
                    }
                  >
                    Agregar
                  </button>
                  <button className="boton" onClick={this.limpiar.bind(this)}>
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                {this.state.claseNormal === false && (
                  <thead>
                    <tr>
                      <th className="titucxc1">ALUMNO</th>
                      <th className="titucxc5">FECHA</th>
                      <th className="titucxc5">NOTA.</th>
                      <th className="titucxc3xpe">
                        <span className="titulo-eliminar">
                          <span className="cerrarme3"></span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                )}
                {this.state.claseNormal === true && (
                  <tr>
                    <th className="titucxc1">ALUMNO</th>
                    <th className="titucxc5">FECHA</th>
                    <th className="titucxc5">NOTA.</th>
                    <th className="titucxc3xpe">
                      <span className="titulo-eliminar">
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                )}

                {this.state.ievaluaciones.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td
                        className="totalescxcx5"
                        onClick={this.buscarAlumno.bind(
                          this,
                          item.variable1,
                          item.variable6
                        )}
                      >
                        {item.variable3}
                      </td>
                      <td
                        className="totalescxcx5"
                        onClick={this.buscarAlumno.bind(
                          this,
                          item.variable1,
                          item.variable6
                        )}
                      >
                        {item.variable5}
                      </td>
                      <td
                        className="totalescxcx5"
                        onClick={this.buscarAlumno.bind(
                          this,
                          item.variable1,
                          item.variable6
                        )}
                      >
                        {item.variable6}
                      </td>
                      <td className="xcerr">
                        {item.variable6 > 0 ? (
                          <span
                            className="cerrarme6"
                            onClick={this.notasTrabajo.bind(
                              this,
                              item.variable1
                            )}
                          ></span>
                        ) : (
                          ""
                        )}

                        {/* {this.state.descripcionE !== "9"
                          ? item.variable6 !== "" && (
                              <span
                                className="cerrarme6"
                                onClick={this.notasTrabajo.bind(
                                  this,
                                  item.variable1
                                )}
                              ></span>
                            )
                          : ""} */}
                        {item.variable7 !== "SI" && item.variable6==='1'   && item.variable8!==1 ? (
                          <span
                            className="cerrarme1"
                            onClick={this.eliminar.bind(this, item.variable1)}
                          ></span>
                        ) : (
                          <span className=""></span>
                        )}
                        {item.variable7 !== "SI" && item.variable8===1 ? (
                          <span
                            className="cerrarme1"
                            onClick={this.eliminar.bind(this, item.variable1)}
                          ></span>
                        ) : (
                          <span className="cerrarme11"></span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
  
};
export default connect(mapStateToProps, mapDispatchToProps)(EvaluacionAction);
