import React from "react";
import { BuscarChats } from "../../API/Core";
import { buscarCantidadChatSinLeer } from "../../API/Core";
import ChatCard from "./ChatCard";
import socket from "../socketio/socketio"
import { connect } from "react-redux";
import { saveCliente, saveToken, saveBotonMensaje, saveBotonMensajeI} from '../../accion/accion'

function ListaChats(props) {
  const [chats, setChats] = React.useState([]);
  const [ceduPro, setCeduPro] = React.useState(""); 

  const initData = async () => { 
    let response = await BuscarChats(props.id_profesor);
    setChats(response.data.variable2);
  };

  const initData2 = async () => { 
    let response = await buscarCantidadChatSinLeer(props.id_profesor);
    if (response.data.variable1){
      props.saveBotonMensaje(true);
    }else{
      props.saveBotonMensaje(false);
    }
  };

  const MarcarComoLeido = (idsala) => {
    let chats_ = Array.from(chats);
    let find = chats_.find((e) => e.variable === String(idsala));
    let index = chats_.indexOf(find);
    chats_[index].variable2 = "1";
    props.saveBotonMensaje(false);
    setChats(chats_);
  };

  React.useEffect(() => {
    //actualizar chat cuando envien mensajes
    socket.on(props.id_profesor, (data) => {
      props.saveBotonMensaje(true);
      //console.log(data)
      initData();
      initData2();
    });
    return () => {
      socket.off();
    };
  }, [props.refreshChats]);


  React.useEffect(() => {
    initData();
    initData2();
  }, [props.refreshChats]);

  return (
    <div>
      {chats.map((el, key) => (
        <ChatCard
          selected={
            props.selected !== null && el.variable === props.selected.id
          }
          onClick={props.onClick}
          MarcarComoLeido={MarcarComoLeido}
          chat={el}
          key={key}
          index={key}
        />
      ))}
      {chats.length === 0 && <p>No hay conversaciones.</p>}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
    botonMen: state.botonMen,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,

}
export default connect(mapStateToProps,mapDispatchToProps)(ListaChats);