import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardSubtitle,
  CardImg,
  CardTitle,
  CardText,
  CardColumns,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import renderHTML from "react-render-html";
import "./modal.css";

const ModalExample = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [someHTML, setSomeHTML] = useState([]);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setSomeHTML(props.someHTML2);
  }, []);

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.onClose}
        scrollable={true}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={props.onClose}>
          Consulta de Planificación
        </ModalHeader>
        <ModalBody
          body
          inverse
          style={{ backgroundColor: "#bbbbbb", borderColor: "#333" }}
        >
          {someHTML.map((item, i) => {
            return (
              <Card className="mb-4">
                {item.variable4 === "SI" ? (
                  <CardHeader>
                    {renderHTML(item.variable3)}
                    {props.usuario1.nivel !== "3" && (
                      <Button
                        color="success"
                        size="sm"
                        block
                        className="habilitarB"
                        onClick={() => props.deshabilitarI(item.variable1)}
                      >
                        Deshabilitar
                      </Button>
                    )}
                  </CardHeader>
                ) : (
                  <CardHeader>
                    {renderHTML(item.variable3)} ***DESHABILITADO AL
                    REPRESENTANTE***
                    {props.usuario1.nivel !== "3" && (
                      <Button
                        color="success"
                        size="sm"
                        block
                        className="habilitarB"
                        onClick={() => props.habilitarI(item.variable1)}
                      >
                        Habilitar
                      </Button>
                    )}
                  </CardHeader>
                )}
                <CardBody>
                  {/* <CardTitle tag="h5">Special Title Treatment</CardTitle> */}
                  <CardText>{renderHTML(item.variable2)}</CardText>
                  <CardText>{renderHTML(item.variable7)}</CardText>
                  <CardText>{renderHTML(item.variable6)}</CardText>
                  <CardText>{renderHTML(item.variable5)}</CardText>
                </CardBody>
              </Card>
            );
          })}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={props.onClose}>
            Cerrar
          </Button>
          <span className="prueba1">
            <i
              className="icono-izquierdamo fa fa-reply"
              onClick={props.izquierda}
            >
              {" "}
            </i>
          </span>
          <span className="prueba2">
            <i
              className="icono-izquierdamo fa fa-share"
              onClick={props.derecha}
            >
              {" "}
            </i>
          </span>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(ModalExample);
