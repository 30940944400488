import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
//componentes
import "./nomina.css";
import Alert from "../../modal/alert";
import ReporteV from "./reporteVacacionesE";

function Antiguedad(props) {
  const [listaOn, setlistaOn] = React.useState(false);
  const [mensajealerta, setmensajealerta] = React.useState(false);
  const [activarOn, setactivarOn] = React.useState(false);
  const [flotadorNOn, setflotadorNOn] = React.useState(false);
  const [personal, setpersonal] = React.useState([]);
  const [anio, setanio] = React.useState([]);
  const [mes, setmes] = React.useState([]);
  const [nominaPersonal, setnominaPersonal] = React.useState([]);
  const [tipoNomina, settipoNomina] = React.useState(0);
  const [montoAn, setmontoAn] = React.useState(0);

  const [data, setData] = React.useState({
    cedulaNomina: "",
    fechaInicio: "",
    fechaFin: "",
  });
  const [tituloM, settituloM] = React.useState("");
  const [cuerpoM, setcuerpoM] = React.useState("");

  const limpiar = () => {
    setmensajealerta(false);
    setactivarOn(false);
    setflotadorNOn(false);

    let data_ = Object.assign({}, data);
    data_ = {
      cedulaNomina: 0,
      fechaFin: "",
      fechaInicio: "",
    };
    setData(data_);
  };

  const onChange = (e) => {
    let index = e.target.getAttribute("id");
    let data_ = Object.assign({}, data);
    data_[index] = e.target.value;
    setData(data_);
  };

  const handleClose = () => {
    setmensajealerta(false);
  };

  const buscarAntiguedad = () => {
    let form = new FormData();
    form.append("cedulaNomina", data.cedulaNomina);
    form.append("fechaini", data.fechaInicio);
    form.append("fechafin", data.fechaFin);
    form.append("boton", "antiguedad");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        setmontoAn(res.data.variable2);
      } else {
        setmensajealerta(true);
        settituloM("Menu Generar Antiguedad.");
        setcuerpoM(res.data.variable2);
      }
    });
  };

  const buscarPersonalI = (e) => {
    if (data.fechaInicio.length > 0 && data.fechaFin.length > 0) {
      if (e.target.value.length > 0) {
        limpiar();
        let rcedu = e.target.value;
        let form = new FormData();
        form.append("cedula", rcedu);
        form.append("boton", "buscarPersonalI");
        let config = {
          headers: { "Content-Type": "text/html" },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
        axios.post(xurl, form, config).then((res) => {
          if (res.data.variable1) {
            settipoNomina(res.data.variable8);
            let data_ = Object.assign({}, data);
            data_["cedulaNomina"] = rcedu;
            setData(data_);
          }
        });
      } else {
        setmensajealerta(true);
        settituloM("Menu Generar Vacaciones.");
        setcuerpoM(
          "No se encontro la tabla de Vacaciones, Favor comunicarse con el Ing. Hender perozo"
        );
      }
    } else {
      setmensajealerta(true);
      settituloM("Menu Generar Antiguedad.");
      setcuerpoM("Debe elegir una Fecha de Inicio y una Fecha Final, Gracias");
    }
  };

  const buscarPersonal = () => {
    let form = new FormData();
    form.append("dedonde", "crearNomina");
    form.append("boton", "buscarPersonal");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xpersonal = res.data.variable1;
      setpersonal(xpersonal);
    });
  };

  React.useEffect(() => {
    //console.log(props.cedulaempleado);
    buscarPersonal();
  }, []);
  return (
    <div className="principal-pago row">
      <div className="contenedor-cxc col-12">
        <div className="titulo-cxc ">
          <p>CONSULTAR ANTIGUEDAD. </p>
        </div>
        {mensajealerta === true && (
          <Alert
            tituloMensaje={tituloM}
            cuerpoMensaje={cuerpoM}
            open={mensajealerta}
            handleClose={handleClose}
          />
        )}

        <div className="cont-listmedi row">
          <div className="cont-personal col-4">
            <label className="titulo-salario2">
              Seleccione un Empleado.
              <select
                id="cedulaNomina"
                name="cedulaNomina"
                value={
                  typeof data.cedulaNomina !== "undefined"
                    ? data.cedulaNomina
                    : ""
                }
                onChange={buscarPersonalI}
              >
                <option value="0">-- Seleccione Empleado --</option>
                {personal.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2 + " ==> " + item.variable3}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="cont-personal col-3">
            <label className="titulo-salario">
              Fecha Inicial.
              <input
                className="form-control"
                type="date"
                name="fechaInicio"
                id="fechaInicio"
                autoComplete="off"
                maxLength="10"
                onChange={onChange}
                value={
                  typeof data.fechaInicio !== "undefined"
                    ? data.fechaInicio
                    : ""
                }
              />
            </label>
          </div>
          <div className="cont-personal col-3">
            <label className="titulo-salario">
              Fecha Final.
              <input
                className="form-control"
                type="date"
                name="fechaFin"
                id="fechaFin"
                autoComplete="off"
                maxLength="10"
                onChange={onChange}
                value={
                  typeof data.fechaFin !== "undefined" ? data.fechaFin : ""
                }
              />
            </label>
          </div>
        </div>

        <div className="cont-personal col-12">
          <button className="botonN" onClick={buscarAntiguedad}>
            Consultar Antiguedad
          </button>
        </div>

        <div className="cont-listmedi row">
          <div className="titulo-cxc2 col-10 ">
            <p className="confi">RESULTADO ANTIGUEDAD. </p>
          </div>
        </div>
        <div className="cont-listmedi row">
          <div className="cont-personal col-4">
            <div className="cont-listmedi row">
              <div className="cont-personalT col-7">
                <label className="titulo-salario">ANTIGUEDAD.</label>
              </div>
              <div className="cont-personal2 col-5">
                <input
                  className="form-control"
                  type="text"
                  name="montoAn"
                  id="montoAn"
                  readOnly="readonly"
                  autoComplete="off"
                  maxLength="10"
                  onChange={onChange}
                  value={typeof montoAn !== "undefined" ? montoAn : ""}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="contenedorcedula1 col-12">
          <button className="boton" onClick={limpiar}>
            Limpiar Datos
          </button>
          <button className="boton" onClick={props.personalClose}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(Antiguedad);
