import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./planEvaluacion.css";
import Alert from "../../modal/alert";

class PlanEAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      idcurso: "",
      descripcion: "",
      trimestre: "",
      trimestre2: "",
      cantidadE: "",
      idplanEvaluacion: "",
      anoEscolar: "",
      anoEscolarN: moment().format("YYYY"),
      imaterias: [],
      icursos: [],
      imateriasA: [],
      ianoEscolar: [],
      idescripcionE: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      asientoOn: false,
      planAnualOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      idmateria: "",
      //anoEscolar: "",
      //idcurso: "",
      descripcion: "",
      trimestre: "",
      trimestre2: "",
      cantidadE: "",
      idplanEvaluacion: "",
      imateriasA: [],
      listaOn: false,
      modificarOn: false,
      asientoOn: false,
      planAnualOn: false,
    });
  };

  limpiar2(e) {
    this.setState({ idcurso: "" });
    this.limpiar();
    this.buscarPlanE();
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarPlanE(e.target.value);
  }

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
        anoEscolar: "",
        trimestre: "",
        trimestre2: "",
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
      });
    }
  };

  cerrarListaAnual = () => {
    //console.log(this.state.listaOn)
    if (this.state.planAnualOn) {
      this.setState({
        planAnualOn: false,
        anoEscolar: "",
        trimestre: "",
      });
    }
    if (!this.state.planAnualOn) {
      this.setState({
        planAnualOn: true,
      });
    }
  };

  consultar(e) {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "buscarPlanEI");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              idcurso: res.data.variable3,
              idmateria: res.data.variable4,
              descripcion: res.data.variable5,
              trimestre: res.data.variable8,
              cantidadE: res.data.variable6,
              idplanEvaluacion: res.data.variable7,
              modificarOn: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan Evaluacion.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  }

  modificar = () => {
    if (this.state.idmateria.length > 0 && this.state.idcurso.length > 0) {
      let data = new FormData();
      data.append("idmaestroE", this.state.idplanEvaluacion);
      data.append("cantidad", this.state.cantidadE);
      data.append("descripcion", this.state.descripcion);
      data.append("trimestre", this.state.trimestre);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "modificarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Plan de Evaluación.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  guardar = () => {
    if (
      this.state.idmateria.length > 0 &&
      this.state.idcurso.length > 0 &&
      this.state.descripcion.length > 0 &&
      this.state.trimestre.length > 0
    ) {
      let data = new FormData();
      data.append("idmateria", this.state.idmateria);
      data.append("idgrado", this.state.idcurso);
      data.append("cantidad", this.state.cantidadE);
      data.append("descripcion", this.state.descripcion);
      data.append("trimestre", this.state.trimestre);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "guardarPlanEvaluacion");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            //this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe seleccionar un Grado y una Materia.",
      });
    }
  };

  copiarPlan = () => {
    if (
      this.state.anoEscolar.length > 0 &&
      this.state.trimestre2.length > 0 &&
      this.state.trimestre.length > 0
    ) {
      let data = new FormData();
      data.append("trimestre1", this.state.trimestre);
      data.append("trimestre2", this.state.trimestre2);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "copiarPlanEvaluacion");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            //this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM:
          "Debe seleccionar el año escolar y los Trimestres que se piden.",
      });
    }
  };

  copiarPlanAnual = () => {
    if (this.state.anoEscolar.length > 0 && this.state.trimestre.length > 0) {
      let data = new FormData();
      data.append("trimestre1", this.state.trimestre);
      data.append("anoEscolarA", this.state.anoEscolar);
      data.append("anoEscolarN", this.state.anoEscolarN);
      data.append("boton", "copiarPlanEvaluacionAnual");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            //this.limpiar();
            this.buscarPlanE(this.state.idcurso);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias.",
              cuerpoM: res.data.variable2,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Asignar Materias.",
              cuerpoM: res.data.variable2,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM:
          "Debe seleccionar el año escolar y los Trimestres que se piden.",
      });
    }
  };

  eliminar = (e) => {
    if (parseFloat(e) > 0) {
      let data = new FormData();
      data.append("idmaestroE", e);
      data.append("boton", "eliminarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Materias.",
            cuerpoM: res.data.variable2,
          });
          this.limpiar();
          this.buscarPlanE(this.state.idcurso);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Materias.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM:
          "Debe existir un registro para poder eliminar la MAteria Asignada.",
      });
    }
  };

  buscarPlanE = (e) => {
    if (this.state.anoEscolar.length > 0) {
      let data = new FormData();
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("idgrado", e);
      data.append("boton", "buscarPlanE");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/planEvaluacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let ximateriasA = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              imateriasA: ximateriasA,
            });
          } else {
            this.setState({
              imateriasA: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Materias.",
        cuerpoM: "Debe Seleccionar el Año Escolar.",
        idcurso: "",
      });
    }
  };

  buscarSalones = () => {
    let data = new FormData();
    data.append("boton", "buscarSalones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;

        this.setState({
          icursos: xicursos,
        });
      });
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarMaterias();
    this.buscarSalones();
    this.buscarAnioEscolar();
    this.buscarDE();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>PLAN DE EVALUACION. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.asientoOn && (
            <div className="flotadorPe">
              <div className="flotadorPe1">
                <div className="contenedorcedula1f col-2">
                  <select
                    id="anoEscolar"
                    name="anoEscolar"
                    value={this.state.anoEscolar}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Año Escolar --</option>
                    {this.state.ianoEscolar.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1 col-3">
                  <select
                    id="trimestre"
                    name="trimestre"
                    value={this.state.trimestre}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Trimestre a Copiar --</option>
                    <option value="1">1ER. TRIMESTRE</option>
                    <option value="2">2DO. TRIMESTRE</option>
                    <option value="3">3ER. TRIMESTRE</option>
                  </select>
                </div>
                <div className="contenedorcedula1 col-3">
                  <select
                    id="trimestre2"
                    name="trimestre2"
                    value={this.state.trimestre2}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Trimestre Nuevo --</option>
                    <option value="1">1ER. TRIMESTRE</option>
                    <option value="2">2DO. TRIMESTRE</option>
                    <option value="3">3ER. TRIMESTRE</option>
                  </select>
                </div>
              </div>
              <div className="xcontenedorcedula1 col-12">
                <button className="boton" onClick={this.copiarPlan.bind(this)}>
                  Procesar.
                </button>
                <button className="boton" onClick={this.cerrarLista.bind(this)}>
                  Salir.
                </button>
              </div>
            </div>
          )}

          {this.state.planAnualOn && (
            <div className="flotadorPe">
              <div className="flotadorPe1">
                <div className="contenedorcedula1f col-2">
                  <select
                    id="anoEscolar"
                    name="anoEscolar"
                    value={this.state.anoEscolar}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Año Escolar Anterior --</option>
                    {this.state.ianoEscolar.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1 col-3">
                  <select
                    id="trimestre"
                    name="trimestre"
                    value={this.state.trimestre}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Trimestre a Copiar --</option>
                    <option value="1">1ER. TRIMESTRE</option>
                    <option value="2">2DO. TRIMESTRE</option>
                    <option value="3">3ER. TRIMESTRE</option>
                  </select>
                </div>
              </div>
              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.copiarPlanAnual.bind(this)}
                >
                  Procesar.
                </button>
                <button
                  className="boton"
                  onClick={this.cerrarListaAnual.bind(this)}
                >
                  Salir.
                </button>
              </div>
            </div>
          )}
          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="anoEscolar"
                  name="anoEscolar"
                  value={this.state.anoEscolar}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Año Escolar --</option>
                  {this.state.ianoEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="contenedorcedula1f col-5">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange2.bind(this)}
                >
                  <option>-- Seleccionar Grado --</option>
                  {this.state.icursos.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="contenedorcedula1f col-4">
                <select
                  id="idmateria"
                  name="idmateria"
                  value={this.state.idmateria}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Materia --</option>
                  {this.state.imaterias.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="contenedorp row">
              <div className="titulo-reprecxc row">
                <div className="contenedorcedula1 col-4">
                  <select
                    id="trimestre"
                    name="trimestre"
                    value={this.state.trimestre}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Seleccionar Trimestre --</option>
                    <option value="1">1ER. TRIMESTRE</option>
                    <option value="2">2DO. TRIMESTRE</option>
                    <option value="3">3ER. TRIMESTRE</option>
                  </select>
                </div>
                <div className="contenedorcedula1f col-4">
                  <select
                    id="descripcion"
                    name="descripcion"
                    value={this.state.descripcion}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Descripción Evaluación --</option>
                    {this.state.idescripcionE.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1x col-3">
                  <input
                    className="form-control"
                    type="text"
                    name="cantidadE"
                    id="cantidadE"
                    autoComplete="off"
                    placeholder="Cantidad Evaluaciones"
                    maxLength="2"
                    onChange={this.onChange.bind(this)}
                    value={this.state.cantidadE.toUpperCase()}
                  />
                </div>
              </div>
            </div>
            <div className="xcontenedorcedula1x col-12">
              <button
                className={this.state.modificarOn ? "botonOf" : "boton"}
                onClick={this.state.modificarOn ? "" : this.guardar.bind(this)}
              >
                Agregar
              </button>
              <button
                className={this.state.modificarOn ? "boton" : "botonOf"}
                onClick={
                  this.state.modificarOn ? this.modificar.bind(this) : ""
                }
              >
                Modificar
              </button>
              <button className="boton" onClick={this.cerrarLista.bind(this)}>
                Copiar Plan Eva.
              </button>
              <button
                className="boton"
                onClick={this.cerrarListaAnual.bind(this)}
              >
                Copiar Plan Eva. Año Anterior.
              </button>
              <button className="boton" onClick={this.limpiar2.bind(this)}>
                Limpiar
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>

          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc3">MATERIAS</th>
                    <th className="titucxc1">DESCRIPCION</th>
                    <th className="titucxc5">CANT/EVA.</th>
                    <th className="titucxc3xpe">
                      <span className="titulo-eliminar">
                        <span className="cerrarme5"></span>
                        <span className="cerrarme3"></span>
                      </span>
                    </th>
                  </tr>
                </thead>

                {this.state.imateriasA.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>
                      <td className="totalescxcx">{item.variable4}</td>
                      <td className="xcerr">
                        <span
                          className="cerrarme5"
                          onClick={this.consultar.bind(this, item.variable1)}
                        ></span>
                        <span
                          className="cerrarme1"
                          onClick={this.eliminar.bind(this, item.variable1)}
                        ></span>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PlanEAction;
