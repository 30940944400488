import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";
import { buscarCantidadChatSinLeer } from "../../API/Core";
import { buscarCantidadChatISinLeer } from "../../API/CoreI";
//componentes
import Alert from "../../Mensajes/alert";
import { saveCliente, saveToken, saveBotonMensaje, saveBotonMensajeI } from "../../accion/accion";
import "./login.css";

class LoginAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redireccionar: false,
      usuario: "",
      clave: "",
      message: "",
      formulario: "",
      mensajealerta: false,
      tokencorreo: "",
      tokenBackend: "",
      tokencorON: false,
      tokenpasoON: false,
      clavenueva: "",
      representante: "",
      anoEscolar: moment().format("YYYY"),
    };
  }

  limpiar = () => {
    this.setState({
      redireccionar: false,
      clave: "",
      message: "",
      formulario: "",
      mensajealerta: false,
      tokencorreo: "",
      tokenBackend: "",
      tokencorON: false,
      tokenpasoON: false,
      clavenueva: "",
      representante: "",
      anoEscolar: moment().format("YYYY"),
    });
  };

  registro(e) {
    this.setState({
      usuario: "",
      clave: "",
      nombre: "",
      correo: "",
      nivel: "",
    });
  }

  verificarUsuario = () => {
    if (this.state.clave.length > 0 && this.state.usuario.length > 0) {
     
        this.verificar();
     
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Inicio de Sesion",
        cuerpoM:
          "Favor Verificar el Usuario y la Clave deben tener información Gracias...",
      });
    }
  };
  verificarToken = () => {
    if (this.state.tokencorreo === this.state.tokenBackend) {
      this.setState({
        tokenpasoON: true,
        mensajealerta: true,
        tituloM: "Menu Inicio de Sesion",
        cuerpoM:
          "Su Clave o Token fue verificado, ahora puede introducir la clave para su Usuario gracias...",
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Inicio de Sesion",
        cuerpoM:
          "La Clave o Token que esta introduciendo no coincide con el que fue enviado por el Sistema intentelo de nuevo gracias...",
      });
    }
  };
  crearClaveUsuario = () => {
    if (this.state.clavenueva.length > 0 && this.state.usuario.length > 0) {
      let data = new FormData();
      data.append("usuario", this.state.usuario);
      data.append("clave", this.state.clavenueva);
      data.append("nombreRepresentante", this.state.representante);
      data.append("boton", "guardar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/usuario.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inicio de Sesion",
              cuerpoM:
                "Se registro correctamente a el usuario, ya puede ingresar con su Usuario y Clave",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inicio de Sesion",
              cuerpoM:
                "Ocurrio un error No se pudo agregar, verifique Gracias...",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Inicio de Sesion",
        cuerpoM:
          "Favor Verificar el Usuario y la Nueva Clave deben tener información Gracias...",
      });
    }
  };

  initData2 = async (e) => {
    let response = await buscarCantidadChatSinLeer(e);
    if (response.data.variable1) {
      this.props.saveBotonMensaje(true);
    } else {
      this.props.saveBotonMensaje(false);
    }
  };

  initData3 = async (e) => {
    let response = await buscarCantidadChatISinLeer(e);
    if (response.data.variable1) {
      this.props.saveBotonMensajeI(true);
    } else {
      this.props.saveBotonMensajeI(false);
    }
  };

  verificar(e) {
    if (this.state.clave.length > 0 && this.state.usuario.length > 0) {
      let data = new FormData();
      data.append("pas", this.state.clave);
      data.append("usu", this.state.usuario);
      data.append("tok", "kamiscake.com.ve");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/valida.php";
      axios
        .post(xurl, data, config)

        //console.log('https://fehensa.com/consultorio/valida.php/?pas=' + this.state.clave+'&usu=' + this.state.usuario+'&tok=kamiscake.com.ve')
        //axios.get('https://institutobilinguelasnaciones.com/terapias/backend/valida.php/?pas=' + this.state.clave+'&usu=' + this.state.usuario+'&tok=kamiscake.com.ve')
        .then((res) => {
          var clav = String(res.data.claveUsu);
          var username = res.data.nombreUsu;
          var nivel = String(res.data.nivelUsu);
          var tokenp = res.data.token;
          var idpersonal = res.data.idpersonal;
          let cedulaU = res.data.cedula;
          // console.log(clav);
          // console.log(username);
          // console.log(tokenp);
          if (
            (clav.length === 0) &
            (username.length === 0) &
            (tokenp === "NO")
          ) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inicio de Sesion",
              cuerpoM:
                "El Usuario No Exite, Debe registrarse Para Iniciar una Sesion...",
            });
          }
          if (clav === this.state.clave && tokenp !== "NO") {
            let anioE = this.state.anoEscolar;
            this.props.saveCliente(username, clav, nivel, anioE, cedulaU);
            this.props.saveToken(tokenp);
            //this.props.saveBotonMensaje(false);
            this.setState({
              redireccionar: true,
            });

            this.initData2(idpersonal);
            this.initData3(idpersonal);
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Inicio de Sesion",
              cuerpoM:
                "No se a podido Validar los datos Favor Verificar el Usuario y la Clave Gracias...",
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Inicio de Sesion",
        cuerpoM:
          "No se puede dejar campos en blanco debe verificar el Usuario o la Clave Gracias...",
        message: "Debe incluir el Usuario y Cliente",
      });
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  render() {
    //console.log("////"+this.state.redireccionar)
    //console.log("------"+this.state.formulario)

    if (this.state.redireccionar === true) {
      return <Redirect to="/" />;
    }

    if (this.state.formulario === "registrarse") {
      return <Redirect to="/registro" />;
    }

    if (this.state.formulario === "") {
      return (
        <div>
          <form onSubmit={this.handleSubmit}>
            {this.state.mensajealerta === true && (
              <Alert
                tituloMensaje={this.state.tituloM}
                cuerpoMensaje={this.state.cuerpoM}
                open={this.state.mensajealerta}
                handleClose={this.handleClose}
              />
            )}
            <div className="formulario">
              <h2>Inicio de Sesion</h2>
              <input
                value={this.state.usuario}
                onChange={this.onChange.bind(this)}
                type="text"
                name="usuario"
                id="usuario"
                autoComplete="off"
                placeholder="&#128101; Usuario"
              />
              {!this.state.tokencorON && (
                <input
                  value={this.state.clave}
                  onChange={this.onChange.bind(this)}
                  type="password"
                  name="clave"
                  id="clave"
                  placeholder="&#128272; Clave"
                />
              )}
              {!this.state.tokenpasoON && this.state.tokencorON && (
                <input
                  value={this.state.tokencorreo}
                  onChange={this.onChange.bind(this)}
                  type="text"
                  name="tokencorreo"
                  id="tokencorreo"
                  placeholder="&#128272; Token enviado"
                />
              )}
              {this.state.tokenpasoON && (
                <input
                  value={this.state.clavenueva}
                  onChange={this.onChange.bind(this)}
                  type="password"
                  name="clavenueva"
                  id="clavenueva"
                  placeholder="&#128272; Clave Nueva"
                />
              )}
              <div className="acciones">
                {!this.state.tokencorON && (
                  <button
                    className="aceptar"
                    onClick={this.verificarUsuario.bind(this)}
                  >
                    Aceptar
                  </button>
                )}
                {!this.state.tokenpasoON && this.state.tokencorON && (
                  <button
                    className="aceptar"
                    onClick={this.verificarToken.bind(this)}
                  >
                    Verificar Token
                  </button>
                )}
                {this.state.tokenpasoON && (
                  <button
                    className="aceptar"
                    onClick={this.crearClaveUsuario.bind(this)}
                  >
                    Crear Clave
                  </button>
                )}
              </div>

              {/* <p>clave: { this.props.usuario1.password}</p><br/>
                            <p>usuario: { this.props.usuario1.usuario }</p><br/>
                            <p>token: { this.props.token.token }</p> */}
              {/* <p>{JSON.stringify(this.state)}</p> */}
            </div>
          </form>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
  saveBotonMensajeI,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginAction);
