import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import AsyncSelect from "react-select/async";
import RingLoader from "react-spinners/ScaleLoader";
//componentes
import "./maternalVeranitoL.css";
import Alert from "../../modal/alert";

class MaternalVLAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idcurso: "",
      descripcionE: "",
      inputValue: "",
      cantidadE: "",
      idplanEvaluacion: "",
      idprofesor: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      anoEscolar: moment().format("YYYY"),
      idtareas: "",
      nombreEvaluacion: "",
      nota: "",
      trimestre: "",
      idarchivos: "",
      ianoEscolar: [moment().format("YYYY")],
      imaterias: [],
      icursos: [],
      imateriasA: [],
      iprofesor: [],
      ialumnos: [],
      ievaluaciones: [],
      idescripcionE: [],
      iactividades: [],
      listaOn: false,
      modificarOn: false,
      verplanillaOn: false,
      activarNotaOn: false,
      loading: false,
    };
  }

  limpiar = () => {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idmateria: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      idcurso: "",
      inputValue: "",
      descripcionE: "",
      cantidadE: "",
      idplanEvaluacion: "",
      cedulaAlumno: "",
      fechaE: moment().format("YYYY-MM-DD"),
      nota: "",
      idplanE: "",
      idtareas: "",
      nombreEvaluacion: "",
      nota: "",
      idarchivos: "",
      ievaluaciones: [],
      listaOn: false,
      modificarOn: false,
      activarNotaOn: false,
      loading: false,
    });
  };

  limpiar2(e) {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      idmateria: "",
      idcurso: "",
      idplanE: "",
      cedulaAlumno: "",
      nombreEvaluacion: "",
      nota: "",
      trimestre: "",
      idarchivos: "",
      imaterias: [],
      icursos: [],
      ialumnos: [],
      loading: false,
    });
  }
  limpiar3() {
    this.setState({
      responsabilidad: "",
      ordenyaseo: "",
      organizacion: "",
      confianza: "",
      iniciativa: "",
      cooperacion: "",
      respeto: "",
      loading: false,
    });
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "trimestre") this.setState({ descripcionE: "" });
    if (index === "idcurso") this.buscarPlanE2(e.target.value);
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.imaterias.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : 0;
    //console.log(index)
    let xcantidadE = this.state.imaterias[index].variable3;
    let xidmateria = this.state.imaterias[index].variable4;
    //console.log("cantidad: "+xcantidadE)
    //console.log("idmateria: "+xidmateria)
    this.consultarActividades(xidmateria);
    this.setState({
      [e.target.name]: e.target.value,
      cantidadE: xcantidadE,
      idmateria: xidmateria,
    });
  };

  onChange4(e, f) {
    let element = e.target;
    console.log(f);
    console.log(e.target.value);
    console.log(e.target.name);

    if (e.target.name !== "") {
      this.setState({ [e.target.name]: e.target.value });
      //console.log(e.target.value);
      console.log(e.target.name);
      let data = new FormData();

      data.append("cedulaAlumno", e.target.name);
      data.append("mesVeranito", "1");
      data.append("edad", f);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "guardarAutorizacion");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternalVeranito.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarAB();
          } else {
            this.setState({});
          }
        });
    }
  }

  onChange5(e, f) {
    let element = e.target;
    //console.log("edad" + f);

    //console.log(e.target.name)

    if (e.target.name !== "") {
      this.setState({ [e.target.name]: e.target.value });
      //console.log(e.target.value);
      console.log(e.target.name);
      let data = new FormData();

      data.append("cedulaAlumno", e.target.name);
      data.append("mesVeranito", "2");
      data.append("edad", f);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("boton", "guardarAutorizacion");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternalVeranito.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarAB();
          } else {
            this.setState({});
          }
        });
    }
  }

  onChange6 = (index, value) => {
    let nominaP_ = this.state.ialumnos; // Array.from(nominaPersonal);
    nominaP_[index].variable4 = value;
    this.setState({ ialumnos: nominaP_ });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarG() {
    let data = new FormData();
    data.append("boton", "buscarG");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/backend/habilitarAgenda.php",
      data: data,
    }).then((res) => {
      let xigrados = res.data.variable2;
      this.setState({
        icursos: xigrados,
      });
    });
  }

  guardar() {
    this.setState({ loading: true });
    let data = new FormData();
    data.append("idgrado", this.state.idcurso);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("arregloVeranito", JSON.stringify(this.state.ialumnos));
    data.append("boton", "guardar");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/backend/maternalVeranito.php",
      data: data,
    }).then((res) => {
      let xigrados = res.data.variable2;
      this.setState({
        //ialumnos: xigrados,
        loading: false,
      });
    });
  }



  buscarAB() {
    this.setState({ loading: true });
    let data = new FormData();
    data.append("idgrado", this.state.idcurso);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("trimestre", this.state.trimestre);
    data.append("boton", "buscarABGL");

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/backend/maternalVeranito.php",
      data: data,
    }).then((res) => {
      let xigrados = res.data.variable2;
      this.setState({
        ialumnos: xigrados,
        loading: false,
      });
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  notasTrabajo = (e) => {
    if (this.state.activarNotaOn) {
      this.setState({ activarNotaOn: false, idarchivos: "" });
    } else {
      this.setState({ activarNotaOn: true, idarchivos: e });
      //console.log(e);
    }
  };

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  calcularSumaValorEntrada = (index, value) => {
    console.log("Index " + index);
    console.log("value " + value);
    //console.log("deuda " + restaP);

    // if (value <= restaP) {
    let cxcRe_ = this.state.ialumnos; //Array.from(cxcRe);
    cxcRe_[index].variable5 = value;
    this.setState({ ialumnos: cxcRe_ });
    //}
  };

  componentDidMount() {
    //this.buscarG();
    this.buscarAnioEscolar();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>SELECCIONAR LISTADOS VERANITO.</p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="cont-listmedi row">
            <div className="cont-personal col-12">
              <div className="contenedorcedula1f col-2">
                <select
                  id="idcurso"
                  name="idcurso"
                  value={this.state.idcurso}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Listado Veranito --</option>
                  <option value="1">Lista de 4 a 6 Enero</option>
                  <option value="2">Lista de 4 a 6 Febrero</option>
                  <option value="3">Lista de 7 a 12 Enero</option>
                  <option value="4">Lista de 7 a 12 Febrero</option>
                </select>
              </div>
              <div className="contenedorcedula1f col-2">
                <span>Año del Veranito</span>
                <select
                  id="anoEscolar"
                  name="anoEscolar"
                  value={this.state.anoEscolar}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Año Veranito --</option>
                  {this.state.ianoEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-12">
                  <button className="boton" onClick={this.buscarAB.bind(this)}>
                    Consultar
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="cont-listmedix row">
            <div className="listcxcx col-12">
              <table className="tablacxcx" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">NOMBRE DEL ALUMNO</th>
                    <th className="titucxc5">EDAD A.</th>
                    <th className="titucxc5">SECCIÓN</th>
                    <th className="titucxc5">EDAD N.</th>
                  </tr>
                </thead>
                {this.state.ialumnos.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxcx">{item.variable2}</td>
                      <td className="totalescxcx">{item.variable3}</td>

                      <td className="totalescxcx" width="10">
                        <div className="contenedorcedula col-12">
                          <select
                            id={item.variable1}
                            name={item.variable1}
                            value={item.variable4}
                            //onChange={this.onChange6.bind(this)}
                            onChange={(e) =>
                              this.onChange6(i, e.target.value)
                            }
                          >
                            <option>-- Tipo de Sección --</option>
                            <option value="1">Sección A</option>
                            <option value="2">Sección B</option>
                          </select>
                        </div>
                      </td>
                      <td className="totalescxcx" width="10">
                        <div className="contenedorcedula col-12">
                          <input
                            className="form-control"
                            type="text"
                            name={item.variable1}
                            id={item.variable1}
                            autoComplete="off"
                            step="any"
                            onChange={(e) =>
                              this.calcularSumaValorEntrada(i, e.target.value)
                            }
                            onKeyPress={(e) =>
                              this.calcularSumaValorEntrada(i, e.target.value)
                            }
                            //value={item.valorEntrada}
                            value={item.variable5}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
                );
              </table>
            </div>
            <div className="cont-personal col-12">
              <div className="titulo-reprecxc row">
                <div className="xcontenedorcedula1x col-12">
                  <button className="boton" onClick={this.guardar.bind(this)}>
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(MaternalVLAction);
