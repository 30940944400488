import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import RingLoader from "react-spinners/ScaleLoader";

//componentes
import "./registroPagoNominaM.css";
import FeatherIcon from "feather-icons-react";
import ModificarMonto from "../modificarMonto/generarPagosC";
import Comprobante from "../comprobante/comprobante";
import Alert from "../../modal/alert";

class CxPAbonos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idprofesor: "",
      quincena: "",
      mes: "",
      monto3: "",
      monto4: "",
      monto5: "",
      banco: "",
      referencia: "",
      tpago: "",
      openvio: "",
      openvio2: "",
      tipoNomina: "",
      tNomina: "",
      fechaAprobada: "",
      fechaE: moment().format("YYYY-MM-DD"),
      anoEscolar: moment().format("YYYY"),
      ianoEscolar: [],
      iprofesor: [],
      pagosNomina: [],
      pagosNominaG: [],

      guardarOn: false,
      botonAsientoOn:false,
      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      activarPago: false,
      proveedores: [],
      cxpproveedor: [],
      nominaPendiente: [],
      nomiPagadas: [],
      listaNominaOn: false,
      listaNomina2On: false,
      nominaCreadaOn: false,
      idnomina: "",
      envioCorreoOn: false,
      idpagoR: "",
      loading: false,
      buscarNomina1On: false,
      buscarNomina2On: false,
      pagoGrupalOn: false,
      montoapagar: 0,
      xpaso: false,
      guardarReciOn: false,
      asientoOn: false,
      botonPasoOn:false,
    };
  }

  limpiar = () => {
    this.setState({
      idprofesor: "",
      quincena: "",
      mes: "",
      monto3: "",
      monto4: "",
      monto5: "",
      banco: "",
      referencia: "",
      tpago: "",
      openvio: "",
      openvio2: "",
      fechaE: moment().format("YYYY-MM-DD"),
      anoEscolar: moment().format("YYYY"),
      pagosNomina: [],
      pagosNominaG: [],

      btnEnviarOn: false,
      listaOn: false,
      mensajealerta: false,
      modificarSaldoOn: false,
      activarPago: false,
      nominaPendiente: [],
      // nomiPagadas: [],
      listaNominaOn: false,
      listaNomina2On: false,
      nominaCreadaOn: false,
      idnomina: "",
      envioCorreoOn: false,
      idpagoR: "",
      loading: false,
      buscarNomina1On: false,
      buscarNomina2On: false,
      pagoGrupalOn: false,
      montoapagar: 0,
      xpaso: false,
      guardarReciOn: false,
      asientoOn: false,
      botonAsientoOn:false,
      botonPasoOn:false,
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  enviarCorreoOn = () => {
    if (this.state.envioCorreoOn) {
      this.setState({
        envioCorreoOn: false,
        idpagoR: "",
      });
    } else {
      this.setState({
        envioCorreoOn: true,
      });
    }
  };

  cerrarListaNomina = () => {
    //console.log(listaOn)
    if (this.state.listaNominaOn) {
      this.setState({
        listaNominaOn: false,
      });
      this.limpiar();
    } else {
      this.setState({
        listaNominaOn: true,
      });
    }
    //console.log(listaOn);
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange1(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.value === "1") {
      this.borrarComprobanteT();
      this.setState({
        buscarNomina1On: true,
        buscarNomina2On: false,
      });
    }
    if (e.target.value === "2") {
      this.setState({
        buscarNomina1On: false,
        buscarNomina2On: true,
      });
    }
  }
  onChange2(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarPago(e.target.value);
  }
  onChange22(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarNominasPagadas(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.consultarPago2(e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange5(e, f) {
    //console.log(e.target.value)
    // console.log("hola"+e)
    //console.log(f.target.checked);
    let montoCheque = this.state.monto5;
    let saldoA = 0;
    let saldoT = 0;
    let saldoViene = 0;
    if (f.target.checked) {
      saldoViene = e;
    } else {
      saldoViene = e * -1;
    }
    saldoA = this.state.montoapagar;
    saldoT = saldoA + saldoViene;
    this.setState({
      [f.target.checked]: !f.target.checked,
      montoapagar: saldoT,
    });
    if (saldoT > montoCheque) {
      this.setState({
        xpaso: true,
        mensajealerta: true,
        tituloM: "Menu Registrar Pago Grupal.",
        cuerpoM: "El monto acumulado excede a el monto a Pagar.",
      });
    } else {
      this.setState({
        xpaso: false,
      });
    }

    //console.log(this.state.listaCorreo)
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  consultaNominaI = (e, f, g, h, i) => {
    let data = new FormData();
    data.append("idnomina", e);
    data.append("tipoNomina", f);
    data.append("montoDeuda", g);
    data.append("tipoNominaPersonal", i);
    data.append("boton", "consultaNominaI");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xpagosN = res.data.variable6;
        if (this.state.nominaCreadaOn) {
          this.setState({
            botonAsientoOn:false,
            activarPago: false,
            listaNomina2On: false,
            quincena: res.data.variable2,
            mes: res.data.variable3,
            anoEscolar: res.data.variable4,
            monto3: res.data.variable5,
            monto4: res.data.variable5,
            pagosNomina: xpagosN,
            tipoNomina: res.data.variable7,
            fechaAprobada: res.data.variable8,
            idnomina: res.data.variable9,
            idprofesor: res.data.variable10,
            tNomina: res.data.variable11,
          });
        } else {
          this.setState({
            botonAsientoOn:false,
            activarPago: true,
            listaNomina2On: false,
            quincena: res.data.variable2,
            mes: res.data.variable3,
            anoEscolar: res.data.variable4,
            monto3: res.data.variable5,
            monto4: res.data.variable5,
            pagosNomina: xpagosN,
            tipoNomina: res.data.variable7,
            fechaAprobada: res.data.variable8,
            idnomina: res.data.variable9,
            idprofesor: res.data.variable10,
            tNomina: res.data.variable11,
          });
        }
      } else {
        // let xcompras = res.data.variable2;
        // this.setState({
        //   pagosNomina: xcompras,
        // });
      }
    });
  };

  borrarComprobanteT = () => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("boton", "borrarComprobanteT");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {});
  };

  consultarNominasPagadas = (e) => {
    let data = new FormData();
    data.append("anoEscolar", e);
    data.append("boton", "consultaNominasPagadas");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xNpagadas = res.data.variable2;
        this.setState({
          nomiPagadas: xNpagadas,
        });
      } else {
        let xNpagadas = res.data.variable2;
        this.setState({
          nomiPagadas: xNpagadas,
        });
      }
    });
  };

  consultarPago = (e) => {
    let data = new FormData();
    data.append("anoEscolar", e);
    data.append("cedulaPersonal", this.state.idprofesor);
    data.append("boton", "consultarPagos");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      }
    });
  };

  consultarPagoGrupal = () => {
    let data = new FormData();
    data.append("referencia", this.state.referencia);
    data.append("boton", "consultarPagoGrupal");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      }
    });
  };

  consultarPago2 = (e) => {
    let data = new FormData();
    data.append("cedulaPersonal", e);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "consultarPago");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      } else {
        let xcompras = res.data.variable2;
        this.setState({
          pagosNomina: xcompras,
        });
      }
    });
  };

  eliminarPago = (e) => {
    let data = new FormData();
    data.append("idpagoNomina", e);
    data.append("boton", "eliminarPago");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          this.buscarNominaP();
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Registrar Pago Nomina.",
            cuerpoM: "Se Elimino el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Registrar Pago Nomina.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  pagoGrupal = () => {
    if (this.state.pagoGrupalOn) {
      this.setState({
        pagoGrupalOn: false,
        buscarNomina1On: true,
      });
    } else {
      this.setState({
        pagoGrupalOn: true,
        buscarNomina1On: false,
      });
    }
  };

  buscarNomina = () => {
    let data = new FormData();
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("openvio", this.state.openvio);
    data.append("boton", "buscarNominaM");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xnominaP = res.data.variable2;
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            nominaPendiente: xnominaP,
            listaNominaOn: true,
            //activarPago: true,
          });
          //this.limpiar();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Pago de Nomina.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  buscarNominaP = () => {
    let data = new FormData();
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("openvio", this.state.openvio);
    data.append("boton", "consultaNominaIM");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xnominaP = res.data.variable2;
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            pagosNomina: [],
            pagosNomina: xnominaP,
            //listaNominaOn: true,
            //nominaCreadaOn: true,
            //activarPago: true,
          });
          //this.limpiar();
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Pago de Nomina.",
            cuerpoM: "Ocurrio un error, Verifique por favor.",
          });
        }
      });
  };

  guardarCxPGrupal = () => {
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("quincena", this.state.quincena);
    data.append("tipoNominaPersonal", this.state.tNomina);
    data.append("tipoNomina", this.state.tipoNomina);
    data.append("fechaAprobada", this.state.fechaAprobada);
    data.append("mes", this.state.mes);
    data.append("monto", this.state.monto5);
    data.append("banco", this.state.banco);
    data.append("fechaRegistro", this.state.fechaE);
    data.append("referencia", this.state.referencia);
    data.append("tpago", this.state.tpago);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("grupal", JSON.stringify(this.state.nominaPendiente));
    data.append("boton", "guardarPagoGrupal");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.consultarPagoGrupal();
          //this.buscarNomina();

          this.setState({
            listaNominaOn: false,
            guardarReciOn: true,
            // idpagoR: res.data.variable2,
            // envioCorreoOn: true,
            // monto3: "",
            // monto4: "",
            // banco: "",
            // referencia: "",
            // tpago: "",
            // idprofesor: "",
            // activarPago: false,
            // listaNomina2On: true,
            // mensajealerta: true,
            // tituloM: "Menu Detalle de Compras.",
            // cuerpoM: "Se Guardo el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Pagos Grupal.",
            cuerpoM: "No se consiguieron registros",
          });
        }
      });
  };

  guardarCxP = () => {
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("idnomina", this.state.idnomina);
    data.append("cedulaPersonal", this.state.idprofesor);
    data.append("quincena", this.state.quincena);
    data.append("tipoNominaPersonal", this.state.tNomina);
    data.append("tipoNomina", this.state.tipoNomina);
    data.append("fechaAprobada", this.state.fechaAprobada);
    data.append("mes", this.state.mes);
    data.append("monto", this.state.monto3);
    data.append("monto4", this.state.monto4);
    data.append("banco", this.state.banco);
    data.append("fechaRegistro", this.state.fechaE);
    data.append("referencia", this.state.referencia);
    data.append("tpago", this.state.tpago);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("boton", "guardarPago");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/registroPagoNomina.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          //this.buscarNominaP();
          this.buscarNomina();
          //this.limpiar();
          this.setState({
            idpagoR: res.data.variable2,
            envioCorreoOn: true,
            monto3: "",
            monto4: "",
            banco: "",
            referencia: "",
            tpago: "",
            idprofesor: "",
            activarPago: false,
            listaNomina2On: true,
            mensajealerta: true,
            tituloM: "Menu Detalle de Compras.",
            cuerpoM: "Se Guardo el Registro con exito.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Detalle de Compras.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  datosCobro = (e, f, g, h) => {
    if (this.state.registrarCOn) {
      this.setState({ registrarCOn: false });
      this.limpiar2();
    } else {
      this.consultarDetalleC(e);
      this.setState({
        idcompra: e,
        registrarCOn: true,
        xbeneficiario: f,
        xmonto1: g,
        xfecha: h,
      });
    }
  };

  buscarProfesor = (e) => {
    let form = new FormData();
    form.append("openvio", e);
    form.append("boton", "buscarPersonal4");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/nomina.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  enviarReciboCorreo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idpagoNomina", e);
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboEmpleadoCorreo.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Envio de Recibo por Correo.",
              cuerpoM: "Se envio el correo de forma correcta.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Envio de Recibo por Correo.",
              cuerpoM: "Ocurrio un error, Verifique por favor.",
            });
          }
        });
    }
  };

  imprimirRecibo = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idpagoNomina", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboEmpleados.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  calcularSumaValorEntrada3 = (
    index,
    value,
    deudaItem,
    cedula,
    tnomina,
    nombre
  ) => {
    //console.log("valor " + value);
    //console.log(this.state.cxcM);
    //console.log(cedula);
    // console.log(tnomina);
    // console.log('element'+element );
    this.agregarItemAsiento(cedula, tnomina, nombre);

    if (value === true) {
      let cxcM_ = Array.from(this.state.nominaPendiente);

      cxcM_[index].valorEntrada3 = value;
      this.setState({ nominaPendiente: cxcM_ });
    }
    if (value === false) {
      let cxcM_ = Array.from(this.state.nominaPendiente);

      cxcM_[index].valorEntrada3 = "";
      this.setState({ nominaPendiente: cxcM_ });
    }

    let montoCheque = this.state.monto5;
    let saldoA = 0;
    let saldoT = 0;
    let saldoViene = 0;
    if (value === true) {
      saldoViene = deudaItem;
    } else {
      saldoViene = deudaItem * -1;
    }
    saldoA = this.state.montoapagar;
    saldoT = saldoA + saldoViene;
    this.setState({
      montoapagar: saldoT,
    });
    let xsaldot=0;
    xsaldot=saldoT - montoCheque;
    if (xsaldot >0.001) {
      this.setState({
        xpaso: true,
        mensajealerta: true,
        tituloM: "Menu Registrar Pago Grupal.",
        cuerpoM: "El monto acumulado excede a el monto a Pagar.",
      });
    } else {
      this.setState({
        xpaso: false,
      });
    }
  };

  salirGrupal = () => {
    this.setState({
      pagoGrupalOn: false,
      buscarNomina1On: true,
      guardarReciOn: false,
      listaNominaOn: true,
      fechaE: moment().format("YYYY-MM-DD"),
      tpago: "",
      banco: "",
      referencia: "",
      monto5: "",
      montoapagar: 0,
      pagosNomina: [],
      nominaPendiente: [],
    });
    this.buscarNomina();
  };

  asientoContable = () => {
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
        listaNominaOn: true,
      });
    }
    if (!this.state.asientoOn) {
      this.setState({
        asientoOn: true,
        listaNominaOn: false,
        botonPasoOn: true
      });
    }
  };
  asientoContable2 = () => {
    
    if (this.state.asientoOn) {
      this.setState({
        asientoOn: false,
        listaNominaOn: true,
      });
    }
    if (!this.state.asientoOn) {
      this.agregarItemAsientoIndividual();
      this.setState({
        asientoOn: true,
        listaNominaOn: false,
        botonAsientoOn:true,
      });
    }
  };

  agregarItemAsiento = (e, f, g) => {
    let data = new FormData();
    data.append("claveUsu", this.props.usuario1.password);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("monto5", this.state.monto5);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("fechaAprobada", this.state.fechaE);
    data.append("cedula", e);
    data.append("tipoNomina", f);
    data.append("nombre", g);

    data.append("boton", "agregarItemAsiento");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
      });
  };

  agregarItemAsientoIndividual2 = () => {
    let data = new FormData();
    data.append("idnomina", this.state.idnomina);
    data.append("claveUsu", this.props.usuario1.password);
    data.append("fechaAprobada", this.state.fechaAprobada);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("tipoNomina", this.state.tNomina);
    data.append("cedula", this.state.idprofesor);
    data.append("monto", this.state.monto3);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("monto5", this.state.monto3);
    data.append("anoEscolar", this.state.anoEscolar);
   
    data.append("boton", "agregarItemAsientoIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
      axios
      .post(xurl, data, config)

      .then((res) => {
        this.guardarCxP()
        this.setState({
          // asientoOn: true,
          // listaNominaOn: false,
          // botonAsientoOn:true,
          loading: false,
        });
      });
  };

  agregarItemAsientoIndividual = () => {
    let data = new FormData();
    data.append("idnomina", this.state.idnomina);
    data.append("claveUsu", this.props.usuario1.password);
    data.append("fechaAprobada", this.state.fechaAprobada);
    data.append("quincena", this.state.quincena);
    data.append("mes", this.state.mes);
    data.append("tipoNomina", this.state.tNomina);
    data.append("cedula", this.state.idprofesor);
    data.append("monto", this.state.monto3);
    data.append("referencia", this.state.referencia);
    data.append("banco", this.state.banco);
    data.append("monto5", this.state.monto3);
    data.append("anoEscolar", this.state.anoEscolar);
   
    data.append("boton", "agregarItemAsientoIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/comprobante.php";
      axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          asientoOn: true,
          listaNominaOn: false,
          botonAsientoOn:true,
          loading: false,
        });
      });
  };

  componentDidMount() {
    this.buscarProfesor();
    this.buscarAnioEscolar();
    this.consultarNominasPagadas(this.state.anoEscolar);
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc3 col-12">
          <div className="titulo-cxc "></div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.asientoOn && (
            <div className="flotadorPe">
              <div className="flotadorPe1">
                <Comprobante
                  claveUsuario={this.props.usuario1.password}
                  comprobanteClose={this.asientoContable}
                />
              </div>
            </div>
          )}

          {this.state.listaNominaOn && (
            <div className="cont-tablanomina2 row">
              <div className="tablanomina1 col-7">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Nominas Creadas Por Pagar</span>
                  <div className="contenedorcedula2 col-3">
                    <button
                      className={this.state.guardarOn ? "botonOf" : "boton"}
                      onClick={this.state.guardarOn ? "" : this.pagoGrupal}
                    >
                      {this.state.pagoGrupalOn === false
                        ? "Activar Pagos Grupal"
                        : "Desactivar Pagos Grupal"}
                    </button>
                  </div>
                  <div className="contenedorcedula2 col-3">
                    {this.state.pagoGrupalOn === true && (
                      <span>Monto {this.state.montoapagar}</span>
                    )}
                  </div>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={() => {
                      this.cerrarListaNomina();
                    }}
                  >
                    X
                  </span>
                </div>
                <span className="titulo-nomina"></span>

                <div className="cont-listnomi row">
                  <div className="listnomi col-12">
                    <table className="tablanomi" border="1">
                      <thead className="theadcreartabla">
                        <tr className="trcreartabla">
                          {/* <th className="xtitunomina1">item.</th> */}
                          <th className="xtitunomina1">Nombre Trabajador.</th>
                          <th className="xtitunomina1">Fecha Nomina.</th>
                          <th className="xtitunomina1">Quincena.</th>
                          <th className="xtitunomina3">Monto.</th>
                          <th className="xtitunomina3">Abonado.</th>
                          <th className="xtitunomina3">Deuda.</th>
                          <th className="xtitunomina3">Tipo Pago.</th>
                          {this.state.pagoGrupalOn && (
                            <th className="xtitunomina3">Seleccione.</th>
                          )}
                        </tr>
                      </thead>

                      <tbody className="evocuerpo1">
                        {this.state.nominaPendiente.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="nombrePersonal"
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8,
                                    item.variable10
                                  );
                                }}
                              >
                                {item.variable9}
                              </td>
                              <td
                                className="nombrePersonal"
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8,
                                    item.variable10
                                  );
                                }}
                              >
                                {item.variable4}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8,
                                    item.variable10
                                  );
                                }}
                              >
                                {item.variable3}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8,
                                    item.variable10
                                  );
                                }}
                              >
                                {item.variable2}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8,
                                    item.variable10
                                  );
                                }}
                              >
                                {item.variable5}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8,
                                    item.variable10
                                  );
                                }}
                              >
                                {item.variable6}
                              </td>
                              <td
                                width="20"
                                onClick={() => {
                                  this.consultaNominaI(
                                    item.variable1,
                                    item.variable7,
                                    item.variable6,
                                    item.variable8,
                                    item.variable10
                                  );
                                }}
                              >
                                {item.variable7}
                              </td>
                              {this.state.pagoGrupalOn && (
                                <td className="totalescxcx" width="10">
                                  <input
                                    className="chskfacRe"
                                    type="checkbox"
                                    name={item.variable1}
                                    id={item.variable1}
                                    onChange={(e) =>
                                      this.calcularSumaValorEntrada3(
                                        i,
                                        e.target.checked,
                                        item.variable6,
                                        item.variable8,
                                        item.variable10,
                                        item.variable9
                                      )
                                    }
                                    value={
                                      parseFloat(item.valorEntrada3) > 0
                                        ? item.valorEntrada3
                                        : ""
                                    }
                                    checked={item.valorEntrada3 > 0}

                                    //value={item.variable1}
                                    // onChange={this.onChange5.bind(
                                    //   this,
                                    //   m.variteiable6
                                    // )}

                                    // checked={item.variable7 === "SI"}
                                    // onChange={this.onChange5.bind(
                                    //   this,
                                    //   item.variable1
                                    // )}
                                  />
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.envioCorreoOn === true && (
            <div className="flotador1x5">
              <div className="flotador15">
                <div className="titulo-cxc ">
                  <p>ELIJA UNO OPCION. </p>
                </div>
                <div className="contenedorcedula1 col-12">
                  <button
                    className="boton"
                    onClick={this.imprimirRecibo.bind(this, this.state.idpagoR)}
                  >
                    Visualizar Recibo
                  </button>
                  <button
                    className="boton"
                    onClick={this.enviarReciboCorreo.bind(
                      this,
                      this.state.idpagoR
                    )}
                  >
                    Enviar Recibo por Correo
                  </button>

                  <button
                    className="boton"
                    onClick={this.enviarCorreoOn.bind(this)}
                  >
                    Salir
                  </button>
                </div>
              </div>
            </div>
          )}

          {this.state.buscarNomina1On === true && (
            <div className="form-group row">
              {this.state.activarPago === true ? (
                <div className="contenedorcedula col-4">
                  <select
                    id="idprofesor"
                    name="idprofesor"
                    value={this.state.idprofesor}
                    onChange={this.onChange3.bind(this)}
                  >
                    <option>-- Seleccionar Profesor --</option>
                    {this.state.iprofesor.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable3}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                ""
              )}

              <div className="contenedorcedula col-2">
                <select
                  id="quincena"
                  name="quincena"
                  value={this.state.quincena}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Quincena --</option>
                  <option value="1"> 1ra. Quincena</option>
                  <option value="2"> 2da. Quincena</option>
                </select>
              </div>

              <div className="contenedorcedula col-3">
                <select
                  id="mes"
                  name="mes"
                  value={this.state.mes}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Mes de Nomina --</option>
                  <option value="1"> Enero.</option>
                  <option value="2"> Febrero.</option>
                  <option value="3"> Marzo.</option>
                  <option value="4"> Abril.</option>
                  <option value="5"> Mayo.</option>
                  <option value="6"> Junio.</option>
                  <option value="7"> Julio.</option>
                  <option value="8"> Agosto.</option>
                  <option value="9"> Septiembre.</option>
                  <option value="10"> Octubre.</option>
                  <option value="11"> Noviembre.</option>
                  <option value="12"> Diciembre.</option>
                </select>
              </div>

              <div className="contenedorcedula col-2">
                <select
                  id="anoEscolar"
                  name="anoEscolar"
                  value={this.state.anoEscolar}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Año Escolar --</option>
                  {this.state.ianoEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}

          {this.state.buscarNomina2On === true && (
            <div className="form-group row">
              <div className="contenedorcedula col-2">
                <select
                  id="anoEscolar"
                  name="anoEscolar"
                  value={this.state.anoEscolar}
                  onChange={this.onChange22.bind(this)}
                >
                  <option>-- Año Escolar --</option>
                  {this.state.ianoEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </div>
              {this.state.anoEscolar.length > 0 && (
                <div className="contenedorcedula col-2">
                  <select
                    id="quincena"
                    name="quincena"
                    value={this.state.quincena}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Seleccionar Fecha --</option>
                    {this.state.nomiPagadas.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable1}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          )}

          {this.state.buscarNomina1On === false &&
            this.state.buscarNomina2On === false &&
            this.state.pagoGrupalOn === false && (
              <div className="form-group row">
                <div className="row">
                  <div className="cont-personal col-12">
                    <div className="radio">
                      <label className="container">
                        Por Pagar
                        <input
                          type="radio"
                          value="1"
                          name="openvio2"
                          checked={this.state.openvio2 === "1"}
                          onChange={this.onChange1.bind(this)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="radio">
                      <label className="container">
                        Ya pagadas
                        <input
                          type="radio"
                          value="2"
                          name="openvio2"
                          checked={this.state.openvio2 === "2"}
                          onChange={this.onChange1.bind(this)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {this.state.pagoGrupalOn === true ? (
            <div className="form-group row">
              <div className="contenedorcedula1f col-2">
                <select
                  id="tpago"
                  name="tpago"
                  value={this.state.tpago}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Forma de Pago --</option>
                  <option value="1"> Transferencia.</option>
                  <option value="2"> Efectivo.</option>
                  <option value="3"> Cheque.</option>
                </select>
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="date"
                  name="fechaE"
                  id="fechaE"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.fechaE.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula col-3">
                <select
                  id="banco"
                  name="banco"
                  value={this.state.banco}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Banco --</option>
                  <option value="1"> Banco BAC</option>
                  <option value="2"> Banco BANESCO</option>
                  <option value="5"> Banco GENERAL</option>
                  <option value="3"> Efectivo</option>
                  <option value="4"> Otros</option>
                </select>
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="text"
                  name="referencia"
                  id="referencia"
                  autoComplete="off"
                  placeholder="Referencia"
                  maxLength="200"
                  onChange={this.onChange.bind(this)}
                  value={this.state.referencia.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="text"
                  name="monto5"
                  id="monto5"
                  autoComplete="off"
                  placeholder="Monto Grupal"
                  maxLength="10"
                  onChange={this.onChange.bind(this)}
                  value={this.state.monto5}
                />
              </div>
              {this.state.xpaso === false &&
                this.state.guardarReciOn === false && (
                  <div className="contenedorcedula2 col-12">
                    {/* {parseFloat(this.state.montoapagar.toFixed(2)) ===
                      parseFloat(this.state.monto5) && ( */}
                      {this.state.botonPasoOn &&
                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={
                          this.state.guardarOn ? "" : this.guardarCxPGrupal
                        }
                      >
                        Guardar Recibos
                      </button>
                    }
                    <button
                      className={this.state.guardarOn ? "botonOf" : "boton"}
                      onClick={this.state.guardarOn ? "" : this.asientoContable}
                    >
                      Asiento Contable
                    </button>
                  </div>
                )}
              {this.state.guardarReciOn === true && (
                <div className="contenedorcedula2 col-12">
                  <button
                    className={this.state.guardarOn ? "botonOf" : "boton"}
                    onClick={this.state.guardarOn ? "" : this.salirGrupal}
                  >
                    Salir a Pagos Multiple
                  </button>
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          {this.state.activarPago === true ? (
            <div className="form-group row">
              <div className="contenedorcedula1f col-2">
                <select
                  id="tpago"
                  name="tpago"
                  value={this.state.tpago}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Forma de Pago --</option>
                  <option value="1"> Transferencia.</option>
                  <option value="2"> Efectivo.</option>
                  <option value="3"> Cheque.</option>
                </select>
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="date"
                  name="fechaE"
                  id="fechaE"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.fechaE.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula col-3">
                <select
                  id="banco"
                  name="banco"
                  value={this.state.banco}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccionar Banco --</option>
                  <option value="1"> Banco BAC</option>
                  <option value="2"> Banco BANESCO</option>
                  <option value="5"> Banco GENERAL</option>
                  <option value="3"> Efectivo</option>
                  <option value="4"> Otros</option>
                </select>
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="text"
                  name="referencia"
                  id="referencia"
                  autoComplete="off"
                  placeholder="Referencia"
                  maxLength="200"
                  onChange={this.onChange.bind(this)}
                  value={this.state.referencia.toUpperCase()}
                />
              </div>
              <div className="contenedorcedula col-2">
                <input
                  className="form-control"
                  type="text"
                  name="monto3"
                  id="monto3"
                  autoComplete="off"
                  placeholder="Monto"
                  maxLength="10"
                  onChange={this.onChange.bind(this)}
                  value={this.state.monto3}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="botones row">
            <div className="contenedorcedula2 col-12">
              {this.state.activarPago === false ||
              this.state.listaNomina2On === true
                ? this.state.buscarNomina1On === true && (
                    <button
                      className={this.state.guardarOn ? "botonOf" : "boton"}
                      onClick={this.state.guardarOn ? "" : this.buscarNomina}
                    >
                      Nomina por Pagar
                    </button>
                  )
                : ""}

              {this.state.activarPago === false ||
              this.state.listaNomina2On === true
                ? this.state.buscarNomina2On === true && (
                    <button
                      className={this.state.guardarOn ? "botonOf" : "boton"}
                      onClick={this.state.guardarOn ? "" : this.buscarNominaP}
                    >
                      Nominas Pagadas
                    </button>
                  )
                : ""}

              {this.state.activarPago === true ? (
                <button
                  className={this.state.botonAsientoOn === false ? "botonOf" : "boton"}
                  onClick={this.state.botonAsientoOn === false ? "" : this.agregarItemAsientoIndividual2}
                >
                  Guardar
                </button>
              ) : (
                ""
              )}
              {this.state.activarPago === true && this.state.pagoGrupalOn === false && (
                <button
                  className="boton"
                  onClick={this.agregarItemAsientoIndividual}
                >
                  Asiento Contable
                </button>
               )} 
              {this.state.pagoGrupalOn === false && (
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Limpiar
                </button>
              )}
              {this.state.pagoGrupalOn === false && (
                <button className="boton" onClick={this.props.personalClose}>
                  Salir
                </button>
              )}
            </div>
          </div>

          <div className="cont-listmedi row">
            <div className="listcxc col-12">
              <table className="tablacxc" border="1">
                <thead>
                  <tr>
                    <th className="titucxc1">NOMBRE TRABAJADOR</th>
                    <th className="titucxc1">QUINCENA</th>
                    <th className="titucxc2X">MES</th>
                    <th className="titucxc1">MONTO</th>
                    <th className="titucxc1">BANCO</th>
                    <th className="titucxc1">REFERENCIA</th>
                    <th className="titucxc1">TIPO PAGO</th>
                    <th className="titucxc3p" width="10">
                      <FeatherIcon
                        icon="delete"
                        size="30px"
                        stroke="#999"
                        color="red"
                      />
                    </th>
                    <th className="titucxc3p" width="10">
                      <FeatherIcon
                        icon="printer"
                        size="30px"
                        stroke="#999"
                        color="green"
                      />
                    </th>
                    <th className="titucxc3p" width="10">
                      <FeatherIcon
                        icon="mail"
                        size="30px"
                        stroke="#999"
                        color="green"
                      />
                    </th>
                  </tr>
                </thead>
                {this.state.pagosNomina.map((item, i) => {
                  return (
                    <tr
                      className={i % 2 === 0 ? "odd" : "odd"}
                      key={item.variable1}
                      id="tabla"
                    >
                      <td className="totalescxc" width="10">
                        {item.variable8}
                      </td>
                      <td className="totalescxc" width="10">
                        {item.variable2}
                      </td>
                      <td className="renglon" width="10">
                        {item.variable3}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable4}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable5}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable6}
                      </td>
                      <td className="totalescxc1" width="10">
                        {item.variable7}
                      </td>
                      <td className="titucxc3p" width="10">
                        <FeatherIcon
                          icon="delete"
                          size="30px"
                          stroke="#999"
                          color="red"
                          onClick={this.eliminarPago.bind(this, item.variable1)}
                        />
                      </td>
                      <td className="titucxc3p" width="10">
                        <FeatherIcon
                          icon="printer"
                          size="30px"
                          stroke="#999"
                          color="green"
                          onClick={this.imprimirRecibo.bind(
                            this,
                            item.variable1
                          )}
                        />
                      </td>
                      <td className="titucxc3p" width="10">
                        <FeatherIcon
                          icon="mail"
                          size="30px"
                          stroke="#999"
                          color="green"
                          onClick={this.enviarReciboCorreo.bind(
                            this,
                            item.variable1
                          )}
                        />
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxPAbonos);
