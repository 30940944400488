import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";
import { connect } from "react-redux";

//componentes
import "./porcentajeMD.css";
import Alert from "../../modal/alert";
import EvaluarTareas from "../evaluarTareas/index";
import CalificarTareas from "../calificarTareas/calificarTareas";
import PublicarNotas from "../publicarNotas/index";

class PorcentajeM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iconcepto: [],
      listaOn: false,
      verplanillaOn: false,
      mensajealerta: false,
      cedulaAlumno: "",
      nombreAlumno: "",
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      fechaHasta: "",
      idevaluacion: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcionE: "",
      ocultar: "",
      idactividad: "",
      idtareas: "",
      idarchivos: "",
      nombreEvaluacion: "",
      banderag: false,
      botonguardarOn: false,
      botonmodiOn: false,
      elimi: false,
      activarNotaOn: false,
      activarEvaluacionOn: false,
      iactividades: [],
      imaterias: [],
      icursos: [],
      ialumnos: [],
      iprofesor: [],
      ifechaPlani: [],
      ifechaplani: [],
      idescripcionE: [],
      ianoEscolar: [moment().format("YYYY")],
      iporcentaje: [],
      trimestre: "",
      tipocierre: "NO",
      porcentaje: "",
    };
  }

  limpiar = () => {
    this.setState({
      listaOn: false,
      verplanillaOn: false,
      cedulaAlumno: "",
      nombreAlumno: "",
      fecha: "",
      textoevo: "",
      anoEscolar: moment().format("YYYY"),
      idprofesor: "",
      idcurso: "",
      idmateria: "",
      idfechaPlani: "",
      fechaDesde: "",
      tipoPlanificacion: "",
      idplanificacion: "",
      idplani: "",
      descripcionE: "",
      trimestre: "",
      ocultar: "",
      idactividad: "",
      idarchivos: "",
      nombreEvaluacion: "",
      idevaluacion: "",
      botonguardarOn: false,
      botonmodiOn: false,
      mensajealerta: false,
      elimi: false,
      activarNotaOn: false,
      activarEvaluacionOn: false,
      tipocierre: "NO",
      porcentaje: "",
      ialumnos: [],
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  onChange1(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        tipocierre: "SI",
      });
    } else {
      this.setState({
        tipocierre: "NO",
      });
    }
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "trimestre") {
      this.setState({
        idevaluacion: "",
      });
    }
    if (index === "idcurso") {
      this.setState({
        idevaluacion: "",
        idmateria: "",
        ialumnos: [],
        cedulaAlumno: "",
        iporcentaje: [],
      });
      this.buscarAlumnos(e.target.value);
    }
    if (index === "idmateria") {
      this.setState({
        idevaluacion: "",
        cedulaAlumno: "",
        iporcentaje: [],
      });
    }
    if (index === "idevaluacion") {
      this.consultar(e.target.value);
      this.setState({
        cedulaAlumno: "",
        iporcentaje: [],
      });
    }
    if (index === "cedulaAlumno") this.consultarx(e.target.value);
  }

  onChange3 = (e) => {
    let idplane = e.target.value;
    let itemfind = this.state.iactividades.find((e) => e.variable1 === idplane);
    let index =
      typeof itemfind !== "undefined"
        ? this.state.iactividades.indexOf(itemfind)
        : 0;
    //console.log(index)
    let nombreA = this.state.iactividades[index].variable5;
    //console.log("nombre Actividad: "+nombreA)
    //console.log("idmateria: "+xidmateria)
    this.setState({
      [e.target.name]: e.target.value,
      nombreEvaluacion: nombreA,
    });
  };

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosGD");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  consultar(e) {
    this.setState({ iporcentaje: [] });
    let data = new FormData();
    data.append("idgrado", this.state.idcurso);
    data.append("idmateria", this.state.idmateria);
    data.append("idevaluacion", e);
    data.append("trimestre", this.state.trimestre);
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("boton", "buscar2");
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/backend/porcentajeMateriaD.php",
      data: data,
    }).then((res) => {
      let xiporcentaje = res.data.variable2;
      this.setState({
        iporcentaje: xiporcentaje,
      });
    });
  }
  consultarx(e) {
    this.setState({ iporcentaje: [] });
    let data = new FormData();
    data.append("idgrado", this.state.idcurso);
    data.append("idmateria", this.state.idmateria);
    data.append("idevaluacion", this.state.idevaluacion);
    data.append("trimestre", this.state.trimestre);
    data.append("cedulaAlumno", e);
    data.append("boton", "buscar2");
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/backend/porcentajeMateriaD.php",
      data: data,
    }).then((res) => {
      let xiporcentaje = res.data.variable2;
      this.setState({
        iporcentaje: xiporcentaje,
      });
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarMaterias = () => {
    let data = new FormData();
    data.append("boton", "buscarMaterias");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximaterias = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            imaterias: ximaterias,
          });
        } else {
          this.setState({
            imaterias: [],
          });
        }
      });
  };

  agregar = () => {
    let data = new FormData();
    data.append("idgrado", this.state.idcurso);
    data.append("idmateria", this.state.idmateria);
    data.append("idevaluacion", this.state.idevaluacion);
    data.append("porcentaje", this.state.porcentaje);
    data.append("usuario", this.props.usuario1.password);
    data.append("trimestre", this.state.trimestre);
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("boton", "guardar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/porcentajeMateriaD.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultar(this.state.idevaluacion);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Porcentaje de Materia.",
            cuerpoM: "Se agrego el porcentaje correctamente.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Porcentaje de Materia.",
            cuerpoM: "Ocurrio un Error o ya Existe Verifique por favor.",
          });
        }
      });
  }; 

  copiarPorcentajes = () => {
    if (
      this.state.idcurso.length > 0 &&
      this.state.idmateria.length > 0 &&
      this.state.cedulaAlumno.length > 0 &&
      this.state.trimestre.length > 0
    ) {
      let data = new FormData();
      data.append("idgrado", this.state.idcurso);
      data.append("idmateria", this.state.idmateria);
      data.append("usuario", this.props.usuario1.password);
      data.append("trimestre", this.state.trimestre);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("boton", "copiarPorcentajes");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/porcentajeMateriaD.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              cedulaAlumno: "",
              mensajealerta: true,
              tituloM: "Menu Porcentaje de Materia.",
              cuerpoM: "Se Copiaron los Porcentaje Correctamente.",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Porcentaje de Materia.",
              cuerpoM:
                "Ocurrio un Error, ya Existen los Porcentajes, Verifique por favor.",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Porcentaje de Materia.",
        cuerpoM: "Debe llenar todos lo que se pide, Verifique por favor.",
      });
    }
  };

  // buscarCurso = () => {
  //   let data = new FormData();
  //   data.append("boton", "buscarSalones");

  //   let config = {
  //     headers: {
  //       "Content-Type": "text/html",
  //     },
  //   };
  //   const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/materia.php";
  //   axios
  //     .post(xurl, data, config)

  //     .then((res) => {
  //       let xicursos = res.data.variable2;

  //       this.setState({
  //         icursos: xicursos,
  //       });
  //     });
  // };

  buscarCurso = () => {
    let data = new FormData();
    data.append("boton", "buscarCurso2");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/planificacion.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xicursos = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            icursos: xicursos,
          });
        } else {
          this.setState({
            icursos: [],
          });
        }
      });
  };

  eliminar = (e) => {
    let data = new FormData();
    data.append("idporcentaje", e);
    data.append("boton", "eliminar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/porcentajeMateriaD.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.consultar(this.state.idevaluacion);
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Porcentaje de Materia.",
            cuerpoM: "Se Elimino el porcentaje correctamente.",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Porcentaje de Materia.",
            cuerpoM: "Ocurrio un Error, No se pudo eliminar el registro.",
          });
        }
      });
  };

  buscarDE = () => {
    let data = new FormData();
    data.append("boton", "buscarDE");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/descripcionEva.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xidescripcionE = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            idescripcionE: xidescripcionE,
          });
        } else {
          this.setState({
            idescripcionE: [],
          });
        }
      });
  };

  notasTrabajo = (e) => {
    if (this.state.activarNotaOn) {
      this.setState({ activarNotaOn: false, idarchivos: "" });
    } else {
      this.setState({ activarNotaOn: true, idarchivos: e });
      //console.log(e);
    }
  };

  handleEditorChange(textoevo, editor) {
    this.setState({ textoevo });
  }
  componentDidMount() {
    //this.consultar();
    this.buscarMaterias();
    this.buscarCurso();
    this.buscarDE();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>PORCENTAJE FINAL POR MATERIA DIAGNOSTICO. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="idcurso"
                      name="idcurso"
                      value={this.state.idcurso}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Grado --</option>
                      {this.state.icursos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idmateria"
                      name="idmateria"
                      value={this.state.idmateria}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Materia --</option>
                      {this.state.imaterias.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="idevaluacion"
                      name="idevaluacion"
                      value={this.state.idevaluacion}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Tipo Evaluacion --</option>
                      {this.state.idescripcionE.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-4">
                    <select
                      id="cedulaAlumno"
                      name="cedulaAlumno"
                      value={this.state.cedulaAlumno}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Alumno --</option>

                      {this.state.ialumnos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                      {/* <option value="">TODOS LOS ALUMNOS</option> */}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <input
                      className="form-control"
                      type="text"
                      name="porcentaje"
                      id="porcentaje"
                      autoComplete="off"
                      placeholder="Porcentaje"
                      maxLength="5"
                      onChange={this.onChange.bind(this)}
                      value={this.state.porcentaje}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="botones col-12">
                  <div className="acciones">
                    <button
                      className={
                        !this.state.botonguardarOn ? "boton" : "botonOf"
                      }
                      onClick={
                        !this.state.botonguardarOn
                          ? this.agregar.bind(this)
                          : ""
                      }
                    >
                      Agregar
                    </button>

                    <button
                      className={
                        !this.state.botonguardarOn ? "boton" : "botonOf"
                      }
                      onClick={this.copiarPorcentajes.bind(this)}
                    >
                      Copiar Porcentaje de Neurotipicos a Diagnostico
                    </button>

                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
              <div className="cont-listmedix row">
                <div className="listcxcx col-12">
                  <table className="table table-sm table-hover" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc3n">GRADO</th>
                        <th className="titucxc3n">MATERIA</th>
                        <th className="titucxc3n">T/EVALUACION</th>
                        <th className="titucxc3f">%</th>
                        <th className="titucxc3xpe">
                          <span className="titulo-eliminar">
                            <span className="cerrarme3"></span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    {this.state.iporcentaje.map((item, i) => {
                      return (
                        <tr
                          className={i % 2 === 0 ? "odd" : "odd"}
                          key={item.variable1}
                          id="tabla"
                        >
                          <td className="cuerpo1ex">{item.variable2}</td>
                          <td className="cuerpo1ex">{item.variable3}</td>
                          <td className="cuerpo1ex">{item.variable4}</td>
                          <td className="titulo3ex">{item.variable5}</td>
                          <td className="titucxc3xpe">
                            <span
                              className="cerrarme3"
                              onClick={this.eliminar.bind(this, item.variable1)}
                            ></span>
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(PorcentajeM);
