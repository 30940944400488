import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./mensualidadGM.css";
import Alert from "../../modal/alert";

class CxC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensajealerta: false,
      codigo3: "",
      codigo4: "",
      anoEscolar: moment().format("YYYY"),
      dia: [],
      mes: [],
      anio: [],
      generarMes: false,
    };
  }

  limpiar = () => {
    this.setState({
      mensajealerta: false,
      codigo3: "",
      codigo4: "",
      generarMes: false,
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  handleClose = () => {
    this.setState({ 
      mensajealerta: false,
      generarMes: false,
    });
  };
  handleGenerarMes = () => {
    this.setState({
      mensajealerta: false,
      generarMes: false,
    });
    this.generarMensualidad();
    //console.log('generarMensualidad')
  };

  mesaGenerarMaternal = () => {
    let data = new FormData();
    data.append("boton", "mesaGenerarMaternal");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Mes de Mensualidad a Crear.",
          cuerpoM: res.data.variable1,
          generarMes: true,
        });
      });
  };

  generarMensualidad = () => {
    let data = new FormData();
    data.append("mesEntrada", this.state.codigo3);
    data.append("anioEntrada", this.state.codigo4);
    data.append("anoEscolar", this.state.anoEscolar);
    data.append("anioEscolar", this.props.usuario1.anioE);
    data.append("boton", "generarMensualidadGeneralMaternal");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let nalumno = res.data.variable2;
          nalumno.map((item, i) => {
            axios.post(
              "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reciboPago.php/?numeroRecibo=" +
                item.variable1
            );
          });
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Generar Mensualidad General Maternal.",
            cuerpoM: "Se cargaron las Mensualidades a las CxC, gracias...",
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Generar Mensualidad General Maternal.",
            cuerpoM:
              "Ocurrio un problema para generar las Mensualidades a las CxC, Verifique por favor.",
          });
        }
      });
  };

  cargarFechas() {
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/terapias/backend/fecha.php";
    axios.post(xurl).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  componentDidMount() {
    this.cargarFechas();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>GENERAR MENSUALIDAD GENERAL MATERNALES. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
            tituloMensaje={this.state.tituloM}
            cuerpoMensaje={this.state.cuerpoM}
            open={this.state.mensajealerta}
            handleClose={this.handleClose}
            generarMes={this.state.generarMes}
            handleEliminar={this.handleGenerarMes}
          />
          )}

          <div className="contenedorp row">
            <div className="titulo-planillai col-12">
              <p>
                PARA GENERAR LA MENSUALIDAD GENERAL DEBE PRESIONAR EL BOTON
                GENERAR MENSUALIDAD.{" "}
              </p>
            </div>
            {/* <div className="fechas col-12">
              <select
                id="codigo3"
                name="codigo3"
                value={this.state.codigo3}
                onChange={this.onChange.bind(this)}
              >
                <option>--Mes--</option>
                {this.state.mes.map((item, i) => {
                  return (
                    <option key={item.variable1} value={item.variable1}>
                      {item.variable2}
                    </option>
                  );
                })}
              </select>
              <select
                id="codigo4"
                name="codigo4"
                value={this.state.codigo4}
                onChange={this.onChange.bind(this)}
              >
                <option>--Año--</option>
                {this.state.anio.map((item, i) => {
                  return (
                    <option key={item.variable1} value={item.variable1}>
                      {item.variable1}
                    </option>
                  );
                })}
              </select>
            </div> */}
            <div className="xcontenedorcedula1 col-12">
              <button
                className="boton"
                onClick={this.mesaGenerarMaternal.bind(this)}
              >
                Generar Mensualidad
              </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxC);
